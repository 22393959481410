import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { SoftSkill } from '../../../shared/models/soft-skill';

@Injectable({
  providedIn: 'root'
})
export class SoftSkillService extends ApiService {

  protected prefix(): string {
    return 'softSkills';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(softSkill: SoftSkill): Observable<any> {
    return this.put(softSkill);
  }

  doDelete(softSkill: SoftSkill): Observable<any> {
    return this.delete(softSkill.id);
  }

  isExist(id): Observable<any> {
    return this.getOne(id).pipe(
      map(data => data.id === id),
    );
  }

  getAllForDatatable(): Observable<SoftSkill[]> {
    return this.getAll().pipe(
      map(items => items.map((item: SoftSkill) => {
          return this.convertFromApi(item);
        }),
      ));
  }

  convertFromApi(item:any){
    return {
      id: item.id,
      name: item.name,
      category: item.category,
      archiving: item.archivingDate ? new Date(item.archivingDate): null,
    };
  }


}
