import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-fm-annual-report-button',
  templateUrl: './fm-annual-report-button.component.html',
  styleUrls: ['./fm-annual-report-button.component.scss']
})
export class FmAnnualReportButtonComponent implements OnInit {

  @Input()
  annualReportNotification: string;

  @Output()
  handleClick = new EventEmitter<Event>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(event) {
    this.handleClick.emit(event);
  }

}
