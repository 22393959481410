<div *ngIf="user$ | async as user">
  <div class="fm-top-card">
    <app-person-card [user]="user" [module]="module" light="true"></app-person-card>
  </div>

  <div class="fm-buttons">
    <app-fm-button [attr.data-testid]="'profile_button_back'" type="text-gradient" btnText="RETOUR" (click)="handleBack()"></app-fm-button>
    <app-fm-button [attr.data-testid]="'profile_button_update'" type="text-gradient" btnText="METTRE À JOUR" (click)="handleUpdate()"></app-fm-button>
  </div>
  <!--  TODO: display-soft-skills-->
  <app-fm-profile [user]="user" [displaySoftSkills]="displaySoftSkills"></app-fm-profile>
</div>
