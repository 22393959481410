import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Certification} from '../../../../../shared/models/certification';
import {FormInlineComponent} from '../../../../../shared/components/form-inline/form-inline.component';
import {MessageService, SelectItem} from 'primeng/api';
import {UserCertificationService} from '../../../../../core/services/user-certification-services/user-certification.service';
import {CertificationState, CertificationStateModel} from '../../../store/certifications.state';
import {
  GetCertifications,
  AddCertification,
  ClearSnapshotCertification,
  DeleteCertification,
  MakeSnapshotCertification,
  RestoreSnapshotCertification,
  UpdateCertification
} from '../../../store/certifications.actions';
import {CertificationIconService} from '../../../../../core/services/certification-icon-services/certification-icon.service';
import {CertificationIcon} from '../../../../../shared/models/certification-icon';
import {Constants} from '../../../../../shared/constants/constants';

@Component({
  selector: 'app-view-certifications',
  templateUrl: './view-certifications.component.html',
  styleUrls: ['./view-certifications.component.scss']
})
export class ViewCertificationsComponent implements OnInit {

  @Select(CertificationState.getCertifications) certifications$: Observable<Certification[]>;

  @ViewChild(FormInlineComponent)
  private formCertification: FormInlineComponent;

  @Input()
  showAddForm: boolean;

  titleForm = 'Ajouter une certification';

  private stateSnapshot: CertificationStateModel;
  private itemToDelete: Certification;

  modal: any = {};
  showModal = false;
  disableCreationSave = false;

  inputForm: any = [];

  certificationDataColumns: any[];
  emptyMessage = 'Aucune certification enregistrée';

  icons: CertificationIcon[] = [];
  iconsOptions: SelectItem[] = [];
  /* certificationIcons = Constants.CERTIFICATION_ICONS; */

  constructor(private messageService: MessageService,
              private certificationIconService: CertificationIconService,
              private userCertificationService: UserCertificationService,
              private store: Store) {
    this.store.dispatch(new GetCertifications());
  }

  ngOnInit(): void {
    this.certificationIconService.getAll().subscribe(
      result => {
        result.map(item => {
          this.iconsOptions.push({
            label: item.name,
            value: {...item},
            icon: 'fm-icon-' + item.name
          });
          item.icon = 'fm-icon-' + item.name;
          this.icons.push({...item});
        });
        this.initDatatable();
        this.initForm();
      },
      (e) => this.displayError(e)
    );
  }

  initDatatable() {
    this.certificationDataColumns = [
      {
        type: 'select',
        field: 'icon',
        subField: 'name',
        label: '',
        icon: 'certification',
        options: this.iconsOptions,
        optionIcon: true,
        optionOnlyIcon: true,
        sort: false,
        className: 'fm-dropdown-icon'
      },
      {
        type: 'text',
        field: 'name',
        label: 'Nom',
        font: 'semibold',
        sort: true,
        maxLength: Constants.NAME_SIZE_MAX,
      },
      {
        type: 'text',
        field: 'organization',
        label: 'Organisme',
        font: 'regular',
        sort: true,
        maxLength: Constants.ORGANIZATION_SIZE_MAX,
      },
      {
        type: 'text',
        field: 'description',
        label: 'Description',
        font: 'regular',
        sort: true,
        maxLength: Constants.DESCRIPTION_SIZE_MAX,
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: true,
      },
      {
        type: 'action',
        action: 'edit',
      },
      {
        type: 'action',
        action: 'delete',
      },
    ];
  }

  initForm() {
    this.inputForm = [
      {
        key: 'nameIcon',
        typeForm: 'dropdown',
        fieldForm: 'icon',
        labelForm: 'Icon',
        optionsForm: this.icons,
        placeHolder: 'Icon',
        icon: true,
        onlyIcon: true,
        requiredForm: true,
        className: 'fm-dropdown-icon'
      },
      {
        key: 'nameCertification',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Certification',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.NAME_SIZE_MAX,
      },
      {
        key: 'nameOrganization',
        typeForm: 'text',
        fieldForm: 'organization',
        labelForm: 'Organisme',
        requiredForm: false,
        placeHolder: 'Organisme',
        maxLength: Constants.ORGANIZATION_SIZE_MAX,
      },
      {
        key: 'nameDescription',
        typeForm: 'text',
        fieldForm: 'description',
        labelForm: 'Description',
        requiredForm: false,
        placeHolder: 'Description',
        maxLength: Constants.DESCRIPTION_SIZE_MAX,
      }
    ];
  }

  handleCreateItem(certification: Certification) {
    if (!this.errorsValidation(certification)) {
      this.store.dispatch(new AddCertification(certification)).subscribe(
        () => {
          this.displayMessage('create');
          this.formCertification.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  handleUpdateItem(certification: Certification) {
    this.disableCreationSave = true;
    const index = this.icons.findIndex(item => item.id === certification.icon.id);
    const iconName = this.iconsOptions[index].icon;
    this.icons.splice(index, 1);
    this.iconsOptions.splice(index, 1);
    this.iconsOptions.unshift({ label: certification.icon.name, value: certification.icon, icon: iconName });
    this.icons.unshift({id: certification.icon.id, name: certification.icon.name, icon: iconName});
    this.store.dispatch(new MakeSnapshotCertification());
  }

  handleSaveUpdateItem(certification: Certification) {
    if (!this.errorsValidation(certification)) {
      this.store.dispatch(new UpdateCertification(this.updateCertification(certification))).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.store.dispatch(new RestoreSnapshotCertification());
          this.store.dispatch(new ClearSnapshotCertification());
          this.disableCreationSave = false;
        }
      );
    }
  }

  handleCancelEditItem() {
    this.disableCreationSave = false;
    this.store.dispatch(new RestoreSnapshotCertification());
    this.store.dispatch(new ClearSnapshotCertification());
  }

  handleDeleteItem(id) {
    this.stateSnapshot = this.store.snapshot().certifications;
    this.itemToDelete = this.stateSnapshot.certifications.find((p) => p.id === id);
    this.userCertificationService.userCertificationExists(this.itemToDelete).subscribe(
      (response) => {
        if (response) {
          this.displayError('userCertificationExists');
        } else {
          this.displayModal('delete');
        }
      },
      (e) => this.displayError(e.status)
    );
  }

  handleModalConfirm() {
    this.deleteItem();
  }

  deleteItem() {
    this.store.dispatch(new DeleteCertification(this.itemToDelete)).subscribe(
      () => this.displayMessage('delete'),
      (e) => this.displayError(e.status)
    );
  }

  updateCertification(certification: Certification): Certification {
    return {
      id: certification.id,
      name: certification.name,
      archivingDate: certification.archivingDate
        ? new Date(certification.archivingDate).toISOString()
        : null,
      organization: certification.organization,
      description: certification.description,
      icon: certification.icon
    };
  }

  errorsValidation(certification) {
    let errors = false;
    if (certification.name === '') {
      this.displayError('name');
      errors = true;
    }
    if (!certification.icon) {
      this.displayError('icon');
      errors = true;
    }
    return errors;
  }

  displayModal(type) {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  displayMessage(type) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La certification a bien été sauvegardée.';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La certification a bien été sauvegardée.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'La certification a bien été supprimée.';
        break;
      }
    }
    this.messageService.add({ severity, summary, detail });
  }

  displayError(e) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Cette certification existe déjà.';
        break;
      case 'name':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner le nom de la certification.';
        break;
      case 'icon':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un pictogramme.';
        break;
      case 'userCertificationExists':
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Cette certification est attribuée à un utilisateur.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }
}
