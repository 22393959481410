<div class="fm-container">
  <p-overlayPanel #op (onHide)="toggleShowHide()" (onShow)="toggleShowHide()">
    <ng-template pTemplate>
      <div *ngIf="items.length === 0" class="fm-notif-header">
        <span class="fm-notif-empty">Aucune nouvelle notification</span>
      </div>
      <div class="fm-notif-body">
        <div *ngFor="let item of items; let index = index" class="fm-notif-box" [ngClass]="item.borderColor ? 'fm-blue-border' : 'fm-green-border'">
          <div *ngIf="item.type === 'member' || item.type === 'manager'" class="fm-notif-content-clickable" (click)="redirect(item.type, item.emitter.id)">
            <a>
              <span class="fm-notif-user" *ngIf="item.emitter">{{item.emitter.firstname}} {{item.emitter.lastname}} </span>
              <span>{{item.comment}}</span>
            </a>
          </div>
          <div *ngIf="item.type === 'managerValidationRequest'" class="fm-notif-content-clickable" (click)="redirect(item.type)">
            <a><span>{{item.comment}}</span></a>
          </div>
          <div *ngIf="item.type === 'annualReport'" class="fm-notif-content">
            <a><span>{{item.comment}}</span></a>
          </div>
          <div class="fm-notif-trash"><i class="fm-icon-trash" [attr.data-testid]="'notification_button_delete_' + (index + 1)" (click)="disableNotif(item)"></i></div>
        </div>
      </div>
      <div class="fm-notif-footer">
        <div class="fm-notif-history" (click)="openHistory()">
          <a>Voir l'historique des notifications</a>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>
  <span [attr.data-testid]="'notification_button_showHide'" (click)="op.toggle($event)" class="p-overlay-badge">
    <img src="../../../assets/img/icon_notification.svg" alt="">
    <span *ngIf="items.length > 0 && items.length <= maxBadgeNumber" class="p-badge">{{items.length}}</span>
    <span *ngIf="items.length > maxBadgeNumber" class="p-badge">{{maxBadgeNumber}}+</span>
  </span>
</div>
