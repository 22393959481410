import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  activeIndex: number;

  items: any = [
    { label: 'Synthèse', routerLink: '/team/summary/members' },
    { label: 'Entretiens', routerLink: '/team/reports' },
    { label: 'Demandes', routerLink: '/team/requests' },
  ];

  constructor(private router: Router) {
    router.events.subscribe(
      (event: NavigationStart) => {
        const routerLink = event.url;
        if (routerLink !== undefined) {
          this.items.forEach((item, activeIndex) => {
            if (item.routerLink === routerLink) {
              this.activeIndex = activeIndex;
            }
          });
        }
      },
      (e) => console.log(e)
    );
  }

  ngOnInit(): void {
  }
}
