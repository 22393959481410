import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AddTeamUser,
  ClearTeamUsers,
  DeleteTeamUser,
  FilterTeamUsers, FilterTeamValidation,
  GetTeamUsers, ResetFilterTeamValidation,
  UpdateTeamUserManager
} from '../../../store/team-users.actions';
import {Select, Store} from '@ngxs/store';
import {UserTeam} from '../../../../../shared/models/user-team';
import {MessageService} from 'primeng/api';
import {InterestService} from '../../../../../core/services/interest-services/interest.service';
import {LevelService} from '../../../../../core/services/level-services/level.service';
import {AuthenticationService} from '../../../../../core/authentication/authentication.service';
import {UserService} from '../../../../../core/services/user-services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../../../shared/models/user';
import {Observable} from 'rxjs';
import {TeamUsersState} from '../../../store/team-users.state';
import {ClearTeamUser, UpdateTeamUser} from '../../../store/team.actions';
import {RoleType} from '../../../../../shared/models/role';
import {AddManagerRequest} from '../../../../../../stores/manager-requests/manager-request.action';

@Component({
  selector: 'app-summary-members',
  templateUrl: './summary-members.component.html',
  styleUrls: ['./summary-members.component.scss']
})
export class SummaryMembersComponent implements OnInit, OnDestroy {
  @Select(TeamUsersState.getUsers)
  public users$: Observable<User[]>;
  @Select(TeamUsersState.countUsersToValidate)
  public countUsersToValidate$: Observable<number>;
  @Select(TeamUsersState.getManager)
  public currentManager$: Observable<User>;
  @Select(TeamUsersState.getLoading)
  public loading$: Observable<boolean>;

  public tableColumns: any[];
  public firstWeekday: string;
  public lastWeekDay: string;
  public module = 'summary';
  public userRHA: User;
  public showAddUser = false;
  public hasManagerRole: boolean;
  public emptyMessage = 'Aucun résultat';
  public results: any[];
  public titleAddUser = 'Intégrer une personne';
  public modal: any = {};
  public showModal = false;
  public mainManager: boolean;
  public addUser: UserTeam;
  public updateUser: User;
  public outUser: User;
  public typeModal: string;
  public validateQualifications = false;

  constructor(
    private messageService: MessageService,
    private interestService: InterestService,
    private levelService: LevelService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit(): void {
    this.hasManagerRole = this.authenticationService.getUserRoles().includes(RoleType.ROLE_MANAGER);

    this.store.dispatch(new GetTeamUsers()).subscribe(
      () => {
        this.initDatatable();
        this.displayWeekDate();
      },
      e => console.log(e)
    );
  }

  public initDatatable(): void {
    this.tableColumns = [
      // columns which displays space between first  and the others columns
      { type: 'empty', action: 'none' },
      {
        type: 'chips',
        field: 'customers',
        subField: 'customer',
        label: 'Clients',
        sort: true
      },
      {
        type: 'chips',
        field: 'userProfiles',
        subField: 'profile',
        interest: 'interest',
        level: 'level',
        label: 'Postes',
        sort: true
      },
      {
        type: 'chips',
        field: 'userSkills',
        subField: 'skill',
        interest: 'interest',
        level: 'level',
        label: 'Compétences',
        sort: true,
      },
      { type: 'action', action: 'cr', field: 'weeklyReports' },
      { type: 'action', action: 'out' },
      { type: 'button' },
    ];
  }

  public isMainManager(selectedUser: User): boolean {
    const team = selectedUser.mainTeam;
    const currentManager = this.store.snapshot().teamUsers.manager;

    return team !== null &&
      currentManager !== null &&
      team.mainManager &&
      team.manager.id === currentManager.id;
  }

  public createNewTeam(mainManager: boolean): void {
    const createNewTeam = {
      teamId: null,
      manager: { id: this.store.snapshot().teamUsers.manager.id },
      member: { id: this.addUser.memberId },
      mainManager
    };
    this.store.dispatch(new AddTeamUser(createNewTeam)).subscribe({
      next: () => this.displayMessage('create'),
      error: (e) => this.displayError(e.status)
    });
    this.addUser = null;
  }

  public updateMainManager(member: User, mainManager: boolean): void {
    const team = {
      teamId: member.currentTeam.teamId,
      manager: { id: member.currentTeam.manager.id },
      member: { id: member.id },
      mainManager
    };
    this.store.dispatch(new UpdateTeamUserManager(team)).subscribe({
      next: () => {
        const type = mainManager ? 'update-main-manager' : 'update-not-main-manager';
        this.displayMessage(type);

        const currentUser = this.store.snapshot().team.currentTeamUser;
        this.store.dispatch(new UpdateTeamUser(currentUser.id));
      },
      error: (e) => this.displayError(e.error?.status)
    });
  }

  public toggleShowAddUser(): void {
    this.showAddUser = !this.showAddUser;
  }

  public handleSearch(event: any): void {
    this.store.dispatch((new FilterTeamUsers(event.target.value)));
  }

  public handleList(event): void {
    this.emptyMessage = 'Aucun résultat';
    if (event !== '') {
      this.userService.getUsersTeamByCriteria(event).subscribe(
        (result) => {
          this.emptyMessage = result.length === 0 ? 'Aucun résultat' : '';
          this.results = result.map((user: UserTeam) => {
            user.memberFullname = user.memberLastname + ' ' + user.memberFirstname;
            return user;
          });
        },
        (e) => this.displayError(e.error?.status)
      );
    }
  }

  public handleSelection(user): void {
    if (user) {
      this.addUser = user;
      this.typeModal = 'add-user';
      this.displayModal();
    }
  }

  public handleModalConfirm(): void {
    switch (this.typeModal) {
      case 'add-user': {
        // Send notification request to admin
        const requestManager = {
          collaborator: { id: this.addUser.memberId },
          manager: { id: this.store.snapshot().teamUsers.manager.id }
        };
        this.store.dispatch(new AddManagerRequest(requestManager)).subscribe({
          next: () => {
            this.displayMessage('manager-request');
          },
          error: (e) => this.displayError(e.status)
        });
        break;
      }
      case 'out-user': {
        const team = { member: this.outUser };
        this.store.dispatch(new DeleteTeamUser(team)).subscribe({
          next: () => this.displayMessage('delete'),
          error: (e) => this.displayError(e.status)
        });
        this.outUser = null;
        break;
      }
      default: {
        break;
      }
    }
  }

  public handleModalCancel(): void {
    switch (this.typeModal) {
      case 'add-user': {
        this.createNewTeam(false);
        break;
      }
      case 'out-user': {
        this.outUser = null;
        break;
      }
      default: {
        break;
      }
    }
  }

  public handleProfileUser(user: User): void {
    this.router.navigateByUrl('/team/summary/show-profile/' + user.id);
  }

  public handleBackgroundUser(user: User): void {
    this.showAddUser = false;
    this.router.navigateByUrl('/team/summary/rha/' + user.id);
  }

  public handleGetOutUser(user): void {
    if (user) {
      this.outUser = user;
      this.typeModal = 'out-user';
      this.displayModal();
    }
  }

  public handleValidateQualifications(event): void {
    if (event.checked) {
      this.store.dispatch(new FilterTeamValidation());
      this.validateQualifications = true;
    } else {
      this.store.dispatch(new ResetFilterTeamValidation());
      this.validateQualifications = false;
    }
  }

  public displayWeekDate(): void {
    const curr = new Date(); // get current date
    const first = (curr.getDate() - curr.getDay()) + 1; // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day + 6
    this.firstWeekday = new Date(curr.setDate(first)).toUTCString();
    this.lastWeekDay = new Date(curr.setDate(last)).toUTCString();
  }

  public displayModal(): void {
    switch (this.typeModal) {
      case 'add-user': {
        const commonMessage = 'Souhaitez-vous vous déclarer en tant que manager de ' +
          '<strong>' + this.addUser?.memberFirstname + ' ' + this.addUser?.memberLastname + '</strong>' +
          ', et obtenir les droits de validation de son dossier de compétences ?';
        const additionalMessage = (this.addUser?.mainTeamId === null) ? '' :
          '\n <br/><br/><strong>' + this.addUser?.memberFirstname + ' ' + this.addUser?.memberLastname + '</strong>' +
          ' a pour manager <strong>' + this.addUser?.managerFirstname + ' ' + this.addUser?.managerLastname + '</strong>';
        const message = commonMessage + additionalMessage;
        this.modal.header = 'Ajouter dans l\'équipe';
        this.modal.content = message;
        this.modal.type = 'yesOrNo';
        break;
      }
      case 'out-user': {
        this.modal.header = 'Sortir de l\'équipe';
        this.modal.content = 'Voulez-vous sortir la personne <strong>' + this.outUser?.firstname + ' ' +
          this.outUser?.lastname + '</strong> de votre équipe ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  public displayMessage(type: string): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Intégration';
        detail = 'Intégration à l\'équipe réussie.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'Suppression de l\'équipe réussie.';
        break;
      }
      case 'update-old-team': {
        const managerFirstname = this.addUser ?
          this.addUser.managerFirstname :
          this.updateUser.mainTeam.manager.firstname;
        const managerLastname = this.addUser ?
          this.addUser.managerLastname :
          this.updateUser.mainTeam.manager.lastname;
        severity = 'success';
        summary = 'Mise à jour';
        detail = 'Le lien manager principal avec ' + managerFirstname + ' ' + managerLastname + ' a été supprimé.';
        break;
      }
      case 'manager-request': {
        severity = 'success';
        summary = 'Demande d\'ajout de collaborateur';
        detail = 'Votre demande a bien été envoyée et sera traitée par l\'administrateur.' ;
      }
    }
    this.messageService.add({severity, summary, detail});
  }

  public displayError(error: any): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (error) {
      case (401):
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Connexion perdue.';
        break;
      case (409):
        severity = 'error';
        summary = 'Demande de validation.';
        detail = 'Une demande est déjà en attente de validation par l\'administrateur.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur';
        detail = 'Une erreur est survenue. Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new ClearTeamUser());
    this.store.dispatch(new ClearTeamUsers());
  }
}
