<div class="fm-title">
  <h1>Synthèse</h1>
</div>

<div class="fm-step">
  <div class="fm-container">
    <div class="info-title">
      <h2 class="bloc-title"> 1.1 Informations générales</h2>
      <div class="fm-line"></div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Date de l'entretien :</h3>
      <span class="bloc-text">{{ interviewStartDate | date: 'dd/MM/yyyy' }}</span>
    </div>
  </div>
  <div class="fm-container">
    <div class="info-title">
      <h2 class="bloc-title"> 1.2 Collaborateur</h2>
      <div class="fm-line"></div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Nom :</h3>
      <span class="bloc-text">{{collaboratorName}}</span>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Prénom :</h3>
      <span class="bloc-text">{{collaboratorFirstname}}</span>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Ancienneté dans le groupe :</h3>
      <span class="bloc-text">{{companySeniority}}</span>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Poste :</h3>
      <span class="bloc-text">{{position}}</span>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Ancienneté dans le poste :</h3>
      <span class="bloc-text">{{positionSeniority}}</span>
    </div>
  </div>
  <div class="fm-container">
    <div class="info-title">
      <h2 class="bloc-title"> 1.3 Manager</h2>
      <div class="fm-line"></div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Nom :</h3>
      <span class="bloc-text">{{managerName}}</span>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Prénom :</h3>
      <span class="bloc-text">{{managerFirstname}}</span>
    </div>
  </div>
  <div class="fm-container">
    <div class="info-title">
      <h2 class="bloc-title"> 2. Bilan de l'année écoulée</h2>
      <div class="fm-line"></div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Collaborateur :</h3>
      <span *ngIf="collaboratorAssessment" class="bloc-text-comment" [innerHTML]="collaboratorAssessment"></span>
      <span *ngIf="!collaboratorAssessment" class="bloc-text-comment">{{emptyFieldCollaborator}}</span>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Manager :</h3>
      <span *ngIf="managerAssessment" class="bloc-text-comment" [innerHTML]="managerAssessment"></span>
      <span *ngIf="!managerAssessment" class="bloc-text-comment">{{emptyFieldManager}}</span>
    </div>
  </div>
  <div class="fm-container">
    <div class="info-title">
      <h2 class="bloc-title"> 3. Objectifs individuels</h2>
      <div class="fm-line"></div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label-padding">Niveau d'atteinte des objectifs de l'année écoulée :</h3>
      <div class="fm-eae-noAction">
        <app-datatable
          [data]="managerCurrentGoals"
          editMode="row"
          [columns]="currentGoalDataColumnsWithoutActions"
          [emptyMessage]="goalsEmptyMessage">
        </app-datatable>
      </div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label-padding">Objectifs pour l'année à venir :</h3>
      <div class="fm-eae-noAction">
        <app-datatable
          [data]="managerNextGoals"
          editMode="row"
          [columns]="nextGoalDataColumnsWithoutActions"
          [emptyMessage]="goalsEmptyMessage">
        </app-datatable>
      </div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Commentaire du collaborateur :</h3>
      <span *ngIf="collaboratorGoalComment" class="bloc-text-comment" [innerHTML]="collaboratorGoalComment"></span>
      <span *ngIf="!collaboratorGoalComment" class="bloc-text-comment">{{emptyFieldCollaborator}}</span>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Commentaire du manager :</h3>
      <span *ngIf="managerGoalComment" class="bloc-text-comment" [innerHTML]="managerGoalComment"></span>
      <span *ngIf="!managerGoalComment" class="bloc-text-comment">{{emptyFieldManager}}</span>
    </div>
  </div>
  <div class="fm-container">
    <div class="info-title">
      <h2 class="bloc-title"> 4. Compétences</h2>
      <div class="fm-line"></div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label-padding">Points forts collaborateur :</h3>
      <div class="fm-eae-noAction">
        <app-datatable
          [data]="collaboratorSkillsStrength"
          editMode="row"
          [columns]="skillsStrengthDataColumnsWithoutActions"
          [emptyMessage]="skillsStrengthEmptyMessage">
        </app-datatable>
      </div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label-padding">Points forts manager :</h3>
      <div class="fm-eae-noAction">
        <app-datatable
          [data]="managerSkillsStrength"
          editMode="row"
          [columns]="skillsStrengthDataColumnsWithoutActions"
          [emptyMessage]="skillsStrengthEmptyMessage">
        </app-datatable>
      </div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label-padding">Axes de développement collaborateur:</h3>
      <div class="fm-eae-noAction">
        <app-datatable
          [data]="collaboratorSkillsImprovement"
          editMode="row"
          [columns]="skillsImprovementDataColumnsWithoutActions"
          [emptyMessage]="skillsImprovementEmptyMessage">
        </app-datatable>
      </div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label-padding">Axes de développement manager :</h3>
      <div class="fm-eae-noAction">
        <app-datatable
          [data]="managerSkillsImprovement"
          editMode="row"
          [columns]="skillsImprovementDataColumnsWithoutActions"
          [emptyMessage]="skillsImprovementEmptyMessage">
        </app-datatable>
      </div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label-padding">Formations collaborateur:</h3>
      <div class="fm-eae-noAction">
        <app-datatable
          [data]="collaboratorSkillsCourseRequest"
          editMode="row"
          [columns]="skillsCourseRequestDataColumnsWithoutActions"
          [emptyMessage]="skillsCourseRequestEmptyMessage">
        </app-datatable>
      </div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label-padding">Formations manager :</h3>
      <div class="fm-eae-noAction">
        <app-datatable
          [data]="managerSkillsCourseRequest"
          editMode="row"
          [columns]="skillsCourseRequestDataColumnsWithoutActions"
          [emptyMessage]="skillsCourseRequestEmptyMessage">
        </app-datatable>
      </div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Commentaire du collaborateur :</h3>
      <span *ngIf="collaboratorSkillsComment" class="bloc-text-comment" [innerHTML]="collaboratorSkillsComment"></span>
      <span *ngIf="!collaboratorSkillsComment" class="bloc-text-comment">{{emptyFieldCollaborator}}</span>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Commentaire du manager :</h3>
      <span *ngIf="managerSkillsComment" class="bloc-text-comment" [innerHTML]="managerSkillsComment"></span>
      <span *ngIf="!managerSkillsComment" class="bloc-text-comment">{{emptyFieldManager}}</span>
    </div>
  </div>
  <div class="fm-container">
    <div class="info-title">
      <h2 class="bloc-title"> 5. Rémunération</h2>
      <div class="fm-line"></div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Collaborateur :</h3>
      <span *ngIf="collaboratorSalaryComment" class="bloc-text-comment" [innerHTML]="collaboratorSalaryComment"></span>
      <span *ngIf="!collaboratorSalaryComment" class="bloc-text-comment">{{emptyFieldCollaborator}}</span>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Manager :</h3>
      <span *ngIf="managerSalaryComment" class="bloc-text-comment" [innerHTML]="managerSalaryComment"></span>
      <span *ngIf="!managerSalaryComment" class="bloc-text-comment">{{emptyFieldManager}}</span>
    </div>
  </div>
  <div class="fm-container">
    <div class="info-title">
      <h2 class="bloc-title"> 6. Commentaire final</h2>
      <div class="fm-line"></div>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Collaborateur :</h3>
      <span *ngIf="collaboratorFinalComment" class="bloc-text-comment" [innerHTML]="collaboratorFinalComment"></span>
      <span *ngIf="!collaboratorFinalComment" class="bloc-text-comment">{{emptyFieldCollaborator}}</span>
    </div>
    <div class="info mb-2">
      <h3 class="bloc-label">Manager :</h3>
      <span *ngIf="managerFinalComment" class="bloc-text-comment" [innerHTML]="managerFinalComment"></span>
      <span *ngIf="!managerFinalComment" class="bloc-text-comment">{{emptyFieldManager}}</span>
    </div>
  </div>
</div>

<div class="fm-step">
  <div class="fm-container">
    <div class="info">
      <span class="bloc-text fm-mr-2">Synthèse de l'entretien finalisé le </span>
      <span class="bloc-validation fm-mr-2">{{ managerValidationDate | date: 'dd/MM/yyyy' }}</span>
      <span class="bloc-text-comment fm-mr-2">par</span>
      <span class="bloc-validation fm-mr-2">{{managerFirstname}} {{managerName | uppercase}}</span>
    </div>
  </div>
</div>

<div class="fm-step">
  <div class="fm-container">
    <div class="info">
      <span class="bloc-text-comment fm-mr-2">Synthèse de l'entretien finalisé le </span>
      <span class="bloc-text-bold fm-mr-2">{{ collaboratorValidationDate | date: 'dd/MM/yyyy' }}</span>
      <span class="bloc-text-comment fm-mr-2">par</span>
      <span class="bloc-text-bold fm-mr-2">{{collaboratorFirstname}} {{collaboratorName | uppercase}}</span>
    </div>
  </div>
  <div>
    <div class="fm-container">
      <div class="mt-4">
        <h3 class="bloc-text-comment w-250">Commentaire explicatif :</h3>
        <span [innerHTML]="collaboratorValidationComment"></span>
        <span *ngIf="!collaboratorValidationComment">Aucun commentaire</span>
      </div>
    </div>
  </div>
</div>

<div>
  <app-fm-button type="text-gradient" btnText="Ré-initialiser" (onClick)="handleReset()">
  </app-fm-button>
</div>

<app-modal
  [attr.data-testid]="'annual_report_modal_component'"
  header="Ré-initialisation de l'entretien annuel"
  type="radioButton"
  [radioButtonElements]="radioButtonElements"
  [(display)]="showModal"
  (eventModalConfirm)="handleModalConfirm($event)">
</app-modal>
