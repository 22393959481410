import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../core/authentication/authentication.service';
import { UserRoleService } from 'src/app/core/services/user-role-services/user.role.service';

export const PARAMETERS = 'Paramètres';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  items: MenuItem[];
  menuLogoutOptions: MenuItem[] = [];

  roles: any[];

  @Output()
  tabindex: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private authenticationService: AuthenticationService,
    private userRoleService: UserRoleService,
    private router: Router
  ) { }

  ngOnInit() {
    this.roles = this.authenticationService.getUserRoles();

    // Set menuLogout
    const optionsItems = [];
    if (this.roles.indexOf('ROLE_ADMIN') > -1) {
      optionsItems.push(
        {
          label: 'Entretiens annuels',
          command: () => this.handleAnnualReport(),
          icon: '',
        }
      );
    }
    optionsItems.push(
      {
        label: 'Déconnexion',
        command: () => this.handleLogout(),
        icon: 'fm-icon-out',
      }
    );
    this.menuLogoutOptions.push(
      {
        label: this.authenticationService.getAuthData().given_name,
        icon: 'fm-icon-ID',
        items: optionsItems
      },
    );
  }

  displayNavbarLink(path: string): boolean {
    if (this.roles !== undefined) {
      return this.userRoleService.isPathAllowedForUser(path, this.roles);
    }

    return false;
  }

  isActiveLink(path: string): boolean {
    return this.router.url.includes(`/${path}`);
  }

  handleLogout() {
    this.authenticationService.logout();
    this.router.navigateByUrl('/login');
  }

  handleAnnualReport() {
    this.router.navigateByUrl('/annualreport/list');
  }
}
