import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NavbarModule } from './modules/navbar/navbar.module';
import { ParametersModule } from './modules/parameters/parameters.module';
import { SkillState } from './modules/qualifications/store/skills.state';
import { ProfileState } from './modules/qualifications/store/profiles.state';
import { SpecialtyState } from './modules/qualifications/store/specialties.state';
import { TeamState } from './modules/team/store/team.state';
import { TeamProfileState } from './modules/team/store/team-profiles.state';
import { TeamSpecialtyState } from './modules/team/store/team-specialties.state';
import { TeamSkillState } from './modules/team/store/team-skills.state';
import { CustomerState } from './modules/customers/store/customers.state';
import { ProjectState } from './modules/customers/store/projects.state';
import { CertificationState } from './modules/formationrh/store/certifications.state';
import { CourseState } from './modules/formationrh/store/courses.state';
import { HomeModule } from './modules/home/home.module';
import { ActivityModule } from './modules/activity/activity.module';
import { TeamModule } from './modules/team/team.module';
import { ProfileModule } from './modules/profile/profile.module';
import { SoftSkillState } from './modules/formationrh/store/rh.state';
import { TeamCourseState } from './modules/team/store/team-courses.state';
import { TeamCertificationState } from './modules/team/store/team-certifications.state';
import { TeamSoftSkillState } from './modules/team/store/team-soft-skills.state';
import { TeamAssignmentState } from './modules/team/store/team-assignments.state';
import { AssignmentState } from './modules/activity/store/assignments.state';
import { DirectoryModule } from './modules/directory/directory.module';
import { DirectoryUsersState } from './modules/directory/store/directory-users.state';
import { DirectoryAssignmentState } from './modules/directory/store/directory-assigments.state';
import { TeamUsersState } from './modules/team/store/team-users.state';
import { UserProfileState } from './modules/profile/store/profile.state';
import { ProfileSkillState } from './modules/profile/store/profile-skills.state';
import { ProfileProfileState } from './modules/profile/store/profile-profiles.state';
import { ProfileSpecialtyState } from './modules/profile/store/profile-specialties.state';
import { ProfileSoftSkillState } from './modules/profile/store/profile-soft-skills.state';
import { ProfileCertificationState } from './modules/profile/store/profile-certifications.state';
import { ProfileCourseState } from './modules/profile/store/profile-courses.state';
import { EnvServiceProvider } from './core/env/env.service.provider';
import { TeamReportState } from './modules/team/store/team-reports.state';
import { RequestState } from '../stores/requests/request.state';
import { ReportState } from '../stores/reports/report.state';
import { AuthenticationState } from '../stores/authentication/authentication.state';
import { RequestModule } from './modules/request/request.module';
import { ManagerRequestState } from '../stores/manager-requests/manager-request.state';
import { AnnualReportModule } from './modules/annual-report/annual-report.module';
import { ActionState } from './modules/history/store/actions.state';
import { HistoryModule } from './modules/history/history.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    NavbarModule,
    HomeModule,
    HistoryModule,
    ParametersModule,
    TeamModule,
    ActivityModule,
    ProfileModule,
    DirectoryModule,
    RequestModule,
    AnnualReportModule,
    NgxsModule.forRoot([
      TeamUsersState,
      SkillState,
      ProfileState,
      SpecialtyState,
      TeamState,
      TeamProfileState,
      TeamSpecialtyState,
      TeamSkillState,
      TeamCourseState,
      TeamCertificationState,
      TeamSoftSkillState,
      TeamAssignmentState,
      CustomerState,
      ProjectState,
      CertificationState,
      CourseState,
      SoftSkillState,
      AssignmentState,
      DirectoryUsersState,
      DirectoryAssignmentState,
      UserProfileState,
      ProfileSkillState,
      ProfileProfileState,
      ProfileSpecialtyState,
      ProfileSoftSkillState,
      ProfileCertificationState,
      ProfileCourseState,
      TeamReportState,
      RequestState,
      ReportState,
      AuthenticationState,
      ManagerRequestState,
      ActionState
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: true })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [EnvServiceProvider],
  bootstrap: [AppComponent],
})
export class AppModule { }
