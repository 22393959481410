import { Component, OnInit } from '@angular/core';
import {SelectItem} from 'primeng/api';
import {Store} from '@ngxs/store';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';
import {RoleType} from '../../../../shared/models/role';
import {FilterManagerRequest, GetManagerRequest} from '../../../../../stores/manager-requests/manager-request.action';

@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss']
})
export class HierarchyComponent implements OnInit {

  typeData: SelectItem[] = [];

  typeDataSelected = 'Validation des demandes';

  showAddForm = false;

  isAdmin: boolean;

  constructor(private store: Store,
              private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.isAdmin = this.authenticationService.getUserRoles().includes(RoleType.ROLE_ADMIN);
    if (this.isAdmin){
      this.typeData = [
        { label: 'Validation des demandes', value: 'Validation des demandes' },
      ];
    } else {
      this.typeData = [];
    }
  }

  handleGlobalSearchChange(query) {
    if (this.typeDataSelected === 'Validation des demandes') {
        this.store.dispatch(new FilterManagerRequest(query));
        this.store.dispatch(new GetManagerRequest());
    }
  }
}
