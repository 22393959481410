export class SetCurrentTeamUser {
  static readonly type = '[Team] Set current user';
  constructor(public payload: number) {}
}

export class UpdateTeamUser {
  static readonly type = '[Team] Update user';
  constructor(public payload: number) {}
}

export class ClearTeamUser {
  static readonly type = '[Team] Clear user';
}
