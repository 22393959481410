import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AnnualReportComponent } from './annual-report/annual-report.component';
import { AnnualReportDetailsComponent } from './pages/annual-report-details/annual-report-details.component';
import { AnnualReportListComponent } from './pages/annual-report-list/annual-report-list.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [AnnualReportComponent, AnnualReportDetailsComponent, AnnualReportListComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ]
})
export class AnnualReportModule { }
