import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { User } from 'src/app/shared/models/user';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from '../../../../../core/authentication/authentication.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../../../../../core/services/project-services/project.service';
import { TeamState } from '../../../store/team.state';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportType } from 'src/app/shared/models/report-type';
import { Customer } from 'src/app/shared/models/customer';
import { Project } from 'src/app/shared/models/project';
import { CustomerService } from 'src/app/core/services/customer-service/customer.service';
import { ReportTypeService } from 'src/app/core/services/report-type-services/report-type.service';
import { UserLight } from 'src/app/shared/models/user-light';
import { UserService } from 'src/app/core/services/user-services/user.service';
import { AssignmentService } from 'src/app/core/services/assignment-service/assignment.service';
import { Assignement } from 'src/app/shared/models/assignement';
import { ReportService } from 'src/app/core/services/report-services/report.service';
import { DeleteAssignment } from 'src/app/modules/activity/store/assignments.actions';
import { Constants } from '../../../../../shared/constants/constants';

@Component({
  selector: 'app-reports-create',
  templateUrl: './reports-create.component.html',
  styleUrls: ['./reports-create.component.scss']
})
export class CreateTeamReportsComponent implements OnInit, OnDestroy {

  @Select(TeamState.getCurrentTeamUser)
  public user$: Observable<User>;

  currentUser: UserLight;
  reportUser: UserLight;
  newAssignment: Assignement = null;
  reportTypes: SelectItem[] = [];
  customers: SelectItem[] = [];
  projects: SelectItem[] = [];

  selectedReportType: ReportType;
  selectedCustomer: Customer;
  selectedProject: Project;
  selectedDate: string;
  selectedRating: number;
  selectedComment: number;
  selectedCustomerManager: string;

  selectedStartDate: string = null;
  selectedEndDate: string = null;

  needsClient = false;
  needsProject = false;
  needsDate = false;
  needsRating = false;
  needsComment = false;
  needsCustomerManager = false;
  isCommentViewable = false;

  public showModal = false;
  public modal: any = {};
  public typeModal: string;
  formData: FormGroup;
  freeAnswerMaxLength = 250;
  fr = Constants.CALENDAR_FR;


  private customerSubscription: Subscription = null;
  private projectSubscription: Subscription = null;
  private reportTypeSubscription: Subscription = null;
  private userSubscription: Subscription = null;
  private assignmentSubscription: Subscription = null;
  private authenticationServiceSubscription: Subscription = null;
  private reportSubscription: Subscription = null;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private reportService: ReportService,
    private customerService: CustomerService,
    private projectService: ProjectService,
    private reportTypeService: ReportTypeService,
    private userService: UserService,
    private assignmentService: AssignmentService,
    private messageService: MessageService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.formData = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.authenticationServiceSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = { id: currentUser.id };
      this.currentUser.firstname = currentUser.firstname;
      this.currentUser.lastname = currentUser.lastname;
    });
    const userId: number = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    this.userSubscription = this.userService.getTeamMember(userId).subscribe(
      (u) => this.reportUser = u,
      () => this.router.navigateByUrl('/pageNotFound'));

    this.reportTypeSubscription = this.reportTypeService.getAll().subscribe(
      reportTypes => {
        reportTypes.map(
          item => this.reportTypes.push({ label: item.name, value: item }));
      });

    this.formData.addControl('reportType', new FormControl(null, Validators.required));
    this.formData.addControl('customer', new FormControl(null, Validators.required));
    this.formData.addControl('project', new FormControl(null, Validators.required));
    this.formData.addControl('date', new FormControl(null, Validators.required));
    this.formData.addControl('rating', new FormControl(null, Validators.required));
    this.formData.addControl('customerManager', new FormControl(null, Validators.required));
    this.formData.addControl('report', new FormControl(null, Validators.required));
  }

  public handleTypeChoice() {

    this.resetChoice();

    this.selectedCustomer = null;
    this.selectedProject = null;
    this.selectedDate = null;
    this.selectedRating = null;
    this.selectedComment = null;
    this.selectedEndDate = null;
    this.selectedStartDate = null;
    this.selectedCustomerManager = null;

    this.newAssignment = null;

    this.projects = [];
    this.customers = [];

    if (this.selectedReportType.name === 'Point de suivi') {
      this.needsClient = true;
      this.customers.push({ label: 'CREATIVE Labs', value: { id: 6, name: 'CREATIVE Labs' } });
      this.customers.push({ label: 'CREATIVE Academy', value: { id: 7, name: 'CREATIVE Academy' } });
      this.customers.push({ label: 'CREATIVE Research', value: { id: 8, name: 'CREATIVE Research' } });
    }
    else if (this.selectedReportType.name === 'Suivi Trimestriel') {
      this.needsClient = true;
      this.customerSubscription = this.customerService.getAll().subscribe(
        customers => {
          customers.map(
            item => this.customers.push({ label: item.name, value: item }));
        });
    }
    else {
      this.showFieldsForCurrentReport();
    }

  }

  public handleClientChoice() {
    this.needsProject = false;
    this.showModal = false;
    this.needsDate = false;
    this.needsRating = false;
    this.needsCustomerManager = false;
    this.needsComment = false;

    this.selectedProject = null;
    this.selectedDate = null;
    this.selectedRating = null;
    this.selectedComment = null;
    this.selectedEndDate = null;
    this.selectedStartDate = null;
    this.selectedCustomerManager = null;

    this.newAssignment = null;
    this.projects = [];

    this.projectSubscription = this.projectService.getCustomerProjects(this.selectedCustomer.id).subscribe(
      projects => {
        this.projects = [];
        projects.map(
          item => this.projects.push({ label: item.name, value: item }));
        this.needsProject = true;
      });
  }

  public handleProjectChoice() {
    this.assignmentSubscription = this.assignmentService.coupleUserProjectExists(this.reportUser.id, this.selectedProject.id).subscribe(
      result => {
        if (result) {
          this.newAssignment = result;
        }
        else {
          this.displayModal();
        }
        this.showFieldsForCurrentReport();
      }
    );

  }

  public displayModal(): void {
    this.modal.header = 'Déclaration de Mission';
    this.modal.content = 'La mission n\'est pas déclarée dans le dossier de compétences!'
      + ' Cliquez sur confirmer afin de créer une mission pour ' + this.reportUser.fullname + '.'
      + ' Veuillez renseigner ultérieurement la date de démarrage de la mission.';
    this.modal.type = 'confirm';
    this.showModal = true;
  }

  public handleModalConfirm(): void {
    if (this.selectedStartDate) {
      const newAssignment = {
        id: 0,
        project: this.selectedProject,
        user: this.reportUser,
        startDate: this.selectedStartDate,
        endDate: this.selectedEndDate
      };
      this.assignmentSubscription.unsubscribe();
      this.assignmentSubscription = this.assignmentService.doPost(newAssignment).subscribe(
        (a) => {
          this.newAssignment = a;
          this.displayMessage('successAssignment');
        },
        (e) => {
          console.log(e.status);
        },
        () => {
          this.showModal = false;
        }
      );
    }
    else {
      this.displayMessage('startDateNeeded');
    }
  }

  public handleModalCancel(): void {
    this.resetAll();
  }

  public validateReport() {

    switch (this.selectedReportType.name) {
      case 'Suivi Trimestriel':
        {
          this.validateQuaterlyreport();
          break;
        }
      case 'Point de suivi':
        {
          this.validateMindsReport();
          break;
        }
    }
  }

  public cancelReport() {
    if (this.newAssignment) { this.store.dispatch(new DeleteAssignment(this.newAssignment)); }
    this.resetAll();
    this.router.navigateByUrl('/team/reports' );
  }

  public displayMessage(e) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 'incomplete':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner tous les champs du formulaire.';
        break;
      case 'customerNeeded':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Un Client Minds est obligatoire lorsque vous sélectionnez Point de suivi.';
        break;
      case 'projectNeeded':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Un Projet Minds est obligatoire lorsque vous sélectionnez Point de suivi.';
        break;
      case 'successAssignment':
        severity = 'success';
        summary = 'Formulaire envoyé';
        detail = 'La mission a été créée avec succès.';
        break;
      case 'successReport':
        severity = 'success';
        summary = 'Formulaire envoyé';
        detail = 'Le Compte Rendu d\'Entretien a été créé avec succès.';
        break;
      case 'startDateNeeded':
        severity = 'error';
        summary = 'Formulaire Incomplet';
        detail = 'Vous devez renseigner au moins une date de début de mission.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({ severity, summary, detail });
  }

  private showFieldsForCurrentReport() {
    if (this.selectedReportType.name === 'Suivi Trimestriel') {
      this.needsDate = true;
      this.needsRating = true;
      this.needsCustomerManager = true;
      this.needsComment = true;
    }
    else if (this.selectedReportType.name === 'Point de suivi') {
      this.needsDate = true;
      this.needsRating = true;
      this.needsComment = true;
    }
  }

  private resetChoice() {
    this.needsClient = false;
    this.needsProject = false;
    this.showModal = false;
    this.needsDate = false;
    this.needsRating = false;
    this.needsCustomerManager = false;
    this.needsComment = false;
  }

  private resetContent() {
    this.selectedReportType = null;
    this.selectedCustomer = null;
    this.selectedProject = null;
    this.selectedDate = null;
    this.selectedRating = null;
    this.selectedComment = null;
    this.selectedEndDate = null;
    this.selectedStartDate = null;
    this.selectedCustomerManager = null;

    this.newAssignment = null;

    this.projects = [];
    this.customers = [];
  }

  private resetAll() {
    this.resetChoice();
    this.resetContent();
  }

  private validateQuaterlyreport() {

    for (const i in this.formData.controls) {
      if (!this.formData.controls[i].valid) {
        this.formData.controls[i].markAsDirty();
      }
    }

    const allAnswers: boolean = this.formData.value.rating &&
      this.formData.value.customer &&
      this.formData.value.project &&
      this.formData.value.reportType &&
      this.formData.value.date &&
      this.formData.value.customerManager &&
      this.formData.value.report;

    let errors = 0;
    if (!allAnswers) {
      errors++;
      this.displayMessage('incomplete');
    }

    if (!this.formData.value.customer) {
      errors++;
      this.displayMessage('customerNeeded');
    }

    if (!this.formData.value.project) {
      errors++;
      this.displayMessage('projectNeeded');
    }

    if (!errors) {
      const quaterlyReport = {
        id: 0,
        date: this.selectedDate,
        type: this.selectedReportType,
        rating: this.selectedRating,
        comment: this.selectedComment,
        customerManager: this.selectedCustomerManager,
        collaborator: this.reportUser,
        interviewer: this.currentUser,
        assignment: this.newAssignment };
      this.reportSubscription = this.reportService.saveQuaterlyReport(quaterlyReport).subscribe(
        () => {
          this.displayMessage('successReport');
          this.router.navigateByUrl('/team/reports' );
        },
        (e) => console.log(e)
      );

      this.resetAll();
    }
  }

  private validateMindsReport() {
    this.selectedCustomerManager = null;
    this.formData.patchValue({ customerManager: null }, { onlySelf: true });
    this.formData.controls.customerManager.markAsPristine({ onlySelf: true });

    for (const i in this.formData.controls) {
      if (!this.formData.controls[i].valid && i !== 'customerManager') {
        this.formData.controls[i].markAsDirty();
      }
    }

    const allAnswers: boolean = this.formData.value.rating &&
      this.formData.value.customer &&
      this.formData.value.project &&
      this.formData.value.reportType &&
      this.formData.value.date &&
      this.formData.value.report;

    let errors = 0;
    if (!allAnswers) {
      errors++;
      this.displayMessage('incomplete');
    }

    if (!this.formData.value.customer) {
      errors++;
      this.displayMessage('customerNeeded');
    }

    if (!this.formData.value.project) {
      errors++;
      this.displayMessage('projectNeeded');
    }

    if (!errors) {
      const mindsReport = {
        id: 0,
        date: this.selectedDate,
        type: this.selectedReportType,
        rating: this.selectedRating,
        comment: this.selectedComment,
        collaborator: this.reportUser,
        interviewer: this.currentUser,
        assignment: this.newAssignment
      };
      this.reportSubscription = this.reportService.saveMindsReport(mindsReport).subscribe(
        () => {
          this.displayMessage('successReport');
          this.router.navigateByUrl('/team/reports' );
        },
        (e) => console.log(e)
      );

      this.resetAll();
    }
  }

  ngOnDestroy() {
    if (this.projectSubscription) { this.projectSubscription.unsubscribe(); }
    if (this.reportTypeSubscription) { this.reportTypeSubscription.unsubscribe(); }
    if (this.userSubscription) { this.userSubscription.unsubscribe(); }
    if (this.authenticationServiceSubscription) { this.authenticationServiceSubscription.unsubscribe(); }
    if (this.customerSubscription) { this.customerSubscription.unsubscribe(); }
    if (this.assignmentSubscription) { this.assignmentSubscription.unsubscribe(); }
    if (this.reportSubscription) { this.reportSubscription.unsubscribe(); }
  }

}
