import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Store } from '@ngxs/store';
import { FilterCertifications, GetCertifications } from '../../store/certifications.actions';
import { FilterCourses, GetCourses } from '../../store/courses.actions';
import {FilterRH, GetRH} from '../../store/rh.actions'

@Component({
  selector: 'app-formationsrhs',
  templateUrl: './formationsrhs.component.html',
  styleUrls: ['./formationsrhs.component.scss'],
})
export class FormationsrhsComponent implements OnInit {

  typeData: SelectItem[] = [
    { label: 'Certifications', value: 'Certifications' },
    { label: 'Formations', value: 'Formations' },
    { label: 'RH', value: 'RH'}
  ];

  typeDataSelected = 'Certifications';
  showAddForm = false;

  constructor(private store: Store) { }

  ngOnInit(): void {}

  handleChangeType(event) {
    this.typeDataSelected = event.value;
    this.toggleShowAddForm(false);
  }

  toggleShowAddForm(show = null) {
    this.showAddForm = !this.showAddForm ? show === null : show;
  }

  handleGlobalSearchChange(query) {
    switch (this.typeDataSelected) {
      case 'Formations':
        query && query.length > 0 ?
          this.store.dispatch(new FilterCourses(query)) :
          this.store.dispatch(new GetCourses());
        break;
      case 'RH':
        query && query.length > 0 ?
        this.store.dispatch(new FilterRH(query)) :
        this.store.dispatch(new GetRH());
        break;
      case 'Certifications':
      default:
        query && query.length > 0 ?
          this.store.dispatch(new FilterCertifications(query)) :
          this.store.dispatch(new GetCertifications());
        break;
    }
  }
}
