import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { User } from '../../models/user';
import { WeeklyActivityReport } from '../../models/weekly-activity-report';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rha-consultation',
  templateUrl: './rha-consultation.component.html',
  styleUrls: ['./rha-consultation.component.scss'],
})
export class RhaConsultationComponent implements OnInit {
  @Input()
  rha: WeeklyActivityReport;

  @Output()
  clickBack = new EventEmitter<Event>();

  user: User;
  fromDate: string;
  toDate: string;
  weekNumber: number;
  projectName: string;
  sectorName: string;
  projeqtor: string;
  coursesSubscription: Subscription;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.user = this.rha.collaborator;

    // Get dates
    const rhaCreateDate = moment(this.rha.date);
    this.weekNumber = rhaCreateDate.isoWeek();
    this.fromDate = rhaCreateDate.startOf('isoWeek').toString();
    this.toDate = rhaCreateDate.endOf('isoWeek').toString();

    // Get Projeqtor
    this.projeqtor =
      this.rha.projeqtor === false ? 'Non effectuées' : 'Effectuées';

    // Get Subject Name
    this.sectorName = this.rha.project?.customer?.name;
    this.projectName = this.rha.project?.name;
    if (this.rha.project === null) {
      this.sectorName = 'CREATIVE Academy';
      this.projectName = this.rha.course?.name;
    }
  }

  redirect() {
    this.clickBack.emit();
  }
}
