import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { InterestService } from '../../../../core/services/interest-services/interest.service';
import { LevelService } from '../../../../core/services/level-services/level.service';
import { User } from '../../../../shared/models/user';
import { Select, Store } from '@ngxs/store';
import {
  ClearCurrentProfileUser,
  SetCurrentProfileUser, SetProfileIsUpdateView,
} from '../../store/profile.actions';
import { UserProfileState } from '../../store/profile.state';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  @Select(UserProfileState.getCurrentUserProfile)
  public currentUser$: Observable<User>;

  @Select(UserProfileState.isUpdateView)
  public isUpdateView$: Observable<boolean>;

  public module = 'profile';

  public constructor(
    private activatedRoute: ActivatedRoute,
    private interestService: InterestService,
    private levelService: LevelService,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(new SetCurrentProfileUser());
    this.activatedRoute.snapshot.firstChild?.routeConfig?.path === 'update' ?
      this.store.dispatch(new SetProfileIsUpdateView(true)) :
      this.store.dispatch(new SetProfileIsUpdateView(false));
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new ClearCurrentProfileUser());
  }
}
