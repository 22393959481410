import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

/**
 * Authorization guard for routes, redirect to /unauthorized page if
 * unauthenticated
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authenticationService.isAuthenticated().pipe(
      map((isAuthorized: boolean) => {
        return isAuthorized ? true : this.router.parseUrl('/login');
      })
    );
  }
}
