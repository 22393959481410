import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizationGuard } from './core/guards/authorization.guard';
import { RoleGuard } from './core/guards/role.guard';
import { LoginModule } from './modules/login/login.module';
import { PageNotFoundModule } from './modules/page-not-found/page-not-found.module';
import { QualificationsModule } from './modules/qualifications/qualifications.module';
import { ParametersModule } from './modules/parameters/parameters.module';
import { TeamModule } from './modules/team/team.module';
import { CustomersModule } from './modules/customers/customers.module';
import { FormationsrhsModule } from './modules/formationrh/formationsrh.module';
import { HomeModule } from './modules/home/home.module';
import { ActivityModule } from './modules/activity/activity.module';
import { FaqModule } from './modules/faq/faq.module';
import { LoginComponent } from './modules/login/pages/login/login.component';
import { PageNotFoundComponent } from './modules/page-not-found/pages/page-not-found/page-not-found.component';
import { QualificationsComponent } from './modules/qualifications/pages/qualifications/qualifications.component';
import { ParametersComponent } from './modules/parameters/pages/parameters/parameters.component';
import { TeamComponent } from './modules/team/pages/team/team.component';
import { CustomersComponent } from './modules/customers/pages/customers/customers.component';
import { FormationsrhsComponent } from './modules/formationrh/pages/formationrh/formationsrhs.component';
import { HomeComponent } from './modules/home/pages/home.component';
import { ActivityComponent } from './modules/activity/pages/activity/activity.component';
import { RhaComponent } from './modules/activity/pages/rha/rha.component';
import { AssignmentsComponent } from './modules/activity/pages/assignments/assignments.component';
import { ProfileModule } from './modules/profile/profile.module';
import { ProfileComponent } from './modules/profile/pages/profile/profile.component';
import { DirectoryComponent } from './modules/directory/pages/directory/directory.component';
import { DirectoryModule } from './modules/directory/directory.module';
import { FaqComponent } from './modules/faq/pages/faq/faq.component';
import { ShowProfileComponent } from './modules/profile/pages/show-profile/show-profile.component';
import { UpdateProfileComponent } from './modules/profile/pages/update-profile/update-profile.component';
import { SummaryMembersComponent } from './modules/team/pages/summary/summary-members/summary-members.component';
import { SummaryShowProfileComponent } from './modules/team/pages/summary/summary-show-profile/summary-show-profile.component';
import { SummaryUpdateProfileComponent } from './modules/team/pages/summary/summary-update-profile/summary-update-profile.component';
import { SummaryRhaComponent } from './modules/team/pages/summary/summary-rha/summary-rha.component';
import { DirectoryShowProfileComponent } from './modules/directory/pages/directory-show-profile/directory-show-profile.component';
import { DirectorySearchComponent } from './modules/directory/pages/directory-search/directory-search.component';
import { ReportsComponent } from './modules/activity/pages/reports/reports.component';
import { TeamReportsComponent } from './modules/team/pages/reports/reports-team/reports-list.component';
import { CreateTeamReportsComponent } from './modules/team/pages/reports/reports-create/reports-create.component';
import { ReportsRhaComponent } from './modules/team/pages/reports/reports-rha/reports-rha.component';
import { ReportsActivityRhaComponent } from './modules/activity/pages/reports/reports-activity-rha/reports-activity-rha.component';
import { ProfilRhaComponent } from './modules/profile/pages/profil-rha/profil-rha.component';
import { RequestCreateComponent } from './modules/request/pages/request-create/request-create.component';
import { RequestListComponent } from './modules/request/pages/request-list/request-list.component';
import { AnnualReportsComponent } from './modules/activity/pages/reports/annual-reports/annual-reports.component';
import { StepOneComponent } from './shared/components/fm-annual-report/step-one/step-one.component';
import { StepTwoComponent } from './shared/components/fm-annual-report/step-two/step-two.component';
import { StepThreeComponent } from './shared/components/fm-annual-report/step-three/step-three.component';
import { StepFourComponent } from './shared/components/fm-annual-report/step-four/step-four.component';
import { StepFiveComponent } from './shared/components/fm-annual-report/step-five/step-five.component';
import { StepSixComponent } from './shared/components/fm-annual-report/step-six/step-six.component';
import { StepSevenComponent } from './shared/components/fm-annual-report/step-seven/step-seven.component';
import { AnnualReportTeamComponent } from './modules/team/pages/reports/reports-team/annual-report-team/annual-report-team.component';
import { HierarchyComponent } from './modules/hierarchy/pages/hierarchy/hierarchy.component';
import { HierarchyModule } from './modules/hierarchy/hierarchy.module';
import { SummaryComponent } from './shared/components/fm-annual-report/summary/summary.component';
import { AnnualReportComponent } from './modules/annual-report/annual-report/annual-report.component';
import { AnnualReportDetailsComponent } from './modules/annual-report/pages/annual-report-details/annual-report-details.component';
import { AnnualReportListComponent } from './modules/annual-report/pages/annual-report-list/annual-report-list.component';
import {HistoryComponent} from './modules/history/pages/history.component';
import { RequestDetailsComponent } from './modules/request/pages/request-details/request-details.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthorizationGuard, RoleGuard],
  },
  {
    path: 'parameters',
    component: ParametersComponent,
    canActivate: [RoleGuard],
    children: [
      {
        path: 'qualifications',
        component: QualificationsComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'clients',
        component: CustomersComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'formations',
        component: FormationsrhsComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'hierarchie',
        component: HierarchyComponent,
        canActivate: [AuthorizationGuard],
      },
    ],
  },
  {
    path: 'team',
    component: TeamComponent,
    canActivate: [RoleGuard],
    children: [
      {
        path: 'reports',
        component: TeamReportsComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'reports/weeklyactivityreport/:id',
        component: ReportsRhaComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'reports/create/:id',
        component: CreateTeamReportsComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'reports/annualreports',
        component: AnnualReportTeamComponent,
        canActivate: [AuthorizationGuard],
        children: [
          {
            path: 'stepone',
            component: StepOneComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'steptwo',
            component: StepTwoComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'stepthree',
            component: StepThreeComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'stepfour',
            component: StepFourComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'stepfive',
            component: StepFiveComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'stepsix',
            component: StepSixComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'stepseven',
            component: StepSevenComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'summary/:id',
            component: SummaryComponent,
            canActivate: [AuthorizationGuard],
          },
        ]
      },
      {
        path: 'summary/members',
        component: SummaryMembersComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'summary/show-profile/:id',
        component: SummaryShowProfileComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'summary/update-profile/:id',
        component: SummaryUpdateProfileComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'summary/rha/:id',
        component: SummaryRhaComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'requests',
        component: RequestListComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'requests/details/:id',
        component: RequestDetailsComponent,
        canActivate: [AuthorizationGuard],
      },
    ],
  },
  {
    path: 'activity',
    component: ActivityComponent,
    canActivate: [RoleGuard],
    children: [
      {
        path: 'rha',
        component: RhaComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'reports/weeklyactivityreport/:id',
        component: ReportsActivityRhaComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'assignments',
        component: AssignmentsComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'reports/annualreports',
        component: AnnualReportsComponent,
        canActivate: [AuthorizationGuard],
        children: [
          {
            path: 'stepone',
            component: StepOneComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'steptwo',
            component: StepTwoComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'stepthree',
            component: StepThreeComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'stepfour',
            component: StepFourComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'stepfive',
            component: StepFiveComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'stepsix',
            component: StepSixComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'stepseven',
            component: StepSevenComponent,
            canActivate: [AuthorizationGuard],
          },
          {
            path: 'summary/:id',
            component: SummaryComponent,
            canActivate: [AuthorizationGuard],
          },
        ]
      },
    ],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [RoleGuard],
    children: [
      {
        path: 'show',
        component: ShowProfileComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'reports/weeklyactivityreport/:id',
        component: ProfilRhaComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'update',
        component: UpdateProfileComponent,
        canActivate: [AuthorizationGuard],
      },
    ],
  },
  {
    path: 'profile/request',
    component: RequestCreateComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'directory',
    component: DirectoryComponent,
    children: [
      { path: 'search', component: DirectorySearchComponent },
      { path: 'show-profile/:id', component: DirectoryShowProfileComponent },
    ],
  },
  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [AuthorizationGuard, RoleGuard],
  },
  {
    path: 'history',
    component: HistoryComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'annualreport',
    component: AnnualReportComponent,
    canActivate: [AuthorizationGuard, RoleGuard],
    children: [
      {
        path: 'list',
        component: AnnualReportListComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'details/:id',
        component: AnnualReportDetailsComponent,
        canActivate: [AuthorizationGuard],
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [AuthorizationGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    LoginModule,
    PageNotFoundModule,
    ParametersModule,
    QualificationsModule,
    CustomersModule,
    TeamModule,
    FormationsrhsModule,
    HomeModule,
    ActivityModule,
    ProfileModule,
    DirectoryModule,
    HierarchyModule,
    FaqModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
