import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { EnvService } from '../env/env.service';

/**
 * HTTP authorization interceptor
 *
 * Add authorization headers to outgoing API requests
 */
@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private envService: EnvService,
    private authenticationService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const { apiUrl } = this.envService;
    if (!request.url.startsWith(apiUrl)) {
      // Only protect API calls
      return next.handle(request);
    }

    const token = this.authenticationService.getToken();
    if (!token) {
      // Unauthenticated
      return next.handle(request);
    }

    return next.handle(this.addAuthorizationHeaders(request));
  }

  /**
   * Add authorization headers to given request.
   *
   * @param request Request
   *
   * @return request clone with headers added
   */
  private addAuthorizationHeaders(request: HttpRequest<any>) {
    return request.clone({ setHeaders: this.getAuthorizationHeaders() });
  }

  /**
   * Get authorization headers with current authentication token
   *
   * @return HTTP headers
   */
  private getAuthorizationHeaders() {
    return { Authorization: `Bearer ${this.authenticationService.getToken()}` };
  }
}
