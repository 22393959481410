import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ReportService} from "../../../../../core/services/report-services/report.service";
import {HrReport} from "../../../../../shared/models/hr-report";

@Component({
  selector: 'app-hr-report',
  templateUrl: './hr-report-component.html',
  styleUrls: ['./hr-report-component.scss']
})
export class HrReportComponent implements OnInit {

  public hrReport: HrReport;

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.getHrReportById(
      parseInt(this.route.snapshot.paramMap.get('id'), 10)
    );
  }

  private getHrReportById(reportId: number) {
    return this.reportService
      .getHrReportByReportId(reportId)
      .subscribe((hrReport) => {
        this.hrReport = hrReport;
      });
  }

  public isEditable(): boolean {
    let date1:any = new Date(this.hrReport.date);
    let date2:any = new Date();
    let diffDays:any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
    return (diffDays < 15);
  }

}
