import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { Skill } from '../../../shared/models/skill';

@Injectable({
  providedIn: 'root',
})
export class SkillService extends ApiService {

  protected prefix(): string {
    return 'skills';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(skill: Skill): Observable<any> {
    return this.put(skill);
  }

  doDelete(skill: Skill): Observable<any> {
    return this.delete(skill.id);
  }

  isExist(id): Observable<any> {
    return this.getOne(id).pipe(
      map(data => data.id === id),
    );
  }

  getAllForDatatable(): Observable<Skill[]> {
    return this.getAll().pipe(
      map(items => items.map((item: Skill) => {
          return this.convertFromApi(item);
        }),
      ));
  }

  convertFromApi(item: any){
    return {
      id: item.id,
      image: item.image,
      name: item.name,
      profile: item.profile.name,
      category: item.category,
      archiving: item.archivingDate ? new Date(item.archivingDate) : null,
    };
  }
}
