<div *ngIf="userSoftSkillsByCategories$ | async as userSoftSkillsByCategories">
  <div *ngFor="let category of softSkillCategories$ | async as softSkillCategories">
    <div class="fm-profile-view">
      <div class="fm-profile-view-header">
        <div class="fm-title">
          <h1>{{category}}</h1>
        </div>
      </div>
      <hr>
      <app-datatable
        [attr.data-testid]="'dataTable_softSkill'"
        editMode="row"
        dataKey="softSkill.id"
        defaultSortField="softSkill.name"
        [data]="userSoftSkillsByCategories[category]"
        [columns]="tableColumns"
        (eventUpdateItem)="handleUpdateItem($event)"
        (eventSaveUpdateItem)="handleSaveUpdateItem($event)">
      </app-datatable>
    </div>
  </div>
</div>
