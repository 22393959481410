import {Component, Input, OnInit} from '@angular/core';
import {UserService} from 'src/app/core/services/user-services/user.service';
import {User} from 'src/app/shared/models/user';
import {UserProfile} from 'src/app/shared/models/user-profile';
import {UserSkill} from 'src/app/shared/models/user-skill';
import {UserSpecialty} from 'src/app/shared/models/user-specialty';
import {SoftSkillService} from 'src/app/core/services/soft-skill-services/soft-skill.service';


@Component({
  selector: 'app-fm-profile',
  templateUrl: './fm-profile.component.html',
  styleUrls: ['./fm-profile.component.scss']
})
export class FmProfileComponent implements OnInit {

  @Input()
  user: User;

  @Input()
  displaySoftSkills = true;

  userProfiles: UserProfile[] = [];
  userSkills: UserSkill[] = [];
  userSpecialties: UserSpecialty[] = [];
  userCourses: any[] = [];
  userCertifications: any[] = [];
  userSoftSkills: any[] = [];
  categories: string[] = [];
  softSkills: any[] = [];
  evenNumberUserSoftSkillsTable: any[] = [];
  oddNumberUserSoftSkillsTable: any[] = [];

  constructor(
    private userService: UserService,
    private softSkillService: SoftSkillService
  ) {
  }

  ngOnInit(): void {
    if (this.user != null) {
      // Order by level then by alphabetical order
      this.userProfiles = this.userService.orderedBylevelThenByAlphabeticalOrder(this.user.userProfiles, 'profile');
      this.userSkills = this.userService.orderedBylevelThenByAlphabeticalOrder(this.user.userSkills, 'skill');
      this.userSpecialties = this.userService.orderedBylevelThenByAlphabeticalOrder(this.user.userSpecialties, 'specialty');
      this.userSoftSkills = this.user.userSoftSkills;

      // Certifications and formations have to be displayed together by alphabetical order
      if (this.user.userCertifications || this.user.userCourses) {
        this.userCertifications.push(...this.user.userCertifications?.map(userCertif => ({
          certification: userCertif.certification,
          interest: userCertif.interest
        })));
        this.userCourses.push(...this.user.userCourses?.map(userCertif => ({
          course: userCertif.course,
          interest: userCertif.interest
        })));
        this.userCertifications.sort(
          (a, b) => (a.certification.name.toLocaleLowerCase() > b.certification.name.toLocaleLowerCase()) ? 1 : -1);
        this.userCourses.sort(
          (a, b) => (a.course.name.toLocaleLowerCase() > b.course.name.toLocaleLowerCase()) ? 1 : -1);
      }

      if (this.displaySoftSkills === true) {

        // Get all softSkill
        this.softSkillService.getAll().subscribe(ss => {

          // Get all softSkill categories
          ss.forEach(softSkill => {
            if (this.categories.indexOf(softSkill.category) === -1) {
              this.categories.push(softSkill.category);
            }
          });
          this.categories.sort();

          // Add softSkills by categories
          this.categories.forEach(category => {
              const softSkills = [];

              // Add softSkills by category
              ss.forEach(
                softSkill => {
                  if (category === softSkill.category) {
                    softSkills.push({
                      id: softSkill.id,
                      name: softSkill.name
                    });
                  }
              });

              // Alphabetic sort
              softSkills.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) ? 1 : -1);

              // Add userSoftSkills level and interest
              softSkills.map(softSkill => {
                this.userSoftSkills.forEach(userSoftSkill => {
                  if (userSoftSkill.softSkill.id === softSkill.id) {
                    softSkill.level = userSoftSkill.level?.value;
                    softSkill.interest = userSoftSkill.interest?.value;
                  }
                  return softSkill;
                });
              });

              this.softSkills.push({category, softSkills});
            }
          );
          // Displays odd numbers from softSkills table in the right-hand column
          this.oddNumberUserSoftSkillsTable = this.softSkills.filter((u, i) => i % 2 !== 0);
          // Displays even numbers from softSkills table in the left-hand column
          this.evenNumberUserSoftSkillsTable = this.softSkills.filter((u, i) => i % 2 === 0);
        });
      }
    }
  }
}
