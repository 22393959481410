import { Router } from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../../../shared/models/user';
import {Select, Store} from '@ngxs/store';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';
import {Request} from '../../../../shared/models/request';
import {RequestState} from '../../requests/request.state'
import {GetFilteredRequest, GetRequestByManager} from '../..//requests/request.action';
import { Constants } from '../../../../shared/constants/constants';

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.scss']
})
export class RequestListComponent implements OnInit, OnDestroy {

  @Select(RequestState.getRequestsByManager)
  public requests$: Observable<Request[]>;

  public emptyMessage = 'Aucune demande n\'est enregistré.';
  public authenticationServiceSubscription: Subscription;
  public requestDataColumns: any [];
  public startDate: string = null;
  public hasManagerRole: boolean;
  public endDate: string = null;
  public search: string = null;
  public currentUser: User;
  fr = Constants.CALENDAR_FR;

  constructor(
    private store: Store,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.authenticationServiceSubscription = this.authenticationService
      .getCurrentUser()
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
        this.store.dispatch(
          new GetRequestByManager(currentUser, null, null, null)
        );
        this.initDatatable();
      });
  }

  private initDatatable(): void {
    this.requestDataColumns = [
      {
        type: 'calendar',
        field: 'date',
        label: 'Date',
        font: 'regular',
        sort: true,
        sortField: 'date',
        isStatic: true,
      },
      {
        type: 'link',
        field: 'type',
        subField: 'name',
        label: 'Type',
        sort: true,
        sortField: 'type.name',
        isStatic: true,
      },
      {
        type: 'text',
        field: 'user',
        subField: 'fullname',
        label: 'Collaborateur',
        font: 'semibold',
        sort: true,
        sortField: 'user.fullname',
        isStatic: true,
      },
    ];
  }

  public handleSearch(): void {
    this.store.dispatch(new GetFilteredRequest(this.search));
  }

  handleFilterValidation(): void {
    const startDate = this.startDate ? new Date(this.startDate).toISOString() : null;
    const endDate = this.endDate ? new Date(this.endDate).toISOString() : null;
    this.store.dispatch(new GetRequestByManager(this.currentUser, startDate, endDate, this.search));
  }

  ngOnDestroy() {
    this.authenticationServiceSubscription.unsubscribe();
  }

}
