import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Request } from '../../../shared/models/request';

@Injectable({
  providedIn: 'root'
})
export class RequestService extends ApiService {

  protected prefix(): string {
    return 'requests';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  getRequestById(id: number): Observable<any> {
    return this.get('/requests/' + id);
  }

  doPost(content: Request): Observable<any> {
    return this.post(content);
  }

  doPut(content: Request): Observable<any> {
    return this.put(content);
  }

  doDelete(id: number): Observable<any> {
    return this.delete(id);
  }

  getAllByManager(
    userId: number,
    startDate: string,
    endDate: string
  ): Observable<Request[]> {
    return this.get(
      '/manager/' + userId + '?startDate=' + startDate + '&endDate=' + endDate
    );
  }

}
