import { Course } from '../../../shared/models/course';

export class MakeSnapshotCourse {
  static readonly type = '[Course] MakeSnapshot';
}

export class RestoreSnapshotCourse {
  static readonly type = '[Course] RestoreSnapshot';
}

export class ClearSnapshotCourse {
  static readonly type = '[Course] ClearSnapshot';
}

export class GetCourses {
  static readonly type = '[Course] GetCourses';
}

export class GetSnapshot {
  static readonly type = '[Course] GetSnapshot';
}

export class AddCourse {
  static readonly type = '[Course] Add';
  constructor(public payload: Course) {}
}

export class UpdateCourse {
  static readonly type = '[Course] update';
  constructor(public payload: Course) {}
}

export class DeleteCourse {
  static readonly type = '[Course] delete';
  constructor(public payload: Course) {}
}

export class FilterCourses {
  static readonly type = '[Course] Filter';
  constructor(public payload: string) {}
}
