import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {Request} from '../../app/shared/models/request';
import {tap} from 'rxjs/operators';
import {GetFilteredRequest, GetRequestByManager} from './request.action';
import {RequestService} from '../../app/core/services/request-service/request.service';

export class RequestStateModel {
  allRequests: Request[];
  filteredRequests: Request[];
}

@State<RequestStateModel>({
  name: 'request',
  defaults: {
    allRequests : [],
    filteredRequests: []
  }
})

@Injectable()
export class RequestState {

  constructor(private requestService: RequestService) {
  }

  @Selector()
  static getRequestsByManager(state: RequestStateModel) {
    return state.filteredRequests;
  }

  @Action(GetRequestByManager)
  getAllByUser({getState, setState}: StateContext<RequestStateModel>, {user, startDate, endDate, search}: any) {
    return this.requestService.getAllByManager(user.id, startDate, endDate).pipe(tap((allRequests) => {
      const filteredRequests = search ? this.setFilteredRequests(allRequests, search) : allRequests;
      const state = getState();
      setState({
        ...state,
        allRequests,
        filteredRequests
      });
    }));
  }

  @Action(GetFilteredRequest)
  getFilteredRRequests({getState, setState}: StateContext<RequestStateModel>, {search}: any) {
    const state = getState();
    const filteredRequests = search && search !== '' ? this.setFilteredRequests(state.allRequests, search.toLowerCase()) :
      state.allRequests;
    setState({
      ...state,
      filteredRequests
    });
  }

  setFilteredRequests(allRequests, search) {
    return allRequests.filter(request => request.type.name.toLowerCase().indexOf(search, 0) !== -1
    || request.user.fullname.toLowerCase().indexOf(search, 0) !== -1 ) ;
  }
}
