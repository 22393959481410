import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { NotificationComponent } from './notification.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';


@NgModule({
  declarations: [NotificationComponent],
  imports: [CommonModule, FormsModule, SharedModule, OverlayPanelModule],
  exports: [NotificationComponent]
})
export class NotificationModule {}
