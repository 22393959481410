import { Customer } from './../../../shared/models/customer';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends ApiService {

  protected prefix(): string {
    return 'customers';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getAllWithProject(): Observable<any> {
    return this.get("projects");
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(customer: Customer): Observable<any> {
    return this.put(customer);
  }

  doDelete(customer: Customer): Observable<any> {
    return this.delete(customer.id);
  }

}
