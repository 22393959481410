<div class="fm-top">
  <div class="fm-top-search">
    <input
      [attr.data-testid]="'directory_field_search'"
      pInputText
      type="text"
      placeholder="Rechercher"
      #searchInput
      (keyup)="inputChanges()"
      (keyup.enter)="handleSearch()"
      value="{{ search }}"/>
    <app-fm-button
      [attr.data-testid]="'directory_button_search'"
      type="search"
      (click)="handleSearch()">
    </app-fm-button>
  </div>
  <div class="fm-top-info">
    <span>Informations</span>
    <p>En priorité, sont affichés les collaborateurs dont les compétences correspondent à la requête. La requête s'effectue sur tous les mots saisis. La recherche entre guillemets ("chef de projet") s'effectue sur l'ensemble exact de mots.</p>
  </div>
</div>
<app-fm-table
  [attr.data-testid]="'directory_table_component'"
  module="directory"
  [data]="users$ | async"
  [isDurationToBeDisplayed]="isDurationToBeDisplayed"
  [columns]="tableColumns"
  [emptyMessage]="emptyMessage"
  (eventDetailsUser)="handleDetailsUser($event)"
  (eventShowAll)="getDataWithoutFilter(false)"
  [dataIsAllLoaded]="dataIsAllLoaded"
  [loading]="loading$ | async">
</app-fm-table>
