import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Assignement } from '../../../shared/models/assignement';
import { tap } from 'rxjs/operators';
import {
  AddUserAssignment,
  DeleteUserAssignment,
  GetTeamAssignmentsByUser,
  MakeSnapshotUserAssignment,
  RestoreSnapshotUserAssignment,
  UpdateUserAssignment,
  ClearUserAssignments
} from './team-assignments.actions';
import { AssignmentService } from '../../../core/services/assignment-service/assignment.service';

export class TeamAssignmentStateModel {
  userAssignments: Assignement[];
  snapshot: Assignement[];
}

@State<TeamAssignmentStateModel>({
  name: 'userAssignments',
  defaults: {
    userAssignments: [],
    snapshot: [],
  }
})

@Injectable()
export class TeamAssignmentState {

  constructor(private assignmentService: AssignmentService) {
  }

  @Selector()
  static getTeamAssignmentsByUser(state: TeamAssignmentStateModel) {
    return state.userAssignments;
  }

  @Action(GetTeamAssignmentsByUser)
  getAllByUserId({getState, setState}: StateContext<TeamAssignmentStateModel>, {payload}: any) {
    return this.assignmentService.getAllByUserId(payload.id).pipe(tap((result) => {
      const userAssignments = result.map(r => this.assignmentService.mapAssignment(r));
      this.assignmentService.sortAssignments(userAssignments);
      const state = getState();
      setState({
        ...state,
        userAssignments
      });
    }));
  }

  @Action(AddUserAssignment)
  add({getState, patchState}: StateContext<TeamAssignmentStateModel>, {payload}: AddUserAssignment) {
    return this.assignmentService.doPost(payload).pipe(tap((result) => {
      const userAssignment = this.assignmentService.mapAssignment(result);
      const state = getState();
      const userAssignments = [...state.userAssignments, userAssignment];
      this.assignmentService.sortAssignments(userAssignments);
      patchState({
        userAssignments
      });
    }));
  }

  @Action(UpdateUserAssignment)
  update({getState, setState}: StateContext<TeamAssignmentStateModel>, {payload}: UpdateUserAssignment) {
    return this.assignmentService.doPut(payload).pipe(
      tap((result) => {
        const userAssignment = this.assignmentService.mapAssignment(result);
        const state = getState();
        const userAssignments = [...state.userAssignments];
        const userAssignmentIndex = userAssignments.findIndex(item => item.id === payload.id);
        userAssignments[userAssignmentIndex] = userAssignment;
        this.assignmentService.sortAssignments(userAssignments);
        setState({
          ...state,
          userAssignments
        });
      }));
  }

  @Action(DeleteUserAssignment)
  delete({getState, setState}: StateContext<TeamAssignmentStateModel>, {payload}: DeleteUserAssignment) {
    const state = getState();
    return this.assignmentService.doDelete(payload.id).pipe(tap(() => {
      const filteredArray = state.userAssignments.filter(item => item.id !== payload.id);
      setState({
        ...state,
        userAssignments: filteredArray,
      });
    }));
  }

  @Action(MakeSnapshotUserAssignment)
  makeSnapShot({getState, setState}: StateContext<TeamAssignmentStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.userAssignments.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotUserAssignment)
  restoreSnapshot({getState, setState}: StateContext<TeamAssignmentStateModel>) {
    const state = getState();
    setState({
      ...state,
      userAssignments: state.snapshot.map(item => ({...item}))
    });
  }

  @Action(ClearUserAssignments)
  clearUserAssignments({getState, setState}: StateContext<TeamAssignmentStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: [],
      userAssignments: [],
    });
  }
}
