import { Customer } from '../../../shared/models/customer';

export class MakeSnapshotCustomer {
  static readonly type = '[Customer] MakeSnapshot';
}

export class RestoreSnapshotCustomer {
  static readonly type = '[Customer] RestoreSnapshot';
}

export class ClearSnapshotCustomer {
  static readonly type = '[Customer] ClearSnapshot';
}

export class GetCustomers {
  static readonly type = '[Customer] GetCustomers';
}

export class GetSnapshot {
  static readonly type = '[Customer] GetSnapshot';
}

export class AddCustomer {
  static readonly type = '[Customer] Add';
  constructor(public payload: Customer) {}
}

export class UpdateCustomer {
  static readonly type = '[Customer] update';
  constructor(public payload: Customer) {}
}

export class DeleteCustomer {
  static readonly type = '[Customer] delete';
  constructor(public payload: Customer) {}
}

export class FilterCustomers {
  static readonly type = '[Customer] Filter';
  constructor(public payload: string) {}
}
