import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Course } from '../../../shared/models/course';
import {
  AddCourse,
  ClearSnapshotCourse,
  DeleteCourse, FilterCourses,
  GetCourses,
  MakeSnapshotCourse,
  RestoreSnapshotCourse,
  UpdateCourse,
} from './courses.actions';
import { CourseService } from 'src/app/core/services/course-services/course.service';
import { searchIndexOf } from '../../../shared/utils/utils';

export class CourseStateModel {
  courses: Course[];
  snapshot: Course[];
}

@State<CourseStateModel>({
  name: 'courses',
  defaults: {
    courses: [],
    snapshot: [],
  },
})
@Injectable()
export class CourseState {

  constructor(private courseService: CourseService) {}

  @Selector()
  static getCourses(state: CourseStateModel) {
    return state.courses;
  }

  @Selector()
  static getSnapshot(state: CourseStateModel) {
    return state.snapshot;
  }

  @Action(GetCourses)
  getAll({ getState, setState }: StateContext<CourseStateModel>) {
    return this.courseService.getAll().pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          courses: result,
        });
      })
    );
  }

  @Action(AddCourse)
  add({ getState, patchState }: StateContext<CourseStateModel>, {payload}: AddCourse) {
    return this.courseService.doPost(payload).pipe(tap((result) => {
        const state = getState();
        patchState({
          courses: [...state.courses, result],
        });
      })
    );
  }

  @Action(UpdateCourse)
  update(
    { getState, setState }: StateContext<CourseStateModel>,
    { payload }: UpdateCourse
  ) {
    return this.courseService.doPut(payload).pipe(
      tap((result) => {
        const state = getState();
        const coursesList = [...state.courses];
        const courseIndex = coursesList.findIndex((item) => item.id === payload.id);
        coursesList[courseIndex] = result;
        setState({
          ...state,
          courses: coursesList,
        });
      })
    );
  }

  @Action(DeleteCourse)
  delete({ getState, setState }: StateContext<CourseStateModel>, { payload }: DeleteCourse) {
    const state = getState();
    return this.courseService.doDelete(payload.id).pipe(
      tap(() => {
        const filteredArray = state.courses.filter(
          (item) => item.id !== payload.id
        );
        setState({
          ...state,
          courses: filteredArray,
        });
      })
    );
  }

  @Action(FilterCourses)
  filter({getState, setState}: StateContext<CourseStateModel>, {payload}: FilterCourses) {
    return this.courseService.getAll().pipe(tap((result) => {
      const state = getState();
      const courses = result.map( r => {
        r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
        return r;
      });
      const filteredCourses = courses.filter(item =>
        item.name && searchIndexOf(item.name, payload) ||
        item.organization && searchIndexOf(item.organization, payload) ||
        item.description && searchIndexOf(item.description, payload));
      setState({
        ...state,
        courses: filteredCourses
      });
    }));
  }

  @Action(MakeSnapshotCourse)
  makeSnapShot({ getState, setState }: StateContext<CourseStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.courses.map((item) => ({ ...item })),
    });
  }

  @Action(RestoreSnapshotCourse)
  restoreSnapshot({ getState, setState }: StateContext<CourseStateModel>) {
    const state = getState();
    setState({
      ...state,
      courses: state.snapshot.map((item) => ({ ...item })),
    });
  }

  @Action(ClearSnapshotCourse)
  clearSnapshot({ getState, setState }: StateContext<CourseStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: null,
    });
  }
}
