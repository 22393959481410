import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthenticationService} from './core/authentication/authentication.service';
import { UserService } from './core/services/user-services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'followminds-angular-fe';
  isAuthenticated$: Observable<boolean>;

  constructor(private authenticationService: AuthenticationService, public userService: UserService)
  {}

  ngOnInit() {
    this.isAuthenticated$ = this.authenticationService.isAuthenticated();
  }
}
