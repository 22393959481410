import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Assignement } from '../../../../shared/models/assignement';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { AssignmentState, AssignmentStateModel } from '../../store/assignments.state';
import {
  AddAssignment,
  ClearAssignments,
  DeleteAssignment,
  GetAssignmentsByUser,
  MakeSnapshotAssignment,
  RestoreSnapshotAssignment,
  UpdateAssignment
} from '../../store/assignments.actions';
import { AuthenticationService } from '../../../../core/authentication/authentication.service';
import { FormInlineComponent } from '../../../../shared/components/form-inline/form-inline.component';
import { Customer } from '../../../../shared/models/customer';
import { Project } from '../../../../shared/models/project';
import { CustomerService } from '../../../../core/services/customer-service/customer.service';
import { MessageService } from 'primeng/api';
import { User } from '../../../../shared/models/user';
import {Profile} from '../../../../shared/models/profile';
import {SalesforceService} from '../../../../core/services/salesforce-service/salesforce.service';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})

export class AssignmentsComponent implements OnInit, OnDestroy {
  @Select(AssignmentState.getAssignmentsByUser)
  public assignments$: Observable<Assignement[]>;

  @ViewChild(FormInlineComponent)
  private formAssignment: FormInlineComponent;

  public assignmentsDataColumns: any = [];
  public emptyMessage = 'Aucune mission n\'est enregistrée.';
  public showAddForm = false;
  public inputForm: any = [];
  public titleForm = 'Ajouter une mission';
  public disableCreationSave = false;
  public modal: any = {};
  public showModal = false;

  private currentUser: User;
  private assignmentState: AssignmentStateModel;
  private projects: Project[] = [];
  private customers: Customer[] = [];
  private profilesUser: Profile[] = [];
  private itemToDelete: Assignement;
  private subscriptions: Subscription;

  public constructor(
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private customersService: CustomerService,
    private store: Store,
    private salesforceService: SalesforceService
  ) {
  }

  public ngOnInit(): void {
    this.initDatatable();

    const currentUser$ = this.authenticationService.getCurrentUser();
    const customers$ = this.customersService.getAllWithProject();

    this.subscriptions = forkJoin({
      currentUser$,
      customers$
    }).subscribe({
      next: (value) => {
        this.currentUser = value.currentUser$;
        this.store.dispatch(new GetAssignmentsByUser(this.currentUser));
        this.customers = value.customers$;
        this.currentUser.userProfiles.forEach(userProfile =>
          this.profilesUser.push(userProfile.profile)
        );
      },
      error: (e) => console.log(e),
      complete: () => this.initForm()
    });
  }

  private initDatatable(): void {
    this.assignmentsDataColumns = [
      {
        type: 'text',
        field: 'customer',
        subField: 'name',
        label: 'Client',
        font: 'semibold',
        sort: true,
        sortField: 'customer.name',
        isStatic: true,
      },
      {
        type: 'text',
        field: 'project',
        subField: 'name',
        label: 'Projet',
        font: 'semibold',
        sort: true,
        sortField: 'project.name',
        isStatic: true,
      },
      {
        type: 'text',
        field: 'profile',
        subField: 'name',
        label: 'Poste',
        font: 'semibold',
        sort: true,
        sortField: 'profile.name',
        isStatic: true,
      },
      {
        type: 'calendar',
        field: 'startDate',
        label: 'Entrée',
        font: 'regular',
        sort: true,
      },
      {
        type: 'calendar',
        field: 'endDate',
        label: 'Sortie',
        font: 'regular',
        sort: true,
      },
      { type: 'action', action: 'edit', width: '8%' },
      { type: 'action', action: 'delete', width: '10%' },
    ];
  }

  private initForm(): void {
    this.inputForm = [
      {
        key: 'nameCustomer',
        typeForm: 'dropdown',
        fieldForm: 'customer',
        labelForm: 'Client',
        placeHolder: 'Client',
        requiredForm: true,
        filter: true,
        optionsForm: this.customers,
      },
      {
        key: 'nameProject',
        typeForm: 'dropdown',
        fieldForm: 'project',
        labelForm: 'Projet',
        placeHolder: 'Projet',
        requiredForm: true,
        filter: true,
        optionsForm: this.projects,
      },
      {
        key: 'nameProfile',
        typeForm: 'dropdown',
        fieldForm: 'profile',
        labelForm: 'Poste',
        placeHolder: 'Poste',
        requiredForm: true,
        filter: true,
        optionsForm: this.profilesUser,
      },
      {
        key: 'nameDateStart',
        typeForm: 'calendar',
        fieldForm: 'startDate',
        labelForm: 'Entrée',
        placeHolder: 'Entrée',
        requiredForm: true,
      },
      {
        key: 'nameDateEnd',
        typeForm: 'calendar',
        fieldForm: 'endDate',
        labelForm: 'Sortie',
        placeHolder: 'Sortie'

      },
    ];
  }

  public toggleShowAddForm(): void {
    this.showAddForm = !this.showAddForm;
  }

  public handleCreateItem(userAssignment: any): void {
    if (!this.errorsValidationCreateOnly(userAssignment)) {
      userAssignment.user = this.currentUser;
      this.store.dispatch(new AddAssignment(userAssignment)).subscribe(
        () => {
          this.displayMessage('create');
          this.formAssignment.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  public handleChangeDropdown(dropdown: any): void {
    if (dropdown.fieldForm === 'customer') {
      const customer: Customer = dropdown.selectedValue;
      if (customer === null) {
        this.projects = [];
      } else {
        this.projects = customer.projects.sort(  (a, b) => {
          return a.name.localeCompare(b.name);
        });
      }

      const index = this.inputForm.findIndex((input) => (input.fieldForm === 'project'));
      this.inputForm[index].optionsForm = this.projects;
    }
  }

  public handleUpdateItem(): void {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotAssignment());
  }

  public handleSaveUpdateItem(userAssignment: Assignement): void {
    if (!this.errorsValidationCreateUpdate(userAssignment)) {
      this.store.dispatch(new UpdateAssignment(userAssignment)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.disableCreationSave = false;
        }
      );
    } else {
      this.store.dispatch(new RestoreSnapshotAssignment());
    }
  }

  public handleCancelEditItem(): void {
    this.store.dispatch(new RestoreSnapshotAssignment());
    this.disableCreationSave = false;
  }

  public handleDeleteItem(id): void {
    this.assignmentState = this.store.snapshot().assignments;
    this.itemToDelete = this.assignmentState.assignments.find((item) => item.id === id);
    this.displayModal('delete');
  }

  public handleModalConfirm(): void {
    this.deleteItem();
  }

  private deleteItem(): void {
    this.store.dispatch(new DeleteAssignment(this.itemToDelete)).subscribe(
      () => this.displayMessage('delete'),
      (e) => this.displayError(e.status)
    );
  }

  private errorsValidationCreateUpdate(userAssignment: any): boolean {
    let errors = false;
    if (!userAssignment.startDate) {
     this.displayError('validation_startDate');
     errors = true;
    }
    if (userAssignment.startDate
      && userAssignment.endDate
      && new Date(userAssignment.endDate).getTime() < new Date(userAssignment.startDate).getTime()){
      this.displayError('validation_endDate_lower');
      errors = true;
    }
    return errors;
  }

  private errorsValidationCreateOnly(userAssignment: any): boolean {
    let errors = false;
    if (!userAssignment.customer) {
      this.displayError('validation_customer');
      errors = true;
    }
    if (!userAssignment.project) {
      this.displayError('validation_project');
      errors = true;
    }
    const commonErrors = this.errorsValidationCreateUpdate(userAssignment);
    return errors || commonErrors;
  }

  private displayMessage(type): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La mission a bien été ajoutée.';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La mission a bien été sauvegardée.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'La mission a bien été supprimée.';
        break;
      }
      case 'import': {
        severity = 'success';
        summary = 'Importation';
        detail = 'L\'importation de SalesForce à correctement fonctionné.';
        break;
      }
    }
    this.messageService.add({severity, summary, detail});
  }

  private displayError(e): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 'validation_customer':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un client.';
        break;
      case 'validation_project':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un projet.';
        break;
      case 'validation_startDate':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner une date d\'entrée.';
        break;
      case 'validation_endDate_lower':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'La date d\'entrée est supérieure à la date de sortie.\n';
        break;
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 403:
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Des entretiens existent pour cette mission.';
        break;
      case 404:
        severity = 'error';
        summary = 'URL non trouvé';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
      case 501:
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  private displayModal(type): void {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.store.dispatch(new ClearAssignments());
  }

  handleImport() {
    this.salesforceService.importSalesforceAssignment(this.currentUser.id).subscribe(
      () => {
        this.displayMessage('import');
        this.store.dispatch(new GetAssignmentsByUser(this.currentUser));
      },
      (e) => this.displayError(e.status)
    );
  }

}
