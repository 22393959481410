import { UserCourse } from '../../../shared/models/user-course';

export class MakeSnapshotProfileUserCourse {
  static readonly type = '[User Profile Course] MakeSnapshot';
}

export class RestoreSnapshotProfileUserCourse {
  static readonly type = '[User Profile Course] RestoreSnapshot';
}

export class GetProfileUserCoursesByUserId {
  static readonly type = '[User Profile Course] Get user courses';
  constructor(public payload: number) {}
}
export class AddProfileUserCourse {
  static readonly type = '[User Profile Course] Add user course';
  constructor(public payload: UserCourse) {}
}

export class UpdateProfileUserCourse {
  static readonly type = '[User Profile Course] Update user course';
  constructor(public payload: UserCourse) {}
}

export class DeleteProfileUserCourse {
  static readonly type = '[User Profile User Course] delete';
  constructor(public payload: UserCourse) {}
}

export class ClearProfileUserCourses {
  static readonly type = '[User Profile Course] Clear user course';
}
