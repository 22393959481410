import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  UpdateUserSoftSkill,
  GetUserSoftSkillsByUserId,
  AddUserSoftSkill,
  ClearUserSoftSkillState
} from './team-soft-skills.actions';
import { UserSoftSkillService } from '../../../core/services/user-soft-skill-services/user-soft-skill.service';
import { SoftSkillService } from '../../../core/services/soft-skill-services/soft-skill.service';
import { UserSoftSkill } from '../../../shared/models/user-soft-skill';
import { LevelService } from '../../../core/services/level-services/level.service';
import { Level } from '../../../shared/models/level';

export class TeamSoftSkillStateModel {
  softSkillCategories: string[];
  userSoftSkillsByCategories: UserSoftSkill[];
}

@State<TeamSoftSkillStateModel>({
  name: 'userSoftSkills',
  defaults: {
    softSkillCategories: [],
    userSoftSkillsByCategories: [],
  }
})
@Injectable()
export class TeamSoftSkillState {

  level: Level;

  constructor(
    private userSoftSkillService: UserSoftSkillService,
    private softSkillsService: SoftSkillService,
    private levelService: LevelService
  ) {}

  @Selector()
  static getSoftSkillCategories(state: TeamSoftSkillStateModel) {
    return state.softSkillCategories;
  }

  @Selector()
  static getUserSoftSkillsByCategories(state: TeamSoftSkillStateModel) {
    return state.userSoftSkillsByCategories;
  }

  @Action(GetUserSoftSkillsByUserId)
  getUserSoftSkillsByUserId({getState, setState}: StateContext<TeamSoftSkillStateModel>, {payload}: GetUserSoftSkillsByUserId) {
    return this.userSoftSkillService.getUserSoftSkillsByUserId(payload).pipe(tap((userSoftSkills) => {
      const userSoftSkillsMerged: UserSoftSkill[] = [];

      this.levelService.getOne(1).subscribe(
        level => this.level = level,
        e => console.log(e),
        () => {
          this.softSkillsService.getAll().subscribe(
            softSkills => {
              softSkills.map(softSkill => {
                let userSoftSkill: UserSoftSkill = userSoftSkills.find(element => element.softSkill.id === softSkill.id);
                if (!userSoftSkill) {
                  userSoftSkill = {
                    id: null,
                    softSkill,
                    user: { id: payload },
                    interest: null,
                    level: this.level
                  };
                }
                userSoftSkillsMerged.push(userSoftSkill);
              });

              const userSoftSkillsOrdered = this.setCategoriesAndUserSoftSkillsByCategories(userSoftSkillsMerged);

              const state = getState();
              setState({
                ...state,
                softSkillCategories: userSoftSkillsOrdered.softSkillCategories,
                userSoftSkillsByCategories: userSoftSkillsOrdered.userSoftSkillsByCategories
              });
            });
        }
      );
    }));
  }

  @Action(AddUserSoftSkill)
  add({getState, setState}: StateContext<TeamSoftSkillStateModel>, {payload}: AddUserSoftSkill) {
    return this.userSoftSkillService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      const categories = [...state.softSkillCategories];
      const userSoftSkillsList = [];
      categories.forEach((category) => {
        userSoftSkillsList[category] = [...state.userSoftSkillsByCategories[category]];
      });
      const userSoftSkillIndex = userSoftSkillsList.findIndex(item => item.softSkill.id === payload.softSkill.id);
      userSoftSkillsList[userSoftSkillIndex] = result;
      setState({
        ...state,
        userSoftSkillsByCategories: userSoftSkillsList,
      });
    }));
  }

  @Action(UpdateUserSoftSkill)
  update({getState, setState}: StateContext<TeamSoftSkillStateModel>, {payload}: UpdateUserSoftSkill) {
    return this.userSoftSkillService.doPut(payload).pipe(
      tap((result) => {
        const state = getState();
        const categories = [...state.softSkillCategories];
        const userSoftSkillsList = [];
        categories.forEach((category) => {
          userSoftSkillsList[category] = [...state.userSoftSkillsByCategories[category]];
        });
        const userSoftSkillIndex = userSoftSkillsList.findIndex(item => item.id === payload.id);
        userSoftSkillsList[userSoftSkillIndex] = result;
        setState({
          ...state,
          userSoftSkillsByCategories: userSoftSkillsList,
        });
    }));
  }

  @Action(ClearUserSoftSkillState)
  clearState({getState, setState}: StateContext<TeamSoftSkillStateModel>) {
    const state = getState();
    setState({
      ...state,
      softSkillCategories: [],
      userSoftSkillsByCategories: []
    });
  }

  private setCategoriesAndUserSoftSkillsByCategories(userSoftSkills: UserSoftSkill[]) {
    const softSkillCategories = [];
    const userSoftSkillsByCategories = [];

    // Order user soft skills by soft skill name
    userSoftSkills.sort(
      (a, b) => a.softSkill.name.toLowerCase() > b.softSkill.name.toLowerCase() ? 1 : -1
    );

    // Set categories
    userSoftSkills.forEach(item => {
      if (softSkillCategories.indexOf(item.softSkill.category) === -1) {
        softSkillCategories.push(item.softSkill.category);
        userSoftSkillsByCategories[item.softSkill.category] = [];
      }
    });
    // Order categories by name
    softSkillCategories.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);

    // Set user soft skills by category
    userSoftSkills.forEach(item => {
      userSoftSkillsByCategories[item.softSkill.category].push(item);
    });

    return { softSkillCategories, userSoftSkillsByCategories };
  }

}
