
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-fm-button',
  templateUrl: './fm-button.component.html',
  styleUrls: ['./fm-button.component.scss']
})
export class FmButtonComponent implements OnInit {
  @Output()
  onClick = new EventEmitter<Event>();

  @Input()
  btnText = '';

  @Input()
  type: 'default' | 'edit' | 'save' | 'save-disabled' |'void' | 'void-blank' | 'delete' | 'search' | 'add' | 'confirm' | 'cancel' |
    'show' | 'hide' | 'reset-user' | 'text-info' | 'text-gradient' | 'text-transparent' | 'out' | 'in-out-person' | 'doc' |
    'doc-disabled' | 'arrow-up' | 'check' | 'close' | 'export' | 'import' | 'consult' | 'fusion';

  @Input()
  btnDisabled = false;

  @Input()
  tooltipText = '';

  constructor() {
  }

  ngOnInit(): void {
  }
}
