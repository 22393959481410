import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './pages/faq/faq.component';
import { QuestionComponent } from './components/question/question.component';
import { PanelModule } from 'primeng/panel';



@NgModule({
  declarations: [FaqComponent, QuestionComponent],
  imports: [
    CommonModule,
    PanelModule,
  ]
})
export class FaqModule { }
