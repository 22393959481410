import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {UserProfileState} from '../../store/profile.state';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../../../shared/models/user';
import {Router} from '@angular/router';
import {
  SetCurrentProfileUser,
  SetProfileIsUpdateView,
} from '../../store/profile.actions';
import {ReportService} from 'src/app/core/services/report-services/report.service';
import {Report} from 'src/app/shared/models/report';

@Component({
  selector: 'app-show-profile',
  templateUrl: './show-profile.component.html',
  styleUrls: ['./show-profile.component.scss']
})
export class ShowProfileComponent implements OnInit, OnDestroy {
  @Select(UserProfileState.getCurrentUserProfile)
  public currentUser$: Observable<User>;

  public reports: Report[] = [];
  public reportsDataColumns: any = [];
  public emptyMessageInterview = 'Aucun compte-rendu';

  private reportsSubscription: Subscription;
  private currentUserSubscription: Subscription;

  constructor(
    private router: Router,
    private store: Store,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.initDatatable();
    this.currentUser$.subscribe(currentUser => {
      if (currentUser) {
        this.reportService.getReportsForUserProfile(currentUser.id).subscribe(reports => {
          this.reports = reports;
        });
      } else {
        this.store.dispatch(new SetCurrentProfileUser());
      }
    });
  }

  private initDatatable(): void {
    this.reportsDataColumns = [
      {
        type: 'calendar',
        field: 'date',
        label: 'Date',
        font: 'regular',
        sort: true,
        sortField: 'date',
        isStatic: true,
      },
      {
        type: 'link',
        field: 'type',
        subField: 'name',
        label: 'Type',
        sort: true,
        sortField: 'type.name',
        isStatic: true,
      },
      {
        type: 'text',
        field: 'collaborator',
        subField: 'fullname',
        label: 'Collaborateur',
        font: 'semibold',
        sort: true,
        sortField: 'collaborator.fullname',
        isStatic: true,
      },
      {
        type: 'text',
        field: 'interviewer',
        subField: 'fullname',
        label: 'Réalisé par',
        font: 'regular',
        sort: true,
        sortField: 'interviewer.fullname',
        isStatic: true,
      },
      { type: 'action', action: 'edit', width: '8%' },
    ];
  }

  handleUpdate() {
    this.store.dispatch(new SetProfileIsUpdateView(true));
    this.router.navigateByUrl('/profile/update');
  }

  handleUpdateReport(event) {}

  ngOnDestroy(): void {}
}
