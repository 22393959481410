import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import {TeamReportState} from '../../../store/team-reports.state';
import {Report} from '../../../../../shared/models/report';
import {GetFilteredReports, GetReportsByManager, GetAnnualReportsByCurrentInterviewer} from '../../../store/team-reports.actions';
import {AuthenticationService} from '../../../../../core/authentication/authentication.service';
import {User} from '../../../../../shared/models/user';
import {TeamUsersState} from '../../../store/team-users.state';
import {UserService} from 'src/app/core/services/user-services/user.service';
import {UserLight} from 'src/app/shared/models/user-light';
import {ReportService} from '../../../../../core/services/report-services/report.service';
import {
  SetAnnualReport,
  SetAnnualReportRole,
  SetPendingAnnualReport
} from '../../../../../../stores/reports/report.action';
import {AnnualReport} from '../../../../../shared/models/annual-report';
import {Constants} from '../../../../../shared/constants/constants';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss']
})
export class TeamReportsComponent implements OnInit, OnDestroy {
  @Select(TeamReportState.getReportsByManager)
  public reports$: Observable<Report[]>;

  @Select(TeamUsersState.getTeamMember)
  public teamMembers$: Observable<User[]>;

  @Select(TeamReportState.getAnnualReportsByCurrentInterviewer)
  public annualReports$: Observable<AnnualReport[]>;

  public displayButtonEAE = true;
  public startDate: string = null;
  public endDate: string = null;
  public search: string = null;
  public reportsDataColumns: any = [];

  public emptyMessage = 'Aucun entretien n\'est enregistré.';
  public authenticationServiceSubscription: Subscription;
  public userSubscription: Subscription;
  public annualReportsSubscription: Subscription;

  public annualReports: AnnualReport[];

  public currentUser: User;

  public showAddForm = false;
  public inputForm: any = [];
  public titleForm = 'Choisir un Collaborateur';
  public results: any = [];
  public disableCreationSave = false;

  public members: UserLight[] = [];
  public selectedMember: any;
  fr = Constants.CALENDAR_FR;

  constructor(
    private store: Store,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private reportService: ReportService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.authenticationServiceSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.store.dispatch(new GetAnnualReportsByCurrentInterviewer(currentUser)).subscribe(() =>
        this.store.dispatch(new GetReportsByManager(currentUser, 'null', 'null', null))
      );

      this.initDatatable();

      this.userSubscription = this.userService.getTeamMembers(currentUser.id).subscribe((list) => {
        this.members = list;
        this.members.forEach(m => m.name = m.fullname);
        this.initForm();
      });
    });
    this.annualReportsSubscription = this.annualReports$.subscribe((annualReports =>
      this.annualReports = annualReports.map(annualReport => {
        switch (annualReport.workflowManager) {
          case 1 :
            annualReport.buttonNotification = Constants.MANAGER_MESSAGE[0] + ' ' +
              annualReport.collaborator.firstname + ' ' +
              annualReport.collaborator.lastname + ' ' +
              Constants.MANAGER_MESSAGE[1];
            this.displayButtonEAE = true;
            break;
          case 2 :
            annualReport.buttonNotification = Constants.MANAGER_MESSAGE[0] + ' ' +
              annualReport.collaborator.firstname + ' ' +
              annualReport.collaborator.lastname + ' ' +
              Constants.MANAGER_MESSAGE[2];
            this.displayButtonEAE = true;
            break;
          case 4 :
            annualReport.buttonNotification = Constants.MANAGER_MESSAGE[6] + ' ' +
              annualReport.collaborator.firstname + ' ' +
              annualReport.collaborator.lastname + ' ' +
              Constants.MANAGER_MESSAGE[7] + '. ' +
              Constants.MANAGER_MESSAGE[4];
            this.displayButtonEAE = true;
            break;
          case 5 :
            annualReport.buttonNotification = Constants.MANAGER_MESSAGE[5] + ' ' +
              annualReport.collaborator.firstname + ' ' +
              annualReport.collaborator.lastname + ' ' +
              Constants.MANAGER_MESSAGE[2];
            this.displayButtonEAE = true;
            break;
          case 6 :
            annualReport.buttonNotification = Constants.MANAGER_MESSAGE[6] + ' ' +
              annualReport.collaborator.firstname + ' ' +
              annualReport.collaborator.lastname + ' ' +
              Constants.MANAGER_MESSAGE[7];
            this.displayButtonEAE = true;
            break;
        }
        return annualReport;
      }
    )));
  }

  private initForm(): void {
    this.inputForm = [
      {
        key: 'nameTeamMember',
        typeForm: 'dropdown',
        fieldForm: 'members.fullname',
        labelForm: 'Team Member',
        placeHolder: 'Team Member',
        requiredForm: true,
        filter: true,
        optionsForm: this.members
      }
    ];
  }

  private initDatatable(): void {
    this.reportsDataColumns = [
      {
        type: 'calendar',
        field: 'date',
        label: 'Date',
        font: 'regular',
        sort: true,
        sortField: 'date',
        isStatic: true,
      },
      {
        type: 'link',
        field: 'type',
        subField: 'name',
        label: 'Type',
        sort: true,
        sortField: 'type.name',
        isStatic: true,
      },
      {
        type: 'text',
        field: 'collaborator',
        subField: 'fullname',
        label: 'Collaborateur',
        font: 'semibold',
        sort: true,
        sortField: 'collaborator.fullname',
        isStatic: true,
      },
      {
        type: 'text',
        field: 'interviewer',
        subField: 'fullname',
        label: 'Réalisé par',
        font: 'regular',
        sort: true,
        sortField: 'interviewer.fullname',
        isStatic: true,
      },
      {type: 'action', action: 'edit', width: '8%'},
    ];
  }

  public handleFilterValidation(): void {
    const startDate = this.startDate ? new Date(this.startDate).toISOString() : null;
    const endDate = this.endDate ? new Date(this.endDate).toISOString() : null;
    this.store.dispatch(new GetReportsByManager(this.currentUser, startDate, endDate, this.search));
  }

  public handleSearch(): void {
    this.store.dispatch(new GetFilteredReports(this.search));
  }

  public handleChangeDropdown(user): void {
    this.router.navigateByUrl('/team/reports/create/' + user.selectedValue.id);
  }

  // Handle click on annual report button
  public handleAnnualReportClick(reportId) {
    // Set role to interviewer
    this.store.dispatch(new SetAnnualReportRole('currentInterviewer'));

    // Get the annualReport corresponding with button clicked
    const collaboratorReport = this.annualReports.find(report => report.id === reportId);
    if (collaboratorReport) {
      // Set pendingAnnualReport
      this.store.dispatch(new SetPendingAnnualReport(collaboratorReport));
      this.store.dispatch(new SetAnnualReport(collaboratorReport));
      // Redirect to annual report step one
      this.router.navigateByUrl('/team/reports/annualreports/stepone');
    }
  }

  public toggleShowAddForm() {
    this.showAddForm = !this.showAddForm;
  }

  public exportWeeklyReport() {
    const startDate = this.startDate ? new Date(this.startDate).toISOString() : null;
    const endDate = this.endDate ? new Date(this.endDate).toISOString() : null;
    this.reportService.exportWeeklyActivityReportByTeamManagerId(
      this.currentUser.id,
      startDate,
      endDate,
      this.search
    ).subscribe((response) => {
      saveAs(response, 'export_rha_' + moment().format('YYYY_DD_MM') + '.csv');
    });
  }

  ngOnDestroy() {
    this.authenticationServiceSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.annualReportsSubscription.unsubscribe();
  }

  handleEditClick(data: any): void {
    if(!data.type.editionUrl){
      return;
    }
    // L'url est définie dans la table report_type et doit matcher le routage défini dans app-routing.module.
    this.router.navigateByUrl('/team/' + data.type.editionUrl + data.id).then();
  }

}
