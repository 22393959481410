<div>
  <!-- filters and export -->
  <div class="fm-filters">
    <div>
      <p-dropdown class="fm-filters-select"
        inputId="yearsDropDown"
        [options]="campaignOptions"
        [(ngModel)]="selectedCampaign"
        (onChange)="handleFilterChange()">
      </p-dropdown>
      <p-dropdown  class="fm-filters-select"
        inputId="officesDropDown"
        [options]="officeOptions"
        [(ngModel)]="selectedOffice"
        (onChange)="handleFilterChange()">
      </p-dropdown>
      <p-dropdown  class="fm-filters-select"
        inputId="officesDropDown"
        [options]="statusOptions"
        [(ngModel)]="selectedStatus"
        (onChange)="handleFilterChange()">
      </p-dropdown>
    </div>
    <div>
      <app-fm-button
        type="export"
        btnText="EXPORTER"
        (onClick)="exportAnnualReports()">
      </app-fm-button>
    </div>
  </div>

  <!-- datatable -->
  <div>
    <app-datatable
      [attr.data-testid]="'dataTable_component'"
      editMode="row"
      [data]="annualReports$ | async"
      [columns]="dataColumns"
      emptyMessage="Aucun résultat"
      (eventDeleteItem)="displayModalDelete($event)"
      (eventConsultClick) = "handleConsultItem($event)"
      >
    </app-datatable>
  </div>
</div>

<!-- modal -->
<app-modal [header]="modal.header"
           [content]="modal.content"
           [type]="modal.type"
           [(display)]="showModal"
           closable="true"
           (eventModalConfirm)="handleDeleteItem()"
           (eventModalCancel)= "handleCancel()">
</app-modal>
