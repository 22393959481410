import {Component, EventEmitter, Input, Output} from '@angular/core';
import { Constants } from '../../constants/constants';
import { UploadService } from '../../../core/services/upload-services/upload.service';
import { Router } from '@angular/router';
import { WeeklyReportService } from 'src/app/core/services/weekly-report.services/weekly-report.service';
import { ReportService } from 'src/app/core/services/report-services/report.service';
import {User} from '../../models/user';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent {

  @Output()
  eventDeleteItem = new EventEmitter<number>();

  @Output()
  eventUpdateItem = new EventEmitter<any>();

  @Output()
  eventSaveUpdateItem = new EventEmitter<any>();

  @Output()
  eventCancelEditItem = new EventEmitter<Event>();

  @Output()
  eventRedirect = new EventEmitter<Event>();

  @Output()
  eventFilteredCategories = new EventEmitter<string>();

  @Output()
  eventRowClick = new EventEmitter<number>();

  @Output()
  eventConsultClick = new EventEmitter<number>();

  @Output()
  eventFusionItem = new EventEmitter<any>();

  @Output()
  eventEditClick = new EventEmitter<any>();

  @Input()
  columns: any = [];

  @Input()
  data: any = [];

  @Input()
  editMode: string;

  @Input()
  filteredCategories: string[];

  @Input()
  emptyMessage = 'Aucune donnée';

  @Input()
  dataKey = 'id';

  @Input()
  defaultSortField = 'name';

  @Input()
  defaultSortOrder = 1;

  @Input()
  displayPagination = true;

  @Input()
  isMainManager = false;

  @Input()
  isAdmin = false;

  @Input()
  displayOnlyQualificationsNotValidated = false;

  @Input()
  isUpdatingProfile = false;

  @Input()
  rowNumber = 10;

  @Input()
  currentUser: User;

  /** Affichage du bouton édition de façon aléatoire */
  @Input()
  displayOrNotEdition = false;

  @Input()
  selectionMode: string;

  btntext = 'Voir tout';
  selectedRha: any;
  isEditingTable = false;
  loadingEnd = false;
  iconImage = this.data.image;
  clonedProducts: { [s: string]: any } = {};

  iconInterest = Constants.INTEREST_ICONS;
  iconLevel = Constants.LEVEL_ICONS;
  fr = Constants.CALENDAR_FR;

  selectedData: any;

  constructor(
    private uploadService: UploadService,
    private router: Router,
  ) {}

  /** Emit data Table link on Click */
  handleRedirect(data: any) {
    this.eventRedirect.emit(data);
  }

  handleClickLink(data: any) {
    if(data?.type === 'Demande de formation'){
      this.router.navigateByUrl(
        `team/requests/details/${data.id}`
      )
    }
    if (data?.type?.name === 'RHA') {
      !this.router.url.includes('activity') &&
      !this.router.url.includes('profile')
        ? this.router.navigateByUrl(
            `team/reports/weeklyactivityreport/${data.id}`
          )
        : !this.router.url.includes('activity') &&
          !this.router.url.includes('team')
        ? this.router.navigateByUrl(
            `profile/reports/weeklyactivityreport/${data.id}`
          )
        : this.router.navigateByUrl(
            `activity/reports/weeklyactivityreport/${data.id}`
          );
    }
    if (data?.type?.name?.startsWith('Entretien Annuel d\'Évaluation')) {
      this.router.url.includes('activity') ?
        this.router.navigateByUrl(`activity/reports/annualreports/summary/${data.id}`) :
        this.router.navigateByUrl(`team/reports/annualreports/summary/${data.id}`);
    }
  }

  filterCategory(event: any) {
    this.eventFilteredCategories.emit(event.query);
  }

  handleDeleteItem(id: any) {
    this.eventDeleteItem.emit(id);
  }

  handleFusionItem(data: any) {
    this.eventFusionItem.emit(data);
  }

  handleValidationItem(data: any, validation: boolean) {
    if (data.validation === null) {
      data.validation = validation;
      this.eventSaveUpdateItem.emit(data);
    }
  }

  handleUpdateItem(data: any) {
    this.isEditingTable = true;
    this.loadingEnd = false;
    this.eventUpdateItem.emit(data);
  }

  handleSaveUpdateItem(data: any) {
    if (this.iconImage) {
      data.image = this.iconImage;
    } else {
      data.image = data.image ? data.image.changingThisBreaksApplicationSecurity : null ;
    }
    this.loadingEnd = false;
    this.isEditingTable = !this.isEditingTable;
    this.eventSaveUpdateItem.emit(data);
    this.iconImage = null;
  }

  handleCancelEditItem() {
    this.isEditingTable = false;
    this.eventCancelEditItem.emit();
  }

  handleUploadImage(event: any, data: any) {
    const file: File = event.files[0];
    this.uploadService.convertFileToString(file).subscribe({
      next: (image: string) => {
        this.loadingEnd = true;
        this.iconImage = image;
      },
    });
  }

  displayAllList() {
    this.btntext = this.displayPagination ? 'Afficher moins' : 'Voir tout';
    this.displayPagination = !this.displayPagination;
  }

  handleRowSelect(data: any) {
    if(this.selectionMode) {
      this.eventRowClick.emit(data.id);
    }
  }

  handleConsultItem(data: any){
    this.eventConsultClick.emit(data.id)
  }

  handleEditClick(data: any): void {
    this.eventEditClick.emit(data);
  }
}
