import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {ReportState} from '../../../../../stores/reports/report.state';
import {Observable, Subscription} from 'rxjs';
import {AnnualReport} from '../../../models/annual-report';
import {SelectItem} from 'primeng/api';
import {GoalLevel} from '../../../models/goal-level';
import {Goal} from '../../../models/goal';
import {Strength} from '../../../models/strength';
import {Improvement} from '../../../models/improvement';
import {ActivatedRoute, Router} from '@angular/router';
import {GetAnnualReportById} from '../../../../../stores/reports/report.action';
import { Request } from 'src/app/shared/models/request';
import { Constants } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {
  @Select(ReportState.getAnnualReport) annualReport$: Observable<AnnualReport>;
  @Select(ReportState.getError) error$: Observable<number>;

  emptyFieldManager = 'Votre manager n\'a pas renseigné cette section';
  emptyFieldCollaborator = 'Le collaborateur n\'a pas renseigné cette section';

  currentGoalDataColumnsWithoutActions: any[];
  nextGoalDataColumnsWithoutActions: any[];
  goalsEmptyMessage = 'Aucun objectf';
  skillsStrengthDataColumnsWithoutActions: any[];
  skillsStrengthEmptyMessage = 'Aucun point fort de renseigné.';
  skillsImprovementDataColumnsWithoutActions: any[];
  skillsImprovementEmptyMessage = 'Aucun point fort à améliorer';
  skillsCourseRequestDataColumnsWithoutActions: any[];
  skillsCourseRequestEmptyMessage = 'Aucune formation de renseigné.';
  levelOptions: SelectItem[] = [
    { label: GoalLevel.GOAL_LEVEL_NOT_EVALUABLE, value: { name: GoalLevel.GOAL_LEVEL_NOT_EVALUABLE, value: 'GOAL_LEVEL_NOT_EVALUABLE' } },
    { label: GoalLevel.GOAL_LEVEL_NOT_REACHED, value: { name: GoalLevel.GOAL_LEVEL_NOT_REACHED, value: 'GOAL_LEVEL_NOT_REACHED' } },
    { label: GoalLevel.GOAL_LEVEL_REACHED, value: { name: GoalLevel.GOAL_LEVEL_REACHED, value: 'GOAL_LEVEL_REACHED' } },
    { label: GoalLevel.GOAL_LEVEL_EXCEEDED, value: { name: GoalLevel.GOAL_LEVEL_EXCEEDED, value: 'GOAL_LEVEL_EXCEEDED' } }
  ];

  annualReportSubscription: Subscription;
  errorSubscription: Subscription;

  interviewStartDate: string;
  companySeniority: string;
  position: string;
  positionSeniority: string;

  collaboratorName: string;
  collaboratorFirstname: string;
  collaboratorAssessment: string;
  collaboratorGoalComment: string;
  collaboratorSkillsComment: string;
  collaboratorSalaryComment: string;
  collaboratorFinalComment: string;
  collaboratorValidationStatus: boolean;
  collaboratorValidationComment: string;
  collaboratorValidationDate: string;
  collaboratorCurrentGoals: Goal[];
  collaboratorNextGoals: Goal[];
  collaboratorSkillsStrength: Strength[];
  collaboratorSkillsImprovement: Improvement[];
  collaboratorSkillsCourseRequest: Request[];


  managerName: string;
  managerFirstname: string;
  managerValidationDate: string;
  managerAssessment: string;
  managerGoalComment: string;
  managerSkillsComment: string;
  managerSalaryComment: string;
  managerFinalComment: string;
  managerCurrentGoals: Goal[];
  managerNextGoals: Goal[];
  managerSkillsStrength: Strength[];
  managerSkillsImprovement: Improvement[];
  managerSkillsCourseRequest: Request[];


  constructor(
    private router: Router,
    private store: Store,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetAnnualReportById(parseInt(this.route.snapshot.paramMap.get('id'), 10)));
    this.annualReportSubscription = this.annualReport$.subscribe(
      annualReport => {
        if (annualReport) {
          this.fillInputWithAnnualReport(annualReport);
          this.initDatatablesCurrentGoalWithoutActions();
          this.initDatatablesNextGoalWithoutActions();
          this.initDatatableSkillsStrengthWithoutActions();
          this.initDatatableSkillsImprovementWithoutActions();
          this.initDatatableSkillsCourseRequestWithoutActions();
        }
      }
    );
    this.errorSubscription = this.error$.subscribe((error) => {
      if (error) {
        this.router.url.includes('activity') ?
          this.router.navigateByUrl('/activity/reports') :
          this.router.navigateByUrl('/team/reports');
      }
    });
  }

  initDatatablesCurrentGoalWithoutActions() {
    this.currentGoalDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Objectifs',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
      {
        type: 'select',
        options: this.levelOptions,
        field: 'level',
        subField: 'name',
        label: 'Niveaux d\'atteinte',
        font: 'regular',
        sort: false,
      },
    ];
  }

  initDatatablesNextGoalWithoutActions() {
    this.nextGoalDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Objectifs',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: '',
        label: '',
        font: 'regular',
        sort: false,
      },
    ];
  }

  initDatatableSkillsStrengthWithoutActions() {
    this.skillsStrengthDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Compétences métiers et comportementales',
        font: 'regular',
        sort: false,
      }
    ];
  }

  initDatatableSkillsImprovementWithoutActions() {
    this.skillsImprovementDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Compétences métiers et comportementales',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
    ];
  }

  initDatatableSkillsCourseRequestWithoutActions() {
    this.skillsCourseRequestDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Nom de formation',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'description',
        label: 'Description',
        font: 'regular',
        sort: false,
      },
    ];
  }

  fillInputWithAnnualReport(annualReport: AnnualReport){
    if (annualReport) {
      this.interviewStartDate = annualReport.interviewStartDate;
      this.companySeniority = annualReport.companySeniority;
      this.position = annualReport.position;
      this.positionSeniority = annualReport.positionSeniority;

      this.collaboratorName = annualReport.collaborator.lastname;
      this.collaboratorFirstname = annualReport.collaborator.firstname;
      this.collaboratorValidationDate = annualReport.collaboratorValidationDate;
      this.collaboratorAssessment = annualReport.collaboratorAssessment;
      this.collaboratorGoalComment = annualReport.collaboratorGoalComment;
      this.collaboratorSkillsComment = annualReport.collaboratorSkillsComment;
      this.collaboratorSalaryComment = annualReport.collaboratorSalaryComment;
      this.collaboratorFinalComment = annualReport.collaboratorFinalComment;

      this.managerName = annualReport.interviewer.lastname;
      this.managerFirstname = annualReport.interviewer.firstname;
      this.managerValidationDate = annualReport.managerValidationDate;
      this.managerAssessment = annualReport.managerAssessment;
      this.managerGoalComment = annualReport.managerGoalComment;
      this.managerSkillsComment = annualReport.managerSkillsComment;
      this.managerSalaryComment = annualReport.managerSalaryComment;
      this.managerFinalComment = annualReport.managerFinalComment;

      this.setCollaboratorCurrentGoals(annualReport);
      this.setCollaboratorNextGoals(annualReport);
      this.setCollaboratorSkillsStrength(annualReport);
      this.setCollaboratorSkillsImprovement(annualReport);
      this.setCollaboratorSkillsCourseRequest(annualReport);

      this.setManagerCurrentGoals(annualReport);
      this.setManagerNextGoals(annualReport);
      this.setManagerSkillsStrength(annualReport);
      this.setManagerSkillsImprovement(annualReport);
      this.setManagerSkillsCourseRequest(annualReport);

    }
  }

  setCollaboratorCurrentGoals(annualReport) {
    this.collaboratorCurrentGoals = [];
    const collaboratorCurrentGoals = annualReport.goals.filter(goal => goal.emitter === 1 && goal.year === 1);
    collaboratorCurrentGoals.forEach(item => {
      const goal = {...item};
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.collaboratorCurrentGoals.push(goal);
    });
  }

  setCollaboratorNextGoals(annualReport) {
    this.collaboratorNextGoals = [];
    const collaboratorNextGoals = annualReport.goals.filter(goal => goal.emitter === 1 && goal.year === 2);
    collaboratorNextGoals.forEach(item => {
      const goal = {...item};
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.collaboratorNextGoals.push(goal);
    });
  }

  setCollaboratorSkillsStrength(annualReport) {
    this.collaboratorSkillsStrength = [];
    const collaboratorSkillsStrength = annualReport.strengths.filter(item => item.emitter === 1);
    collaboratorSkillsStrength.forEach(item => {
      const strength = {...item};
      this.collaboratorSkillsStrength.push(strength);
    });
  }

  setCollaboratorSkillsImprovement(annualReport) {
    this.collaboratorSkillsImprovement = [];
    const collaboratorSkillsImprovements = annualReport.improvements.filter(goal => goal.emitter === 1);
    collaboratorSkillsImprovements.forEach(item => {
      const improvement = {...item};
      this.collaboratorSkillsImprovement.push(improvement);
    });
  }

  setCollaboratorSkillsCourseRequest(annualReport) {
    this.collaboratorSkillsCourseRequest = [];
    const collaboratorSkillsCourseRequests = annualReport.courseRequests.filter(goal => goal.emitter === Constants.REQUEST_EMITTER_TYPE.collaborator);
    collaboratorSkillsCourseRequests.forEach(item => {
      const course = { ...item };
      this.collaboratorSkillsCourseRequest.push(course);
    });
  }

  setManagerCurrentGoals(annualReport: AnnualReport) {
    this.managerCurrentGoals = [];
    const managerCurrentGoals = annualReport.goals.filter(goal => goal.emitter === 2 && goal.year === 1);
    managerCurrentGoals.forEach(item => {
      const goal = {...item};
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.managerCurrentGoals.push(goal);
    });
  }

  setManagerNextGoals(annualReport: AnnualReport) {
    this.managerNextGoals = [];
    const managerNextGoals = annualReport.goals.filter(goal => goal.emitter === 2 && goal.year === 2);
    managerNextGoals.forEach(item => {
      const goal = {...item};
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.managerNextGoals.push(goal);
    });
  }

  setManagerSkillsStrength(annualReport: AnnualReport) {
    this.managerSkillsStrength = [];
    const managerSkillsStrength = annualReport.strengths.filter(item => item.emitter === 2);
    managerSkillsStrength.forEach(item => {
      const goal = {...item};
      this.managerSkillsStrength.push(goal);
    });
  }

  setManagerSkillsImprovement(annualReport: AnnualReport) {
    this.managerSkillsImprovement = [];
    const managerSkillsImprovement = annualReport.improvements.filter(item => item.emitter === 2);
    managerSkillsImprovement.forEach(item => {
      const goal = {...item};
      this.managerSkillsImprovement.push(goal);
    });
  }

  setManagerSkillsCourseRequest(annualReport) {
    this.managerSkillsCourseRequest = [];
    const managerSkillsCourseRequests = annualReport.courseRequests.filter(item => item.emitter === Constants.REQUEST_EMITTER_TYPE.manager);
    managerSkillsCourseRequests.forEach(item => {
      const course = { ...item };
      this.managerSkillsCourseRequest.push(course);
    });
  }

  ngOnDestroy(): void {
    this.annualReportSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
  }
}
