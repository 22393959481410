import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private defaultImagePath = './assets/img/';
  private defaultImageName = 'default_image.png';

  constructor(protected httpClient?: HttpClient) {
  }

  getDefaultImageToString(): Observable<string> {
    return new Observable(observer => {
      this.httpClient.get(this.defaultImagePath + this.defaultImageName, {responseType: 'blob'}).subscribe(
        (image) => {
            this.convertFileToString(image).subscribe(
              (data) => observer.next(data),
              (e) => observer.error(e),
              () => observer.complete()
            );
          },
        (e) => observer.error(e)
        );
    });
  }

  convertFileToString(file: Blob): Observable<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Observable(observer => {
      reader.onloadend = () => {
        observer.next(reader.result.toString());
        observer.complete();
      };
    });
  }
}
