import { Specialty } from './../../../shared/models/specialty';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class SpecialtyService extends ApiService {

  protected prefix(): string {
    return 'specialties';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPut(content: Specialty): Observable<any> {
    return this.put(content);
  }

  doPost(content: Specialty): Observable<any> {
    return this.post(content);
  }

  doDelete(specialty: Specialty): Observable<any> {
    return this.delete(specialty.id);
  }
}
