<div class="fm-top"></div>
<div class="fm-intro">
  <h1>Les questions que vous vous posez sur follow’minds</h1>
  <p>
    Voici une liste de réponses à des questions que vous vous posez peut-être autour de l'adoption de follow'minds.
    Cette liste est extraite des échanges qui ont déjà eu lieu avec les premiers participants à la mise en place de ce projet.
    Des échanges avec vos managers autour de ce produit seront autant d'occasions pour nous d'enrichir cette liste à l'avenir.
  </p>
  <div class="fm-video">
    <div>
      <span>Tutoriel Followminds</span><br/>
      <video controls>
        <source src="../../../../../assets/video/TUTORIEL_FOLLOWMINDS.mp4" type="video/mp4">
      </video>
    </div>
    <div>
      <span>Tutoriel Followminds Manager</span><br/>
      <video controls>
        <source src="../../../../../assets/video/TUTORIEL_FOLLOWMINDS_CRH_MANAGERS.mp4" type="video/mp4">
      </video>
    </div>
  </div>
</div>
<div class="fm-questions" *ngFor="let question of questions">
  <app-question [question]="question"></app-question>
</div>
