<app-form-inline
  [attr.data-testid]="'formInline_component'"
  [titleForm]="titleForm"
  [listInput]="inputForm"
  (eventCreateItem)="handleCreateItem($event)"
  *ngIf="showAddForm"
  [saveDisabled]="disableCreationSave">
</app-form-inline>
<hr>
<app-datatable
  [attr.data-testid]="'dataTable_component'"
  editMode="row"
  [data]="profiles$ | async"
  [columns]="profileDataColumns"
  [emptyMessage]="emptyMessage"
  (eventDeleteItem)="handleDeleteItem($event)"
  (eventUpdateItem)="handleUpdateItem()"
  (eventFusionItem)="handleFusionItem($event)"
  (eventSaveUpdateItem)="handleSaveUpdateItem($event)"
  (eventCancelEditItem)="handleCancelEditItem()"
  (eventRedirect)="handleRedirect($event)">
</app-datatable>

<app-modal
  [header]="modal.header"
  [content]="modal.content"
  [type]="modal.type"
  [data]="profilesToMerge"
  [(display)]="showModal"
  (eventModalConfirm)="handleModalConfirm()"
  (eventFusionConfirm)="handleFusionConfirm($event)"
  [attr.data-testid]="'modal_component'"
>
</app-modal>
