import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProfileService} from '../../../../core/services/profile-services/profile.service';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';
import {RequestService} from '../../../../core/services/request-service/request.service';
import {User} from '../../../../shared/models/user';
import {Subscription} from 'rxjs';
import { Request } from '../../../../shared/models/request';
import {Motivation} from '../../../../shared/models/motivation';

@Component({
  selector: 'app-request-create',
  templateUrl: './request-create.component.html',
  styleUrls: ['./request-create.component.scss']
})

export class RequestCreateComponent implements OnInit, OnDestroy {
  user: User;
  formData: FormGroup;
  userSubscription: Subscription;
  certification: boolean;
  cpf: boolean;
  hours: number;
  formation: string;
  description: string;
  motivations = Motivation;
  selectedMotivation: any; // needed to fix primeNg bug

  constructor(private formBuilder: FormBuilder,
              private profileService: ProfileService,
              private messageService: MessageService,
              private authService: AuthenticationService,
              private requestService: RequestService,
              private router: Router ) {
    this.formData = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.getCurrentUser().subscribe(
      user => this.user = user
    );
    this.formData.addControl('certification', new FormControl(null, Validators.required));
    this.formData.addControl('hours', new FormControl(null, Validators.required));
    this.formData.addControl('name', new FormControl(null, Validators.required));
    this.formData.addControl('description', new FormControl(null));
    this.formData.addControl('cpf', new FormControl(null, Validators.required));
    this.formData.addControl('motivation', new FormControl(null, Validators.required));
  }

  displayMessage(e) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 'incomplete':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner tous les champs du formulaire.';
        break;
      case 'requestSent':
        severity = 'success';
        summary = 'Formulaire envoyé';
        detail = 'Votre demande de formation a bien été envoyée.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  validateRequest(){

    // Mark invalid fields to dirty
    for (const i in this.formData.controls) {
      if (!this.formData.controls[i].valid) {
        this.formData.controls[i].markAsDirty();
      }
    }

    const allAnswers: boolean = this.formData.value.certification &&
      this.formData.value.hours &&
      this.formData.value.name &&
      this.formData.value.cpf &&
      this.formData.value.motivation;

    let errors = 0;
    if (!allAnswers){
      errors++;
      this.displayMessage('incomplete');
    }
    if (!errors) {
      const request: Request = {
        id: null,
        type: 'Demande de formation',
        user:  this.user,
        certification: this.formData.value.certification,
        hours: this.formData.value.hours,
        name: this.formData.value.name,
        cpf: this.formData.value.cpf,
        motivation: this.formData.value.motivation,
        description: this.formData.value.description,
        date: new Date()
      };

      this.requestService.doPost(request).subscribe(
        () => {
          this.displayMessage('requestSent');
          this.formData.reset();
        },
        (e) => this.displayMessage('error')
      );
    }
  }

  redirectToUpdatePage(){
    this.router.navigateByUrl('profile/update');
    this.formData.reset();
  }
  ngOnDestroy(){
    this.userSubscription.unsubscribe();
  }
}
