<div class="p-grid">
  <form [formGroup]="dateFormGroup">
    <div class="p-col" formArrayName="items">
      <div class="shadow-sm p-3 mb-5 bg-white rounded">
        <div
          class="p-formgroup-inline"
          *ngFor="let item of items.controls; let i = index"
          [formGroupName]="i"
        >
          <div class="p-field p-col-12 p-md-4">
            <label for="icon">ENTREE</label>
            <p-calendar
              formControlName="dateEntree"
              [showIcon]="true"
              inputId="icon2"
            ></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="icon">SORTIE</label>
            <p-calendar
              formControlName="dateSortie"
              [showIcon]="true"
              inputId="icon2"
            ></p-calendar>
          </div>
          <div>
            <button (click)="onSubmit()" class="btn btn-primary mr-2 mt-5">
              <i class="pi pi-check"></i>
            </button>
            <button (click)="removeDuration(i)" class="btn btn-secondary mt-5">
              <i class="pi pi-trash"></i>
            </button>
          </div>
        </div>
        <button
          (click)="addDuration()"
          class="btn btn-primary ml-4"
        >
          <i class="pi pi-plus"></i>
        </button>
      </div>
    </div>
  </form>
</div>

