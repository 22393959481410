import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-select-collaborator',
  templateUrl: './select-collaborator.component.html',
  styleUrls: ['./select-collaborator.component.scss']
})
export class SelectCollaboratorComponent implements OnInit {

  @Output()
  eventFilterUsers = new EventEmitter<string>();

  @Output()
  eventResultSelect = new EventEmitter<Event>();

  @Input()
  results: any[];

  @Input()
  val: any;

  @Input()
  title = '';

  @Input()
  emptyMessage: string;

  constructor() { }

  ngOnInit(): void {
  }

  filterUsers(criteria) {
    this.eventFilterUsers.emit(criteria.query);
  }

  onSelect(event) {
    this.eventResultSelect.emit(event);
    this.val = '';
  }
}
