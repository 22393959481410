import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetAnnualReportsByCurrentInterviewer, GetFilteredReports, GetReportsByInterviewer, GetReportsByManager} from './team-reports.actions';
import {Report} from '../../../shared/models/report';
import {ReportStateModel} from '../../../../stores/reports/report.state';
import {ReportService} from '../../../core/services/report-services/report.service';
import {tap} from 'rxjs/operators';
import { AnnualReport } from 'src/app/shared/models/annual-report';
import { AnnualReportService } from 'src/app/core/services/annual-report-service/annual-report.service';

export class TeamReportStateModel {
  allReports: Report[];
  filteredReports: Report[];
  allAnnualReportsByCurrentInterviewer: AnnualReport[]
}

@State<TeamReportStateModel>({
  name: 'teamReports',
  defaults: {
    allReports : [],
    filteredReports: [],
    allAnnualReportsByCurrentInterviewer: []
  }
})

@Injectable()
export class TeamReportState {

  constructor(private reportService: ReportService, private annualReportService: AnnualReportService) {
  }

  @Selector()
  static getAnnualReportsByCurrentInterviewer(state: ReportStateModel){
    return state.allAnnualReportsByCurrentInterviewer;
  }

  @Selector()
  static getReportsByReporter(state: TeamReportStateModel) {
    return state.filteredReports;
  }

  @Selector()
  static getReportsByManager(state: TeamReportStateModel) {
    return state.filteredReports;
  }


  @Selector()
  static getFilteredReports(state: TeamReportStateModel) {
    return state.filteredReports;
  }

  @Action(GetAnnualReportsByCurrentInterviewer)
  getAnnualReportsByCurrentInterviewer({getState, setState}: StateContext<ReportStateModel>, {user}: GetAnnualReportsByCurrentInterviewer){
    return this.annualReportService.getAnnualReportsByCurrentInterviewer(user.id).pipe(tap((annualReports: AnnualReport[]) => {
      return setState({
        ...getState(),
        allAnnualReportsByCurrentInterviewer : annualReports
      });
    }));
  }

  @Action(GetReportsByInterviewer)
  getAllByReporter({getState, setState}: StateContext<TeamReportStateModel>, {user, startDate, endDate, search}: any) {
    return this.reportService.getAllByInterviewer(user.id, startDate, endDate).pipe(tap((allReports) => {
      const filteredReports = search ? this.setFilteredReports(allReports, search) : allReports;
      const state = getState();
      setState({
        ...state,
        allReports,
        filteredReports
      });
    }));
  }

  @Action(GetReportsByManager)
  getAllByManager({getState, setState}: StateContext<TeamReportStateModel>, {user, startDate, endDate, search}: any) {
    return this.reportService.getAllByManager(user.id, startDate, endDate).pipe(tap((allReports) => {
      allReports.forEach(report => {
        if (report.type.id === 4 && getState().allAnnualReportsByCurrentInterviewer.length != 0) {
          getState().allAnnualReportsByCurrentInterviewer.forEach((annualReport) => {
              if(annualReport.id === report.id){
                report.type.name = report.type.name + ' - ' + annualReport.campaignYear;
              }
            })
        }
      });
      const filteredReports = search ? this.setFilteredReports(allReports, search) : allReports;
      const state = getState();
      setState({
        ...state,
        allReports,
        filteredReports
      });
    }));
  }


  @Action(GetFilteredReports)
  getFilteredReports({getState, setState}: StateContext<TeamReportStateModel>, { search }: any) {
    const state = getState();
    const filteredReports = search && search !== '' ? this.setFilteredReports(state.allReports, search.toLowerCase()) :
      state.allReports;
    setState({
      ...state,
      filteredReports
    });
  }

  setFilteredReports(allReports, search) {
    return allReports.filter(report => report.type.name.toLowerCase().indexOf(search) > -1 
    || report.collaborator.fullname.toLowerCase().indexOf(search) > -1 
    /* || report.currentInterviewer.fullname.toLowerCase().indexOf(search, 0) !== -1  */
    || report.interviewer.fullname.toLowerCase().indexOf(search) > -1);
  }

}
