<div class="fm-top">
  <div class="fm-top-content">
    <div>
      <label>Date de début :</label>
      <p-calendar id="startDate" [attr.data-testid]="'team_report_field_startDate'" [(ngModel)]="startDate" placeholder="jj/mm/aaaa" dateFormat="dd/mm/yy"
        [locale]="fr" appendTo="body">
      </p-calendar>
    </div>
    <div>
      <label>Date de fin :</label>
      <p-calendar id="endDate" [attr.data-testid]="'team_report_field_endDate'" [(ngModel)]="endDate" placeholder="jj/mm/aaaa" dateFormat="dd/mm/yy"
        [locale]="fr" appendTo="body">
      </p-calendar>
    </div>
    <div>
      <app-fm-button type="text-transparent" [attr.data-testid]="'team_report_button_validate'" [btnText]="'Valider'.toUpperCase()"
        (onClick)="handleFilterValidation()">
      </app-fm-button>
    </div>
  </div>
</div>
<div class="fm-actions">
  <app-fm-button [attr.data-testid]="'team_button_export_weekly_report'" type="export" btnText="EXPORTER" (onClick)="exportWeeklyReport()">
  </app-fm-button>
  <input [attr.data-testid]="'report_input_search'" pInputText type="text" placeholder="Rechercher" [(ngModel)]="search" (ngModelChange)="handleSearch()" />
  <app-fm-button [attr.data-testid]="'team_button_showHideForm'" [type]="showAddForm ? 'hide' : 'show'" (onClick)="toggleShowAddForm()">
  </app-fm-button>
</div>
<div class="fm-eae-list" *ngFor="let annualReport of annualReports$ | async">
  <app-fm-annual-report-button *ngIf="displayButtonEAE && annualReport.workflowCollaborator != 5 " (handleClick)="handleAnnualReportClick(annualReport.id)"
    [annualReportNotification]="annualReport.buttonNotification">
  </app-fm-annual-report-button>
</div>
<app-form-inline [attr.data-testid]="'assignment_form_component'" [listInput]="inputForm" [titleForm]="titleForm"
  (eventChangeDropdown)="handleChangeDropdown($event)" [saveDisabled]="false" [showSaveButton]="false" [showClearButton]="false" *ngIf="showAddForm">
</app-form-inline>
<hr>
<app-datatable
  [attr.data-testid]="'team_report_dataTable_component'"
  [data]="reports$ | async"
  [columns]="reportsDataColumns"
  [emptyMessage]="emptyMessage"
  defaultSortField="date"
  [currentUser]="currentUser"
  [displayOrNotEdition]="true"
  defaultSortOrder="-1"
  (eventEditClick)="handleEditClick($event)">
</app-datatable>
