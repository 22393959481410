import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetUserCertifications,
  AddUserCertification,
  DeleteUserCertification,
  UpdateUserCertification,
  GetUserCertificationsByUserId,
  MakeSnapshotUserCertification,
  RestoreSnapshotUserCertification,
  ClearUserCertifications
} from './team-certifications.actions';
import { UserCertificationService } from '../../../core/services/user-certification-services/user-certification.service';
import { UserCertification } from '../../../shared/models/user-certification';


export class TeamCertificationStateModel {
  userCertifications: UserCertification[];
  snapshot: UserCertification[];
}

@State<TeamCertificationStateModel>({
  name: 'userCertifications',
  defaults: {
    userCertifications: [],
    snapshot: []
  }
})
@Injectable()
export class TeamCertificationState {

  constructor(private userCertificationService: UserCertificationService) {
  }

  @Selector()
  static getUserCertifications(state: TeamCertificationStateModel) {
    return state.userCertifications;
  }

  @Action(GetUserCertifications)
  getAll({getState, setState}: StateContext<TeamCertificationStateModel>) {
    return this.userCertificationService.getAll().pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userCertifications: result,
      });
    }));
  }

  @Action(GetUserCertificationsByUserId)
  getUserCertificationsByUserId({getState, setState}: StateContext<TeamCertificationStateModel>, {payload}: GetUserCertificationsByUserId) {
    return this.userCertificationService.getUserCertificationsByUserId(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userCertifications: result.map( r => {
          r.startDate = r.startDate ? new Date(r.startDate) : null;
          r.endDate = r.endDate ? new Date(r.endDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddUserCertification)
  add({getState, patchState}: StateContext<TeamCertificationStateModel>, {payload}: AddUserCertification) {
    return this.userCertificationService.doPost(payload).pipe(tap((result) => {
      result.startDate = result.startDate ? new Date(result.startDate) : null;
      result.endDate = result.endDate ? new Date(result.endDate) : null;
      const state = getState();
      patchState({
        userCertifications: [...state.userCertifications, result]
      });
    }));
  }

  @Action(UpdateUserCertification)
  update({getState, setState}: StateContext<TeamCertificationStateModel>, {payload}: UpdateUserCertification) {
    return this.userCertificationService.doPut(payload).pipe(
      tap((result) => {
        result.startDate = result.startDate ? new Date(result.startDate) : null;
        result.endDate = result.endDate ? new Date(result.endDate) : null;
        const state = getState();
        const userCertificationsList = [...state.userCertifications];
        const userCertificationIndex = userCertificationsList.findIndex(item => item.id === payload.id);
        userCertificationsList[userCertificationIndex] = result;
        setState({
          ...state,
          userCertifications: userCertificationsList,
        });
    }));
  }

  @Action(DeleteUserCertification)
  delete({getState, setState}: StateContext<TeamCertificationStateModel>, {payload}: DeleteUserCertification) {
    const state = getState();
    return this.userCertificationService.doDelete(payload.id).pipe(tap(() => {
      const filteredArray = state.userCertifications.filter(item => item.id !== payload.id);
      setState({
        ...state,
        userCertifications: filteredArray,
      });
    }));
  }

  @Action(MakeSnapshotUserCertification)
  makeSnapShot({getState, setState}: StateContext<TeamCertificationStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.userCertifications.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotUserCertification)
  restoreSnapshot({getState, setState}: StateContext<TeamCertificationStateModel>) {
    const state = getState();
    setState({
      ...state,
      userCertifications: state.snapshot.map(item => ({...item}))
    });
  }

  @Action(ClearUserCertifications)
  clearUserCertifications({getState, setState}: StateContext<TeamCertificationStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: [],
      userCertifications: [],
    });
  }
}
