import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { ApiService } from '../api.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends ApiService {

  protected prefix(): string {
    return 'projects';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPut(content: Project): Observable<any> {
    return this.put(content);
  }

  doPost(content: Project): Observable<any> {
    return this.post(content);
  }

  doDelete(content: Project): Observable<any> {
    return this.delete(content.id);
  }

  customerExists(customerId: number): Observable<any> {
    return this.get('/customer/' + customerId).pipe(
      map(items => items.length > 0)
    );
  }

  getCustomerProjects(customerId:number): Observable<any> {
    return this.get('/customer/' + customerId) ;
  }

  getMindsProjects(): Observable<any> {
    return this.get('/minds');
  }
}
