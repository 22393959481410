import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl: string = null;

  constructor(
    private envService: EnvService,
    protected httpClient?: HttpClient
  ) {
    this.baseUrl = this.envService.apiUrl + '/' + this.prefix();
  }

  /** Process GET request on API server. */
  public get(url: string | number = '', params: Map<string, string> = new Map(), responseType: string = ''): Observable<any> {
    let httpParams = new HttpParams();
    params.forEach((value, key) => {
      httpParams = httpParams.append(key, value);
    });
    if (responseType === 'blob'){
      return this.httpClient.get(this.absUrl(url), { params : httpParams, responseType: 'blob' });
    } else {
      return this.httpClient.get(this.absUrl(url), { params : httpParams });
    }
  }

  /** Process POST request on API server. */
  public post(content: any, url: string | number = ''): Observable<any> {
    return this.httpClient.post(this.absUrl(url), content);
  }

  /** Process PUT request on API server. */
  public put(content: any, url: string | number = '', params: Map<string, string> = new Map() ): Observable<any> {
    let httpParams = new HttpParams();
    params.forEach((value, key) => {
      httpParams = httpParams.append(key, value);
    });
    return this.httpClient.put(this.absUrl(url) + `${content.id}`, content, { params : httpParams });
  }

  /** Process DELETE request on API server. */
  public delete(url: string | number, options: any = {}): Observable<any> {
    return this.httpClient.delete(this.absUrl(url), options);
  }

  /** Returns absolute url and replaces duplicate '/' characters in url. */
  protected absUrl(url: string | number): string {
    return (this.baseUrl + '/' + url).replace(/([^:])\/\/+/g, '$1/');
  }

  /** Gives the service prefix, to be overridden in child services. */
  protected prefix(): string {
    return '';
  }

}
