import {Component} from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

  questions = [
    {
      header: 'Comment utiliser Follow\'minds pour préparer mon entretien annuel ?',
      type: 'link-img',
      text: 'En tant que collaborateur, manager ou non, retrouvez le guide du déroulé de votre entretien annuel d\'évaluation présenté lors de votre formation.',
      image: '../../../../../assets/img/faq/pdf.png',
      alt: 'Guide utilisateur Entretien annuel d\'évaluation',
      href: '../../../../../assets/pdf/guide_utilisateur_entretien_annuel_evaluation.pdf',
      fileName: 'guide_utilisateur_entretien_annuel_evaluation.pdf',
      collapsed: false,
    },
    {
      header: 'En quoi follow’minds s’inscrit dans la politique RH du groupe ?',
      type: 'text',
      text: 'En tant que consultant, vous êtes au centre des préoccupations de l’entreprise et mon objectif' +
          ' est que vous restiez chez CREATIVE, que vous vous y épanouissiez le plus longtemps possible.' +
          ' La cartographie de vos compétences et de vos aspirations doit nous permettre de vous proposer un parcours à la carte,' +
          ' composé de missions en adéquation avec vos aspirations ainsi que d’éventuelles formations ayant' +
          ' pour but de parfaire vos compétences ou en acquérir de nouvelles. L’outil follow’minds est le socle' +
          ' de cette stratégie.',
      collapsed: true,
    },
    {
      header: 'Qu’est-ce que follow\'minds va concrètement apporter à la gestion de ma carrière ?',
      type: 'text',
      text: 'A travers Follow’minds, vous partagerez avec votre responsable de BU, votre manager,' +
          ' ainsi que votre chargée de ressources humaines un espace privé de gestion de votre carrière.' +
          ' Cet espace vous permettra d’avoir accès à tous vos suivis de missions, vos objectifs,' +
          ' vos entretiens annuels d’évaluation et entretiens professionnels.<br/>' +
          'A terme, lorsque toutes les fonctionnalités seront disponibles, vous pourrez même y ajouter' +
          ' des suggestions d’orientations, de formations ou des souhaits de certifications.\n',
      collapsed: true,
    },
    {
      header: 'Qu’est-ce que follow\'minds va m’apporter au quotidien ?',
      type: 'text',
      text: 'Follow’minds met à disposition un annuaire basé sur les métiers, les compétences et' +
          ' les niveaux d’expérience partagés au sein de Créative.' +
          '<br/>Il est alors très facile d’identifier la communauté technique autour d’une technologie,' +
          ' identifier un expert pour vous apporter de l’aide, quelle que soit l’agence.' +
          '<br/>Enfin, instantanément, lorsqu’une technologie vous plaît ou vous déplaît,' +
          ' vous pourrez y marquer votre niveau d’intérêt.',
      collapsed: true,
    },
    {
      header: 'Quelles informations va-t-on référencer dans follow\'minds ?',
      type: 'text',
      text: 'Une fois l’outil finalisé, vous y retrouverez de façon catégorisée les compétences métiers,' +
          ' les compétences humaines (ou soft skills), l’historique de vos missions et' +
          ' l’historique des comptes-rendus' +
          ' de suivis (de mission, minds, entretiens annuels, entretiens professionnels…).',
      collapsed: true,
    },
    {
      header: 'Comment sont évaluées mes compétences ?',
      type: 'image',
      text: 'Pour améliorer la compréhension, le référentiel d’évaluation est établi sur' +
          ' une échelle unique à 5 niveaux (non évalué, bronze, argent, or, référent) pour toutes les compétences.' +
          '<br/>Cette évaluation sera effectuée en concertation avec vos managers et' +
          ' pourra évoluer au fil de votre expérience.',
      image: 'masque.png',
      collapsed: true,
    },
    {
      header: 'Comment follow’minds me permet de faire part de mon intérêt pour les différentes compétences ?',
      type: 'text',
      text: 'Un système simple de 3 smileys permet de manifester votre intérêt ou votre désintérêt pour une compétence.' +
          ' L’utilisation de cette échelle est facultative et vous pourrez revenir à tout moment' +
          ' sur cette marque d’intérêt dans votre dossier.',
      collapsed: true,
    },
    {
      header: 'Qui a accès à mon dossier ?',
      type: 'table',
      text: 'Voici le tableau d’accès aux différentes données associées à votre profil. A noter par exemple' +
          ' qu’un responsable de BU peut également être votre manager. Dans ce cas,' +
          ' il aura les droits cumulés des 2 rôles.' +
          '<br/><br/>' +
          '<span>(<i class="fm-icon-view"></i> disponible en visualisation, <i class="fm-icon-edit"></i> éditable)</span>' +
          '<br/><br/>',
      table: '<table>' +
        '<thead>' +
          '<tr>' +
            '<th>Catégorie</th>' +
            '<th>Tâches</th>' +
            '<th>Moi-même</th>' +
            '<th>Manager</th>' +
            '<th>CRH</th>' +
            '<th>Res. BU/DA</th>' +
            '<th>Tous les collaborateurs de CREATIVE</th>' +
          '</tr>' +
        '</thead>' +
        '<tbody>' +
          '<tr>' +
            '<td rowspan="3">Compétences métiers</td>' +
            '<td>Liste des Compétences</td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
          '</tr>' +
          '<tr>' +
            '<td>Niveau d\'évaluation</td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
          '</tr>' +
          '<tr>' +
            '<td>Niveau d\'intérêt</td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
          '</tr>' +
          '<tr>' +
            '<td rowspan="3">Compétences humaines</td>' +
            '<td>Liste des Compétences</td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td></td>' +
          '</tr>' +
          '<tr>' +
            '<td>Niveau d\'évaluation</td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td></td>' +
          '</tr>' +
          '<tr>' +
            '<td>Niveau d\'intérêt</td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td></td>' +
          '</tr>' +
          '<tr>' +
            '<td rowspan="2">Formations & certifications</td>' +
            '<td>Liste des formations</td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
          '</tr>' +
          '<tr>' +
            '<td>Commentaire</td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td></td>' +
          '</tr>' +
          '<tr>' +
            '<td>Historique des missions</td>' +
            '<td>Liste des périodes de mission</td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td  class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td></td>' +
          '</tr>' +
          '<tr>' +
            '<td rowspan="5">Entretiens</td>' +
            '<td>Suivi de mission</td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td></td>' +
          '</tr>' +
          '<tr>' +
            '<td>Fin de mission</td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td></td>' +
          '</tr>' +
          '<tr>' +
            '<td>Entretien annuel</td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td></td>' +
          '</tr>' +
          '<tr>' +
            '<td>Entretien professionnel</td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td></td>' +
          '</tr>' +
          '<tr>' +
            '<td>Rapports d\'activité Minds</td>' +
            '<td class="center"><i class="fm-icon-edit"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td class="center"><i class="fm-icon-view"></i></td>' +
            '<td></td>' +
          '</tr>' +
        '</tbody>' +
        '</table>',
      collapsed: true,
    },
    {
      header: 'Comment sont sécurisées mes données personnelles ?',
      type: 'text',
      text: 'Les données sont stockées au sein de l’infrastructure du Groupe CREATIVE certifiée ISO27001 depuis 2019,' +
          ' elles ne sont pas copiées/transférées en dehors de cette infrastructure.' +
          '<br/>L’infrastructure, le logiciel, la base de données ainsi que les procédés de sauvegarde' +
          ' des données respectent la réglementation européenne RGPD.' +
          '<br/>L’infrastructure est auditée 2 fois par an et le service informatique procède de façon régulière' +
          ' à des tests de vulnérabilité permettant de maintenir un haut niveau de sécurité de l’infrastructure.' +
          '<br/>Dans le cadre de son développement, les équipes techniques ont pris en considération' +
          ' le guide de programmation sécurisée publiée par l’équipe projet qui intègre les bonnes partiques' +
          ' de "security and privacy by design" notamment OWASP.\n' +
          '<br/>Lorsqu’un collaborateur quitte l’entreprise, ses données sont anonymisées' +
          ' dès son départ et supprimées au bout de 2 ans.',
      collapsed: true,
    },
    {
      header: 'Pourquoi Créative a développé son propre outil ?',
      type: 'text',
      text: 'Depuis plusieurs années CREATIVE est en recherche d’un outil permettant de gérer' +
          ' les compétences évoluant sans cesse autour de métiers extrêmement hétérogènes.' +
          '<br/>A ce critère de recherche s’ajoutait la volonté de mettre les collaborateurs au centre de cet outil' +
          ' et de les rendre acteurs de leur carrière en multipliant les canaux d’interactions.' +
          '<br/>Finalement nous avons constaté que pour avoir un outil efficace, il fallait l’adosser à notre stratégie RH.' +
          ' Quoi de mieux que de le développer pour mettre nos collaborateurs au centre de cet enjeu ?',
      collapsed: true,
    },
    {
      header: 'Comment les salariés ont pris part à cette réflexion ?',
      type: 'text',
      text: 'Vous êtes nombreux à être intervenus ces derniers mois sur le projet, que ce soit sur les phases' +
          ' de spécifications ou les phases de développement. Nous avons également réalisé des ateliers' +
          ' avec les équipes commerciales, RH et les différentes directions afin de recueillir les besoins de chacun.' +
          '<br/>Près de 15% d’entre vous ont pris part à ces premières phases. Par la suite,' +
          ' vous continuerez d’être intégrés aux sprints de développement ainsi qu’à des ateliers de réflexion.' +
          ' Suivez vos mails de communication sur le sujet !',
      collapsed: true,
    },
    {
      header: 'Pourquoi toutes les fonctionnalités annoncées ne sont-elles pas présentes ?',
      type: 'text',
      text: 'L’outil se veut évolutif pour répondre aux enjeux de notre stratégie RH.' +
          ' Inutile de vous faire la tirade du tunnel ! Nous avons souhaité vous mettre à disposition' +
          ' une première version répondant à nos besoins prioritaires.' +
          ' Nous enrichirons régulièrement ces fonctionnalités pour le rendre toujours plus interactif' +
          ' et l’intégrer dans vos outils du quotidien. ',
      collapsed: true,
    },
    {
      header: 'J\'ai une question. A qui je m\'adresse ?',
      type: 'link',
      text: 'Si vous n’avez pas trouvé les réponses à vos interrogations ici,' +
          ' n’hésitez pas à vous rapprocher de votre manager.' +
          '<br/>Pour les idées d\'évolutions, guettez les ateliers de réflexions et n’hésitez pas à adresser un mail à :<br/>',
      link: 'followminds@groupe-creative.fr',
      href: 'mailto:followminds@groupe-creative.fr',
      collapsed: true,
    },
  ];
}
