import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetProfileUserSpecialtiesByUserId,
  MakeSnapshotProfileUserSpecialty,
  RestoreSnapshotProfileUserSpecialty,
  UpdateProfileUserSpecialty,
  AddProfileUserSpecialty,
  ClearProfileUserSpecialties,
  DeleteProfileUserSpecialty
} from './profile-specialties.actions';
import { UserSpecialtyService } from '../../../core/services/user-specialty-services/user-specialty.service';
import { UserSpecialty } from '../../../shared/models/user-specialty';


export class ProfileSpecialtyStateModel {
  userSpecialties: UserSpecialty[];
  snapshot: UserSpecialty[];
  httpStatus: number;
}

@State<ProfileSpecialtyStateModel>({
  name: 'profileUserSpecialties',
  defaults: {
    userSpecialties: [],
    snapshot: [],
    httpStatus: null
  }
})
@Injectable()
export class ProfileSpecialtyState {

  constructor(private userSpecialtyService: UserSpecialtyService) {
  }

  @Selector()
  static getUserSpecialties(state: ProfileSpecialtyStateModel) {
    return state.userSpecialties;
  }

  @Action(GetProfileUserSpecialtiesByUserId)
  getProfileUserSpecialtiesByUserId(
    {getState, setState}: StateContext<ProfileSpecialtyStateModel>,
    {payload}: GetProfileUserSpecialtiesByUserId
  ) {
    return this.userSpecialtyService.getUserSpecialtiesByUserId(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userSpecialties: result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddProfileUserSpecialty)
  add({getState, patchState}: StateContext<ProfileSpecialtyStateModel>, {payload}: AddProfileUserSpecialty) {
    return this.userSpecialtyService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      patchState({
        userSpecialties: [...state.userSpecialties, result]
      });
    }));
  }

  @Action(UpdateProfileUserSpecialty)
  update({getState, setState}: StateContext<ProfileSpecialtyStateModel>, {payload}: UpdateProfileUserSpecialty) {
    return this.userSpecialtyService.doPut(payload).pipe(
      tap((result) => {
        result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
        const state = getState();
        const userSpecialtiesList = [...state.userSpecialties];
        const userSpecialtyIndex = userSpecialtiesList.findIndex(item => item.id === payload.id);
        userSpecialtiesList[userSpecialtyIndex] = result;
        setState({
          ...state,
          userSpecialties: userSpecialtiesList,
        });
    }));
  }

  @Action(DeleteProfileUserSpecialty)
  delete({getState, setState}: StateContext<ProfileSpecialtyStateModel>, {payload}: DeleteProfileUserSpecialty) {
    const state = getState();
    return this.userSpecialtyService.doDelete(payload.id, {observe: 'response'}).pipe(tap((observe) => {
      if (observe.status === 200) {
        const filteredArray = state.userSpecialties.filter(item => item.id !== payload.id);
        setState({
          ...state,
          userSpecialties: filteredArray,
          httpStatus: 200,
        });
      } else {
        setState({
          ...state,
          httpStatus: 202,
        });
      }
    }));
  }

  @Action(MakeSnapshotProfileUserSpecialty)
  makeSnapShot({getState, setState}: StateContext<ProfileSpecialtyStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.userSpecialties.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotProfileUserSpecialty)
  restoreSnapshot({getState, setState}: StateContext<ProfileSpecialtyStateModel>) {
    const state = getState();
    setState({
      ...state,
      userSpecialties: state.snapshot.map(item => ({...item}))
    });
  }

  @Action(ClearProfileUserSpecialties)
  clearProfileUserSpecialties({getState, setState}: StateContext<ProfileSpecialtyStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: [],
      userSpecialties: [],
    });
  }
}
