import { Specialty } from './../../../shared/models/specialty';

import { SpecialtyService } from 'src/app/core/services/specialty-services/specialty.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetSpecialties,
  AddSpecialty,
  UpdateSpecialty,
  DeleteSpecialty,
  MakeSnapshotSpecialty,
  ClearSnapshotSpecialty,
  RestoreSnapshotSpecialty,
  FilterSpecialties
} from './specialties.actions';
import {FilterSkills} from "./skills.actions";
import {SkillStateModel} from "./skills.state";
import {searchIndexOf} from "../../../shared/utils/utils";

export class SpecialtyStateModel {
  specialties: Specialty[];
  snapshot: Specialty[];
}

@State<SpecialtyStateModel>({
  name: 'specialties',
  defaults: {
    specialties: [],
    snapshot: [],
  }
})
@Injectable()
export class SpecialtyState {

  constructor(private specialtyService: SpecialtyService) {
  }

  @Selector()
  static getSpecialties(state: SpecialtyStateModel) {
    return state.specialties;
  }

  @Selector()
  static getSnapshot(state: SpecialtyStateModel) {
    return state.snapshot;
  }

  @Action(GetSpecialties)
  getAll({getState, setState}: StateContext<SpecialtyStateModel>) {
    return this.specialtyService.getAll().pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        specialties: result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddSpecialty)
  add({getState, patchState}: StateContext<SpecialtyStateModel>, {payload}: AddSpecialty) {
    return this.specialtyService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      patchState({
        specialties: [...state.specialties, result]
      });
    }));
  }

  @Action(UpdateSpecialty)
  update({getState, setState}: StateContext<SpecialtyStateModel>, {payload}: UpdateSpecialty) {
    return this.specialtyService.doPut(payload).pipe(tap((result) => {
      result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
      const state = getState();
      const specialtiesList = [...state.specialties];
      const specialtyIndex = specialtiesList.findIndex(item => item.id === payload.id);
      specialtiesList[specialtyIndex] = result;
      setState({
        ...state,
        specialties: specialtiesList,
      });
    }));
  }

  @Action(DeleteSpecialty)
  delete({getState, setState}: StateContext<SpecialtyStateModel>, {payload}: DeleteSpecialty) {
    const state = getState();
    return this.specialtyService.doDelete(payload).pipe(tap(() => {
      const filteredArray = state.specialties.filter(item => item.id !== payload.id);
      setState({
        ...state,
        specialties: filteredArray,
      });
    }));
  }

  @Action(FilterSpecialties)
  filter({getState, setState}: StateContext<SpecialtyStateModel>, {payload}: FilterSpecialties) {
    return this.specialtyService.getAll().pipe(tap((result) => {
      const state = getState();
      const specialties = result.map( r => {
        r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
        return r;
      });
      const filteredSpecialties = specialties.filter(item =>
        item.name && searchIndexOf(item.name, payload) ||
        item.category && searchIndexOf(item.category, payload) ||
        item.profile.name && searchIndexOf(item.profile.name, payload)
      );
      setState({
        ...state,
        specialties: filteredSpecialties
      });
    }));
  }

  @Action(MakeSnapshotSpecialty)
  makeSnapShot({getState, setState}: StateContext<SpecialtyStateModel>) {
      const state = getState();
      setState({
        ...state,
        snapshot: state.specialties.map(item => ({...item}))
      });
  }


  @Action(RestoreSnapshotSpecialty)
  restoreSnapshot({getState, setState}: StateContext<SpecialtyStateModel>) {
      const state = getState();
      setState({
        ...state,
        specialties: state.snapshot.map(item => ({...item}))
      });
  }


  @Action(ClearSnapshotSpecialty)
  clearSnapshot({getState, setState}: StateContext<SpecialtyStateModel>) {
      const state = getState();
      setState({
        ...state,
        snapshot: null,
      });
  }


}
