<div  class="container">
  <div class="fm-button">
    <app-fm-button
      type="text-info"
      [attr.data-testid]="'request-back'"
      [btnText]="'RETOUR'"
      (onClick)="redirectToUpdatePage()">
    </app-fm-button>
  </div>
  <div class="fm-title d-flex">
    <h2>Demande de formation</h2>
    <span>&nbsp;(*Obligatoire)</span>
  </div>
  <form [formGroup]="formData">
    <div class="fm-form">
      <div class="fm-question-margin-bottom">
        <h2 class="fm-margin-bottom">1. Nom de ta formation*</h2>
        <textarea
          type="text"
          [attr.data-testid]="'request_field_name'"
          pInputTextarea
          formControlName="name"
          placeholder="Nom de la formation">
    </textarea>
      </div>
      <div class="fm-question-margin-bottom">
        <h2 class="fm-margin-bottom">2. Description de la formation</h2>
        <textarea
          [attr.data-testid]="'request_field_description'"
          pInputTextarea
          formControlName="description"
          placeholder="Décris en quelques mots la formation, l'organisme ou saisie le lien de la formation">
    </textarea>
      </div>
      <div class="fm-question-margin-bottom">
        <h2 class="fm-margin-top fm-margin-bottom">3. S'agit-il d'une formation dans le cadre du CPF?*</h2>
        <div class="p-field-radiobutton">
          <p-radioButton
            [attr.data-testid]="'request_field_cpf_true'"
            value="true"
            name="cpf"
            [(ngModel)]="cpf"
            formControlName="cpf">
          </p-radioButton>
          <label>Oui</label>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            [attr.data-testid]="'request_field_cpf_false'"
            value="false"
            name="cpf"
            [(ngModel)]="cpf"
            formControlName="cpf">
          </p-radioButton>
          <label>Non</label>
        </div>
      </div>
      <div class="fm-question-margin-bottom">
        <h2 class="fm-margin-bottom">4. Nombre d'heures de la formation?*</h2>
        <p-inputNumber
          [attr.data-testid]="'request_field-hour'"
          formControlName="hours"
          placeholder="Indique un nombre"
          [inputStyle]="{
          boxShadow: '0 0 10px #1432461A',
          fontFamily: 'Montserrat-Regular, sans-serif',
          fontSize: '16px'}">
        </p-inputNumber>
      </div>
      <div class="fm-question-margin-bottom">
        <h2 class="fm-margin-top fm-margin-bottom">5. Est-ce une formation certifiante ?*</h2>
        <div class="p-field-radiobutton">
          <p-radioButton
            [attr.data-testid]="'request_field_certification_true'"
            value="true"
            name="certification"
            [(ngModel)]="certification"
            formControlName="certification">
          </p-radioButton>
          <label>Oui</label>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            [attr.data-testid]="'request_field_certification_false'"
            value="false"
            name="certification"
            formControlName="certification"
            [(ngModel)]="certification">
          </p-radioButton>
          <label>Non</label>
        </div>
      </div>
      <div class="fm-question-margin-bottom">
        <h2 class="fm-margin-top fm-margin-bottom">6. Motivation de la demande ?*</h2>
        <div *ngFor="let motivation of motivations | enumToArray " class="p-field-radiobutton">
          <p-radioButton
            [attr.data-testid]="'request_field_reasons-'+motivation.id"
            name="motivation"
            [(ngModel)]="selectedMotivation"
            [value]="motivation.id"
            formControlName="motivation">
          </p-radioButton>
          <label>{{motivation.name}}</label>
        </div>
      </div>
    </div>
  </form>


  <div class="fm-buttons">
    <app-fm-button
      [attr.data-testid]="'request_button_cancel'"
      type="cancel"
      btnText="Annuler"
      routerLink="/profile/update">
    </app-fm-button>
    <app-fm-button
      [attr.data-testid]="'request_button_validate'"
      type="text-gradient"
      btnText="VALIDER"
      (onClick)="validateRequest()">
    </app-fm-button>
  </div>
</div>
