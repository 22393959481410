import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ActivityComponent } from './pages/activity/activity.component';
import { RhaComponent } from './pages/rha/rha.component';
import { AssignmentsComponent } from './pages/assignments/assignments.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportsComponent } from './pages/reports/reports.component';
import { ReportsActivityRhaComponent } from './pages/reports/reports-activity-rha/reports-activity-rha.component';
import { AnnualReportsComponent } from './pages/reports/annual-reports/annual-reports.component';

@NgModule({
  declarations: [
    ActivityComponent,
    RhaComponent,
    AssignmentsComponent,
    ReportsComponent,
    ReportsActivityRhaComponent,
    AnnualReportsComponent,
  ],
    imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
})
export class ActivityModule {}
