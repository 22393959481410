import { NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChipsModule } from 'primeng/chips';
import { AutoCompleteModule } from 'primeng/autocomplete';
import {CheckboxModule} from 'primeng/checkbox';
import {CardModule} from 'primeng/card';
import {TooltipModule} from 'primeng/tooltip';
import {FileUploadModule} from 'primeng/fileupload';
import {RatingModule} from 'primeng/rating';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import {EditorModule} from 'primeng/editor';
import {StepsModule} from 'primeng/steps';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';

@NgModule({
  imports: [
    ButtonModule,
    AccordionModule,
    MenubarModule,
    TableModule,
    TabMenuModule,
    DropdownModule,
    MessagesModule,
    MessageModule,
    CalendarModule,
    DialogModule,
    BrowserAnimationsModule,
    ChipsModule,
    AutoCompleteModule,
    CardModule,
    TooltipModule,
    FileUploadModule,
    RatingModule,
    InputTextareaModule,
    RadioButtonModule,
    InputNumberModule,
    EditorModule,
    StepsModule,
    ConfirmDialogModule
  ],
  declarations: [],
  exports: [
    ButtonModule,
    AccordionModule,
    MenubarModule,
    TableModule,
    TabMenuModule,
    DropdownModule,
    MessagesModule,
    MessageModule,
    ButtonModule,
    MenubarModule,
    ButtonModule,
    ToastModule,
    CalendarModule,
    DialogModule,
    BrowserAnimationsModule,
    ChipsModule,
    AutoCompleteModule,
    CheckboxModule,
    CardModule,
    TooltipModule,
    FileUploadModule,
    RatingModule,
    InputTextareaModule,
    RadioButtonModule,
    InputNumberModule,
    EditorModule,
    StepsModule,
    ConfirmDialogModule
  ],
  providers: [ConfirmationService],
})
export class PrimeNGModule {}
