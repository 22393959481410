import { Skill } from '../../../shared/models/skill';

export class MakeSnapshotSkill {
  static readonly type = '[Skill] MakeSnapshot';
}

export class RestoreSnapshotSkill {
  static readonly type = '[Skill] RestoreSnapshot';
}

export class ClearSnapshotSkill {
  static readonly type = '[Skill] ClearSnapshot';
}

export class GetSkills {
  static readonly type = '[Skill] GetSkills';
}

export class GetSnapshot {
  static readonly type = '[Skill] GetSnapshot';
}

export class AddSkill {
  static readonly type = '[Skill] Add';
  constructor(public payload: Skill) {}
}

export class UpdateSkill {
  static readonly type = '[Skill] update';
  constructor(public payload: Skill) {}
}

export class DeleteSkill {
  static readonly type = '[Skill] delete';
  constructor(public payload: Skill) {}
}

export class FilterSkills {
  static readonly type = '[Skill] Filter';
  constructor(public payload: string) {}
}
