import { UserSoftSkill } from '../../../shared/models/user-soft-skill';

export class MakeSnapshotUserSoftSkill {
  static readonly type = '[User SoftSkill] MakeSnapshot';
}

export class RestoreSnapshotUserSoftSkill {
  static readonly type = '[User SoftSkill] RestoreSnapshot';
}

export class GetUserSoftSkillsByUserId {
  static readonly type = '[User SoftSkill] Get by user id';
  constructor(public payload: number) {}
}

export class AddUserSoftSkill {
  static readonly type = '[User SoftSkill] Add';
  constructor(public payload: UserSoftSkill) {}
}

export class UpdateUserSoftSkill {
  static readonly type = '[User SoftSkill] update';
  constructor(public payload: UserSoftSkill) {}
}

export class ClearUserSoftSkillState {
  static readonly type = '[User SoftSkill] clear state';
}
