import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Interest } from '../../../shared/models/interest';

@Injectable({
  providedIn: 'root'
})
export class InterestService extends ApiService {

  protected prefix(): string {
    return 'interests';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPut(content: Interest): Observable<any> {
    return this.put(content);
  }

  doPost(content: Interest): Observable<any> {
    return this.post(content);
  }

  doDelete(content: Interest): Observable<any> {
    return this.delete(content.id);
  }
}
