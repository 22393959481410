import { SelectItem } from 'primeng/api';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toSelectItem' })
export class ToSelectItemPipe implements PipeTransform {

  public transform(value: any[]): SelectItem[] {
    if (!value) { return undefined; }
    const returnValue = value.map(p => ({ label: p.name, value: p, icon: p.icon }));

    return returnValue;
  }
}
