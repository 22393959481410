<div class="fm-actions">
  <app-fm-button type="import" btnText="IMPORTER"
                 (onClick)="handleImport()">
  </app-fm-button>
  <app-fm-button
    [attr.data-testid]="'assignment_button_showHideForm'"
    [type]="showAddForm ? 'hide' : 'show'"
    (onClick)="toggleShowAddForm()">
  </app-fm-button>
</div>

<app-form-inline
  [attr.data-testid]="'assignment_form_component'"
  [listInput]="inputForm"
  [titleForm]="titleForm"
  (eventCreateItem)="handleCreateItem($event)"
  (eventChangeDropdown)="handleChangeDropdown($event)"
  [saveDisabled]="disableCreationSave"
  *ngIf="showAddForm">
</app-form-inline>

<hr>

<app-datatable
  [attr.data-testid]="'assignment_dataTable_component'"
  editMode="row"
  [data]="assignments$ | async"
  [columns]="assignmentsDataColumns"
  [emptyMessage]="emptyMessage"
  (eventDeleteItem)="handleDeleteItem($event)"
  (eventUpdateItem)="handleUpdateItem()"
  (eventSaveUpdateItem)="handleSaveUpdateItem($event)"
  (eventCancelEditItem)="handleCancelEditItem()">
</app-datatable>

<app-modal
  [attr.data-testid]="'assignment_modal_component'"
  [header]="modal.header"
  [content]="modal.content"
  [type]="modal.type"
  [(display)]="showModal"
  (eventModalConfirm)="handleModalConfirm()">
</app-modal>
