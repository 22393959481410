import { Injectable } from '@angular/core';
import { mergeMap, tap} from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetTeamUsers,
  FilterTeamUsers,
  ClearTeamUsers,
  ResetFilterTeamUsers,
  AddTeamUser,
  DeleteTeamUser,
  UpdateTeamUserManager,
  UpdateTeamUsers,
  FilterTeamValidation,
  ResetFilterTeamValidation,
  GetTeamMembers
} from './team-users.actions';
import { UserService } from '../../../core/services/user-services/user.service';
import { User } from '../../../shared/models/user';
import { searchIndexOf } from '../../../shared/utils/utils';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { TeamService } from '../../../core/services/team-services/team.service';

export class TeamUserStateModel {
  users: User[];
  usersToValidate: User[];
  manager: User;
  loading: boolean;
  snapshot: User[];
}
@State<TeamUserStateModel>({
  name: 'teamUsers',
  defaults: {
    users: [],
    usersToValidate: [],
    manager: null,
    loading: false,
    snapshot: []
  }
})
@Injectable()
export class TeamUsersState {

  private manager: User;
  private users: User[];
  private filteredUsers: User[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private teamService: TeamService,
    private userService: UserService
  ) {}

  @Selector()
  static getUsers(state: TeamUserStateModel) {
    return state.users;
  }

  @Selector()
  static countUsersToValidate(state: TeamUserStateModel) {
    return state.usersToValidate.length;
  }

  @Selector()
  static getManager(state: TeamUserStateModel) {
    return state.manager;
  }

  @Selector()
  static getLoading(state: TeamUserStateModel) {
    return state.loading;
  }

  @Selector()
  static getTeamMember(state: TeamUserStateModel)
  {
    return state.users;
  }

  @Action(GetTeamMembers)
  getTeamMembers({getState, setState}: StateContext<TeamUserStateModel>) {
    const state = getState();
    setState({
      ...state,
      loading: true
    });
    return this.authenticationService.getCurrentUser().pipe(
      mergeMap((manager) => {
        this.manager = manager;
        return this.userService.getTeamMembers(manager.id);
      }),
      tap((users) => {
        setState({
          ...state,
          manager: this.manager,
          users: this.users,
          loading: false
        });
    }));
  }

  @Action(GetTeamUsers)
  getAll({getState, setState}: StateContext<TeamUserStateModel>) {
    const state = getState();
    setState({
      ...state,
      loading: true
    });
    return this.authenticationService.getCurrentUser().pipe(
      mergeMap((manager) => {
        this.manager = manager;
        return this.teamService.getMembers();
      }),
      tap((users) => {
        users.map(user => {
          user.weeklyReports = user.collaboratorReports.filter(report => report.type.id === 1);
        });
        this.users = this.userService.orderUsersByProperties(users);
        setState({
          ...state,
          manager: this.manager,
          users: this.users,
          usersToValidate: this.users.filter((user) => this.hasUserToBeValidatedByMainManager(user)),
          loading: false
        });
    }));
  }

  @Action(UpdateTeamUsers)
  updateTeamUsers({getState, setState}: StateContext<TeamUserStateModel>, {payload}: UpdateTeamUsers) {
      const index = this.users.findIndex((user) => user.id === payload.id);
      if (index !== -1) {
        this.users[index] = this.userService.orderUsersByProperties([payload]).shift();
      }

      const state = getState();
      setState({
        ...state,
        users: this.users,
        usersToValidate: this.users.filter((user) => this.hasUserToBeValidatedByMainManager(user)),
      });
  }

  @Action(UpdateTeamUserManager)
  update({getState, setState}: StateContext<TeamUserStateModel>, {payload}: UpdateTeamUserManager) {
    return this.teamService.doPut(payload);
  }

  @Action(AddTeamUser)
  add({getState, setState}: StateContext<TeamUserStateModel>, {payload}: AddTeamUser) {
    return this.teamService.doPost(payload).pipe(tap((newUser) => {
      newUser.weeklyReports = newUser.weeklyReports || [];
      this.users.push(newUser);
      this.users = this.userService.orderUsersByProperties(this.users);
      const state = getState();
      setState({
        ...state,
        users: this.users,
        usersToValidate: this.users.filter((user) => this.hasUserToBeValidatedByMainManager(user)),
      });
    }));
  }

  @Action(DeleteTeamUser)
  delete({getState, setState}: StateContext<TeamUserStateModel>, {payload}: DeleteTeamUser) {
    const state = getState();
    return this.teamService.doDelete(payload.member.id).pipe(tap(() => {
      this.users = state.users.filter(item => item.id !== payload.member.id);
      setState({
        ...state,
        users: this.users,
        usersToValidate: this.users.filter((user) => this.hasUserToBeValidatedByMainManager(user)),
      });
    }));
  }

  // Filter Users by firstname,lastname,office,customer
  @Action(FilterTeamUsers)
  filter({getState, setState}: StateContext<TeamUserStateModel>, {payload}: FilterTeamUsers) {
    return new Observable(observer => {
      const state = getState();
      this.filteredUsers = this.users.filter(item =>
        item.firstname && searchIndexOf(item.firstname, payload) ||
        item.lastname && searchIndexOf(item.lastname, payload) ||
        item.office.name && searchIndexOf(item.office.name, payload) ||
        this.getFilteredCustomers(item, payload)
      );
      setState({
        ...state,
        users: this.filteredUsers,
        usersToValidate: this.filteredUsers.filter((user) => this.hasUserToBeValidatedByMainManager(user)),
      });
      observer.next(state);
    });
  }

  @Action(FilterTeamValidation)
  filterValidation({getState, setState}: StateContext<TeamUserStateModel>) {
    return new Observable(observer => {
      const state = getState();
      setState({
        ...state,
        users: state.usersToValidate,
      });
      observer.next(state);
    });
  }

  @Action(ResetFilterTeamUsers)
  resetFilter({getState, setState}: StateContext<TeamUserStateModel>) {
    const state = getState();
    this.filteredUsers = [];
    setState({
      ...state,
      users: this.users,
      usersToValidate: this.users.filter((user) => this.hasUserToBeValidatedByMainManager(user)),
    });
  }

  @Action(ResetFilterTeamValidation)
  resetFilterValidation({getState, setState}: StateContext<TeamUserStateModel>) {
    const state = getState();
    setState({
      ...state,
      users: this.filteredUsers.length === 0 ? this.users : this.filteredUsers,
    });
  }

  // Get Users filtered by customer name
  getFilteredCustomers(item, payload) {
    const assignments = item.assignments;
    let exist = false;
    if (assignments !== 'undefined' && assignments !== null) {
      assignments.forEach((assignment) => {
        if (assignment.project.customer.name && searchIndexOf(assignment.project.customer.name, payload)) {
             exist = true;
        }
      });
      return exist;
    }
  }

  hasUserToBeValidatedByMainManager(selectedUser: User): boolean {
    return this.manager && selectedUser.hasToValidate && this.teamService.isMainManager(this.manager, selectedUser);
  }

  @Action(ClearTeamUsers)
  clearUsers({getState, setState}: StateContext<TeamUserStateModel>) {
    const state = getState();
    setState({
      ...state,
      manager: null,
      users: [],
      usersToValidate: [],
    });
  }
}
