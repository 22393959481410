import { UserProfile } from '../../../shared/models/user-profile';

export class MakeSnapshotTeamUserProfile {
  static readonly type = '[Team User Profile] MakeSnapshot';
}

export class RestoreSnapshotTeamUserProfile {
  static readonly type = '[Team User Profile] RestoreSnapshot';
}

export class GetTeamUserProfiles {
  static readonly type = '[Team User Profile] Get all';
}

export class GetTeamUserProfilesByUserId {
  static readonly type = '[Team User Profile] Get by user id';
  constructor(public payload: number) {}
}

export class AddTeamUserProfile {
  static readonly type = '[Team User Profile] Add';
  constructor(public payload: UserProfile) {}
}

export class UpdateTeamUserProfile {
  static readonly type = '[Team User Profile] update';
  constructor(public payload: UserProfile) {}
}

export class DeleteTeamUserProfile {
  static readonly type = '[Team User Profile] delete';
  constructor(public payload: UserProfile) {}
}

export class ClearTeamUserProfiles {
  static readonly type = '[Team User Profile] Clear user profiles';
}
