import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { SoftSkill } from '../../models/soft-skill';

@Component({
  selector: 'app-table-radio-button',
  templateUrl: './table-radio-button.component.html',
  styleUrls: ['./table-radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TableRadioButtonComponent),
      multi: true,
    },
  ],
})
export class TableRadioButtonComponent implements OnInit, ControlValueAccessor {
  @Input() name: string;
  @Input('quality-data') qualityData: SoftSkill[];
  form: FormGroup;

  public value: any;
  public disabled = false;

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this._fb.group({});
    if (this.qualityData) {
      this.qualityData.forEach((quality) => {
        this.form.addControl(
          quality.name,
          this._fb.control(null, Validators.required)
        );
      });

      this.form.valueChanges.subscribe((val) => {
        this.onChange(val);
        this.value = val.selects;
      });
    }
  }

  onSubmit() {
    // mapper le resultat
    console.log(this.form.value);
  }

  writeValue(selects) {
    this.value = selects;
  }

  public onChange(newVal: any) {}
  public onTouched(_?: any) {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
