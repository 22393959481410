import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfficeService extends ApiService{
  protected prefix(): string {
    return 'offices';
  }

  getAll(): Observable<any> {
    return this.get();
  }
}
