import { UserSpecialty } from '../../../shared/models/user-specialty';

export class MakeSnapshotUserSpecialty {
  static readonly type = '[User Specialty] MakeSnapshot';
}

export class RestoreSnapshotUserSpecialty {
  static readonly type = '[User Specialty] RestoreSnapshot';
}

export class GetUserSpecialties {
  static readonly type = '[User Specialty] Get all';
}

export class GetUserSpecialtiesByUserId {
  static readonly type = '[User Specialty] Get by user id';
  constructor(public payload: number) {}
}

export class AddUserSpecialty {
  static readonly type = '[User Specialty] Add';
  constructor(public payload: UserSpecialty) {}
}

export class UpdateUserSpecialty {
  static readonly type = '[User Specialty] update';
  constructor(public payload: UserSpecialty) {}
}

export class DeleteUserSpecialty {
  static readonly type = '[User Specialty] delete';
  constructor(public payload: UserSpecialty) {}
}

export class ClearUserSpecialties {
  static readonly type = '[User Specialty] Clear user specialties';
}
