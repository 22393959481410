import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {TeamState} from '../../../store/team.state';
import {Observable} from 'rxjs';
import {User} from '../../../../../shared/models/user';
import {ResetFilterTeamUsers, UpdateTeamUsers} from '../../../store/team-users.actions';
import {ClearTeamUser, SetCurrentTeamUser} from '../../../store/team.actions';
import {AuthenticationService} from '../../../../../core/authentication/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-summary-show-profile',
  templateUrl: './summary-show-profile.component.html',
  styleUrls: ['./summary-show-profile.component.scss']
})
export class SummaryShowProfileComponent implements OnInit, OnDestroy {
  @Select(TeamState.getCurrentTeamUser)
  public user$: Observable<User>;

  public module = 'summary';
  public displaySoftSkills = false;

  constructor(
    private authenticationService: AuthenticationService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new SetCurrentTeamUser(parseInt(this.route.snapshot.paramMap.get('id'), 10)));
    this.user$.subscribe(user => {
      if (user) {
        this.displaySoftSkills = user.currentTeam.mainManager;
      }
    });
  }

  public handleBack(): void {
    this.router.navigateByUrl('/team/summary/members');
    this.store.dispatch(new ClearTeamUser());
    this.store.dispatch(new ResetFilterTeamUsers());
  }

  handleUpdate() {
    const currentUser = this.store.snapshot().team.currentTeamUser;
    this.router.navigateByUrl('/team/summary/update-profile/' + currentUser.id);
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearTeamUser());
    this.store.dispatch(new ResetFilterTeamUsers());
  }
}
