import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-fm-tab-menu',
  templateUrl: './fm-tab-menu.component.html',
  styleUrls: ['./fm-tab-menu.component.scss'],
})
export class FmTabMenuComponent implements OnInit, OnChanges {
  @Input()
  items: MenuItem[];

  @Input()
  activeIndex: number;

  activeItem: MenuItem;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.items && this.items.length > 0) {
      this.activeItem = this.items[this.activeIndex];
    }
  }

  ngOnInit(): void {
  }
}
