import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { User } from '../../../shared/models/user';
import { SetCurrentTeamUser, UpdateTeamUser, ClearTeamUser } from './team.actions';
import {mergeMap, tap} from 'rxjs/operators';
import { TeamService } from '../../../core/services/team-services/team.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';

export class TeamStateModel {
  currentTeamUser: User;
  manager: User;
}

@State<TeamStateModel>({
  name: 'team',
  defaults: {
    currentTeamUser: null,
    manager: null
  }
})
@Injectable()
export class TeamState {

  constructor(
    private teamService: TeamService,
    private authenticationService: AuthenticationService
  ) {
  }

  @Selector()
  static getCurrentTeamUser(state: TeamStateModel) {
    return state.currentTeamUser;
  }

  @Action(SetCurrentTeamUser)
  setCurrentTeamUser({getState, setState}: StateContext<TeamStateModel>, {payload}: SetCurrentTeamUser) {
    return this.authenticationService.getCurrentUser().pipe(
      mergeMap((manager) => {
        const state = getState();
        setState({
          ...state,
          manager,
        });
        return this.teamService.getMember(payload);
      }),
      tap((user) => {
        const state = getState();
        setState({
          ...state,
          currentTeamUser: user
        });
      }));
  }

  @Action(UpdateTeamUser)
  updateTeamUser({getState, setState}: StateContext<TeamStateModel>, {payload}: UpdateTeamUser) {
    return this.teamService.getMember(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        currentTeamUser: result,
      });
    }));
  }

  @Action(ClearTeamUser)
  clearTeamUser({getState, setState}: StateContext<TeamStateModel>) {
    const state = getState();
    setState({
      ...state,
      currentTeamUser: null,
      manager: null
    });
  }
}
