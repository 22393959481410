import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './pages/login/login.component';
import { ConnectButtonComponent } from './components/connect-button/connect-button.component';
import { TestGuardComponent } from './pages/test-guard/test-guard.component';
import { InfoComponent } from './pages/info/info.component';

@NgModule({
  declarations: [LoginComponent, ConnectButtonComponent, TestGuardComponent, InfoComponent],
  imports: [CommonModule, FormsModule, SharedModule],
})
export class LoginModule {}
