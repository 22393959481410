export abstract class Constants {

  static readonly INTEREST_ICONS: string[] = [
    'fm-icon-happy',
    'fm-icon-sad',
    'fm-icon-equal',
  ];

  static readonly INTEREST_LABEL: string[] = [
    'je n\'aime pas',
    'neutre',
    'j\'aime',
  ];

  static readonly LEVEL_ICONS: string[] = [
    'fm-level-undefined',
    'fm-level-bronze',
    'fm-level-silver',
    'fm-level-gold',
    'fm-level-lead',
  ];

  static readonly LEVEL_LABEL: string[] = [
    'non évalué',
    'bronze',
    'argent',
    'or',
    'référent',
  ];

  /*   static readonly CERTIFICATION_ICONS: string[] = [
      'fm-icon-safety',
      'fm-icon-electricity',
      'fm-icon-crown',
      'fm-icon-pc',
    ]; */

  static readonly NAME_SIZE_MAX: number = 35;
  static readonly CATEGORY_SIZE_MAX: number = 35;
  static readonly ORGANIZATION_SIZE_MAX: number = 35;
  static readonly COMMENT_SIZE_MAX: number = 100;
  static readonly DESCRIPTION_SIZE_MAX: number = 100;
  static readonly NAME_RH_SIZE_MAX: number = 250;

  static readonly EAE_INPUT_TEXT_SIZE_MAX: number = 255;

  static readonly MINDS_CUSTOMER_NAMES: string[] = [
    'CREATIVE Labs',
    'CREATIVE Research'
  ];

  // EAE
  static readonly MANAGER_MESSAGE: string[] = [
    'Préparation',
    'à réaliser',
    'en cours',
    'validée',
    'Finalisation de la synthèse en cours',
    'Demande de signature',
    'Synthèse',
    'invalidée'
  ];

  static readonly COLLABORATOR_MESSAGE: string[] = [
    'Préparation de l\'entretien à réaliser',
    'Préparation de l\'entretien en cours',
    'Préparation validée, en attente de l\'entretien',
    'Préparation validée. En attente de la rédaction finale',
    'Synthèse validée, en attente de la signature collaborateur',
    'Synthèse invalidée. En attente de la rédaction finale'
  ];

  /**
   * Annual reports filter
  */
  static readonly CAMPAIGN_FILTER_YEAR_BOUND: number = 4;
  static readonly AR_FILTER_OFFICE_ALL_LABEL: string = 'Toutes les agences';
  static readonly AR_FILTER_CAMPAIGN_PREFIX: string = 'Campagne ';
  static readonly AR_FILTER_STATUS_VALID: string = 'validated';
  static readonly AR_FILTER_STATUS_UNVALID: string = 'unvalidated';
  static readonly AR_FILTER_STATUS_ALL: string = 'all';
  static readonly AR_FILTER_STATUS_VALID_LABEL: string = 'EAE validés';
  static readonly AR_FILTER_STATUS_UNVALID_LABEL: string = 'EAE non validés';
  static readonly AR_FILTER_STATUS_ALL_LABEL: string = 'Tous les EAE';

  /**
   * Calendar locale
  */
  static readonly CALENDAR_FR = {
    firstDayOfWeek: 1,
    dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    dayNamesMin: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
  };

  /**
   * Course request type
   */
  static readonly COURSE_REQUEST_TYPE: string[] = [
    'Demande de formation',
    'Proposition de formation',
  ];

  /**
    * Course request type
    */
  static readonly REQUEST_EMITTER_TYPE = {
    collaborator: 1,
    manager: 2,
  }

}
