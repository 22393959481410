import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { UserSoftSkill } from '../../../shared/models/user-soft-skill';

@Injectable({
  providedIn: 'root',
})
export class UserSoftSkillService extends ApiService {

  protected prefix(): string {
    return 'userSoftSkill';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(userSoftSkill: UserSoftSkill): Observable<any> {
    return this.put(userSoftSkill);
  }

  doDelete(userSoftSkill: UserSoftSkill): Observable<any> {
    return this.delete(userSoftSkill.id);
  }

  userSofSkillExists(softSkill): Observable<any> {
    return this.get('softSkill/' + softSkill.id).pipe(
      map(items => items.length > 0)
    );
  }

  getUserSoftSkillsByUserId(userId: number): Observable<any> {
    return this.get('/user/' + userId);
  }
}
