import { NotificationService } from '../../../core/services/notification-service/notification.service';
import { ClearActions, GetActions } from './../store/actions.actions';
import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { ActionState } from '../store/actions.state';
import { Actions } from '../../../shared/models/actions';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  @Select(ActionState.getActions)
  public userActions$: Observable<Actions[]>;

  public datesOfActions: any = [];
  public commentOfActions: any = [];
  public list: any = [];
  public notifEmmiter: string;

  public dates: string[] = [];
  public formattedTodayDate: string;
  public formattedYesterdayDate: string;
  public todayDate = new Date();
  public yesterdayDate = new Date();
  public datepipe: DatePipe = new DatePipe('fr-FR');

  public emptyMessage = 'Aucun élément n\'est enregistré.';

  constructor(
    private store: Store,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.yesterdayDate.setDate(this.yesterdayDate.getDate() - 1);
    this.formattedTodayDate = this.datepipe.transform(this.todayDate, 'yyyy-MM-dd', 'fr');
    this.formattedYesterdayDate = this.datepipe.transform(this.yesterdayDate, 'yyyy-MM-dd', 'fr');

    const actionObservable$ = this.store.dispatch(new GetActions());
    const notif$ = this.notificationService.getAll();

    const observable = forkJoin({
        notif$,
        actionObservable$
    });
    observable.subscribe({
      next: value => {
        value.notif$.forEach(element => {
          element.historyType = "notification";
          this.list.push(element);
          if(element.date != null && !this.dates.includes(element.date.split('T')[0])) {
            this.dates.push(element.date.split('T')[0]);
          }
        });
        value.actionObservable$.actions.actions.forEach( element => {
          element.historyType = "action";
          this.list.push(element);
          if(element.date != null && !this.dates.includes(element.date.split('T')[0])) {
            this.dates.push(element.date.split('T')[0]);
          }
        });
      },
      error: (e) => console.log('error', e),
      complete: () => {
        this.dates.sort((a: any, b: any) => new Date(b).getTime() - new Date(a).getTime());
        this.list.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
      }
    });
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new ClearActions());
  }
}
