import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  AddCertification,
  ClearSnapshotCertification,
  DeleteCertification, FilterCertifications, GetCertifications,
  MakeSnapshotCertification,
  RestoreSnapshotCertification,
  UpdateCertification
} from './certifications.actions';
import { Certification } from 'src/app/shared/models/certification';
import { CertificationService } from 'src/app/core/services/certification-services/certification.service';
import { searchIndexOf } from '../../../shared/utils/utils';

export class CertificationStateModel {
  certifications: Certification[];
  snapshot: Certification[];
}

@State<CertificationStateModel>({
  name: 'certifications',
  defaults: {
    certifications: [],
    snapshot: [],
  }
})
@Injectable()
export class CertificationState {

  constructor(private certificationService: CertificationService) {
  }

  @Selector()
  static getCertifications(state: CertificationStateModel) {
    return state.certifications;
  }

  @Selector()
  static getSnapshot(state: CertificationStateModel) {
    return state.snapshot;
  }

  @Action(GetCertifications)
  getAll({getState, setState}: StateContext<CertificationStateModel>) {
    return this.certificationService.getAll().pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        certifications: result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddCertification)
  add({getState, patchState}: StateContext<CertificationStateModel>, {payload}: AddCertification) {
    return this.certificationService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      patchState({
        certifications: [...state.certifications, result]
      });
    }));
  }

  @Action(UpdateCertification)
  update({getState, setState}: StateContext<CertificationStateModel>, {payload}: UpdateCertification) {
    return this.certificationService.doPut(payload).pipe(tap((result) => {
      result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
      const state = getState();
      const certificationsList = [...state.certifications];
      const certificationIndex = certificationsList.findIndex(item => item.id === payload.id);
      certificationsList[certificationIndex] = result;
      setState({
        ...state,
        certifications: certificationsList,
      });
    }));
  }


  @Action(DeleteCertification)
  delete({getState, setState}: StateContext<CertificationStateModel>, {payload}: DeleteCertification) {
    const state = getState();
    return this.certificationService.doDelete(payload.id).pipe(tap(() => {
      const filteredArray = state.certifications.filter(item => item.id !== payload.id);
      setState({
        ...state,
        certifications: filteredArray,
      });
    }));
  }

  @Action(FilterCertifications)
  filter({getState, setState}: StateContext<CertificationStateModel>, {payload}: FilterCertifications) {
    return this.certificationService.getAll().pipe(tap((result) => {
      const state = getState();
      const certifications = result.map( r => {
        r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
        return r;
      });
      const filteredCertifications = certifications.filter(item =>
        item.name && searchIndexOf(item.name, payload) ||
        item.organization && searchIndexOf(item.organization, payload) ||
        item.description && searchIndexOf(item.description, payload));
      setState({
        ...state,
        certifications: filteredCertifications
      });
    }));
  }

  @Action(MakeSnapshotCertification)
  makeSnapShot({getState, setState}: StateContext<CertificationStateModel>) {
      const state = getState();
      setState({
        ...state,
        snapshot: state.certifications.map(item => ({...item}))
      });
  }

  @Action(RestoreSnapshotCertification)
  restoreSnapshot({getState, setState}: StateContext<CertificationStateModel>) {
      const state = getState();
      setState({
        ...state,
        certifications: state.snapshot.map(item => ({...item}))
      });
  }

  @Action(ClearSnapshotCertification)
  clearSnapshot({getState, setState}: StateContext<CertificationStateModel>) {
      const state = getState();
      setState({
        ...state,
        snapshot: null,
      });
  }

}
