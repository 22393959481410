import {Selector, State, Action, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {Report} from 'src/app/shared/models/report';
import {ReportService} from 'src/app/core/services/report-services/report.service';
import {AnnualReport} from 'src/app/shared/models/annual-report';
import {AnnualReportService} from 'src/app/core/services/annual-report-service/annual-report.service';
import {
  GetAnnualReportsByUser,
  GetFilteredReports,
  GetReportsByCollaborator,
  SetPendingAnnualReport,
  UpdateAnnualReport,
  ClearPendingAnnualReport,
  GetAnnualReport,
  SetAnnualReportRole,
  GetAnnualReportsByInterviewer,
  GetAnnualReportsByCurrentInterviewer,
  SetAnnualReport,
  ValidateAnnualReport, GetAnnualReportById, GetAnnualReportsByFilters, DeleteReport
} from './report.action';
import {tap} from 'rxjs/operators';

export class ReportStateModel {
  allReports: Report[];
  filteredReports: Report[];
  allAnnualReportsByUser: AnnualReport[];
  allAnnualReportsByInterviewer: AnnualReport[];
  allAnnualReportsByCurrentInterviewer: AnnualReport[];
  pendingAnnualReport: AnnualReport;
  annualReport: AnnualReport;
  role: string;
  error: number;
}

@State<ReportStateModel>({
  name: 'report',
  defaults: {
    allReports : [],
    filteredReports: [],
    allAnnualReportsByUser: [],
    allAnnualReportsByInterviewer: [],
    allAnnualReportsByCurrentInterviewer: [],
    annualReport: null,
    pendingAnnualReport: null,
    role: 'collaborator',
    error: null,
  }
})

@Injectable()
export class ReportState {

  constructor(private reportService: ReportService,
              private annualReportService: AnnualReportService) {
  }

  @Selector()
  static getReportsByCollaborator(state: ReportStateModel) {
    return state.filteredReports;
  }

  @Selector()
  static getAnnualReportsByUser(state: ReportStateModel){
    return state.allAnnualReportsByUser;
  }

  @Selector()
  static getAnnualReportsByInterviewer(state: ReportStateModel){
    return state.allAnnualReportsByInterviewer;
  }

  @Selector()
  static getAnnualReportsByCurrentInterviewer(state: ReportStateModel){
    return state.allAnnualReportsByCurrentInterviewer;
  }

  @Selector()
  static getAnnualReportsByFilters(state: ReportStateModel){
    return state.allReports;
  }

  @Selector()
  static  getValidateReportByInterviewer(state: ReportStateModel){
    return state.allAnnualReportsByInterviewer;
  }

  @Selector()
  static getAnnualReport(state: ReportStateModel){
   return state.annualReport;
  }

  @Selector()
  static getPendingAnnualReport(state: ReportStateModel){
    return state.pendingAnnualReport;
  }

  @Selector()
  static getRole(state: ReportStateModel) {
    return state.role;
  }

  @Selector()
  static getError(state: ReportStateModel){
    return state.error;
  }

  @Action(GetAnnualReportsByUser)
  getAnnualReportsByUser({getState, setState}: StateContext<ReportStateModel>, {user}: GetAnnualReportsByUser){
    return this.annualReportService.getAnnualReportsByUser(user.id).pipe(tap((annualReports: AnnualReport[]) => {
      return setState({
        ...getState(),
        allAnnualReportsByUser : annualReports
      });
    }));
  }

  @Action(GetAnnualReportsByInterviewer)
  getAnnualReportsByInterviewer({getState, setState}: StateContext<ReportStateModel>, {user}: GetAnnualReportsByInterviewer){
    return this.annualReportService.getAnnualReportsByInterviewer(user.id).pipe(tap((annualReports: AnnualReport[]) => {
      return setState({
        ...getState(),
        allAnnualReportsByInterviewer : annualReports
      });
    }));
  }

  @Action(GetAnnualReportsByCurrentInterviewer)
  getAnnualReportsByCurrentInterviewer({getState, setState}: StateContext<ReportStateModel>, {user}: GetAnnualReportsByCurrentInterviewer){
    return this.annualReportService.getAnnualReportsByCurrentInterviewer(user.id).pipe(tap((annualReports: AnnualReport[]) => {
      return setState({
        ...getState(),
        allAnnualReportsByCurrentInterviewer : annualReports
      });
    }));
  }

  @Action(GetAnnualReport)
  getAnnualReport({getState, setState}: StateContext<ReportStateModel>, {user}: GetAnnualReport){
    return this.annualReportService.getAnnualReportsByUser(user.id).pipe(tap((annualReports: AnnualReport[]) => {
      return setState({
        ...getState(),
        annualReport : annualReports.find(report => report),
        pendingAnnualReport: annualReports.find(report => report)
      });
    }));
  }

  @Action(GetAnnualReportById)
  getAnnualReportById({getState, setState}: StateContext<ReportStateModel>, {annualReportId}: GetAnnualReportById){
    setState({
      ...getState(),
      annualReport: null
    });
    return this.annualReportService.getOne(annualReportId).pipe(tap((annualReport: AnnualReport) => {
      return setState({
        ...getState(),
        annualReport
      });
    },
      (e) => {
        return setState({
          ...getState(),
          annualReport: null,
          error: e.status
        });
      }));
  }

  @Action(GetAnnualReportsByFilters)
  getAnnualReportsByFilters({ getState, setState }: StateContext<ReportStateModel>, { status, campaignYear, officeId }: any) {
    return this.annualReportService.getAnnualReportsByFilters(status, campaignYear, officeId).pipe(tap((allReports) => {
      allReports.forEach(report => {
          report.collaboratorValidationStatus = report.collaboratorValidationStatus ? 'Validé' : 'Non validé';
      });
      const state = getState();
      setState({
        ...state,
        allReports
      });
    }));
  }

  @Action(DeleteReport)
  deleteAnnualReport({ getState, setState }: StateContext<ReportStateModel>, {id}: DeleteReport){
    return this.annualReportService.doDelete(id).pipe(tap(() =>{
    const state = getState();
    const newAnnualReports = state.allReports.filter(item => item.id !== id);
    setState({
      ...state,
      allReports: newAnnualReports
      });
    }))
  }

  @Action(GetReportsByCollaborator)
  getAllByCollaborator({ getState, setState }: StateContext<ReportStateModel>, { user, startDate, endDate, search }: any) {
    return this.reportService.getAllByCollaborator(user.id, startDate, endDate).pipe(tap((allReports) => {
      allReports.forEach(report => {
        if (report.type.id === 4 && getState().allAnnualReportsByUser.length != 0) {
          getState().allAnnualReportsByUser.forEach((annualReport) => {
              if(annualReport.id === report.id){
                report.type.name = report.type.name + ' - ' + annualReport.campaignYear;
              }
            })
        }
      });
      const filteredReports = search ? this.setFilteredReports(allReports, search) : allReports;
      const state = getState();
      setState({
        ...state,
        allReports,
        filteredReports
      });
    }));
  }

  @Action(GetFilteredReports)
  getFilteredReports({ getState, setState }: StateContext<ReportStateModel>, { search }: any) {
    const state = getState();
    const filteredReports = search && search !== '' ? this.setFilteredReports(state.allReports, search.toLowerCase()) :
      state.allReports;
    setState({
      ...state,
      filteredReports
    });
  }

  setFilteredReports(allReports, search) {
    return allReports.filter(report => report.type.name.toLowerCase().indexOf(search, 0) !== -1 
    || report.currentInterviewer.fullname.toLowerCase().indexOf(search, 0) !== -1
    || report.interviewer.fullname.toLowerCase().indexOf(search, 0) !== -1);
  }

  @Action(SetPendingAnnualReport)
  setPendingAnnualReport({getState, setState}: StateContext<ReportStateModel>, {pendingAnnualReport}: SetPendingAnnualReport) {
    const state = getState();
    setState({
      ...state,
      pendingAnnualReport,
    });
  }

  @Action(SetAnnualReport)
  setAnnualReport({getState, setState}: StateContext<ReportStateModel>, {annualReport}: SetAnnualReport) {
    const state = getState();
    setState({
      ...state,
      annualReport,
    });
  }

  @Action(SetAnnualReportRole)
  setAnnualReportRole({getState, setState}: StateContext<ReportStateModel>, {role}: SetAnnualReportRole) {
    const state = getState();
    setState({
      ...state,
      role,
    });
  }

  @Action(UpdateAnnualReport)
  updateAnnualReport({getState, setState}: StateContext<ReportStateModel>, {role}: ValidateAnnualReport){
    const state = getState();
    if (role === 'collaborator' && state.pendingAnnualReport.workflowCollaborator === 1 && state.pendingAnnualReport.workflowManager < 4) { state.pendingAnnualReport.workflowCollaborator = 2; }
    if (role === 'currentInterviewer' && state.pendingAnnualReport.workflowManager === 1) { state.pendingAnnualReport.workflowManager = 2; }
    const params = new Map();
    params.set('role', role);
    return this.annualReportService.doPut(state.pendingAnnualReport, params).pipe(tap(() => {
      setState({
        ...state,
        annualReport: getState().pendingAnnualReport
      });
    }));
}

  @Action(ValidateAnnualReport)
  validateAnnualReport({getState, setState}: StateContext<ReportStateModel>, {workflow, role}: ValidateAnnualReport){
    const state = getState();
    switch (workflow){
     case  'preparation':
         state.pendingAnnualReport.workflowCollaborator = 3;
         state.pendingAnnualReport.preparationValidationDate = new Date().toISOString();
         break;
      case  'validation':
        if (role === 'collaborator') {
          state.pendingAnnualReport.workflowCollaborator = 5;
          state.pendingAnnualReport.collaboratorValidationDate = new Date().toISOString();
        }
        if (role === 'currentInterviewer') {
          state.pendingAnnualReport.workflowManager = 5;
          state.pendingAnnualReport.managerValidationDate = new Date().toISOString();
        }
        break;
      case 'invalidation':
        state.pendingAnnualReport.workflowCollaborator = 6;
        state.pendingAnnualReport.workflowManager = 4;
        break;
    }
    const params = new Map();
    params.set('role', role);
    return this.annualReportService.doPut(state.pendingAnnualReport, params).pipe(tap(() => {
      setState({
        ...state,
        annualReport: {...getState().pendingAnnualReport}
      });
    }));
  }

  @Action(ClearPendingAnnualReport)
  clearPendingAnnualReport({getState, setState}: StateContext<ReportStateModel>) {
    const state = getState();
    setState({
      ...state,
      allReports : [],
      filteredReports: [],
      allAnnualReportsByUser: [],
      allAnnualReportsByInterviewer: [],
      annualReport: null,
      pendingAnnualReport: null,
      role: 'collaborator'
    });
  }

}
