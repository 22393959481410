import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Assignement } from '../../../shared/models/assignement';
import { tap } from 'rxjs/operators';
import {
  AddAssignment,
  ClearAssignments,
  DeleteAssignment,
  GetAssignmentsByUser,
  RestoreSnapshotAssignment,
  MakeSnapshotAssignment,
  UpdateAssignment
} from './assignments.actions';
import { AssignmentService } from '../../../core/services/assignment-service/assignment.service';

export class AssignmentStateModel {
  assignments: Assignement[];
  snapshot: Assignement[];

}

@State<AssignmentStateModel>({
  name: 'assignments',
  defaults: {
    assignments: [],
    snapshot: []
  }
})

@Injectable()
export class AssignmentState {

  constructor(private assignmentService: AssignmentService) {
  }

  @Selector()
  static getAssignmentsByUser(state: AssignmentStateModel) {
    return state.assignments;
  }

  @Action(GetAssignmentsByUser)
  getAllByUserId({getState, setState}: StateContext<AssignmentStateModel>, {payload}: any) {
    return this.assignmentService.getAllByUserId(payload.id).pipe(tap((result) => {
      const state = getState();

      // 1 - alphabetical order for projects if customers are identical
      result.sort((a, b) => (a.project.name.toLowerCase() > b.project.name.toLowerCase()) ? -1 : 1);
      // // 2 - alphabetical order for customers if startDate are identical
      result.sort((a, b) => (a.project.customer.name.toLowerCase() > b.project.customer.name.toLowerCase()) ? 1 : -1);

      setState({
        ...state,
        assignments: result.map(r => {
          if (r.profile == null) {
            r.profile = new Object();
            r.profile.name = ' ';
            r.profile.id = '';
          }
          return this.assignmentService.mapAssignment(r);
        }).sort((a, b) => (a.startDate > b.startDate) ? -1 : 1),
      });
    }));
  }


  @Action(AddAssignment)
  add({getState, patchState}: StateContext<AssignmentStateModel>, {payload}: AddAssignment) {
    return this.assignmentService.doPost(payload).pipe(tap((result) => {
      if (result.profile == null) {
        result.profile = new Object();
        result.profile.name = ' ';
        result.profile.id = '';
      }
      const assignment = this.assignmentService.mapAssignment(result);
      const state = getState();
      const assignments = [...state.assignments, assignment];
      this.assignmentService.sortAssignments(assignments);
      patchState({
        assignments
      });
    }));
  }

  @Action(UpdateAssignment)
  update({getState, setState}: StateContext<AssignmentStateModel>, {payload}: UpdateAssignment) {
    return this.assignmentService.doPut(payload).pipe(
      tap((result) => {
        const assignment = this.assignmentService.mapAssignment(result);
        const state = getState();
        const assignments = [...state.assignments];
        const assignmentIndex = assignments.findIndex(item => item.id === payload.id);
        assignments[assignmentIndex] = assignment;
        this.assignmentService.sortAssignments(assignments);
        setState({
          ...state,
          assignments
        });
      }));
  }

  @Action(DeleteAssignment)
  delete({getState, setState}: StateContext<AssignmentStateModel>, {payload}: DeleteAssignment) {
    const state = getState();
    return this.assignmentService.doDelete(payload.id).pipe(tap(() => {
      const filteredArray = state.assignments.filter(item => item.id !== payload.id);
      setState({
        ...state,
        assignments: filteredArray,
      });
    }));
  }

  @Action(MakeSnapshotAssignment)
  makeSnapShot({getState, setState}: StateContext<AssignmentStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.assignments.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotAssignment)
  restoreSnapshot({getState, setState}: StateContext<AssignmentStateModel>) {
    const state = getState();
    setState({
      ...state,
      assignments: state.snapshot.map(item => ({...item}))
    });
  }

  @Action(ClearAssignments)
  clearAssignments({getState, setState}: StateContext<AssignmentStateModel>) {
    const state = getState();
    setState({
      ...state,
      assignments: [],
    });
  }
}
