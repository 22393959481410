<div class="fm-top">
  <div class="fm-top-content">
      <div>
          <label>Date de début :</label>
          <p-calendar id="startDate"
                      [attr.data-testid]="'request_field_startDate'"
                      [(ngModel)]="startDate"
                      placeholder="jj/mm/aaaa"
                      dateFormat="dd/mm/yy"
                      [locale]="fr"
                      appendTo="body">
          </p-calendar>
      </div>
      <div>
          <label>Date de fin :</label>
          <p-calendar id="endDate"
                      [attr.data-testid]="'request_field_endDate'"
                      [(ngModel)]="endDate"
                      placeholder="jj/mm/aaaa"
                      dateFormat="dd/mm/yy"
                      [locale]="fr"
                      appendTo="body">
          </p-calendar>
      </div>
      <div>
          <app-fm-button type="text-transparent" [attr.data-testid]="'request_button_validate'"
                         [btnText]="'Valider'.toUpperCase()" (onClick)="handleFilterValidation()">
          </app-fm-button>
      </div>
  </div>
</div>
<div class="fm-actions">
  <input [attr.data-testid]="'request_input_search'" pInputText type="select" placeholder="Rechercher"
         [(ngModel)]="search" (ngModelChange)="handleSearch()" />
</div>
<hr>
<app-datatable
  [attr.data-testid]="'request_dataTable_component'"
  [data]="requests$ | async"
  [columns]="requestDataColumns" [emptyMessage]="emptyMessage"
  defaultSortField="date"
  defaultSortOrder="-1">
</app-datatable>
