import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})

export class ChipComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  name: string;

  @Input()
  levelLabel: string;

  @Input()
  interestLabel: string;

  @Input()
  organizationLabel: string;

  @Input()
  descriptionLabel: string;

  @Input()
  type: string;

  @Input()
  chipsClass: string;

  @Input()
  opacity: boolean;

  @Input()
  picto = false;

  @Input()
  module: string;

  @Input()
  isValidated: boolean;

  tooltipMsg: string;

  ngOnInit(): void {
    switch (this.type) {
      case 'profile':
      case 'skill':
      case 'specialty':
        const isLevelLabel = null != this.levelLabel && this.levelLabel.length > 1 ? ' - niveau ' + this.levelLabel  : '' ;
        const isInterestLabel = null != this.interestLabel && this.interestLabel.length > 1 ? ' - ' + this.interestLabel : '' ;
        this.tooltipMsg = this.name + isLevelLabel + isInterestLabel;
        break;
      case 'course':
      case 'certification':
        const isOrganization = null != this.organizationLabel && this.organizationLabel.length > 1 ? ' (' + this.organizationLabel + ')' : '' ;
        this.tooltipMsg = this.name + isOrganization ;
        break;
      case 'customer':
        const isDescription = null != this.descriptionLabel && this.descriptionLabel.length > 1 ? ' (' + this.descriptionLabel + ')' : '' ;
        this.tooltipMsg = this.name + isDescription;
        break;
      default: {
        this.tooltipMsg = '';
      }
    }
  }

}
