import { SkillService } from '../../../../../core/services/skill-service/skill.service';
import { ClearSnapshotSkill, RestoreSnapshotSkill } from '../../../store/skills.actions';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { MessageService, SelectItem } from 'primeng/api';
import { Skill } from 'src/app/shared/models/skill';
import { Profile } from 'src/app/shared/models/profile';
import { UserSkillService } from 'src/app/core/services/user-skill-services/user-skill.service';
import { ProfileService } from 'src/app/core/services/profile-services/profile.service';
import {
  AddSkill,
  DeleteSkill,
  GetSkills,
  MakeSnapshotSkill,
  UpdateSkill,
} from '../../../store/skills.actions';
import { SkillState, SkillStateModel } from '../../../store/skills.state';
import { FormInlineComponent } from 'src/app/shared/components/form-inline/form-inline.component';
import {Constants} from '../../../../../shared/constants/constants';
import {UploadService} from '../../../../../core/services/upload-services/upload.service';

@Component({
  selector: 'app-view-skills',
  templateUrl: './view-skills.component.html',
  styleUrls: ['./view-skills.component.scss'],
})
export class ViewSkillsComponent implements OnInit, OnDestroy {
  @Select(SkillState.getSkills) skills$: Observable<Skill[]>;

  @ViewChild(FormInlineComponent)
  private formSkill: FormInlineComponent;

  @Input()
  showAddForm: boolean;

  @Input()
  isRedirected: boolean;

  titleForm = 'Ajouter une compétence';

  private stateSnapshot: SkillStateModel;
  private itemToDelete: Skill;

  modal: any = {};
  showModal = false;
  disableCreationSave = false;

  profiles: Profile[] = [];
  profilesSelection: SelectItem[] = [];
  subscriptions: Subscription;

  skillsCategories: string[] = [];
  skillsCategoriesSuggestions$ = new BehaviorSubject<string[]>(null);

  inputForm: any = [];
  uploadKey = 'uploadSkill';
  defaultImage = '';

  skillByProfileId: Skill[]=[];
  skills: Skill[]=[];
  skillToMergeFrom: Skill;
  skillToMergeTo: Skill;

  skillDataColumns: any[];
  skillActionsColumns: any[];
  emptyMessage = 'Aucune compétence enregistrée';
  mergeSkillsSubscription: Subscription;

  constructor(
    private userSkillService: UserSkillService,
    private messageService: MessageService,
    private skillService: SkillService,
    private profileService: ProfileService,
    private uploadService: UploadService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    if (!this.isRedirected) {
      this.store.dispatch(new GetSkills());
    }

    const profiles$ = this.profileService.getAll();
    const defaultImage$ = this.uploadService.getDefaultImageToString();
    const observable = forkJoin({
      profiles$,
      defaultImage$,
    });

    this.subscriptions = observable.subscribe({
      next: value => {
        this.defaultImage = value.defaultImage$;
        value.profiles$.map(
          (profile) => {
            this.profiles.push(profile);
            this.profilesSelection.push({label: profile.name, value: profile});
          }
        );
        this.initCategories();
        this.initDatatable();
        this.initForm();
      },
      error: (e) => this.displayError(e.status)
    });
  }

  initCategories() {
    this.skills$.subscribe(
      result => {
        this.skills = [];
        this.skillsCategories = [];
        result.map(skill => {
          if (!this.skills.includes(skill)) {
            this.skills.push(skill);
          }

          if (!this.skillsCategories.includes(skill.category)) {
            this.skillsCategories.push(skill.category);
          }
        });
      },
      e => this.displayError(e.status),
      () =>{
        this.skillsCategories.sort();
      }
    );
  }

  initDatatable() {
    this.skillDataColumns = [
      {
        type: 'image',
        field: 'image',
        label: '',
        sort: false,
      },
      {
        type: 'text',
        field: 'name',
        label: 'Nom',
        font: 'semibold',
        sort: true,
        maxLength: Constants.NAME_SIZE_MAX
      },
      {
        type: 'autocomplete',
        field: 'category',
        label: 'Catégorie',
        font: 'regular',
        sort: true,
        suggestions: this.skillsCategoriesSuggestions$,
        suggestionField: null,
        maxLength: Constants.CATEGORY_SIZE_MAX,
        filterItems: (event) => this.filterCategories(event.query)
      },
      {
        type: 'select',
        options: this.profilesSelection,
        field: 'profile',
        subField: 'name',
        label: 'Poste',
        font: 'semibold',
        placeholder: 'Poste',
        clearable: true,
        sort: true,
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: true,
      },
      {
        type: 'action',
        action: 'edit',
      },
      {
        type: 'action',
        action: 'fusion',
      },
      {
        type: 'action',
        action: 'delete',

      }
    ];
  }

  initForm(){
      this.inputForm = [
        {
          key: this.uploadKey,
          typeForm: 'image',
          fieldForm: 'image',
          labelForm: 'IMAGE',
          valueForm: this.defaultImage,
          requiredForm: true,
        },
        {
            key: 'nameSkill',
            typeForm: 'text',
            fieldForm: 'name',
            labelForm: 'Compétence',
            valueForm: '',
            requiredForm: true,
            maxLength: Constants.NAME_SIZE_MAX
        },
         {
            key: 'nameProfile',
            typeForm: 'dropdown',
            fieldForm: 'profile',
            labelForm: 'Poste',
            requiredForm: false,
            optionsForm: this.profiles,
            placeHolder: 'Poste'
        },
        {
          key: 'nameCategory',
          typeForm: 'autocomplete',
          fieldForm: 'category',
          labelForm: 'Catégorie',
          requiredForm: false,
          placeHolder: 'Catégorie',
          suggestions: this.skillsCategoriesSuggestions$,
          suggestionField: null,
          filterItems: (event) => this.filterCategories(event.query),
          maxLength: Constants.CATEGORY_SIZE_MAX,
        }
    ];
  }

  filterCategories(query) {
    this.skillsCategoriesSuggestions$.next(
      this.skillsCategories.filter(
        category => category && category.toLowerCase().indexOf(query.toLowerCase()) !== -1
      ));
  }

  handleCreateItem(skill: Skill) {
    if (!this.errorsValidation(skill)) {
      this.store.dispatch(new AddSkill(skill)).subscribe(() => {
          this.displayMessage('create');
          this.formSkill.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  handleUpdateItem(skill: Skill) {
    this.disableCreationSave = true;
    if (skill.profile){
      const index = this.profiles.findIndex(item => item.id === skill.profile.id);
      this.profiles.splice(index, 1);
  
      const empty = this.profilesSelection.findIndex(item => item.label === "Aucun poste");
      if(empty != -1){
        this.profilesSelection.splice(empty, 1);
      }

      const profile = this.profilesSelection.findIndex(item => item.label === skill.profile.name);
      this.profilesSelection.splice(profile,1);
      this.profilesSelection.sort((a, b) => (a.value.name > b.value.name) ? 1 : -1)
      this.profilesSelection.unshift({ label: skill.profile.name, value: skill.profile });
      
    }else{
      
      this.profilesSelection.sort((a, b) => (a.value.name > b.value.name) ? 1 : -1)
      const index = this.profilesSelection.findIndex(item => item.label === "Aucun poste");

      if(index != -1){
        this.profilesSelection.splice(index, 1);
      }
      this.profilesSelection.unshift({ label: 'Aucun poste', value: null });
      
    }
    
    this.store.dispatch(new MakeSnapshotSkill());
  }

  handleSaveUpdateItem(skill: Skill) {
      if (!this.errorsValidation(skill)) {
        this.store.dispatch(new UpdateSkill(this.updateSkill(skill))).subscribe(
          () => {
            this.displayMessage('update');
            this.disableCreationSave = false;
          },
          (e) => {
            this.displayError(e.status);
            this.store.dispatch(new RestoreSnapshotSkill());
            this.store.dispatch(new ClearSnapshotSkill());
            this.disableCreationSave = false;
          }
        );
      }
  }

  handleCancelEditItem() {
    this.disableCreationSave = false;
    this.store.dispatch(new RestoreSnapshotSkill());
    this.store.dispatch(new ClearSnapshotSkill());
  }

  handleModalConfirm() {
    this.deleteItem();
  }

  handleDeleteItem(id: number) {
    this.stateSnapshot = this.store.snapshot().skills;
    this.itemToDelete = this.stateSnapshot.skills.find((s) => s.id === id);

    this.userSkillService.userSkillExists(this.itemToDelete).subscribe(
      (response) => {
        if (response) {
          this.displayError('userSkillExists');
        } else {
          this.displayModal('delete', null);
        }
      },
      (e) => this.displayError(e.status)
    );
  }

  handleFusionItem(skill: Skill) {
    this.skillToMergeFrom = skill;
    this.displayModal('fusion', skill);
  }

  handleFusionConfirm(skill: Skill) {
    this.skillToMergeTo = skill;
    let ids: any[]=[];
    ids.push(this.skillToMergeFrom.id);
    ids.push(this.skillToMergeTo.id);
    this.mergeSkillsSubscription = this.userSkillService.mergeSkills(ids).subscribe(
      () => this.store.dispatch(new GetSkills()),
      error => console.log(error),
      () => {
        this.displayMessage('fusion');
        this.mergeSkillsSubscription.unsubscribe();
      }
    );
  }

  deleteItem() {
    this.store.dispatch(new DeleteSkill(this.itemToDelete)).subscribe(
      () => this.displayMessage('delete'),
      (e) => this.displayError(e.status)
    );
  }

  updateSkill(skill: Skill): Skill {
    return {
      id: skill.id,
      name: skill.name,
      profile: {
        id: skill.profile ? skill.profile.id : null,
      },
      category: skill.category,
      archivingDate: skill.archivingDate
        ? new Date(skill.archivingDate).toISOString()
        : null,
      image: skill.image,
    };
  }
    
  

  errorsValidation(skill) {
    let errors = false;
    if (skill.name === '') {
      this.displayError('name');
      errors = true;
    }
    return errors;
  }

  displayModal(type, data) {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      case 'fusion': {
        this.skillByProfileId = this.skills.filter(skill => skill.id != data.id)
        this.skillByProfileId.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.modal.header = 'Fusion d\'une compétence';
        this.modal.content = 'Vers quelle compétence souhaitez-vous fusionner <b>« ' + data.name +' »</b> ? ' +
        '</br>Le logo, le nom, la catégorie de la compétence sélectionnée ci-dessous seront conservés.';
        this.modal.type = 'fusion';
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  displayMessage(typeError) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (typeError) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La compétence a bien été sauvegardée.';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La compétence a bien été sauvegardée.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'La compétence a bien été supprimée.';
        break;
      }
      case 'fusion': {
        severity = 'success';
        summary = 'Fusion';
        detail = 'La compétence a bien été fusionnée.';
        break;
      }
    }
    this.messageService.add({ severity, summary, detail });
  }

  displayError(e) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Cette compétence existe déjà.';
        break;
      case 'name':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner le nom de la compétence.';
        break;
      case 'userSkillExists':
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Cette compétence est attribuée à un utilisateur.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
