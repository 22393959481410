import {User} from '../../../shared/models/user';

export class SetCurrentProfileUser {
  static readonly type = '[User Profile] Set current user';
}

export class UpdateCurrentProfileUser {
  static readonly type = '[User Profile] Update current user';
}

export class ClearCurrentProfileUser {
  static readonly type = '[User Profile] Clear current user';
}

export class UpdateProfileUser {
  static readonly type = '[User Profile] Update profile user';
  constructor(public payload: User) {}
}

export class SetProfileIsUpdateView {
  static readonly type = '[User Profile] Set profile is update view';
  constructor(public payload: boolean) {}
}
