import {Component, Input, OnInit} from '@angular/core';
import {IconService} from '../../../core/services/icon-service/icon.service';
import {Constants} from '../../constants/constants';

@Component({
  selector: 'app-chips-list',
  templateUrl: './chips-list.component.html',
  styleUrls: ['./chips-list.component.scss']
})
export class ChipsListComponent implements OnInit {

  @Input()
  chipsData = [];

  @Input()
  chipsLabel: any;

  @Input()
  notDisplayChipslabel = false;

  @Input()
  chipsNumber = 0;

  @Input()
  subFieldName = '';

  @Input()
  picto = false;

  @Input()
  opacity: boolean;

  @Input()
  dots: boolean;

  @Input()
  module: string;

  tooltipMsgDots: string;

  listWrap = '';

  maxWidth: number;

  properties = ['certification', 'course', 'profile', 'skill', 'specialty', 'customer'];

  constants = Constants;

  constructor(public iconService: IconService) { }

  ngOnInit(): void {
    let nbLetters = 0;
    this.dots = false;
    let msg = '';
    if (this.module === 'profile'){
      this.listWrap = 'p-flex-wrap';
      this.chipsNumber = 300;
    } else {
      if (this.chipsData != null && this.chipsData.length > 0) {
        this.maxWidth = (this.module === 'home') ? 34 : 26;
        this.properties.forEach(property => {
          if (property in this.chipsData[0]) {
            if (property === 'certification') {
              this.chipsNumber = 1;
              this.dots = (this.chipsNumber < this.chipsData.length);
              if (this.dots){
                for (let j = 0; j < this.chipsData.length; j++) {
                  msg += j === this.chipsData.length || j === 0 ? '' : ', ';
                  msg += this.chipsData[j][property].name;
                }
              }
            } else {
              let i = 0;
              while (i < this.chipsData.length) {
                // maximum width available, if it goes over, delete other items
                const totalLetters = nbLetters + this.chipsData[i][property].name.length + 2;
                if (totalLetters > this.maxWidth && i > 0) {
                  for (let j = 0; j < this.chipsData.length; j++) {
                    msg += j === this.chipsData.length || j === 0 ? '' : ', ';
                    msg += this.chipsData[j][property].name;
                    this.tooltipMsgDots = msg;
                  }
                  const deleteNumber = this.chipsData.length - i;
                  this.chipsData.splice(i, deleteNumber);
                  this.dots = true;

                } else {
                  nbLetters = nbLetters + this.chipsData[i][property].name.length + 2;
                  i++;
                  this.chipsNumber = this.chipsNumber + 1;
                }
              }
            }
          }
        });
      }
    }
    this.tooltipMsgDots = msg;
  }

  isValidated(data: any): boolean {
    return data.validation === undefined ? true : !!data.validation;
  }
}
