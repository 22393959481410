import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {ManagerRequest} from '../../app/shared/models/manager-request';
import {ManagerRequestService} from '../../app/core/services/manager-request-service/manager-request.service';
import {
  AddManagerRequest, DeleteManagerRequest,
  FilterManagerRequest,
  GetAllManagerRequests, UpdateManagerRequest,
} from './manager-request.action';
import {searchIndexOf} from '../../app/shared/utils/utils';

export class ManagerRequestStateModel {
  allManagerRequests: ManagerRequest[];
  managerRequest: ManagerRequest[];
}

@State<ManagerRequestStateModel>({
  name: 'managerRequest',
  defaults: {
    allManagerRequests : [],
    managerRequest: null
  }
})

@Injectable()
export class ManagerRequestState {

  constructor(private managerRequestService: ManagerRequestService) {
  }

  @Selector()
  static getAllManagerRequests(state: ManagerRequestStateModel){
    return state.allManagerRequests;
  }

  @Selector()
  static getManagerRequest(state: ManagerRequestStateModel){
    return state.managerRequest;
  }

  @Action(AddManagerRequest)
  add({getState, patchState}: StateContext<ManagerRequestStateModel>, {payload}: AddManagerRequest) {
    return this.managerRequestService.doPost(payload).pipe(tap((result) => {
      return result;
    }));
  }

  @Action(GetAllManagerRequests)
  getAll({ getState, setState}: StateContext<ManagerRequestStateModel>){
    return this.managerRequestService.getAll().pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          allManagerRequests: result,
        });
      })
    );
  }

  @Action(FilterManagerRequest)
  filter({getState, setState}: StateContext<ManagerRequestStateModel>, {payload}: FilterManagerRequest) {
    return this.managerRequestService.getAll().pipe(tap((result) => {
      const state = getState();
      const managerRequest = result.map( r => {
        r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
        return r;
      });
      const filteredManagerRequest = managerRequest.filter(item =>
        item.collaborator.fullname && searchIndexOf(item.collaborator.fullname, payload) ||
        item.manager.fullname && searchIndexOf(item.manager.fullname, payload));
      setState({
        ...state,
        allManagerRequests: filteredManagerRequest
      });
    }));
  }


  @Action(UpdateManagerRequest)
  updateManagerRequest({getState, setState}: StateContext<ManagerRequestStateModel>, { payload }: UpdateManagerRequest){
    return this.managerRequestService.doPut(payload).pipe(tap((result) => {
      return result;
    }));
  }

  @Action(DeleteManagerRequest)
  delete({getState, setState}: StateContext<ManagerRequestStateModel>, {payload}: DeleteManagerRequest) {
    const state = getState();
    return this.managerRequestService.doDelete(payload).pipe(tap(() => {
      const filteredArray = state.allManagerRequests.filter(item => item.id !== payload.id);
      setState({
        ...state,
        allManagerRequests: filteredArray,
      });
    }));
  }
}
