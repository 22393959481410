import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetProfileUserCertificationsByUserId,
  AddProfileUserCertification,
  UpdateProfileUserCertification,
  MakeSnapshotProfileUserCertification,
  RestoreSnapshotProfileUserCertification,
  ClearProfileUserCertifications, DeleteProfileUserCertification
} from './profile-certifications.actions';
import { UserCertificationService } from '../../../core/services/user-certification-services/user-certification.service';
import { UserCertification } from '../../../shared/models/user-certification';

export class ProfileCertificationStateModel {
  userCertifications: UserCertification[];
  snapshot: UserCertification[];
  httpStatus: number;
}

@State<ProfileCertificationStateModel>({
  name: 'profileUserCertifications',
  defaults: {
    userCertifications: [],
    snapshot: [],
    httpStatus: null,
  }
})
@Injectable()
export class ProfileCertificationState {

  constructor(private userCertificationService: UserCertificationService) {
  }

  @Selector()
  static getUserCertifications(state: ProfileCertificationStateModel) {
    return state.userCertifications;
  }

  @Action(GetProfileUserCertificationsByUserId)
  getProfileUserCertificationsByUserId(
    {getState, setState}: StateContext<ProfileCertificationStateModel>,
    {payload}: GetProfileUserCertificationsByUserId
  ) {
    return this.userCertificationService.getUserCertificationsByUserId(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userCertifications: result.map( r => {
          r.startDate = r.startDate ? new Date(r.startDate) : null;
          r.endDate = r.endDate ? new Date(r.endDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddProfileUserCertification)
  add({getState, patchState}: StateContext<ProfileCertificationStateModel>, {payload}: AddProfileUserCertification) {
    return this.userCertificationService.doPost(payload).pipe(tap((result) => {
      result.startDate = result.startDate ? new Date(result.startDate) : null;
      result.endDate = result.endDate ? new Date(result.endDate) : null;
      const state = getState();
      patchState({
        userCertifications: [...state.userCertifications, result]
      });
    }));
  }

  @Action(UpdateProfileUserCertification)
  update({getState, setState}: StateContext<ProfileCertificationStateModel>, {payload}: UpdateProfileUserCertification) {
    return this.userCertificationService.doPut(payload).pipe(
      tap((result) => {
        result.startDate = result.startDate ? new Date(result.startDate) : null;
        result.endDate = result.endDate ? new Date(result.endDate) : null;
        const state = getState();
        const userCertificationsList = [...state.userCertifications];
        const userCertificationIndex = userCertificationsList.findIndex(item => item.id === payload.id);
        userCertificationsList[userCertificationIndex] = result;
        setState({
          ...state,
          userCertifications: userCertificationsList,
        });
    }));
  }

  @Action(DeleteProfileUserCertification)
  delete({getState, setState}: StateContext<ProfileCertificationStateModel>, {payload}: DeleteProfileUserCertification) {
    const state = getState();
    return this.userCertificationService.doDelete(payload.id, {observe: 'response'}).pipe(tap((observe) => {
      if (observe.status === 200) {
        const filteredArray = state.userCertifications.filter(item => item.id !== payload.id);
        setState({
          ...state,
          userCertifications: filteredArray,
          httpStatus: 200,
        });
      } else {
        setState({
          ...state,
          httpStatus: 202,
        });
      }
    }));
  }

  @Action(MakeSnapshotProfileUserCertification)
  makeSnapShot({getState, setState}: StateContext<ProfileCertificationStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.userCertifications.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotProfileUserCertification)
  restoreSnapshot({getState, setState}: StateContext<ProfileCertificationStateModel>) {
    const state = getState();
    setState({
      ...state,
      userCertifications: state.snapshot.map(item => ({...item}))
    });
  }

  @Action(ClearProfileUserCertifications)
  clearProfileUserCertifications({getState, setState}: StateContext<ProfileCertificationStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: [],
      userCertifications: [],
    });
  }
}
