import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { MessageService, SelectItem } from 'primeng/api';
import { ProfileState, ProfileStateModel } from '../../../store/profiles.state';
import {
  ClearSnapshotProfile,
  DeleteProfile,
  GetProfiles,
  MakeSnapshotProfile,
  RestoreSnapshotProfile,
  UpdateProfile,
} from '../../../store/profiles.actions';
import { AddProfile } from '../../../store/profiles.actions';
import { FormInlineComponent } from 'src/app/shared/components/form-inline/form-inline.component';
import { Profile } from 'src/app/shared/models/profile';
import { UserProfileService } from '../../../../../core/services/user-profile-services/user-profile.service';
import {Constants} from '../../../../../shared/constants/constants';

@Component({
  selector: 'app-view-profiles',
  templateUrl: './view-profiles.component.html',
  styleUrls: ['./view-profiles.component.scss'],
})
export class ViewProfilesComponent implements OnInit {
  @Select(ProfileState.getProfiles) profiles$: Observable<Profile[]>;

  @ViewChild(FormInlineComponent)
  private formProfile: FormInlineComponent;

  @Input()
  showAddForm: boolean;

  @Output()
  eventRedirect = new EventEmitter<Event>();

  titleForm = 'Ajouter un poste';

  private stateSnapshot: ProfileStateModel;
  private itemToDelete: Profile;

  modal: any = {};
  showModal = false;
  disableCreationSave = false;

  profiles: Profile[]=[];
  profileToMergeFrom: Profile;
  profileToMergeTo: Profile;
  profilesToMerge: Profile[]=[];
  mergeProfilesSubscription: any;

  profilesCategories: string[] = [];
  profilesCategoriesSuggestions$ = new BehaviorSubject<string[]>(null);

  inputForm: any = [];

  profileDataColumns: any[];
  emptyMessage = 'Aucun poste enregistré';

  constructor(private messageService: MessageService,
              private userProfileService: UserProfileService,
              private store: Store) {
    this.store.dispatch(new GetProfiles());
  }

  ngOnInit(): void {
    this.initCategories();
    this.initDatatable();
    this.initForm();
  }

  initCategories() {
    this.profiles$.subscribe(
      result => {
        this.profiles = [];
        this.profilesCategories = [];
        result.map(profile => {
          if (!this.profiles.includes(profile)) {
            this.profiles.push(profile);
          }

          if (!this.profilesCategories.includes(profile.category)) {
            this.profilesCategories.push(profile.category);
          }
        });
      },
      e => this.displayError(e.status),
      () => this.profilesCategories.sort()
    );
  }

  initDatatable() {
    this.profileDataColumns = [
      {
        type: 'link',
        field: 'name',
        label: 'Nom',
        font: 'semibold',
        sort: true,
        maxLength: Constants.NAME_SIZE_MAX
      },
      {
        type: 'autocomplete',
        field: 'category',
        label: 'Catégorie',
        font: 'regular',
        sort: true,
        suggestions: this.profilesCategoriesSuggestions$,
        suggestionField: null,
        maxLength: Constants.CATEGORY_SIZE_MAX,
        filterItems: (event) => this.filterCategories(event.query)
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: true,
      },
      { type: 'action', action: 'edit', width: '8%' },
      {
        type: 'action',
        action: 'fusion',
      },
      { type: 'action', action: 'delete', width: '10%' },
    ];
  }

  initForm() {
    this.inputForm = [
      {
        key: 'nameProfil',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Poste',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.NAME_SIZE_MAX,
      },
      {
        key: 'nameCategory',
        typeForm: 'autocomplete',
        fieldForm: 'category',
        labelForm: 'Catégorie',
        requiredForm: false,
        placeHolder: 'Catégorie',
        suggestions: this.profilesCategoriesSuggestions$,
        suggestionField: null,
        filterItems: (event) => this.filterCategories(event.query),
        maxLength: Constants.CATEGORY_SIZE_MAX,
      }
    ];
  }

  filterCategories(query) {
    this.profilesCategoriesSuggestions$.next(
      this.profilesCategories.filter(
        category => category && category.toLowerCase().indexOf(query.toLowerCase()) !== -1
      ));
  }

  handleCreateItem(profile: Profile) {
    if (!this.errorsValidation(profile)) {
      this.store.dispatch(new AddProfile(profile)).subscribe(
        () => {
          this.displayMessage('create');
          this.formProfile.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  handleUpdateItem() {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotProfile());
  }

  handleSaveUpdateItem(profile: Profile) {
    if (!this.errorsValidation(profile)) {
      this.store.dispatch(new UpdateProfile(this.updateProfile(profile))).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.store.dispatch(new RestoreSnapshotProfile());
          this.store.dispatch(new ClearSnapshotProfile());
          this.disableCreationSave = false;
        }
      );
    }
  }

  // Redirection From Profile to skills
  handleRedirect(profile){
    this.eventRedirect.emit(profile);
  }

  handleCancelEditItem() {
    this.disableCreationSave = false;
    this.store.dispatch(new RestoreSnapshotProfile());
    this.store.dispatch(new ClearSnapshotProfile());
  }

  handleDeleteItem(id) {
    this.stateSnapshot = this.store.snapshot().profiles;
    this.itemToDelete = this.stateSnapshot.profiles.find((p) => p.id === id);
    this.userProfileService.userProfileExists(this.itemToDelete).subscribe(
      (response) => {
        if (response) {
          this.displayError('userProfileExists');
        } else {
          this.displayModal('delete', null);
        }
      },
      (e) => this.displayError(e.status)
    );
  }

  handleModalConfirm() {
    this.deleteItem();
  }

  handleFusionItem(profile: Profile) {
    this.profileToMergeFrom = profile;
    this.displayModal('fusion', profile);
  }

  handleFusionConfirm(profile: Profile) {
    this.profileToMergeTo = profile;
    let ids: any[]=[];
    ids.push(this.profileToMergeFrom.id);
    ids.push(this.profileToMergeTo.id);
    this.mergeProfilesSubscription = this.userProfileService.mergeProfiles(ids).subscribe(
      () => this.store.dispatch(new GetProfiles()),
      error => console.log(error),
      () => {
        this.displayMessage('fusion');
        this.mergeProfilesSubscription.unsubscribe();
      }
    );
  }

  deleteItem() {
    this.store.dispatch(new DeleteProfile(this.itemToDelete)).subscribe(
      () => this.displayMessage('delete'),
      (e) => this.displayError(e.status)
    );
  }

  updateProfile(profile: Profile): Profile {
    return {
      id: profile.id,
      name: profile.name,
      category: profile.category,
      archivingDate: profile.archivingDate
        ? new Date(profile.archivingDate).toISOString()
        : null,
    };
  }

  errorsValidation(profile) {
    let errors = false;
    if (profile.name === '') {
      this.displayError('name');
      errors = true;
    }
    return errors;
  }

  displayModal(type, data) {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      case 'fusion': {
        this.profilesToMerge = this.profiles.filter( (profile => profile.id != data.id));
        this.profilesToMerge.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.modal.header = 'Fusion de postes';
        this.modal.content = 'Vers quel poste souhaitez-vous fusionner <b>« ' + data.name +' »</b> ? ' +
        '</br>Le logo, le nom, la catégorie du poste sélectionné ci-dessous seront conservés.';
        this.modal.type = 'fusion';
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  displayMessage(type) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'Le poste a bien été sauvegardé.';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'Le poste a bien été sauvegardé.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'Le poste a bien été supprimé.';
        break;
      }
      case 'fusion': {
        severity = 'success';
        summary = 'Fusion';
        detail = 'Le poste a bien été fusionné.';
        break;
      }
    }
    this.messageService.add({ severity, summary, detail });
  }

  displayError(e) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 400:
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Ce poste est attribué à une compétence ou à une spécialité.';
        break;
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Ce poste existe déjà.';
        break;
      case 'name':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner le nom du poste.';
        break;
      case 'userProfileExists':
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Ce poste est attribué à un utilisateur.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }
}
