export class GetDirectoryUsers {
  static readonly type = '[DirectoryUser] Get all';
}

export class GetTop10DirectoryUsers {
  static readonly type = '[DirectoryUser] Get top 10';
}

export class GetDirectoryUsersOnlyOrigin {
  static readonly type = '[DirectoryUser] Get all only for Origin';
}

export class GetDirectoryUsersOrigin {
  static readonly type = '[DirectoryUser] Get all Origin';
}

export class GetDirectoryUserByUserId {
  static readonly type = '[DirectoryUser] Get by user id';

  constructor(public payload: number) {
  }
}

export class GetDirectoryUsersByProperties {
  static readonly type = '[DirectoryUser] Get user by properties';

  constructor(public payload: string) {
  }
}

export class GetDirectoryUsersBySkills {
  static readonly type = '[DirectoryUser] Get user by skills';

  constructor(public payload: string) {
  }
}

export class ClearDirectoryUser {
  static readonly type = '[DirectoryUser] Clear user';

  constructor() {
  }
}

export class ClearDirectoryUsers {
  static readonly type = '[DirectoryUser] Clear users';

  constructor() {
  }
}
