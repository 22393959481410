import {Component, Input, OnInit} from '@angular/core';
import {Question} from '../../../../shared/models/question';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  @Input()
  question: Question;

  toggleClass = false;

  constructor() { }

  ngOnInit(): void {
  }

  downloadFile(filePath, fileName) {
    if (filePath) {
      saveAs(filePath, fileName);
    }
  }
}
