<div class="fm-title">
  <h1>3. Objectifs individuels</h1>
</div>
<div class="fm-step">
  <form [formGroup]="formData">
    <div class="fm-form p-d-flex p-flex-column">

      <!-- Current goals -->
      <h2>Niveau d'atteinte des objectifs de l'année écoulée</h2>
      <div class="fm-eae-button-showAddForm">
        <h3>Collaborateur</h3>
        <app-fm-button *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          [type]="collaboratorCurrentGoalShowAddForm ? 'hide' : 'show'" (onClick)="toggleCollaboratorCurrentGoalForm()">
        </app-fm-button>
      </div>

      <!-- Collaborator current goals -->
      <app-form-inline class="fm-form-inline" [attr.data-testid]="'formInline_component'" titleForm="Ajouter un objectif"
        [listInput]="collaboratorCurrentGoalInputForm" (eventCreateItem)="handleCollaboratorCurrentGoalCreateItem($event)" *ngIf="role === 'collaborator' &&
          collaboratorCurrentGoalShowAddForm &&
          workflowCollaborator < 3 &&
          workflowManager < 4 &&
          !isTooLate" [saveDisabled]="collaboratorCurrentGoalDisableCreationSave">
      </app-form-inline>
      <hr>
      <app-datatable *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate" [data]="collaboratorCurrentGoals"
        editMode="row" [columns]="currentGoalDataColumns" [emptyMessage]="goalEmptyMessage" (eventDeleteItem)="handleCollaboratorCurrentGoalDeleteItem($event)"
        (eventSaveUpdateItem)="handleCollaboratorCurrentGoalSaveUpdateItem($event)" (eventCancelEditItem)="handleCollaboratorCurrentGoalCancelEditItem()">
      </app-datatable>
      <div class="fm-eae-noAction">
        <app-datatable *ngIf="workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate" [data]="collaboratorCurrentGoals" editMode="row"
          [columns]="currentGoalDataColumnsWithoutActions" [emptyMessage]="goalEmptyMessage">
        </app-datatable>
      </div>
      <span *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate">Le collaborateur n'a pas encore validé sa
        préparation</span>

      <!-- Manager current goals -->
      <div class="fm-eae-button-showAddForm">
        <h3 class="fm-eae-title">Manager</h3>
        <app-fm-button *ngIf="role === 'currentInterviewer' && workflowManager !== 5" [type]="managerCurrentGoalShowAddForm ? 'hide' : 'show'"
          (onClick)="toggleManagerCurrentGoalForm()">
        </app-fm-button>
      </div>
      <app-form-inline class="fm-form-inline" [attr.data-testid]="'formInline_component'" titleForm="Ajouter un objectif"
        [listInput]="managerCurrentGoalInputForm" (eventCreateItem)="handleManagerCurrentGoalCreateItem($event)"
        *ngIf="role === 'currentInterviewer' && managerCurrentGoalShowAddForm && workflowManager !== 5" [saveDisabled]="managerCurrentGoalDisableCreationSave">
      </app-form-inline>
      <hr>
      <app-datatable *ngIf="role === 'currentInterviewer' && workflowManager !== 5" [data]="managerCurrentGoals" editMode="row"
        [columns]="currentGoalDataColumns" [emptyMessage]="goalEmptyMessage" (eventDeleteItem)="handleManagerCurrentGoalDeleteItem($event)"
        (eventSaveUpdateItem)="handleManagerCurrentGoalSaveUpdateItem($event)" (eventCancelEditItem)="handleManagerCurrentGoalCancelEditItem()">
      </app-datatable>
      <div class="fm-eae-noAction">
        <app-datatable *ngIf="workflowManager === 5" [data]="managerCurrentGoals" editMode="row" [columns]="currentGoalDataColumnsWithoutActions"
          [emptyMessage]="goalEmptyMessage">
        </app-datatable>
      </div>
      <span *ngIf="role === 'collaborator' && workflowManager !== 5">Le manager n'a pas complété cette partie</span>

      <!-- Next goals -->
      <h2 class="fm-eae-title">Objectifs pour l'année à venir</h2>
      <div class="fm-eae-button-showAddForm">
        <h3>Collaborateur</h3>
        <app-fm-button *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          [type]="collaboratorNextGoalShowAddForm ? 'hide' : 'show'" (onClick)="toggleCollaboratorNextGoalForm()">
        </app-fm-button>
      </div>
      <!-- Collaborator next goals -->
      <app-form-inline class="fm-form-inline" [attr.data-testid]="'formInline_component'" titleForm="Ajouter un objectif"
        [listInput]="collaboratorNextGoalInputForm" (eventCreateItem)="handleCollaboratorNextGoalCreateItem($event)" *ngIf="role === 'collaborator' &&
          collaboratorNextGoalShowAddForm &&
          workflowCollaborator < 3 &&
          workflowManager < 4 &&
          !isTooLate" [saveDisabled]="collaboratorNextGoalDisableCreationSave">
      </app-form-inline>
      <hr>
      <app-datatable *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate" [data]="collaboratorNextGoals"
        editMode="row" [columns]="nextGoalDataColumns" [emptyMessage]="goalEmptyMessage" (eventDeleteItem)="handleCollaboratorNextGoalDeleteItem($event)"
        (eventSaveUpdateItem)="handleCollaboratorNextGoalSaveUpdateItem($event)" (eventCancelEditItem)="handleCollaboratorNextGoalCancelEditItem()">
      </app-datatable>
      <div class="fm-eae-noAction">
        <app-datatable *ngIf="workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate" [data]="collaboratorNextGoals" editMode="row"
          [columns]="nextGoalDataColumnsWithoutActions" [emptyMessage]="goalEmptyMessage">
        </app-datatable>
      </div>
      <span *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate">Le collaborateur n'a pas encore validé sa
        préparation</span>

      <!-- Manager next goals -->
      <div class="fm-eae-button-showAddForm">
        <h3 class="fm-eae-title">Manager</h3>
        <app-fm-button *ngIf="role === 'currentInterviewer' && workflowManager !== 5" [type]="managerNextGoalShowAddForm ? 'hide' : 'show'"
          (onClick)="toggleManagerNextGoalForm()">
        </app-fm-button>
      </div>
      <app-form-inline class="fm-form-inline" [attr.data-testid]="'formInline_component'" titleForm="Ajouter un objectif" [listInput]="managerNextGoalInputForm"
        (eventCreateItem)="handleManagerNextGoalCreateItem($event)" *ngIf="role === 'currentInterviewer' && managerNextGoalShowAddForm && workflowManager !== 5"
        [saveDisabled]="managerNextGoalDisableCreationSave">
      </app-form-inline>
      <hr>
      <app-datatable *ngIf="role === 'currentInterviewer' && workflowManager !== 5" [data]="managerNextGoals" editMode="row" [columns]="nextGoalDataColumns"
        [emptyMessage]="goalEmptyMessage" (eventDeleteItem)="handleManagerNextGoalDeleteItem($event)"
        (eventSaveUpdateItem)="handleManagerNextGoalSaveUpdateItem($event)" (eventCancelEditItem)="handleManagerNextGoalCancelEditItem()">
      </app-datatable>
      <div class="fm-eae-noAction">
        <app-datatable *ngIf="workflowManager === 5" [data]="managerNextGoals" editMode="row" [columns]="nextGoalDataColumnsWithoutActions"
          [emptyMessage]="goalEmptyMessage">
        </app-datatable>
      </div>
      <span *ngIf="role === 'collaborator' && workflowManager !== 5">Le manager n'a pas complété cette partie</span>

      <!-- Comments -->
      <h2 class="fm-eae-title">Commentaire du collaborateur</h2>
      <p-editor *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
        [style]="{'height':'320px', 'box-shadow': '0 0 10px #1432461A'}" [maxlength]="freeAnswerMaxLength" formControlName="collaboratorGoalComment"
        [(ngModel)]="collaboratorGoalComment" [placeholder]=collaboratorGoalCommentPlaceholder (focusout)="handleChange($event)">
      </p-editor>
      <span *ngIf="collaboratorGoalComment && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)" [innerHTML]="collaboratorGoalComment"
        class="fm-text-editor"></span>
      <span *ngIf="!collaboratorGoalComment && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)" class="fm-text-editor">Le collaborateur n'a pas
        complété cette partie</span>
      <span *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && !isTooLate && workflowManager < 4" class="fm-text-editor">Le collaborateur n'a
        pas encore validé sa préparation</span>

      <h2 class="fm-eae-title">Commentaire du manager</h2>
      <p-editor *ngIf="role === 'currentInterviewer' && workflowManager !== 5" [style]="{'height':'320px', 'box-shadow': '0 0 10px #1432461A'}"
        [maxlength]="freeAnswerMaxLength" formControlName="managerGoalComment" [(ngModel)]="managerGoalComment" [placeholder]=managerGoalCommentPlaceholder
        (focusout)="handleChange($event)">
      </p-editor>
      <span *ngIf="managerGoalComment && workflowManager === 5" [innerHTML]="managerGoalComment" class="fm-text-editor"></span>
      <span *ngIf="managerGoalComment && role === 'collaborator' && workflowManager !== 5" class="fm-text-editor">Le manager n'a pas encore validé sa
        synthèse</span>
      <span *ngIf="!managerGoalComment && (workflowManager === 5 || (role === 'collaborator' && workflowManager !== 5))" class="fm-text-editor">Le manager n'a
        pas complété cette partie</span>
    </div>
  </form>
</div>