import { UserProfile } from '../../../shared/models/user-profile';

export class MakeSnapshotProfileUserProfile {
  static readonly type = '[User Profile User Profile] MakeSnapshot';
}

export class RestoreSnapshotProfileUserProfile {
  static readonly type = '[User Profile User Profile] RestoreSnapshot';
}

export class GetProfileUserProfilesByUserId {
  static readonly type = '[User Profile User Profiles] Get user profiles';
  constructor(public payload: number) {}
}
export class AddProfileUserProfile {
  static readonly type = '[User Profile User Profile] Add';
  constructor(public payload: UserProfile) {}
}

export class UpdateProfileUserProfile {
  static readonly type = '[User Profile User Profile] update';
  constructor(public payload: UserProfile) {}
}

export class DeleteProfileUserProfile {
  static readonly type = '[User Profile User Profile] delete';
  constructor(public payload: UserProfile) {}
}

export class ClearProfileUserProfiles {
  static readonly type = '[User Profile User Profile] Clear user profiles';
}
