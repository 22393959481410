import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { ExportService } from 'src/app/core/services/export-service/export.service';
import { OfficeService } from 'src/app/core/services/office-service/office.service';
import { Constants } from 'src/app/shared/constants/constants';
import { AnnualReport } from 'src/app/shared/models/annual-report';
import { GetAnnualReportsByFilters,ClearPendingAnnualReport, DeleteReport } from 'src/stores/reports/report.action';
import { ReportState } from 'src/stores/reports/report.state';

@Component({
  selector: 'app-annual-report-list',
  templateUrl: './annual-report-list.component.html',
  styleUrls: ['./annual-report-list.component.scss']
})
export class AnnualReportListComponent implements OnInit, OnDestroy {

  @Select(ReportState.getAnnualReportsByFilters)
  annualReports$: Observable<AnnualReport>;

  showModal = false;
  typeModal: string;
  modal: any = {};
  idToDelete: number;

  dataColumns: any[];

  officeOptions: SelectItem[];
  selectedOffice: number;

  campaignOptions: SelectItem[];
  selectedCampaign: number;

  statusOptions: SelectItem[];
  selectedStatus: string;
  officeServiceSubscription: Subscription;

  constructor(
    private store: Store,
    private router: Router,
    private exportService:ExportService,
    private officeService: OfficeService
  ) { }

  ngOnInit(): void {
    this.initDatatable();
    this.initOfficeOptions();
    this.initCampaignOptions();
    this.initStatusOptions();
    this.handleFilterChange();
  }

  ngOnDestroy(): void {
      this.officeServiceSubscription.unsubscribe();
  }

  initOfficeOptions(): void {
    this.officeOptions = [];
    this.officeOptions.push({
      label: Constants.AR_FILTER_OFFICE_ALL_LABEL,
      value: null,
    });
    this.officeServiceSubscription = this.officeService.getAll().subscribe((result) =>
      result.map((office) =>
        this.officeOptions.push({
          label: office.name,
          value: office.id,
        })
      )
    );
    this.selectedOffice = null;
  }

  initCampaignOptions() {
    let currentCampaign = new Date().getFullYear();
    this.campaignOptions = [];
    const LIMIT = Constants.CAMPAIGN_FILTER_YEAR_BOUND;
    for (var y = currentCampaign; y >= currentCampaign - LIMIT; y--) {
      this.campaignOptions.push({
        label: Constants.AR_FILTER_CAMPAIGN_PREFIX + y.toString(),
        value: y,
      });
    }
    this.selectedCampaign = this.campaignOptions[0].value;
  }

  initStatusOptions() {
    this.statusOptions = [
      { label: Constants.AR_FILTER_STATUS_VALID_LABEL, value: Constants.AR_FILTER_STATUS_VALID},
      { label: Constants.AR_FILTER_STATUS_UNVALID_LABEL, value: Constants.AR_FILTER_STATUS_UNVALID},
      { label: Constants.AR_FILTER_STATUS_ALL_LABEL, value: Constants.AR_FILTER_STATUS_ALL},
    ];
    this.selectedStatus = Constants.AR_FILTER_STATUS_VALID;
  }

  initDatatable() {
    this.dataColumns = [
      {
        type: 'text',
        field: 'campaignYear',
        label: 'Campagne',
        font: 'regular',
        sort: false,
      },
      {
        type: 'calendar',
        field: 'interviewStartDate',
        label: 'Date',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'collaborator',
        subField: 'fullname',
        label: 'Collaborateur',
        font: 'regular',
        sort: false,
        isStatic: true
      },
      {
        type: 'text',
        field: 'interviewer',
        subField: 'fullname',
        label: 'Manager',
        font: 'regular',
        sort: false,
        isStatic: true
      },
      {
        type: 'text',
        field: 'collaboratorValidationStatus',
        label: 'Statut',
        font: 'regular',
        sort: false,
      },
      {
        type: 'action',
        label: '',
        action: 'consult',
        width: '10%'
      },
      {
        type: 'action',
        action: 'delete',
        label: '',
        width: '10%'
      },
    ];
  }

  handleDeleteItem(){
    this.store.dispatch(new DeleteReport(this.idToDelete));
  }

  handleFilterChange() {
    this.dispatchAnnualReports();
    this.store.dispatch(new ClearPendingAnnualReport());
  }

  displayModalDelete(id){
    this.modal.header = "Supprimer un entretien annuel d'évaluation";
    this.modal.content = 'Voulez-vous supprimer cet EAE';
    this.modal.type = 'yesOrNo';
    this.idToDelete = id;

    this.showModal = true;
  }

  handleConsultItem(id){
    if(id) {
      this.router.navigateByUrl(`annualreport/details/${id}`);
    }
  }

  public handleCancel(){
    return;
  }

  private dispatchAnnualReports() {
    this.store.dispatch(
      new GetAnnualReportsByFilters(
        this.selectedStatus,
        this.selectedCampaign,
        this.selectedOffice
      )
    );
  }

  public exportAnnualReports() {
    this.exportService.getAnnualReports().subscribe((response) => {
      saveAs(response, 'export_annual_reports' + moment().format('YYYY_DD_MM') + '.csv');
    });
  }
}
