import { User } from '../../../shared/models/user';

export class GetAnnualReportsByCurrentInterviewer {
  static readonly type = '[Report] GetAnnualReportsByCurrentInterviewer';
  constructor(public user: User) {}
}

export class GetReportsByInterviewer {
  static readonly type = '[Report] GetReportsByReporter';
  constructor(public user: User, public startDate: string, public endDate: string, public search: string) {}
}

export class GetReportsByManager {
  static readonly type = '[Report] GetReportsByManager';
  constructor(public user: User, public startDate: string, public endDate: string, public search: string) {}
}

export class GetFilteredReports {
  static readonly type = '[Report] GetFilteredReports';
  constructor(public search: string) {}
}
