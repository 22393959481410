<div class="fm-menu">
    <p-menubar>
        <ul>
            <li *ngIf="displayNavbarLink('home')">
                <img [attr.data-testid]="'menubar_img_home'" routerLink="/home" src="../../../../../assets/icons/fm_logo.svg" alt="" title="">
            </li>
            <li *ngIf="displayNavbarLink('home')" [className]="isActiveLink('home') ? 'link-active' : 'link-not-active'">
                <a [attr.data-testid]="'menubar_a_home'" routerLink="/home" class="border-left"><i class="fm-icon-home"></i>Accueil</a>
            </li>
            <li *ngIf="displayNavbarLink('profile')" [className]="isActiveLink('profile') ? 'link-active' : 'link-not-active'">
                <a [attr.data-testid]="'menubar_a_profile'" routerLink="/profile/show"><i class="fm-icon-doc"></i>Mon profil</a>
            </li>
            <li *ngIf="displayNavbarLink('activity')" [className]="isActiveLink('activity') ? 'link-active' : 'link-not-active'">
                <a [attr.data-testid]="'menubar_a_reports'" routerLink="/activity/reports"><i class="fm-icon-activity"></i>Mon activité</a>
            </li>
            <li *ngIf="displayNavbarLink('team')" [className]="isActiveLink('team') ? 'link-active' : 'link-not-active'">
                <a [attr.data-testid]="'menubar_a_summary'" routerLink="/team/summary/members"><i class="fm-icon-team"></i>Mon équipe</a>
            </li>
            <li *ngIf="displayNavbarLink('directory')" [className]="isActiveLink('directory') ? 'link-active' : 'link-not-active'">
                <a [attr.data-testid]="'menubar_a_directory'" routerLink="/directory/search"><i class="fm-icon-directory"></i>Annuaire</a>
            </li>
            <li *ngIf="displayNavbarLink('parameters')" [className]="isActiveLink('parameters') ? 'link-active' : 'link-not-active'">
                <a [attr.data-testid]="'menubar_a_qualifications'" routerLink="/parameters/qualifications"><i class="fm-icon-config"></i>Paramétrage</a>
            </li>
            <li *ngIf="displayNavbarLink('faq')" [className]="isActiveLink('faq') ? 'link-active' : 'link-not-active'">
              <a [attr.data-testid]="'menubar_a_faq'" routerLink="/faq"><i class="fm-icon-chat"></i>FAQ</a>
            </li>
        </ul>
    </p-menubar>
    <div class="fm-notification">
        <app-notification></app-notification>
    </div>
    <p-menubar [model]="menuLogoutOptions" class="fm-profile"></p-menubar>
</div>
