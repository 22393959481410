import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Store } from '@ngxs/store';
import { FilterProfiles, GetProfiles } from '../../store/profiles.actions';
import { FilterSkills, GetSkills } from '../../store/skills.actions';
import { FilterSpecialties, GetSpecialties } from '../../store/specialties.actions';

@Component({
  selector: 'app-qualifications',
  templateUrl: './qualifications.component.html',
  styleUrls: ['./qualifications.component.scss'],
})
export class QualificationsComponent implements OnInit {

  typeData: SelectItem[] = [
    { label: 'Compétences', value: 'Compétences' },
    { label: 'Postes', value: 'Postes' }
  ];

  typeDataSelected = 'Compétences';
  showAddForm = false;
  globalSearch: string;
  isRedirected = false;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }

  // Get data selected from profile and do redirection
  handleRedirectFromProfile(data) {
    this.globalSearch = data.name;
    this.typeDataSelected = 'Compétences';
    this.handleGlobalSearchChange(data.name) ;
    this.isRedirected = true;
  }

  handleChangeType(event) {
    this.isRedirected = false;
    this.typeDataSelected = event.value;
    this.toggleShowAddForm(false);
  }

  toggleShowAddForm(show = null) {
    this.showAddForm = !this.showAddForm ? show === null : show;
  }

  handleGlobalSearchChange(query) {
    switch (this.typeDataSelected) {
      case 'Postes':
        query && query.length > 0 ?
          this.store.dispatch(new FilterProfiles(query)) :
          this.store.dispatch(new GetProfiles());
        break;
      case 'Spécialités':
        query && query.length > 0 ?
          this.store.dispatch(new FilterSpecialties(query)) :
          this.store.dispatch(new GetSpecialties());
        break;
      case 'Compétences':
      default:
        query && query.length > 0 ?
          this.store.dispatch(new FilterSkills(query)) :
          this.store.dispatch(new GetSkills());
        break;
    }
  }
}
