import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './pages/profile/profile.component';
import { SharedModule } from '../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ProfileViewSkillsComponent } from './pages/update-profile/views/profile-view-skills/profile-view-skills.component';
import { ProfileViewProfilesComponent } from './pages/update-profile/views/profile-view-profiles/profile-view-profiles.component';
import { ProfileViewSpecialtiesComponent } from './pages/update-profile/views/profile-view-specialties/profile-view-specialties.component';
import { ProfileViewSoftSkillsComponent } from './pages/update-profile/views/profile-view-soft-skills/profile-view-soft-skills.component';
import { ProfileViewCertificationsComponent } from './pages/update-profile/views/profile-view-certifications/profile-view-certifications.component';
import { ProfileViewCoursesComponent } from './pages/update-profile/views/profile-view-courses/profile-view-courses.component';
import { UpdateProfileComponent } from './pages/update-profile/update-profile.component';
import { ShowProfileComponent } from './pages/show-profile/show-profile.component';
import { ProfilRhaComponent } from './pages/profil-rha/profil-rha.component';

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileViewSkillsComponent,
    ProfileViewProfilesComponent,
    ProfileViewSpecialtiesComponent,
    ProfileViewSoftSkillsComponent,
    ProfileViewCertificationsComponent,
    ProfileViewCoursesComponent,
    UpdateProfileComponent,
    ShowProfileComponent,
    ProfilRhaComponent,
  ],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  exports: [
    ProfileComponent,
    ProfileViewSkillsComponent,
    ProfileViewProfilesComponent,
    ProfileViewSpecialtiesComponent,
    ProfileViewSoftSkillsComponent,
    ProfileViewCertificationsComponent,
    ProfileViewCoursesComponent,
  ],
})
export class ProfileModule {}
