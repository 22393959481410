import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { User } from '../../../shared/models/user';
import { tap } from 'rxjs/operators';
import { UserService } from '../../../core/services/user-services/user.service';
import {
  SetCurrentProfileUser,
  UpdateCurrentProfileUser,
  ClearCurrentProfileUser,
  UpdateProfileUser,
  SetProfileIsUpdateView
} from './profile.actions';
import { AuthenticationService } from '../../../core/authentication/authentication.service';


export class UserProfileStateModel {
  currentUserProfile: User;
  updating: boolean;
}

@State<UserProfileStateModel>({
  name: 'profile',
  defaults: {
    currentUserProfile: null,
    updating: false
  }
})
@Injectable()
export class UserProfileState {

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {
  }

  @Selector()
  static getCurrentUserProfile(state: UserProfileStateModel) {
    return state.currentUserProfile;
  }

  @Selector()
  static isUpdateView(state: UserProfileStateModel) {
    return state.updating;
  }

  @Action(SetCurrentProfileUser)
  setCurrentProfileUser({getState, setState}: StateContext<UserProfileStateModel>) {
    return this.authenticationService.getProfileUser().pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        currentUserProfile: result,
      });
    }));
  }

  @Action(UpdateCurrentProfileUser)
  updateCurrentProfileUser({getState, setState}: StateContext<UserProfileStateModel>) {
    const currentUserId = getState().currentUserProfile.id;
    return this.userService.getOne(currentUserId).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        currentUserProfile: result,
      });
    }));
  }

  @Action(ClearCurrentProfileUser)
  clearCurrentProfileUser({getState, setState}: StateContext<UserProfileStateModel>) {
    const state = getState();
    setState({
      ...state,
      currentUserProfile: null,
      updating: false
    });
  }

  @Action(UpdateProfileUser)
  updateProfilePicture({getState, setState}: StateContext<UserProfileStateModel>, {payload}: UpdateProfileUser) {
    return this.userService.doPut(payload).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          currentUserProfile : payload,
        });
      }));
  }

  @Action(SetProfileIsUpdateView)
  setProfileIsUpdateView({getState, setState}: StateContext<UserProfileStateModel>, {payload}: SetProfileIsUpdateView) {
    const state = getState();
    setState({
      ...state,
      updating : payload,
    });
  }
}
