import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportTypeService extends ApiService {

  protected prefix(): string {
    return 'reportTypes';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(content: any): Observable<any> {
    return this.put(content);
  }

  doDelete(id: number): Observable<any> {
    return this.delete(id);
  }
}
