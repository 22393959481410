import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {AuthenticationService} from '../../app/core/authentication/authentication.service';
import {GetAnnualReportUser} from './authentication.action';
import {User} from '../../app/shared/models/user';

export class AuthenticationStateModel {
  annualReportUser: User;
}

@State<AuthenticationStateModel>({
  name: 'authentication',
  defaults: {
    annualReportUser : null,
  }
})

@Injectable()
export class AuthenticationState {

  constructor(private authenticationService: AuthenticationService) {}

  @Selector()
  static getAnnualReportUser(state: AuthenticationStateModel) {
    return state.annualReportUser;
  }

  @Action(GetAnnualReportUser)
  getAnnualReportUser({getState, setState}: StateContext<AuthenticationStateModel>) {
    return this.authenticationService.getAnnualReportUser().pipe(tap((annualReportUser) => {
      const state = getState();
      setState({
        ...state,
        annualReportUser,
      });
    }));
  }

}
