<div class="fm-button">
  <app-fm-button [attr.data-testid]="'profile_button_update'" type="text-gradient" btnText="METTRE À JOUR" (onClick)="handleUpdate()"></app-fm-button>
</div>
<app-fm-profile [user]="currentUser$ | async" [displaySoftSkills]="true"></app-fm-profile>

<div class="fm-title">
  <h2>Mes derniers comptes rendus</h2>
</div>
<app-datatable
  [attr.data-testid]="'profile_dataTable_interviews'"
  [data]="reports"
  [columns]="reportsDataColumns"
  [emptyMessage]="emptyMessageInterview"
  [rowNumber]="3"
  (eventUpdateItem)="handleUpdateReport($event)"
  defaultSortField="date"
  defaultSortOrder="-1">
</app-datatable>
