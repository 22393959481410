export function normalizeText(text): string {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
}

export function searchIndexOf(content, query) {
  if (content && query) {
    const normalizedContent = normalizeText(content);
    const normalizedQuery = normalizeText(query);
    return normalizedContent.indexOf(normalizedQuery) !== -1;
  }
  return true;
}

export function convertUnsafeCharactersForUrl(field: string): string {
  return field
    .replace(/\s/g, '%20')
    .replace(/"/g, '%22')
    .replace(/</g, '%3C')
    .replace(/>/g, '%3E')
    .replace(/#/g, '%23')
    .replace(/\|/g, '%7C');
}

export function convertSafeCharactersFromUrlToInput(field: string): string {
  return field
    .replace(/%20/g, ' ')
    .replace(/%22/g, '"')
    .replace(/%3C/g, '<')
    .replace(/%3E/g, '>')
    .replace(/%23/g, '#')
    .replace(/%7C/g, '|');
}
