<form [formGroup]="mainGroup">
  <app-select-item-array
    [name]="'Competence technique'"
    formControlName="ProfilList"
  ></app-select-item-array>
  <app-table-radio-button
    [name]="'SAVOIR ETRE'"
    [quality-data]="savoirEtrequalities"
    formControlName="savoirEtre"
  ></app-table-radio-button>
  <app-table-radio-button
    [name]="'MANAGEMENT'"
    [quality-data]="managementqualities"
    formControlName="management"
  ></app-table-radio-button>
  <app-select-item-array
    formControlName="formation"
    [is-formation]="true"
  ></app-select-item-array>
</form>

<div class="jumbotron">
  <pre>Form one value: {{ mainGroup.get("ProfilList").value | json }}</pre>
  <pre>Form two value: {{ mainGroup.get("CompetenceList").value | json }}</pre>
  <pre>Form two value: {{ mainGroup.get("savoirEtre").value | json }}</pre>
  <pre>Form two value: {{ mainGroup.get("management").value | json }}</pre>
  <pre>Whole form value: {{ mainGroup.value | json }}</pre>
</div>
