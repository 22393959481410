export class EnvService {

  // The values that are defined here are the default values (dev environment) that can be overridden by env.js
  public production = false;
  public apiUrl = 'http://localhost:8080/api';
  public auth = {
    stsServer: 'https://sso.minds.k8s/auth/realms/creative',
    clientId: 'followminds',
    scope: 'openid profile email offline_access',
  };
  public version = '_PROJECT_VERSION_';
  public rha = {
    projeqtorServer: 'https://outils.groupe-creative.fr/projeqtor/',
    projeqtorPdf: 'https://minds.groupe-creative.fr/documents/projeqtor.pdf',
    surveyFormsOffice: 'https://forms.office.com/Pages/ResponsePage.aspx?id=wvbNB2a4_E-nz-rrdVRflWnGUEOAa-9Go940FciqxLRURE9PNDM0MkpRRTdNNlhRUEg3VDBYMkYxQS4u',
  };
}
