<div class="fm-left">
  <div class="photo" *ngIf="user">
    <img [src]="user.image || '../../../../assets/img/photo_profil.png'" alt="">
  </div>
  <div *ngIf="module=='profile' && updating && !loadingEnd" class="file-upload">
    <p-fileUpload #fileUpload
                  mode="basic"
                  customUpload="true"
                  chooseIcon="fm-icon-edit"
                  chooseLabel=""
                  uploadLabel=""
                  showUploadButton="false"
                  auto="true"
                  (onSelect)="handleFileUpload($event)"
    >
    </p-fileUpload>
  </div>
  <div class="quote">
    <img src="../../../../assets/img/Quote.svg" alt="">
  </div>
</div>
<div *ngIf="!light" class="fm-right">
  <span class="fm-fullname">{{user?.lastname | uppercase}} {{user?.firstname}}</span>
  <span class="fm-office">{{user?.office?.name}}</span>
  <span class="fm-email">{{user?.email}}</span>
  <span class="fm-office">{{user?.subsidiary?.name}}</span>
</div>
<div *ngIf="light" class="fm-bloc">
  <div class="fm-right-light">
    <span class="fm-fullname-light">{{user?.lastname | uppercase}} {{user?.firstname}}</span>
    <span class="fm-office-light">{{user?.office?.name}}</span>
    <span class="fm-email-light">{{user?.email}}</span>
    <span class="fm-office-light">{{user?.subsidiary?.name}}</span>
  </div>
</div>
