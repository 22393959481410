<div class="fm-title">
  <h1>4. Compétences</h1>
</div>
<div class="fm-step">
  <form [formGroup]="formData">
    <div class="fm-form p-d-flex p-flex-column">

      <!-- Skills strength -->
      <h2>Points forts</h2>
      <div class="fm-eae-button-showAddForm">
        <h3>Collaborateur</h3>
        <app-fm-button *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          [type]="collaboratorSkillsStrengthShowAddForm ? 'hide' : 'show'"
          (onClick)="toggleCollaboratorSkillsStrengthForm()">
        </app-fm-button>
      </div>

      <!-- Collaborator skills strength -->
      <app-form-inline class="fm-form-inline" [attr.data-testid]="'formInline_component'"
        titleForm="Ajouter une compétence" [listInput]="collaboratorSkillsStrengthInputForm"
        (eventCreateItem)="handleCollaboratorSkillsStrengthCreateItem($event)" *ngIf="collaboratorSkillsStrengthShowAddForm &&
          role === 'collaborator' &&
          workflowCollaborator < 3 &&
          workflowManager < 4 &&
          !isTooLate" [saveDisabled]="collaboratorSkillsStrengthDisableCreationSave">
      </app-form-inline>
      <hr>
      <app-datatable *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
        [data]="collaboratorSkillsStrength" editMode="row" [columns]="skillsStrengthDataColumns"
        [emptyMessage]="skillsStrengthEmptyMessage"
        (eventDeleteItem)="handleCollaboratorSkillsStrengthDeleteItem($event)">
      </app-datatable>
      <div class="fm-eae-noAction">
        <app-datatable *ngIf="workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate"
          [data]="collaboratorSkillsStrength" editMode="row" [columns]="skillsStrengthDataColumnsWithoutActions"
          [emptyMessage]="skillsStrengthEmptyMessage">
        </app-datatable>
      </div>
      <div *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate">Le
        collaborateur n'a pas encore validé sa
        préparation</div>

      <!-- Manager skills strength -->
      <div class="fm-eae-button-showAddForm">
        <h3 class="fm-eae-title">Manager</h3>
        <app-fm-button *ngIf="role === 'currentInterviewer' && workflowManager !== 5"
          [type]="managerSkillsStrengthShowAddForm ? 'hide' : 'show'" (onClick)="toggleManagerSkillsStrengthForm()">
        </app-fm-button>
      </div>
      <app-form-inline class="fm-form-inline" [attr.data-testid]="'formInline_component'"
        titleForm="Ajouter une compétence" [listInput]="managerSkillsStrengthInputForm"
        (eventCreateItem)="handleManagerSkillsStrengthCreateItem($event)"
        *ngIf="role === 'currentInterviewer' && managerSkillsStrengthShowAddForm && workflowManager !== 5"
        [saveDisabled]="managerSkillsStrengthDisableCreationSave">
      </app-form-inline>
      <hr>
      <app-datatable *ngIf="role === 'currentInterviewer' && workflowManager !== 5" [data]="managerSkillsStrength"
        editMode="row" [columns]="skillsStrengthDataColumns" [emptyMessage]="skillsStrengthEmptyMessage"
        (eventDeleteItem)="handleManagerSkillsStrengthDeleteItem($event)">
      </app-datatable>
      <div class="fm-eae-noAction">
        <app-datatable *ngIf="workflowManager === 5" [data]="managerSkillsStrength" editMode="row"
          [columns]="skillsStrengthDataColumnsWithoutActions" [emptyMessage]="skillsStrengthEmptyMessage">
        </app-datatable>
      </div>
      <span *ngIf="role === 'collaborator' && workflowManager !== 5">Le manager n'a pas complété cette partie</span>

      <!-- Skills improvement -->
      <h2 class="fm-eae-title">Axes de développement</h2>
      <div class="fm-eae-button-showAddForm">
        <h3>Collaborateur</h3>
        <app-fm-button *ngIf="role === 'collaborator' && workflowCollaborator < 3 && !isTooLate && workflowManager < 4"
          [type]="collaboratorSkillsImprovementShowAddForm ? 'hide' : 'show'"
          (onClick)="toggleCollaboratorSkillsImprovementForm()">
        </app-fm-button>
      </div>
      <app-form-inline class="fm-form-inline" [attr.data-testid]="'formInline_component'"
        titleForm="Ajouter une compétence" [listInput]="collaboratorSkillsImprovementInputForm"
        (eventCreateItem)="handleCollaboratorSkillsImprovementCreateItem($event)" *ngIf="collaboratorSkillsImprovementShowAddForm &&
          role === 'collaborator' &&
          workflowCollaborator < 3 &&
          !isTooLate &&
          workflowManager < 4" [saveDisabled]="collaboratorSkillsImprovementDisableCreationSave">
      </app-form-inline>
      <hr>
      <app-datatable *ngIf="role === 'collaborator' && workflowManager < 3 && !isTooLate && workflowManager < 4"
        [data]="collaboratorSkillsImprovement" editMode="row" [columns]="skillsImprovementDataColumns"
        [emptyMessage]="skillsImprovementEmptyMessage"
        (eventDeleteItem)="handleCollaboratorSkillsImprovementDeleteItem($event)">
      </app-datatable>
      <div class="fm-eae-noAction">
        <app-datatable *ngIf="workflowCollaborator >= 3 || isTooLate || workflowManager >= 4"
          [data]="collaboratorSkillsImprovement" editMode="row" [columns]="skillsImprovementDataColumnsWithoutActions"
          [emptyMessage]="skillsImprovementEmptyMessage">
        </app-datatable>
      </div>
      <div *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate">Le
        collaborateur n'a pas encore validé sa
        préparation
      </div>

      <!-- Manager improvement -->
      <div class="fm-eae-button-showAddForm">
        <h3 class="fm-eae-title">Manager</h3>
        <app-fm-button *ngIf="role === 'currentInterviewer' && workflowManager !== 5"
          [type]="managerSkillsImprovementShowAddForm ? 'hide' : 'show'"
          (onClick)="toggleManagerSkillsImprovementForm()">
        </app-fm-button>
      </div>
      <app-form-inline class="fm-form-inline" [attr.data-testid]="'formInline_component'"
        titleForm="Ajouter une compétence" [listInput]="managerSkillsImprovementInputForm"
        (eventCreateItem)="handleManagerSkillsImprovementCreateItem($event)"
        *ngIf="role === 'currentInterviewer' && managerSkillsImprovementShowAddForm && workflowManager !== 5"
        [saveDisabled]="managerSkillsImprovementDisableCreationSave">
      </app-form-inline>
      <hr>
      <app-datatable *ngIf="role === 'currentInterviewer' && workflowManager !== 5" [data]="managerSkillsImprovement"
        editMode="row" [columns]="skillsImprovementDataColumns" [emptyMessage]="skillsImprovementEmptyMessage"
        (eventDeleteItem)="handleManagerSkillsImprovementDeleteItem($event)">
      </app-datatable>
      <div class="fm-eae-noAction">
        <app-datatable *ngIf="workflowManager === 5" [data]="managerSkillsImprovement" editMode="row"
          [columns]="skillsImprovementDataColumnsWithoutActions" [emptyMessage]="skillsImprovementEmptyMessage">
        </app-datatable>
      </div>
      <span *ngIf="role === 'collaborator' && workflowManager !== 5">Le manager n'a pas complété cette partie</span>

      <!-- Skills courseRequest -->
      <h2 class="fm-eae-title">Formations</h2>
      <div class="fm-eae-button-showAddForm">
        <h3>Collaborateur</h3>
        <app-fm-button *ngIf="role === 'collaborator' && workflowCollaborator < 3 && !isTooLate && workflowManager < 4"
          [type]="collaboratorSkillsCourseRequestShowAddForm ? 'hide' : 'show'"
          (onClick)="toggleCollaboratorSkillsCourseRequestForm()">
        </app-fm-button>
      </div>
      <app-form-inline class="fm-form-inline" [attr.data-testid]="'formInline_component'"
        titleForm="Ajouter une formation" [listInput]="collaboratorSkillsCourseRequestInputForm"
        (eventCreateItem)="handleCollaboratorSkillsCourseRequestCreateItem($event)" *ngIf="collaboratorSkillsCourseRequestShowAddForm &&
          role === 'collaborator' &&
          workflowCollaborator < 3 &&
          !isTooLate &&
          workflowManager < 4" [saveDisabled]="collaboratorSkillsCourseRequestDisableCreationSave">
      </app-form-inline>
      <hr>
      <app-datatable *ngIf="role === 'collaborator' && workflowManager < 3 && !isTooLate && workflowManager < 4"
        [data]="collaboratorSkillsCourseRequest" editMode="row" [columns]="skillsCourseRequestDataColumns"
        [emptyMessage]="skillsCourseRequestEmptyMessage"
        (eventDeleteItem)="handleCollaboratorSkillsCourseRequestDeleteItem($event)">
      </app-datatable>
      <div class="fm-eae-noAction">
        <app-datatable *ngIf="workflowCollaborator >= 3 || isTooLate || workflowManager >= 4"
          [data]="collaboratorSkillsCourseRequest" editMode="row" [columns]="skillsCourseRequestDataColumnsWithoutActions"
          [emptyMessage]="skillsCourseRequestEmptyMessage">
        </app-datatable>
      </div>
      <div *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate">Le
        collaborateur n'a pas encore validé sa
        préparation
      </div>
      <!-- Manager courses request -->
      <div class="fm-eae-button-showAddForm">
        <h3 class="fm-eae-title">Manager</h3>
        <app-fm-button *ngIf="role === 'currentInterviewer' && workflowManager !== 5"
          [type]="managerSkillsCourseRequestShowAddForm ? 'hide' : 'show'"
          (onClick)="toggleManagerSkillsCourseRequestForm()">
        </app-fm-button>
      </div>
      <app-form-inline class="fm-form-inline" [attr.data-testid]="'formInline_component'"
        titleForm="Ajouter une formation" [listInput]="managerSkillsCourseRequestInputForm"
        (eventCreateItem)="handleManagerSkillsCourseRequestCreateItem($event)"
        *ngIf="role === 'currentInterviewer' && managerSkillsCourseRequestShowAddForm && workflowManager !== 5"
        [saveDisabled]="managerSkillsCourseRequestDisableCreationSave">
      </app-form-inline>
      <hr>
      <app-datatable *ngIf="role === 'currentInterviewer' && workflowManager !== 5" [data]="managerSkillsCourseRequest"
        editMode="row" [columns]="skillsCourseRequestDataColumns" [emptyMessage]="skillsCourseRequestEmptyMessage"
        (eventDeleteItem)="handleManagerSkillsCourseRequestDeleteItem($event)">
      </app-datatable>
      <div class="fm-eae-noAction">
        <app-datatable *ngIf="workflowManager === 5" [data]="managerSkillsCourseRequest" editMode="row"
          [columns]="skillsCourseRequestDataColumnsWithoutActions" [emptyMessage]="skillsCourseRequestEmptyMessage">
        </app-datatable>
      </div>
      <span *ngIf="role === 'collaborator' && workflowManager !== 5">Le manager n'a pas complété cette partie</span>
      <!-- Comments -->
      <h2 class="fm-eae-title">Commentaire du collaborateur</h2>
      <p-editor *ngIf="role === 'collaborator' && workflowCollaborator < 3 && !isTooLate && workflowManager < 4"
        [style]="{'height':'320px', 'box-shadow': '0 0 10px #1432461A'}" [maxlength]="freeAnswerMaxLength"
        formControlName="collaboratorSkillsComment" [(ngModel)]="collaboratorSkillsComment"
        [placeholder]=collaboratorSkillsCommentPlaceholder (focusout)="handleChange($event)">
      </p-editor>
      <span *ngIf="collaboratorSkillsComment && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)"
        [innerHTML]="collaboratorSkillsComment" class="fm-text-editor"></span>
      <span *ngIf="!collaboratorSkillsComment && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)"
        class="fm-text-editor">Le collaborateur n'a
        pas complété cette partie</span>
      <span *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && !isTooLate && workflowManager < 4"
        class="fm-text-editor">Le collaborateur n'a
        pas
        encore validé sa préparation</span>

      <h2 class="fm-eae-title">Commentaire du manager</h2>
      <p-editor *ngIf="role === 'currentInterviewer' && workflowManager !== 5"
        [style]="{'height':'320px', 'box-shadow': '0 0 10px #1432461A'}" [maxlength]="freeAnswerMaxLength"
        formControlName="managerSkillsComment" [(ngModel)]="managerSkillsComment"
        [placeholder]=managerSkillsCommentPlaceholder (focusout)="handleChange($event)">
      </p-editor>
      <span *ngIf="managerSkillsComment && workflowManager === 5" [innerHTML]="managerSkillsComment"
        class="fm-text-editor"></span>
      <span *ngIf="managerSkillsComment && role === 'collaborator' && workflowManager !== 5" class="fm-text-editor">Le
        manager n'a pas complété cette
        partie</span>
      <span
        *ngIf="!managerSkillsComment && (workflowManager === 5 || (role === 'collaborator' && workflowManager !== 5))"
        class="fm-text-editor">Le manager n'a
        pas complété cette partie</span>
    </div>
  </form>
</div>