import {Component, Input, OnInit} from '@angular/core';
import {UserProfile} from '../../models/user-profile';
import {UserSkill} from '../../models/user-skill';
import {UserSpecialty} from '../../models/user-specialty';
import {Subscription} from 'rxjs';
import {UserService} from '../../../core/services/user-services/user.service';

@Component({
  selector: 'app-person-summary',
  templateUrl: './person-summary.component.html',
  styleUrls: ['./person-summary.component.scss']
})
export class PersonSummaryComponent implements OnInit {
  @Input()
  user;

  @Input()
  module;

  chipsNumber = 3;
  userProfiles: UserProfile[];
  userSkills: UserSkill[];
  userSpecialties: UserSpecialty[];

  constructor(
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    if (this.user != null) {
      // Order profiles, skills and specialties by level then by alphabetical order
      this.userProfiles = this.userService.orderedBylevelThenByAlphabeticalOrder(this.user.userProfiles, 'profile');
      this.userSkills = this.userService.orderedBylevelThenByAlphabeticalOrder(this.user.userSkills, 'profile');
      this.userSpecialties = this.userService.orderedBylevelThenByAlphabeticalOrder(this.user.userSpecialties, 'profile');

      // Remove profile that aren't validated
      let i = 0;
      while (i < this.userProfiles.length) {
        if (!!this.userProfiles[i].validation === false) {
          this.userProfiles.splice(i, 1);
        } else {
          i++;
        }
      }

      // remove skills that aren't validated
      i = 0;
      while (i < this.userSkills.length) {
        if (!!this.userSkills[i].validation === false) {
          this.userSkills.splice(i, 1);
        } else {
          i++;
        }
      }

      // remove specialties that aren't validated
      i = 0;
      while (i < this.userSpecialties.length) {
        if (!!this.userSpecialties[i].validation === false) {
          this.userSpecialties.splice(i, 1);
        } else {
          i++;
        }
      }
    }
  }
}
