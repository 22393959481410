<form [formGroup]="formData">
  <div class="fm-form p-d-flex">
    <h2>{{title}}</h2>
    <p-editor
        [style]="{'height':'320px', 'box-shadow': '0 0 10px #1432461A'}"
        [maxlength]="freeAnswerMaxLength"
        [formControlName]="formNameEditor"
        [placeholder]=placeholder
        [(ngModel)]="fmNgModel"
        (focusout)="handleChange($event)">
    </p-editor>
  </div>
</form>
