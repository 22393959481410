import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home.component';
import { NavbarService } from '../../core/services/navbar-services/navbar.service';
import { SharedModule } from '../../shared/shared.module';
import { PrimeNGModule } from '../../shared/prime.module';
import { CardModule } from 'primeng/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { HomeBoxComponent } from './components/home-box/home-box.component';

@NgModule({
  declarations: [
      HomeComponent,
      HomeBoxComponent,
    ],
  imports: [
    CommonModule,
    SharedModule,
    PrimeNGModule,
    CardModule,
    BrowserAnimationsModule,
    AutoCompleteModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [
    HomeComponent,
  ],
  providers: [ NavbarService ]
})
export class HomeModule { }
