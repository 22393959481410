<div class="container">
  <div fxLayout="row">
    <h2 fxLayout="row" fxLayoutAlign="start center" fxFlex="50"> Entretiens annuels d'évaluation</h2>
  </div>
  <br />
  <div>
    <app-fm-button type="text-gradient" btnText="Lancer la campagne pour tous les nouveaux arrivants" (onClick)="handleLaunchCampaignNewArrivals()">
    </app-fm-button>
  </div>
  <br />
  <app-modal [attr.data-testid]="'annual_report_modal_component'" header="Campagne annuel pour les nouveaux arrivants" type="confirm" [(display)]="showModal"
    (eventModalConfirm)="handleModalConfirm()" class="modal-compaign">
    <p> Veuillez choisir l'année de la campagne annuelle </p>
    <p-dropdown class="fm-filters-select" inputId="yearsDropDown" [options]="campaignOptions" [(ngModel)]="selectedCampaign">
    </p-dropdown>
  </app-modal>

  <router-outlet></router-outlet>
  <!-- le filtre à gauche-->
</div>