import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-selection-menu',
  templateUrl: './selection-menu.component.html',
  styleUrls: ['./selection-menu.component.scss']
})
export class SelectionMenuComponent implements OnInit {

  @Output()
  changeType = new EventEmitter<Event>();

  @Output()
  toggleShowAddForm = new EventEmitter<Event>();

  @Output()
  globalSearchChange = new EventEmitter<string>();

  @Input()
  title: string;

  @Input()
  typeDataSelected: string;

  @Input()
  typeData: SelectItem[];

  @Input()
  showAddForm = false;

  @Input()
  showAddButton = true;

  @Input()
  globalSearch: string;

  constructor() { }

  ngOnInit(): void {
  }

  handleChangeType(event) {
    this.globalSearch = '';
    this.changeType.emit(event);
  }

  handleShowAddForm(event) {
    this.toggleShowAddForm.emit(event);
  }

  handleGlobalSearchChange() {
    this.globalSearchChange.emit(this.globalSearch);
  }
}
