<div class="fm-team-view">
  <div class="fm-team-view-header">
    <div class="fm-title">
      <h1>Compétences</h1>
    </div>
    <div class="fm-action">
      <app-fm-button [attr.data-testid]="'button_showHideSkill'" [type]="showAddForm ? 'hide' : 'show'" (onClick)="toggleShowAddForm()"></app-fm-button>
    </div>
  </div>

  <app-form-inline
    [attr.data-testid]="'formInline_component'"
    [listInput]="inputForm"
    titleForm="Ajouter une compétence"
    (eventCreateItem)="handleCreateItem($event)"
    [saveDisabled]="disableCreationSave"
    *ngIf="showAddForm">
  </app-form-inline>
  <hr>
  <app-datatable
    [attr.data-testid]="'dataTable_component'"
    editMode="row"
    defaultSortField="skill.name"
    [data]="userSkills$ | async"
    [columns]="tableColumns"
    [emptyMessage]="emptyMessage"
    [isMainManager]="isMainManager"
    [displayOnlyQualificationsNotValidated]="displayOnlyQualificationsNotValidated"
    (eventDeleteItem)="handleDeleteItem($event)"
    (eventUpdateItem)="handleUpdateItem($event)"
    (eventSaveUpdateItem)="handleSaveUpdateItem($event)"
    (eventCancelEditItem)="handleCancelEditItem()">
  </app-datatable>

  <app-modal [attr.data-testid]="'modal_component'"
             [header]="modal.header"
             [content]="modal.content"
             [type]="modal.type"
             [(display)]="showModal"
             (eventModalConfirm)="handleModalConfirm()">
  </app-modal>
</div>
