import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification-service/notification.service';
import { UserService } from 'src/app/core/services/user-services/user.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  items = [];
  showNotification = false;
  notificationSubscription: Subscription;
  maxBadgeNumber = 99;

  constructor(
    private messageService: MessageService,
    private notificationService: NotificationService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getItems();
    // Actualize notifications every 5 minutes
    this.notificationSubscription = interval(5 * 60 * 1000).subscribe(() => { this.getItems(); });
  }

  getItems(){
    this.notificationService.getUserNotificationsByStatusTrue().subscribe(
      data => {
        const newItems = [];
        data.forEach(element => {
          const id = `${element.id}`;
          const comment = `${element.comment}`;
          const emitter = element.emitter;
          const type = element.type;
          const borderColor = type === 'member';
          newItems.push({id, type, emitter, comment, borderColor});
        });
        if (JSON.stringify(this.items) !== JSON.stringify(newItems)) {
          this.items = [];
          newItems.forEach((newItem) => this.items.push(newItem));
        }
      },
      (e) => this.displayError(e.status)
    );
  }

  disableNotif(content){
    this.notificationService.doPut(content).subscribe(
      () => this.getItems(),
      (e) => this.displayError(e.status, content?.user)
    );
  }

  redirect(type: string, id: number = null) {
    switch (type) {
      case 'member':
        this.userService.getOne(id).subscribe(() => {
          this.router.navigate(['/team/summary/update-profile/', id]);
        });
        break;
      case 'manager':
        this.router.navigateByUrl('/profile/update');
        break;
      case 'managerValidationRequest':
        this.router.navigateByUrl('/team/summary/members');
        break;
      default:
        return;
    }
  }

  toggleShowHide() {
    this.showNotification = !this.showNotification;
    if (this.showNotification){
      this.getItems();
    }
  }

  private displayError(e: number, user = null): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 403:
        severity = 'error';
        summary = 'Impossible de supprimer la notification';
        detail = user + ' possède des qualifications qui ne sont pas validées ou invalidées.';
        break;
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 501:
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  ngOnDestroy(): void {
    this.notificationSubscription.unsubscribe();
  }

  openHistory(): void {
    this.router.navigateByUrl('/history');
  }
}
