import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from "@angular/router";

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss'],
})
export class ParametersComponent implements OnInit  {

  items: any = [
    { label: 'Qualifications', routerLink: '/parameters/qualifications' },
    { label: 'Clients', routerLink: '/parameters/clients' },
    { label: 'Formations & RH', routerLink: '/parameters/formations' },
    { label: 'Hiérarchie', routerLink: '/parameters/hierarchie' },
  ];

  activeIndex: number;

  constructor(private router: Router) {
    router.events.subscribe(
      (event: NavigationStart) => {
        const routerLink = event.url;
        if (routerLink !== undefined) {
          this.items.forEach((item, activeIndex) => {
            if (item.routerLink === routerLink) {
              this.activeIndex = activeIndex;
            }
          });
        }
      },
      (e) => console.log(e)
    );
  }

  ngOnInit(): void {
  }
}
