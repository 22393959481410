import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../../../shared/models/user';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';
import {TeamService} from '../../../../core/services/team-services/team.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  items: any = [];

  activeIndex: number;
  activeIndexMinds: number;

  public minds: boolean;
  public user$: Observable<User>;
  public routerLink: string;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private teamService: TeamService
  ) {
    this.router.events.subscribe(
      (event: NavigationStart) => {
        this.routerLink = event.url;
      },
      (e) => console.log(e)
    );
  }

  public ngOnInit(): void {
    this.user$ = this.authService.getHomeUser();
    this.user$.subscribe(user => this.teamService.getMinds(user.id).subscribe(value => {
      this.minds = value;

      //if (this.minds) {
        this.items = [
          { label: 'Mes entretiens', routerLink: '/activity/reports' },
          { label: 'Mes missions', routerLink: '/activity/assignments' },
          { label: 'Mon RHA', routerLink: '/activity/rha' },
        ];
      //}else{
      //  this.items = [
      //    { label: 'Mes entretiens', routerLink: '/activity/reports' },
      //    { label: 'Mes missions', routerLink: '/activity/assignments' },
      //  ];
      //}

      this.items.forEach((item, activeIndex) => {
        if (item.routerLink === this.routerLink) {
          this.activeIndex = activeIndex;
        }
      });

    }));
  }
}
