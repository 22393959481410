import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule } from 'angular-auth-oidc-client';
import {
  AuthenticationService,
  configureAuthentication,
} from './authentication/authentication.service';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, AuthModule.forRoot()],
  providers: [
    AuthenticationService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuthentication,
      deps: [AuthenticationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    }
  ],
})
export class CoreModule {}
