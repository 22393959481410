<div class="fm-top">
  <div class="fm-top-content">
    <div>
      <label>Date de début :</label>
      <p-calendar
        id="startDate"
        [attr.data-testid]="'report_field_startDate'"
        [(ngModel)]="startDate"
        placeholder="jj/mm/aaaa"
        dateFormat="dd/mm/yy"
        [locale]="fr"
        appendTo="body">
      </p-calendar>
    </div>
    <div>
      <label>Date de fin :</label>
      <p-calendar
        id="endDate"
        [attr.data-testid]="'report_field_endDate'"
        [(ngModel)]="endDate"
        placeholder="jj/mm/aaaa"
        dateFormat="dd/mm/yy"
        [locale]="fr"
        appendTo="body">
      </p-calendar>
    </div>
    <div>
      <app-fm-button
        type="text-transparent"
        [attr.data-testid]="'report_button_validate'"
        [btnText]="'Valider'.toUpperCase()"
        (onClick)="handleFilterValidation()">
      </app-fm-button>
    </div>
  </div>
</div>
<div class="fm-actions">
  <input [attr.data-testid]="'report_input_search'" pInputText type="text" placeholder="Rechercher" [(ngModel)]="search" (ngModelChange)="handleSearch()"/>
  <app-fm-button type="text-gradient" btnText="EXPORTER" (onClick)="exportWeeklyReport()"></app-fm-button>
</div>
<app-fm-annual-report-button
  *ngIf="displayButtonEAE"
  (handleClick)="handleAnnualReportClick()"
  [annualReportNotification]="annualReportNotification"
>
</app-fm-annual-report-button>
<app-datatable
  [attr.data-testid]="'report_dataTable_component'"
  [data]="reports$ | async"
  [columns]="reportsDataColumns"
  [emptyMessage]="emptyMessage"
  (eventUpdateItem)="handleUpdateItem()"
  defaultSortField="date"
  defaultSortOrder="-1">
</app-datatable>
