import { FormationsrhsComponent } from './pages/formationrh/formationsrhs.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageService } from 'primeng/api';
import { SharedModule } from 'src/app/shared/shared.module';
import { ViewCoursesComponent } from './pages/formationrh/view-courses/view-courses.component';
import { ViewCertificationsComponent } from './pages/formationrh/view-certifications/view-certifications.component';
import { ViewRhComponent } from './pages/formationrh/view-rh/view-rh.component';

@NgModule({
  declarations: [
    FormationsrhsComponent,
    ViewCoursesComponent,
    ViewCertificationsComponent,
    ViewRhComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [MessageService]

})
export class FormationsrhsModule { }
