import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {UserRoleService} from '../../../core/services/user-role-services/user.role.service';
import {User} from '../../../shared/models/user';
import {Router} from '@angular/router';
import {convertUnsafeCharactersForUrl} from '../../../shared/utils/utils';
import {TeamService} from '../../../core/services/team-services/team.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public minds: boolean;
  public teams: boolean;

  public user$: Observable<User>;

  public boxes = [];

  public constructor(
    private authService: AuthenticationService,
    private rolesService: UserRoleService,
    private router: Router,
    private teamService: TeamService,
  ) {
  }

  public ngOnInit(): void {
    this.user$ = this.authService.getHomeUser();
    this.teams = this.rolesService.isPathAllowedForUser('team', this.authService.getUserRoles());

    this.user$.subscribe(user => this.teamService.getMinds(user.id).subscribe(value => {
      this.minds = value;
      // Testing if user can see the box my team
      if (this.teams) {
        this.boxes.push({
          type: 'team',
          dataTestId: 'home_bloc_team'
        });
      }
      // See the box user's profile
      this.boxes.push({
        type: 'profile',
        dataTestId: 'home_bloc_profile'
      });
      // Testing if user can see the box this team
      //if (this.minds) {
        this.boxes.push({
          type: 'minds',
          dataTestId: 'home_bloc_minds'
        });
      //}
      // See the box user's certification
      this.boxes.push({
        type: 'certification',
        dataTestId: 'home_bloc_certification'
      });
    }));
  }

  public handleDirectory(event): void {

    let query = event.query;

    if (event.target) {
      query = event.target.value;
    }

    const search = convertUnsafeCharactersForUrl(query);
    this.router.navigate(['/directory/search'], {queryParams: {search}});
  }
}
