import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {User} from '../../models/user';
import {UploadService} from '../../../core/services/upload-services/upload.service';
import {MessageService} from 'primeng/api';
import {FileUpload} from 'primeng/fileupload';
import {Store} from '@ngxs/store';
import {UpdateProfileUser} from '../../../modules/profile/store/profile.actions';

@Component({
  selector: 'app-person-card',
  templateUrl: './person-card.component.html',
  styleUrls: ['./person-card.component.scss']
})
/**
 * Displays image and user informations (firstname, lastname, agency and email)
 */
export class PersonCardComponent implements OnInit {
  @ViewChild(FileUpload) fileUpload: FileUpload;

  @Input()
  user: User;

  @Input()
  module: string;

  @Input()
  updating = false;

  @Input()
  uploadKey = '';

  @Input()
  light = false;

  public haveMainManager = false;
  public mainManager = '';
  public managersName = '';

  maxFileSize = 100000;
  loadingEnd;

  constructor(private uploadService: UploadService,
              private messageService: MessageService,
              private store: Store) {
  }

  ngOnInit(): void {
    // this.computeManagersName();
  }

  /**
   * Compute Managers name from user.currentTeam to string
   * @private
   */
  public computeManagersName() {
    // Is there a main manager
    this.user.teams.forEach((team) => {
      if (team.mainManager) {
        this.mainManager = team.manager.firstname + ' ' + team.manager.lastname.toUpperCase();
        this.haveMainManager = true;
      }
    });

    // Are there another teams than main team
    let i = 1;
    this.user.teams.forEach((team) => {
      if (!team.mainManager) {
        this.managersName += team.manager.firstname;
        this.managersName += ' ';
        this.managersName += team.manager.lastname.toUpperCase();
        if (this.user.teams.length > 1 && i < this.user.teams.length - (this.haveMainManager ? 1 : 0)) {
          this.managersName += ', ';
          i++;
        }
      }
    });
  }

  handleFileUpload(event: any) {
    const file: File = event.files[0];
    if (!(file.type.endsWith('jpeg') || file.type.endsWith('png'))) {
      const severity = 'error';
      const summary = 'Erreur lors de l\'upload';
      const detail = 'Seuls les fichiers de type .jpg et .png sont autorisés';
      this.fileUpload.clear();
      this.messageService.add({severity, summary, detail});
      return;
    }

    if (file.size > this.maxFileSize) {
      const severity = 'error';
      const summary = 'Erreur lors de l\'upload';
      const detail = 'La photo ne doit pas dépasser les 100 Ko.';
      this.fileUpload.clear();
      this.messageService.add({severity, summary, detail});
      return;
    }

    this.uploadService.convertFileToString(file).subscribe({
      next: (image: string) => {
        // loadingEnd is used to hide the uploaded file's name inside the component
        this.loadingEnd = true;
        this.user.image = image;
        this.store.dispatch(new UpdateProfileUser(this.user)).subscribe(
          () => {
            this.loadingEnd = false; // set back to false to allow new upload
          },
          (e) => console.log(e)
        );
      }
    });
  }
}
