import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';
import {Observable, BehaviorSubject} from 'rxjs';
import {AnnualReport} from '../../../shared/models/annual-report';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AnnualReportService extends ApiService{

  hasCollaboratorValidated$ = new BehaviorSubject<boolean>(null);

  protected prefix(): string {
    return 'reports/annualReports';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: AnnualReport): Observable<any> {
    return this.post(content);
  }

  doPut(content: AnnualReport, params: Map<string, string>): Observable<any> {
    return this.put(content, '', params);
  }

  doDelete(id: number): Observable<any> {
    return this.delete(id);
  }

  getAnnualReportsByUser(userId: number){
    return this.get(`?by=user&id=${userId}`);
  }

  getAnnualReportsByInterviewer(userId: number){
    return this.get(`?by=interviewer&id=${userId}`);
  }

  getAnnualReportsByCurrentInterviewer(userId: number){
    return this.get(`?by=currentInterviewer&id=${userId}`);
  }

  getAnnualReportsByFilters(status: string = 'validated', campaignYear: number = 2021, officeId: number = null ){
    let paramsUrl = `?by=admin`;
    paramsUrl += status !== null ? `&status=${status}` : '';
    paramsUrl += campaignYear !== null ? `&campaignYear=${campaignYear}` : '';
    paramsUrl += officeId !== null ? `&officeId=${officeId}` : '';

    return this.get(paramsUrl);
  }

  initiateNewCollaboratorAnnualReports(campaignYear: number) {
    return this.get(`initiateNewCollaboratorAnnualReports/?campaignYear=${campaignYear}`);
  }

  isTooLate(interviewDate: string){
    if (interviewDate) {
      const interviewEndDate = new Date(interviewDate);
      interviewEndDate.setHours(23, 59, 59, 59);

      const todayDate = new Date();
      todayDate.setDate(todayDate.getDate());

      return moment(todayDate).isAfter(interviewEndDate);
    } else {
      return false;
    }
  }
}
