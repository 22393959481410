import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { UserProfileService } from '../../../core/services/user-profile-services/user-profile.service';
import { UserProfile } from '../../../shared/models/user-profile';
import {
  AddProfileUserProfile,
  MakeSnapshotProfileUserProfile,
  RestoreSnapshotProfileUserProfile,
  GetProfileUserProfilesByUserId,
  UpdateProfileUserProfile,
  ClearProfileUserProfiles,
  DeleteProfileUserProfile
} from './profile-profiles.actions';

export class ProfileUserProfileStateModel {
  userProfiles: UserProfile[];
  snapshot: UserProfile[];
  httpStatus: number;
}

@State<ProfileUserProfileStateModel>({
  name: 'profileUserProfiles',
  defaults: {
    userProfiles: [],
    snapshot: [],
    httpStatus: null
  }
})
@Injectable()
export class ProfileProfileState {

  constructor(private userProfileService: UserProfileService) {
  }

  @Selector()
  static getUserProfiles(state: ProfileUserProfileStateModel) {
    return state.userProfiles;
  }

  @Action(GetProfileUserProfilesByUserId)
  getProfileUserProfilesByUserId(
    {getState, setState}: StateContext<ProfileUserProfileStateModel>,
    {payload}: GetProfileUserProfilesByUserId
  ) {
    return this.userProfileService.getUserProfilesDetailsByUserId(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userProfiles: result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddProfileUserProfile)
  add({getState, patchState}: StateContext<ProfileUserProfileStateModel>, {payload}: AddProfileUserProfile) {
    return this.userProfileService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      patchState({
        userProfiles: [...state.userProfiles, result]
      });
    }));
  }

  @Action(UpdateProfileUserProfile)
  update({getState, setState}: StateContext<ProfileUserProfileStateModel>, {payload}: UpdateProfileUserProfile) {
    return this.userProfileService.doPut(payload).pipe(
      tap((result) => {
        result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
        const state = getState();
        const userProfilesList = [...state.userProfiles];
        const userProfileIndex = userProfilesList.findIndex(item => item.id === payload.id);
        userProfilesList[userProfileIndex] = result;
        setState({
          ...state,
          userProfiles: userProfilesList,
        });
    }));
  }

  @Action(DeleteProfileUserProfile)
  delete({getState, setState}: StateContext<ProfileUserProfileStateModel>, {payload}: DeleteProfileUserProfile) {
    const state = getState();
    return this.userProfileService.doDelete(payload.id, {observe: 'response'}).pipe(tap((observe) => {
      if (observe.status === 200) {
        const filteredArray = state.userProfiles.filter(item => item.id !== payload.id);
        setState({
          ...state,
          userProfiles: filteredArray,
          httpStatus: 200,
        });
      } else {
        setState({
          ...state,
          httpStatus: 202,
        });
      }
    }));
  }

  @Action(MakeSnapshotProfileUserProfile)
  makeSnapShot({getState, setState}: StateContext<ProfileUserProfileStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.userProfiles.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotProfileUserProfile)
  restoreSnapshot({getState, setState}: StateContext<ProfileUserProfileStateModel>) {
    const state = getState();
    setState({
      ...state,
      userProfiles: state.snapshot.map(item => ({...item}))
    });
  }

  @Action(ClearProfileUserProfiles)
  clearProfileUserProfiles({getState, setState}: StateContext<ProfileUserProfileStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: [],
      userProfiles: [],
    });
  }
}
