import { Profile } from 'src/app/shared/models/profile';

export class MakeSnapshotProfile {
  static readonly type = '[Profile] MakeSnapshot';
}

export class RestoreSnapshotProfile {
  static readonly type = '[Profile] RestoreSnapshot';
}

export class ClearSnapshotProfile {
  static readonly type = '[Profile] ClearSnapshot';
}

export class GetProfiles {
  static readonly type = '[Profile] Get';
}

export class AddProfile {
  static readonly type = '[Profile] Add';
  constructor(public payload: Profile) {}
}

export class UpdateProfile {
  static readonly type = '[Profile] Update';
  constructor(public payload: Profile) {}
}

export class DeleteProfile {
  static readonly type = '[Profile] Delete';
  constructor(public payload: Profile) {}
}

export class FilterProfiles {
  static readonly type = '[Profile] Filter';
  constructor(public payload: string) {}
}
