<p-panel [header]="question.header"
         [collapsed]="question.collapsed"
         toggleable="true"
         expandIcon="fm-icon-arrow-expand"
         collapseIcon="fm-icon-arrow-collapse">
  <div *ngIf="question.type === 'text'">
    <p [innerHTML]="question.text"></p>
  </div>

  <div *ngIf="question.type === 'image'" class="content-img">
    <img *ngIf="question.image" [src]="'../../../assets/img/faq/' + question.image" alt="">
    <p [innerHTML]="question.text"></p>
  </div>

  <div *ngIf="question.type == 'link'" class="content-link">
    <p [innerHTML]="question.text"></p>
    <a *ngIf="question.link" href="{{question.href}}">{{question.link}}</a>
  </div>

  <div *ngIf="question.type == 'link-img'" class="content-link">
    <p [innerHTML]="question.text"></p>
    <br/>
    <img class="fm-link-50" [src]="'../../../assets/img/faq/' + question.image" [alt]="question.alt" (click)="downloadFile(question.href, question.fileName)" />
  </div>

  <div *ngIf="question.type == 'table'">
    <p [innerHTML]="question.text"></p>
    <div [innerHtml]="question.table" class="content-table"></div>
  </div>
</p-panel>
