<div class="fm-step-one ">
  <form [formGroup]="formData">
    <div class="fm-info">
      <h2>1.1 Informations générales</h2>
      <div class="fm-card p-d-flex p-jc-between">
        <div class="p-d-flex p-flex-column">
          <h3>Date de l'entretien</h3>
          <p *ngIf="interviewStartDate && (role$ | async) === 'collaborator'">
            Le {{interviewStartDate | date: 'dd/MM/yyyy'}}
          </p>
          <p *ngIf="!interviewStartDate && (role$ | async) === 'collaborator'">
            Votre manager n'a pas encore renseigné cette section
          </p>
          <p-calendar *ngIf="(role$ | async) === 'currentInterviewer' && workflowManager < 4" [minDate]="minDateValue" id="startDate"
            [attr.data-testid]="'annual_report_date'" [(ngModel)]="annualReportDateCalendar" formControlName="annualReportDateCalendar" placeholder="jj/mm/aaaa"
            dateFormat="dd/mm/yy" [locale]="fr" appendTo="body" (onSelect)="handleChange($event)" (onClickOutside)="handleChange($event)">
          </p-calendar>
          <p *ngIf="(role$ | async) === 'currentInterviewer' && workflowManager >= 4">
            Le {{interviewStartDate | date: 'dd/MM/yyyy'}}
          </p>
        </div>
      </div>
    </div>
    <div class="fm-info">
      <h2>1.2 Collaborateur</h2>
      <div class="fm-card">
        <div class="p-d-flex mb-4">
          <div class=" fm-w33 p-d-flex p-flex-column">
            <h3>Nom</h3>
            <p>{{collaboratorName | uppercase}}</p>
          </div>
          <div class="fm-w33 p-d-flex p-flex-column">
            <h3>Prénom</h3>
            <p>{{collaboratorFirstname | titlecase}}</p>
          </div>
        </div>
        <div class="p-d-flex">
          <div class="fm-w33 p-d-flex p-flex-column p-jc-between">
            <h3>Ancienneté dans le groupe</h3>
            <input type="text" pInputText *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
              [(ngModel)]="companySeniority" formControlName="companySeniority" (focusout)="handleChange($event)">
            <p *ngIf="workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate">{{companySeniority}}</p>
            <p *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate">
              Saisie collaborateur en cours
            </p>
          </div>
          <div class="fm-w33 p-d-flex p-flex-column p-jc-between">
            <h3>Poste</h3>
            <input type="text" pInputText *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
              [(ngModel)]="position" formControlName="position" (focusout)="handleChange($event)">
            <p *ngIf="workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate">{{position}}</p>
            <p *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate">
              Saisie collaborateur en cours
            </p>
          </div>
          <div class="fm-w33 p-d-flex p-flex-column p-jc-between">
            <h3>Ancienneté dans le poste</h3>
            <input type="text" pInputText *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
              [(ngModel)]="positionSeniority" formControlName="positionSeniority" (focusout)="handleChange($event)">
            <p *ngIf="workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate">{{positionSeniority}}</p>
            <p *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate">
              Saisie collaborateur en cours
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="fm-info">
      <h2>1.3 Manager</h2>
      <div class="fm-card p-d-flex ">
        <div class="fm-w33 p-d-flex p-flex-column">
          <h3>Nom</h3>
          <p>{{managerName | uppercase}}</p>
        </div>
        <div class="fm-w33 p-d-flex p-flex-column">
          <h3>Prénom</h3>
          <p>{{managerFirstname | titlecase}}</p>
        </div>
      </div>
    </div>
  </form>
</div>