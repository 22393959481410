<div class="fm-profile-view">
  <div class="fm-profile-view-header">
    <div class="fm-title">
      <h1>Formations</h1>
    </div>
    <div class="fm-action">
      <app-fm-button
        [type]="showAddForm ? 'hide' : 'show'"
        (onClick)="toggleShowAddForm()"
        [attr.data-testid]="'button_showHideFormCourse'">
      </app-fm-button>
    </div>
  </div>

  <app-form-inline
    [attr.data-testid]="'formInline_course'"
    [listInput]="inputForm"
    titleForm="Déclarer une nouvelle formation à mon manager"
    (eventCreateItem)="handleCreateItem($event)"
    [saveDisabled]="disableCreationSave"
    *ngIf="showAddForm">
  </app-form-inline>
  <hr>
  <app-datatable
    [attr.data-testid]="'dataTable_course'"
    editMode="row"
    defaultSortField="course.name"
    [data]="userCourses$ | async"
    [columns]="tableColumns"
    [emptyMessage]="emptyMessage"
    (eventUpdateItem)="handleUpdateItem($event)"
    (eventSaveUpdateItem)="handleSaveUpdateItem($event)"
    (eventCancelEditItem)="handleCancelEditItem()"
    (eventDeleteItem)="handleDeleteItem($event)">
  </app-datatable>

  <app-modal
    [attr.data-testid]="'course_modal_component'"
    [header]="modal.header"
    [content]="modal.content"
    [type]="modal.type"
    [(display)]="showModal"
    (eventModalConfirm)="handleModalConfirm()">
  </app-modal>
</div>
