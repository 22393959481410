import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-collaborator-form',
  templateUrl: './collaborator-form.component.html',
  styleUrls: ['./collaborator-form.component.scss'],
})
export class CollaboratorFormComponent implements OnInit {
  mainGroup: FormGroup;

  savoirEtrequalities = [
    { name: 'Relation client', id: 'A' },
    { name: "Esprit d'equipe", id: 'M' },
    { name: 'Respect des consignes et des horaires', id: 'P' },
    { name: 'Communication', id: 'R' },
  ];

  managementqualities = [
    { name: 'Capacite a diriger', id: 'S' },
    { name: "Animation d'equipe", id: 'SS' },
  ];

  constructor(private formBuilder: FormBuilder) {
    this.mainGroup = formBuilder.group({
      ProfilList: [{}],
      CompetenceList: [{}],
      savoirEtre: [{}],
      management: [{}],
      formation: [{}],
    });
  }

  ngOnInit(): void {}
}
