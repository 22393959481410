import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {
  AddCourse,
  ClearSnapshotCourse, DeleteCourse, GetCourses,
  MakeSnapshotCourse,
  RestoreSnapshotCourse,
  UpdateCourse
} from '../../../store/courses.actions';
import {CourseState, CourseStateModel} from '../../../store/courses.state';
import {MessageService} from 'primeng/api';
import {UserCourseService} from '../../../../../core/services/user-course-services/user-course.service';
import {FormInlineComponent} from '../../../../../shared/components/form-inline/form-inline.component';
import {Course} from '../../../../../shared/models/course';
import {Constants} from '../../../../../shared/constants/constants';

@Component({
  selector: 'app-view-courses',
  templateUrl: './view-courses.component.html',
  styleUrls: ['./view-courses.component.scss']
})
export class ViewCoursesComponent implements OnInit {

  @Select(CourseState.getCourses) courses$: Observable<Course[]>;

  @ViewChild(FormInlineComponent)
  private formCourse: FormInlineComponent;

  @Input()
  showAddForm: boolean;

  titleForm = 'Ajouter une formation';

  private stateSnapshot: CourseStateModel;
  private itemToDelete: Course;

  modal: any = {};
  showModal = false;
  disableCreationSave = false;

  inputForm: any = [];

  courseDataColumns: any[];
  emptyMessage = 'Aucune formation enregistrée';

  constructor(private messageService: MessageService,
              private userCourseService: UserCourseService,
              private store: Store) {
    this.store.dispatch(new GetCourses());
  }

  ngOnInit(): void {
    this.initDatatable();
    this.initForm();
  }

  initDatatable() {
    this.courseDataColumns = [
      {
        type: 'icon',
        isStatic: true,
        className: 'fm-icon-cup',
        sort: false,
      },
      {
        type: 'text',
        field: 'name',
        label: 'Nom',
        font: 'semibold',
        sort: true,
        maxLength: Constants.NAME_SIZE_MAX,
      },
      {
        type: 'text',
        field: 'organization',
        label: 'Organisme',
        font: 'regular',
        sort: true,
        maxLength: Constants.ORGANIZATION_SIZE_MAX,
      },
      {
        type: 'text',
        field: 'description',
        label: 'Description',
        font: 'regular',
        sort: true,
        maxLength: Constants.DESCRIPTION_SIZE_MAX,
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: false,
      },
      {
        type: 'action',
        action: 'edit',
        width: '8%'
      },
      {
        type: 'action',
        action: 'delete',
        width: '10%'
      },
    ];
  }

  initForm() {
    this.inputForm = [
      {
        key: 'nameCourse',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Formation',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.NAME_SIZE_MAX,
      },
      {
        key: 'nameOrganization',
        typeForm: 'text',
        fieldForm: 'organization',
        labelForm: 'Organisme',
        requiredForm: false,
        placeHolder: 'Organisme',
        maxLength: Constants.ORGANIZATION_SIZE_MAX,
      },
      {
        key: 'nameDescription',
        typeForm: 'text',
        fieldForm: 'description',
        labelForm: 'Description',
        requiredForm: false,
        placeHolder: 'Description',
        maxLength: Constants.DESCRIPTION_SIZE_MAX,
      }
    ];
  }

  handleCreateItem(course: Course) {
    if (!this.errorsValidation(course)) {
      this.store.dispatch(new AddCourse(course)).subscribe(
        () => {
          this.displayMessage('create');
          this.formCourse.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  handleUpdateItem() {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotCourse());
  }

  handleSaveUpdateItem(course: Course) {
    if (!this.errorsValidation(course)) {
      this.store.dispatch(new UpdateCourse(course)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.store.dispatch(new RestoreSnapshotCourse());
          this.store.dispatch(new ClearSnapshotCourse());
          this.disableCreationSave = false;
        }
      );
    }
  }

  handleCancelEditItem() {
    this.disableCreationSave = false;
    this.store.dispatch(new RestoreSnapshotCourse());
    this.store.dispatch(new ClearSnapshotCourse());
  }

  handleDeleteItem(id) {
    this.stateSnapshot = this.store.snapshot().courses;
    this.itemToDelete = this.stateSnapshot.courses.find((p) => p.id === id);
    this.userCourseService.userCourseExists(this.itemToDelete).subscribe(
      (response) => {
        if (response) {
          this.displayError('userCourseExists');
        } else {
          this.displayModal('delete');
        }
      },
      (e) => this.displayError(e.status)
    );
  }

  handleModalConfirm() {
    this.deleteItem();
  }

  deleteItem() {
    this.store.dispatch(new DeleteCourse(this.itemToDelete)).subscribe(
      () => this.displayMessage('delete'),
      (e) => this.displayError(e.status)
    );
  }

  errorsValidation(course) {
    let errors = false;
    if (course.name === '') {
      this.displayError('name');
      errors = true;
    }
    return errors;
  }

  displayModal(type) {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  displayMessage(type) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La formation a bien été sauvegardée.';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La formation a bien été sauvegardée.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'La formation a bien été supprimée.';
        break;
      }
    }
    this.messageService.add({ severity, summary, detail });
  }

  displayError(e) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Cette formation existe déjà.';
        break;
      case 'name':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner le nom de la formation.';
        break;
      case 'userCourseExists':
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Cette formation est attribuée à un utilisateur.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }
}
