import { UserSkill } from '../../../shared/models/user-skill';

export class MakeSnapshotUserSkill {
  static readonly type = '[User Skill] MakeSnapshot';
}

export class RestoreSnapshotUserSkill {
  static readonly type = '[User Skill] RestoreSnapshot';
}

export class GetUserSkills {
  static readonly type = '[User Skill] Get all';
}

export class GetUserSkillsByUserId {
  static readonly type = '[User Skill] Get by user id';
  constructor(public payload: number) {}
}

export class AddUserSkill {
  static readonly type = '[User Skill] Add';
  constructor(public payload: UserSkill) {}
}

export class UpdateUserSkill {
  static readonly type = '[User Skill] update';
  constructor(public payload: UserSkill) {}
}

export class DeleteUserSkill {
  static readonly type = '[User Skill] delete';
  constructor(public payload: UserSkill) {}
}

export class ClearUserSkills {
  static readonly type = '[User Skill] Clear user skills';
}
