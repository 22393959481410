import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {forkJoin, Observable, Subscription} from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { MessageService , SelectItem} from 'primeng/api';
import { ProfileService } from '../../../../../../core/services/profile-services/profile.service';
import { UserProfile } from '../../../../../../shared/models/user-profile';
import { FormInlineComponent } from '../../../../../../shared/components/form-inline/form-inline.component';
import { ProfileProfileState } from '../../../../store/profile-profiles.state';
import { Interest } from '../../../../../../shared/models/interest';
import { Level } from '../../../../../../shared/models/level';
import { User } from '../../../../../../shared/models/user';
import { Profile } from '../../../../../../shared/models/profile';
import { Skill } from '../../../../../../shared/models/skill';
import { Specialty } from '../../../../../../shared/models/specialty';
import {
  GetProfileUserProfilesByUserId,
  AddProfileUserProfile,
  UpdateProfileUserProfile,
  RestoreSnapshotProfileUserProfile,
  MakeSnapshotProfileUserProfile,
  ClearProfileUserProfiles,
  DeleteProfileUserProfile
} from '../../../../store/profile-profiles.actions';
import { UserSkillService } from '../../../../../../core/services/user-skill-services/user-skill.service';
import { UserSpecialtyService } from '../../../../../../core/services/user-specialty-services/user-specialty.service';


@Component({
  selector: 'app-profile-view-profiles',
  templateUrl: './profile-view-profiles.component.html',
  styleUrls: ['./profile-view-profiles.component.scss'],
})
export class ProfileViewProfilesComponent implements OnInit, OnDestroy {
  @Select(ProfileProfileState.getUserProfiles)
  public userProfiles$: Observable<UserProfile[]>;

  @ViewChild(FormInlineComponent)
  private formUserProfile: FormInlineComponent;

  @Input()
  private interests: Interest[] = [];
  @Input()
  private levels: Level[] = [];
  @Input()
  private interestsOptions: SelectItem[] = [];
  @Input()
  private levelsOptions: SelectItem[] = [];

  private currentUser: User;
  private itemToDelete: UserProfile;
  private userSkillExists: Skill;
  private userSpecialtyExists: Specialty;
  private profileSubscription: Subscription;
  private profiles: Profile[];
  private defaultInterestsOptions: SelectItem[] = [];
  private defaultLevelsOptions: SelectItem[] = [];

  public modal: any = {};
  public showModal = false;
  public inputForm: any = [];
  public showAddForm: boolean;
  public disableCreationSave = false;
  public tableColumns: any[];
  public emptyMessage = 'Aucun profil n\'est enregistré';

  public constructor(
    private messageService: MessageService,
    private profileService: ProfileService,
    private userSkillService: UserSkillService,
    private userSpecialtyService: UserSpecialtyService,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.currentUser = this.store.snapshot().profile.currentUserProfile;
    this.store.dispatch(new GetProfileUserProfilesByUserId(this.currentUser.id));

    this.interestsOptions.map(
      item => this.defaultInterestsOptions.push(item)
    );
    this.levelsOptions.map(
      item => this.defaultLevelsOptions.push(item)
    );

    this.profileSubscription = this.profileService.getAll().subscribe({
      next: (profiles: Profile[]) => this.profiles = profiles,
      complete: () => {
        this.initDatatable();
        this.initForm();
      },
      error: (e) => this.displayError(e.status)
    });
  }

  private initDatatable(): void {
    this.tableColumns = [
      {
        type: 'select',
        field: 'profile',
        subField: 'name',
        label: 'Poste',
        isStatic: true,
        font: 'semibold',
        sort: false
      },
      {
        type: 'select',
        field: 'interest',
        subField: 'name',
        label: 'Intérêt',
        icon: 'interest',
        options: this.interestsOptions,
        optionIcon: true,
        optionOnlyIcon: true,
        clearable: true,
        sort: false
      },
      {
        type: 'select-if-not-validated',
        field: 'level',
        subField: 'value',
        subFieldLabel: 'name',
        label: 'Niveau',
        icon: 'level',
        options: this.levelsOptions,
        optionIcon: true,
        font: 'regular',
        sort: false
      },
      {
        type: 'validation',
        field: 'validation',
        label: 'Validation',
        sort: false,
        isStatic: true
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: false,
        isStatic: true
      },
      { type: 'action', action: 'edit', width: '8%' },
      { type: 'action', action: 'delete', width: '10%' },
    ];
  }

  private initForm(): void {
    this.inputForm = [
      {
        key: 'nameProfile',
        typeForm: 'dropdown',
        filter: true,
        fieldForm: 'profile',
        labelForm: 'Poste',
        requiredForm: true,
        placeHolder: 'Poste',
        optionsForm: this.profiles,
      },
      {
        key: 'nameInterest',
        typeForm: 'dropdown',
        fieldForm: 'interest',
        labelForm: 'Intérêt',
        requiredForm: false,
        optionsForm: this.interests,
        placeHolder: 'Intérêt',
        icon: true,
        onlyIcon: true
      },
      {
        key: 'nameLevel',
        typeForm: 'dropdown',
        fieldForm: 'level',
        labelForm: 'Niveau',
        optionsForm: this.levels,
        placeHolder: 'Niveau',
        icon: true
      }
    ];
  }

  public toggleShowAddForm(): void {
    this.showAddForm = !this.showAddForm;
  }

  public handleCreateItem(userProfile: UserProfile): void {
    if (!this.errorsValidation(userProfile, 'create')){
      userProfile.user = this.currentUser;
      userProfile.validation = null ;
      if (userProfile.level === null) { userProfile.level = this.levels[0]; }
      this.store.dispatch(new AddProfileUserProfile(userProfile)).subscribe(
        () => {
          this.displayMessage('create');
          this.formUserProfile.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  public handleDeleteItem(id: number): void {
    const userProfile = this.store.snapshot().profileUserProfiles.userProfiles.find(item => item.id === id);
    this.itemToDelete = userProfile;

    const userSkills$ = this.userSkillService.getUserSkillsByUserId(userProfile.user.id);
    const userSpecialties$ = this.userSpecialtyService.getUserSpecialtiesByUserId(userProfile.user.id);

    const observable = forkJoin({
      userSkills$,
      userSpecialties$,
    });
    observable.subscribe({
      next: value => {
        this.userSkillExists = value.userSkills$.find(item => item.skill?.profile?.id === userProfile.profile.id);
        this.userSpecialtyExists = value.userSpecialties$.find(item => item.specialty?.profile?.id === userProfile.profile.id);
      },
      complete: () => {
        if (!!this.userSkillExists) {
          this.displayError('delete-skill-exists');
        }
        if (!!this.userSpecialtyExists) {
          this.displayError('delete-specialty-exists');
        }
        if (!this.userSkillExists && !this.userSpecialtyExists) {
          if (userProfile.validation === null) {
            this.displayModal('delete');
          } else {
            this.deleteItem();
          }
        }
      },
      error: (e) => this.displayError(e.status)
    });
  }

  private setInterestOptions(userProfile: UserProfile): void {
    // Interest options
    const indexInterest = this.interests.findIndex(item => item.id === userProfile.interest?.id);
    if (indexInterest === -1) {
      // Put blank interest
      this.interestsOptions.unshift({label: null, value: null, icon: null});
    } else {
      const iconNameInterest = this.interestsOptions[indexInterest]?.icon;
      // Delete current Interest value of the interests options with indexInterest help
      this.interestsOptions.splice(indexInterest, 1);
      // Put current Interest value at first Interests options place
      this.interestsOptions.unshift({ label: userProfile.interest?.name, value: userProfile.interest, icon: iconNameInterest });
    }
  }

  private setLevelOptions(userProfile: UserProfile): void {
    // Level options
    const indexLevel = this.levels.findIndex(item => item.id === userProfile.level?.id);
    // Get icon's name
    const iconNameLevel = this.levelsOptions[indexLevel].icon;
    // Delete current Level value of the levels options with indexLevel help
    this.levelsOptions.splice(indexLevel, 1);
    // Put current Level value at first Levels options place
    this.levelsOptions.unshift({ label: userProfile.level.name, value: userProfile.level, icon: iconNameLevel });
  }

  private resetInterestsOptions(): void {
    const length = this.interestsOptions.length;
    this.interestsOptions.splice(0, length);
    this.defaultInterestsOptions.map(
      item => this.interestsOptions.push(item)
    );
  }

  private resetLevelsOptions(): void {
    const length = this.levelsOptions.length;
    this.levelsOptions.splice(0, length);
    this.defaultLevelsOptions.map(
      item => this.levelsOptions.push(item)
    );
  }

  public handleUpdateItem(userProfile: UserProfile): void {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotProfileUserProfile());

    this.resetInterestsOptions();
    this.resetLevelsOptions();
    this.setInterestOptions(userProfile);
    this.setLevelOptions(userProfile);
  }

  public handleSaveUpdateItem(userProfile: UserProfile): void {
    if (!this.errorsValidation(userProfile, 'update')) {
      this.store.dispatch(new UpdateProfileUserProfile(userProfile)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.disableCreationSave = false;
        }
      );
    }
  }

  public handleCancelEditItem(): void {
    this.store.dispatch(new RestoreSnapshotProfileUserProfile());
    this.disableCreationSave = false;
  }

  public handleModalConfirm(): void {
    this.deleteItem();
  }

  private deleteItem(): void {
    this.store.dispatch(new DeleteProfileUserProfile(this.itemToDelete)).subscribe(
      (store) => {
        if (store.profileUserSkills.httpStatus === 200) {
          this.displayMessage('delete');
        } else {
          this.displayMessage('delete-notification');
        }
      },
      (e) => this.displayError(e.status)
    );
  }

  /**
   * Forms validation (create and update)
   * @param userProfile UserProfile
   * @param action string
   * @return boolean
   */
  private errorsValidation(userProfile: UserProfile, action: string): boolean {
    let errors = false;
    if (!userProfile.profile) {
      errors = true;
      this.displayError('poste');
    }
    return errors;
  }

  private displayModal(type: string): void {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  private displayMessage(type: string) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'Le poste a bien été sauvegardé';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'Le poste a bien été sauvegardé';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'Poste supprimé.';
        break;
      }
      case 'delete-notification': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'La demande de suppression a bien été envoyée à votre manager principal.';
        break;
      }
    }
    this.messageService.add({severity, summary, detail});
  }

  private displayError(e: any): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Ce poste est déjà attribué à cet utilisateur.';
        break;
      case 'poste':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un poste.';
        break;
      case 'interest':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un intérêt.';
        break;
      case 'delete-skill-exists':
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Ce profil est attribué à une compétence.';
        break;
      case 'delete-specialty-exists':
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Ce profil est attribué à une spécialité.';
        break;
      case 501:
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  public ngOnDestroy(): void {
    this.resetLevelsOptions();
    this.resetInterestsOptions();
    this.profileSubscription.unsubscribe();
    this.store.dispatch(new ClearProfileUserProfiles());
  }
}
