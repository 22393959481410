import { UserCertification } from '../../../shared/models/user-certification';

export class MakeSnapshotUserCertification {
  static readonly type = '[User Certification] MakeSnapshot';
}

export class RestoreSnapshotUserCertification {
  static readonly type = '[User Certification] RestoreSnapshot';
}

export class GetUserCertifications {
  static readonly type = '[User Certification] Get all';
}

export class GetUserCertificationsByUserId {
  static readonly type = '[User Certification] Get by user id';
  constructor(public payload: number) {}
}

export class AddUserCertification {
  static readonly type = '[User Certification] Add';
  constructor(public payload: UserCertification) {}
}

export class UpdateUserCertification {
  static readonly type = '[User Certification] update';
  constructor(public payload: UserCertification) {}
}

export class DeleteUserCertification {
  static readonly type = '[User Certification] delete';
  constructor(public payload: UserCertification) {}
}

export class ClearUserCertifications {
  static readonly type = '[User Certification] Clear user certifications';
}
