import { Team } from '../../../shared/models/team';
import { User } from '../../../shared/models/user';

export class GetTeamUsers {
  static readonly type = '[UserTeam] Get all';
}
export class UpdateTeamUsers {
  static readonly type = '[UserTeam] Update user in users team';
  constructor(public payload: User) {}
}
export class AddTeamUser {
  static readonly type = '[UserTeam] Add member to the team';
  constructor(public payload: Team) {}
}
export class UpdateTeamUserManager {
  static readonly type = '[UserTeam] Update member to the team';
  constructor(public payload: Team) {}
}
export class DeleteTeamUser {
  static readonly type = '[UserTeam] Delete member from the team';
  constructor(public payload: Team) {}
}
export class FilterTeamUsers {
  static readonly type = '[UserTeam] Filter Users';
  constructor(public payload: string) {}
}
export class FilterTeamValidation {
  static readonly type = '[UserTeam] Filter Validation';
  constructor() {}
}
export class ClearTeamUsers {
  static readonly type = '[UserTeam] Clear Users';
  constructor() {}
}
export class ResetFilterTeamUsers {
  static readonly type = '[UserTeam] Reset filter Users';
  constructor() {}
}
export class ResetFilterTeamValidation {
  static readonly type = '[UserTeam] Reset filter Validation';
  constructor() {}
}
export class GetTeamMembers {
  static readonly type = '[UserTeam] Get Manager\'s Team Members';
  constructor() {}
}
