
<app-form-inline
[attr.data-testid]="'formInline_component'"
[titleForm]="titleForm"
[listInput]="inputForm"
[uploadKey]="uploadKey"
(eventCreateItem)="handleCreateItem($event)"
*ngIf="showAddForm"
[saveDisabled]="disableCreationSave">
</app-form-inline>
<hr>
<app-datatable
[attr.data-testid]="'dataTable_component'"
editMode="row"
[data]="skills$ | async"
[columns]="skillDataColumns"
[emptyMessage]="emptyMessage"
(eventDeleteItem)="handleDeleteItem($event)"
(eventUpdateItem)="handleUpdateItem($event)"
(eventFusionItem)="handleFusionItem($event)"
(eventSaveUpdateItem)="handleSaveUpdateItem($event)"
(eventCancelEditItem)="handleCancelEditItem()">
</app-datatable>

<app-modal
[header]="modal.header"
[content]="modal.content"
[type]="modal.type"
[data]="skillByProfileId"
[(display)]="showModal"
[closable]="true"
(eventModalConfirm)="handleModalConfirm()"
(eventFusionConfirm)="handleFusionConfirm($event)"
[attr.data-testid]="'modal_component'"
>
</app-modal>


