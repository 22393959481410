<script src="select-collaborator.component.ts"></script>
<div class="fm-sel-collab-main">
  <h2>{{ title }}</h2>
  <div class="fm-sel-collab-container">
        <p-autoComplete [(ngModel)]="val"
                        [attr.data-testid]="'summary_members_field_select'"
                        [suggestions]="results"
                        (completeMethod)="filterUsers($event)"
                        (onSelect)="onSelect($event)"
                        minLength="2"
                        placeholder="Nom, prénom ou adresse email"
                        dropdown="true"
                        dropdownIcon="fm-icon-search fm-icon-grey fm-icon-sm"
                        field="memberFullname"
                        [emptyMessage]="emptyMessage"
        >
        </p-autoComplete>
      </div>
</div>
