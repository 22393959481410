import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';
import { Profile } from '../../../shared/models/profile';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService extends ApiService {

  protected prefix(): string {
    return 'userProfiles';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(content: any): Observable<any> {
    return this.put(content);
  }

  doDelete(id: number, options: any = {}): Observable<any> {
    return this.delete(id, options);
  }

  getUserProfilesByUserId(userId: number): Observable<any> {
    return this.get('/user/' + userId);
  }

  getUserProfilesDetailsByUserId(userId: number): Observable<any> {
    return this.get('/details/user/' + userId);
  }

  userProfileExists(profile: Profile): Observable<any> {
    return this.get('profile/' + profile.id).pipe(
      map(items => items.length > 0)
    );
  }

  getAllByString(): Observable<any> {
    return this.get();
  }

  mergeProfiles(ids: String[]): Observable<any> {
    let idFrom = ids[0];
    let idTo = ids[1];
    return this.get('profile/merge/from/' + idFrom + '/to/' + idTo);
  }
}
