import { Specialty } from 'src/app/shared/models/specialty';

export class MakeSnapshotSpecialty {
  static readonly type = '[Specialty] MakeSnapshot';
}

export class RestoreSnapshotSpecialty {
  static readonly type = '[Specialty] RestoreSnapshot';
}

export class ClearSnapshotSpecialty {
  static readonly type = '[Specialty] ClearSnapshot';
}

export class GetSpecialties {
  static readonly type = '[Specialty] Get';
}

export class AddSpecialty {
  static readonly type = '[Specialty] Add';
  constructor(public payload: Specialty) {}
}

export class UpdateSpecialty {
  static readonly type = '[Specialty] update';
  constructor(public payload: Specialty) {}
}

export class DeleteSpecialty {
  static readonly type = '[Specialty] delete';
  constructor(public payload: Specialty) {}
}

export class FilterSpecialties {
  static readonly type = '[Specialty] Filter';
  constructor(public payload: string) {}
}
