import {User} from '../../app/shared/models/user';

export class GetFilteredRequest {
  static readonly type = '[Request] GetFilteredRequest';
  constructor(public search: string) {}
}
export class GetRequestByManager {
  static readonly type = '[Request] GetRequestByManager';
  constructor(public user: User, public startDate: string, public endDate: string, public search: string) {}
}
