import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { Assignement } from '../../../shared/models/assignement';
import { User } from '../../../shared/models/user';
import * as moment from 'moment';
import { isArray } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {

  isNavBarVisible = true;

  set showNavBar(v: any) {
    this.isNavBarVisible = v;
  }

  protected prefix(): string {
    return 'users';
  }

  /**
   * Get the list of users
   */
  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(content: any): Observable<any> {
    return this.put(content);
  }

  doDelete(id: number): Observable<any> {
    return this.delete(id);
  }

  isExist(id): Observable<any> {
    return this.getOne(id).pipe(
      map(data => data.id === id),
    );
  }

  getCurrentUser(email: string, page?: string): Observable<any> {
    const params = new Map();
    params.set('email', email);
    if (page) {
      params.set('page', page);
    }
    return this.get('currentUser', params);
  }

  getUsersByCriteria(criteria: string): Observable<any> {
    return this.get('search/' + criteria);
  }

  getUsersTeamByCriteria(criteria: string): Observable<any> {
    return this.get('team/' + criteria);
  }

  getUsersBySkills(criteria: string): Observable<any> {
    return this.get('search/skills/' + criteria);
  }

  getUsersByProperties(criteria: string): Observable<any> {
    return this.get('search/properties/' + criteria);
  }

  getTeamMember(teamMemberId: number): Observable<any> {
    return this.get('teamMember/' + teamMemberId);
  }

  getTeamMembers(managerId: number): Observable<any> {
    return this.get('teamMembers/' + managerId);
  }

  orderUsersByProperties(users: any[]): any[] {
    return users.map((user) => {
      user.duration = this.getProjectDurationUntilToday(user);
      user.customers = this.getCustomers(user);
      user.projects = user.assignments?.map(p => p.project.name).join(', ');
      user.userSkills = this.orderedBylevelThenByAlphabeticalOrder(user.userSkills, 'skill');
      user.userProfiles = this.orderedBylevelThenByAlphabeticalOrder(user.userProfiles, 'profile'),
      user.background = user.weeklyReports.length > 0;
      return user;
    }).sort((a, b) => (a.lastname.toLowerCase() > b.lastname.toLowerCase()) ? 1 : -1);
  }

  getUsersForDirectory(page?: string, pageNumber?: number): Observable<any> {
    const params = new Map();
    if (page) {
      params.set('page', page);
    }
    if (pageNumber !== undefined && pageNumber !== null) {
      params.set('pageNumber', pageNumber);
    }

    return this.get('', params).pipe(
      map((users: User[] ) => {
          return users.map( user => ({
            ...user,
            duration : this.getProjectDurationUntilToday(user),
            userSkills : this.orderedBylevelThenByAlphabeticalOrder(user.userSkills, 'skill'),
            userSpecialties : this.orderedBylevelThenByAlphabeticalOrder(user.userSpecialties, 'specialty'),
            userProfiles : this.orderedBylevelThenByAlphabeticalOrder(user.userProfiles, 'profile'),
          })).sort((a, b) => (a.lastname.toLowerCase() > b.lastname.toLowerCase()) ? 1 : -1);
        }
      ));
  }

  getOngoingProject(user: any): Assignement {
    return user.assignments?.find(a => (new Date(a.endDate).getTime() === 0) || (new Date().getTime() < new Date(a.endDate).getTime()));
  }

  getCustomers(user: any): any {
    const customers = [];
    // get customers from all the ongoing assignments
    user.assignments?.filter(a => (new Date(a.endDate).getTime() === 0) || (new Date().getTime() < new Date(a.endDate).getTime()))
      .forEach(a => customers.push({customer: a.project.customer}));
    return customers;
  }

  // Get Longest Duration of assignment
  getProjectDurationUntilToday(user: any): string {
    // Get current assignment
    const currentAssignment = user.assignments?.filter(a =>
      (new Date(a.endDate).getTime() === 0) || (new Date().getTime() < new Date(a.endDate).getTime()));
    // Get long assignment
    const  longAssignment = currentAssignment?.reduce((prev, current) => {
      return ((new Date()?.getTime() - new Date(prev.startDate)?.getTime())
        >  (new Date()?.getTime() - new Date(current.startDate)?.getTime()) ) ? prev : current;
    }, 0);
    if (longAssignment === 0) { return ''; }

    // Get Duration
    const assignmentDateFormatted = moment(longAssignment.startDate, '', 'fr').format();
    const assignmentDate = moment(new Date(assignmentDateFormatted), '', 'fr');
    const nowDate = moment(new Date(new Date().setHours(0, 0, 0, 0)), '', 'fr');

    const numberDays = nowDate.diff(assignmentDate, 'days');
    const numberMonth = nowDate.diff(assignmentDate, 'months');

    let duration: string;
    switch (true) {
      case numberDays === 0:
        duration = 'depuis aujourd\'hui';
        break;
      case numberDays < 7:
        const daySuffix = numberDays === 1 ? ' jour' : ' jours';
        duration = numberDays + daySuffix;
        break;
      case numberMonth === 0:
        const numberWeeks = nowDate.diff(assignmentDate, 'weeks');
        const weekSuffix = numberWeeks === 1 ? ' semaine' : ' semaines';
        duration = numberWeeks + weekSuffix;
        break;
      case numberMonth < 12:
        duration = numberMonth + ' mois';
        break;
      default:
        const numberYears =  nowDate.diff(assignmentDate, 'years');
        const yearSuffix = numberYears === 1 ? ' an' : ' ans';
        duration = numberYears + yearSuffix;
    }

    return '- ' + duration;
  }

  orderedBylevelThenByAlphabeticalOrder(elements: any, subElementName: string): any[] {
    if (isArray(elements) === false) { elements = []; }
    elements?.sort(
      (a, b) => {
        const compareAlphabetically = a[subElementName]?.name.toLocaleLowerCase()
          .localeCompare(b[subElementName]?.name.toLocaleLowerCase());
        const compareLevel = b.level.value.toString().localeCompare(a.level.value.toString());
        return compareLevel || compareAlphabetically;
      });
    return elements;
  }
}
