<div class="fm-button">
  <app-fm-button type="text-gradient" btnText="RETOUR" (onClick)="handleBack()"></app-fm-button>
</div>

<p-dropdown
  [attr.data-testid]="'dropDown_page'"
  [options]="selectItems"
  [(ngModel)]="selectedItem"
  class="fm-select-items">
</p-dropdown>

<div *ngIf="selectedItem === 'Mes compétences métiers'">
  <app-profile-view-profiles
    [attr.data-testid]="'view_profiles'"
    [interests]="interests"
    [interestsOptions]="interestsOptions"
    [levels]="levels"
    [levelsOptions]="levelsOptions"
  ></app-profile-view-profiles>
  <app-profile-view-specialties
    [attr.data-testid]="'view_specialties'"
    [interests]="interests"
    [interestsOptions]="interestsOptions"
    [levels]="levels"
    [levelsOptions]="levelsOptions"
  ></app-profile-view-specialties>
  <app-profile-view-skills
    [attr.data-testid]="'view_skills'"
    [interests]="interests"
    [interestsOptions]="interestsOptions"
    [levels]="levels"
    [levelsOptions]="levelsOptions"
  ></app-profile-view-skills>
</div>

<!--  TODO: display-soft-skills-->
<div *ngIf="selectedItem === 'Mes compétences transverses'">
  <app-profile-view-soft-skills
    [attr.data-testid]="'view_softSkills'"
    [interestsOptions]="interestsOptions"
    [levelsOptions]="levelsOptions"
  ></app-profile-view-soft-skills>
</div>

<div *ngIf="selectedItem === 'Mes formations et certifications'">
  <app-profile-view-courses
    [attr.data-testid]="'view_courses'"
    [interests]="interests"
    [interestsOptions]="interestsOptions"
  ></app-profile-view-courses>
  <app-profile-view-certifications
    [attr.data-testid]="'view_certifications'"
    [interests]="interests"
    [interestsOptions]="interestsOptions"
  ></app-profile-view-certifications>
  <div>
    <app-fm-button
      [attr.data-testid]="'button_pageRequest'"
      type="text-gradient"
      btnText="DEMANDER UNE FORMATION"
      (click)="handleShowPageRequest()"
      class="fm-button-request">
    </app-fm-button>
  </div>
</div>
