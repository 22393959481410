import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from 'src/app/shared/models/notification';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends ApiService{

  protected prefix(): string {
    return 'notifications';
  }

  /**
   * Get the list of notifications
   */
   getUserNotificationsByStatusTrue(): Observable<any> {
    return this.get('/status/');
  }

  getAll(): Observable<any> {
    return this.get();
  }

  doPut(notification: Notification): Observable<any> {
    return this.put(notification);
  }

}
