import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import { MessageService, SelectItem } from 'primeng/api';
import { Store, Select } from '@ngxs/store';
import { SpecialtyState, SpecialtyStateModel } from '../../../store/specialties.state';
import {
  ClearSnapshotSpecialty,
  DeleteSpecialty,
  GetSpecialties,
  MakeSnapshotSpecialty,
  RestoreSnapshotSpecialty,
  UpdateSpecialty
} from '../../../store/specialties.actions';
import { UserSpecialtyService } from '../../../../../core/services/user-specialty-services/user-specialty.service';
import { ProfileService } from 'src/app/core/services/profile-services/profile.service';
import { FormInlineComponent } from 'src/app/shared/components/form-inline/form-inline.component';
import { Specialty } from 'src/app/shared/models/specialty';
import { AddSpecialty } from '../../../store/specialties.actions';
import { Profile } from 'src/app/shared/models/profile';
import { SkillService } from 'src/app/core/services/skill-service/skill.service';
import {Constants} from '../../../../../shared/constants/constants';

@Component({
  selector: 'app-view-specialties',
  templateUrl: './view-specialties.component.html',
  styleUrls: ['./view-specialties.component.scss'],
})
export class ViewSpecialtiesComponent implements OnInit {
  @Select(SpecialtyState.getSpecialties) specialties$: Observable<Specialty[]>;

  @ViewChild(FormInlineComponent)
  private formSpecialty: FormInlineComponent;

  @Input()
  showAddForm: boolean;

  titleForm = 'Ajouter une spécialité';

  private stateSnapshot: SpecialtyStateModel;
  private itemToDelete: Specialty;

  modal: any = {};
  showModal = false;
  disableCreationSave = false;

  profiles: Profile[] = [];
  profilesSelection: SelectItem[] = [];
  profileSubscription: Subscription;

  specialtiesCategories: string[] = [];
  specialtiesCategoriesSuggestions$ = new BehaviorSubject<string[]>(null);

  inputForm: any = [];

  specialtyDataColumns: any[];
  specialtyActionsColumns: any[];
  emptyMessage = 'Aucune spéciatlité enregistrée';

  constructor(
    private userSpecialtyService: UserSpecialtyService,
    private messageService: MessageService,
    private skillService: SkillService,
    private profileService: ProfileService,
    private store: Store
  ) {
    this.store.dispatch(new GetSpecialties());
  }

  ngOnInit(): void {
    this.profileSubscription = this.profileService.getAll().subscribe(
      (profilesReceive) => {
        profilesReceive.forEach((profile) => this.profiles.push(profile));
        profilesReceive.forEach((profile) =>
          this.profilesSelection.push({ label: profile.name, value: profile })
        );
        this.initCategories();
        this.initDatatable();
        this.initForm();
      },
      (e) => this.displayError(e.status)
    );
  }

  initCategories() {
    this.specialties$.subscribe(
      result => {
        this.specialtiesCategories = [];
        result.map(specialty => {
          if (!this.specialtiesCategories.includes(specialty.category)) {
            this.specialtiesCategories.push(specialty.category);
          }
        });
      },
      e => this.displayError(e.status),
      () => this.specialtiesCategories.sort()
    );
  }

  initDatatable() {
    this.specialtyDataColumns = [
      {
        type: 'text',
        field: 'name',
        label: 'Nom',
        font: 'semibold',
        sort: true,
        maxLength: Constants.NAME_SIZE_MAX
      },
      {
        type: 'autocomplete',
        field: 'category',
        label: 'Catégorie',
        font: 'regular',
        sort: true,
        suggestions: this.specialtiesCategoriesSuggestions$,
        suggestionField: null,
        maxLength: Constants.CATEGORY_SIZE_MAX,
        filterItems: (event) => this.filterCategories(event.query)
      },
      {
        type: 'select',
        options: this.profilesSelection,
        field: 'profile',
        subField: 'name',
        label: 'Poste',
        font: 'semibold',
        sort: true,
        sortField: 'profile.name'
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: true,
      },
      { type: 'action', action: 'edit', width: '8%' },
      { type: 'action', action: 'delete', width: '10%' },
    ];
  }

  initForm() {
    this.inputForm = [
      {
        key: 'nameSpecialty',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Spécialité',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.NAME_SIZE_MAX,
      },
      {
        key: 'nameProfile',
        typeForm: 'dropdown',
        fieldForm: 'profile',
        labelForm: 'Poste',
        requiredForm: true,
        optionsForm: this.profiles,
        placeHolder: 'Poste',
      },
      {
        key: 'nameCategory',
        typeForm: 'autocomplete',
        fieldForm: 'category',
        labelForm: 'Catégorie',
        requiredForm: false,
        placeHolder: 'Catégorie',
        suggestions: this.specialtiesCategoriesSuggestions$,
        suggestionField: null,
        filterItems: (event) => this.filterCategories(event.query),
        maxLength: Constants.CATEGORY_SIZE_MAX,
      }
    ];
  }

  filterCategories(query) {
    this.specialtiesCategoriesSuggestions$.next(
      this.specialtiesCategories.filter(
        category => category && category.toLowerCase().indexOf(query.toLowerCase()) !== -1
      ));
  }

  handleCreateItem(specialty: Specialty) {
    if (!this.errorsValidation(specialty)) {
      this.store.dispatch(new AddSpecialty(specialty)).subscribe(
        () => {
          this.displayMessage('create');
          this.formSpecialty.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  handleUpdateItem(specialty: Specialty) {
    this.disableCreationSave = true;
    let index = this.profiles.findIndex(item=>item.id === specialty.profile.id);
    this.profiles.splice(index,1);
    this.profilesSelection.splice(index,1);
    this.profilesSelection.unshift({ label: specialty.profile.name, value: specialty.profile });
    this.profiles.unshift(specialty.profile);
    this.store.dispatch(new MakeSnapshotSpecialty());
  }

  handleSaveUpdateItem(specialty: Specialty) {
    if (!this.errorsValidation(specialty)) {
      this.store.dispatch(new UpdateSpecialty(specialty)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.store.dispatch(new RestoreSnapshotSpecialty());
          this.store.dispatch(new ClearSnapshotSpecialty());
          this.disableCreationSave = false;
        }
      );
    }
  }

  handleCancelEditItem(data) {
    this.disableCreationSave = false;
    this.store.dispatch(new RestoreSnapshotSpecialty());
    this.store.dispatch(new ClearSnapshotSpecialty());
  }

  handleDeleteItem(id) {
    this.stateSnapshot = this.store.snapshot().specialties;
    this.itemToDelete = this.stateSnapshot.specialties.find((p) => p.id === id);

    this.userSpecialtyService.userSpecialtyExists(this.itemToDelete).subscribe(
      (response) => {
        if (response) {
          this.displayError('userSpecialtyExists');
        } else {
          this.displayModal('delete');
        }
      },
      (e) => this.displayError(e.status)
    );
  }

  handleModalConfirm() {
    this.deleteItem();
  }

  deleteItem() {
    this.store.dispatch(new DeleteSpecialty(this.itemToDelete)).subscribe(
      () => this.displayMessage('delete'),
      (e) => this.displayError(e.status)
    );
  }

  updateSpecialty(specialty: Specialty): Specialty {
    return {
      id: specialty.id,
      name: specialty.name,
      profile: {
        id: specialty.profile.id,
      },
      category: specialty.category,
      archivingDate: specialty.archivingDate
        ? new Date(specialty.archivingDate).toISOString()
        : null,
    };
  }

  errorsValidation(specialty) {
    let errors = false;
    if (specialty.name === '') {
      this.displayError('name');
      errors = true;
    }
    if (!specialty.profile) {
      this.displayError('profile');
      errors = true;
    }
    return errors;
  }

  displayModal(type) {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  displayMessage(typeError) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (typeError) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La spécialité a bien été sauvegardée.';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La spécialité a bien été sauvegardée.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'La spécialité a bien été supprimée.';
        break;
      }
    }
    this.messageService.add({ severity, summary, detail });
  }

  displayError(e) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Cette spécialité existe déjà.';
        break;
      case 'name':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner le nom de la spécialité.';
        break;
      case 'profile':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un poste.';
        break;
      case 'userSpecialtyExists':
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Cette spécialité est attribuée à un utilisateur.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }
}
