import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import {
  GetUserSoftSkillsByUserId,
  UpdateUserSoftSkill,
  AddUserSoftSkill,
  ClearUserSoftSkillState
} from '../../../../store/team-soft-skills.actions';
import { TeamSoftSkillState } from '../../../../store/team-soft-skills.state';
import { MessageService , SelectItem} from 'primeng/api';
import { UserSoftSkill } from '../../../../../../shared/models/user-soft-skill';

@Component({
  selector: 'app-view-soft-skills',
  templateUrl: './view-soft-skills.component.html',
  styleUrls: ['./view-soft-skills.component.scss'],
})
export class ViewSoftSkillsComponent implements OnInit, OnDestroy {
  @Select(TeamSoftSkillState.getSoftSkillCategories)
  public softSkillCategories$: Observable<UserSoftSkill[]>;
  @Select(TeamSoftSkillState.getUserSoftSkillsByCategories)
  public userSoftSkillsByCategories$: Observable<UserSoftSkill[]>;

  @Input()
  private interestsOptions: SelectItem[] = [];

  @Input()
  private levelsOptions: SelectItem[] = [];

  private defaultInterestsOptions: SelectItem[] = [];
  private defaultLevelsOptions: SelectItem[] = [];

  public tableColumns: any[];

  public constructor(
    private messageService: MessageService,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    const teamState = this.store.snapshot().team;
    this.store.dispatch(new GetUserSoftSkillsByUserId(teamState.currentTeamUser.id));

    this.interestsOptions.map(
      item => this.defaultInterestsOptions.push(item)
    );
    this.levelsOptions.map(
      item => this.defaultLevelsOptions.push(item)
    );

    this.initDatatable();
  }

  private initDatatable(): void {
    this.tableColumns = [
      {
        type: 'text',
        field: 'softSkill',
        subField: 'name',
        label: 'Nom',
        isStatic: true,
        font: 'semibold',
        sort: true
      },
      // {
      //   type: 'select',
      //   field: 'interest',
      //   subField: 'name',
      //   label: 'Intérêt',
      //   icon: 'interest',
      //   options: this.interestsOptions,
      //   optionIcon: true,
      //   optionOnlyIcon: true,
      //   clearable: true,
      //   sort: false
      // },
      {
        type: 'select',
        field: 'level',
        subField: 'value',
        subFieldLabel: 'name',
        label: 'Niveau',
        icon: 'level',
        options: this.levelsOptions,
        optionIcon: true,
        font: 'regular',
        sort: false
      },
      { type: 'action', action: 'edit', width: '8%' },
    ];
  }

  private setInterestOptions(userSoftSkill: UserSoftSkill): void {
    // Interest options
    const indexInterest = this.interestsOptions.findIndex(item => item.value.id === userSoftSkill.interest?.id);
    if (indexInterest === -1) {
      // Put blank interest
      this.interestsOptions.unshift({label: null, value: null, icon: null});
    } else {
      const iconNameInterest = this.interestsOptions[indexInterest].icon;
      // Delete current Interest value of the interests options with indexInterest help
      this.interestsOptions.splice(indexInterest, 1);
      // Put current Interest value at first Interests options place
      this.interestsOptions.unshift({ label: userSoftSkill.interest?.name, value: userSoftSkill.interest, icon: iconNameInterest });
    }
  }

  private setLevelOptions(userSoftSkill: UserSoftSkill): void {
    // Level options
    const indexLevel = this.levelsOptions.findIndex(item => item.value.id === userSoftSkill.level?.id);
    // Get icon's name
    const iconNameLevel = this.levelsOptions[indexLevel].icon;
    // Delete current Level value of the levels options with indexLevel help
    this.levelsOptions.splice(indexLevel, 1);
    // Put current Level value at first Levels options place
    this.levelsOptions.unshift({ label: userSoftSkill.level.name, value: userSoftSkill.level, icon: iconNameLevel });
  }

  private resetInterestsOptions(): void {
    const length = this.interestsOptions.length;
    this.interestsOptions.splice(0, length);
    this.defaultInterestsOptions.map(
      item => this.interestsOptions.push(item)
    );
  }

  private resetLevelsOptions(): void {
    const length = this.levelsOptions.length;
    this.levelsOptions.splice(0, length);
    this.defaultLevelsOptions.map(
      item => this.levelsOptions.push(item)
    );
  }

  public handleUpdateItem(userSoftSkill: UserSoftSkill): void {
    this.resetInterestsOptions();
    this.resetLevelsOptions();
    this.setInterestOptions(userSoftSkill);
    this.setLevelOptions(userSoftSkill);
  }

  public handleSaveUpdateItem(userSoftSkill: UserSoftSkill): void {
    if (userSoftSkill.id === null) {
      this.store.dispatch(new AddUserSoftSkill(userSoftSkill)).subscribe(
        () => this.displayMessage('create'),
        (e) => this.displayError(e.status)
      );
    } else {
      this.store.dispatch(new UpdateUserSoftSkill(userSoftSkill)).subscribe(
        () => this.displayMessage('update'),
        (e) => this.displayError(e.status)
      );
    }
  }

  private displayMessage(type): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create':
      case 'update':
        severity = 'success';
        summary = 'Edition';
        detail = 'Le poste a bien été sauvegardé';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  private displayError(e: any): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 501:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail, sticky: true});
  }

  public ngOnDestroy(): void {
    this.resetLevelsOptions();
    this.resetInterestsOptions();
    this.store.dispatch(new ClearUserSoftSkillState());
  }
}

