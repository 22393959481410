<div class="fm-top">
  <span>Si tu repars en mission :</span>
  <div class="fm-top-content">
    <div class="fm-top-pc">
      <i class="fm-icon-pc"></i>
      <span>N'oublie pas de redonner ton PC</span>
    </div>
    <div class="fm-top-feedback">
      <i class="fm-icon-interview"></i>
      <span>N'oublie pas de remplir l'enquête de satisfaction</span>
      <app-fm-button
        type="text-transparent"
        [attr.data-testid]="'rha_button_survey'"
        [btnText]="'Répondre à l\'enquète'.toUpperCase()"
        (onClick)="redirectToRhaSurvey()">
      </app-fm-button>
    </div>
  </div>
</div>

<div class="fm-title">
  <h2>Mon Rapport Hebdomadaire d'Activité</h2>
</div>
<form [formGroup]="formData">
  <div class="fm-form">
    <h2 class="fm-margin-bottom">1. Comment s'est passée ta semaine ?</h2>
    <p-rating
      [attr.data-testid]="'rha_field_rating'"
      id="rating"
      stars="4"
      formControlName="rating"
      [cancel]="false">
    </p-rating>

    <h2 class="fm-margin-top fm-margin-bottom">2. Sur quel sujet travailles-tu ?</h2>
    <div class="p-field-radiobutton">
      <p-radioButton
        [attr.data-testid]="'rha_field_project_academy'"
        name="rhaproject"
        [(ngModel)]="selectProject"
        value="-1"
        formControlName="project">
      </p-radioButton>
      <label>Creative ACADEMY</label>
      <p-dropdown
        [attr.data-testid]="'rha_field_course'"
        [options]="coursesOptions"
        [(ngModel)]="selectCourse"
        formControlName="course"
        placeholder="Sélectionner la formation"
        [style]="{'width': '500px'}">
      </p-dropdown>
    </div>
    <div *ngFor="let sub of projects$ | async" class="p-field-radiobutton">
      <p-radioButton
        [attr.data-testid]="'rha_field_project_' + sub.name"
        [(ngModel)]="selectProject"
        name="rhaproject"
        [value]="sub.id"
        formControlName="project">
      </p-radioButton>
      <label>{{sub.name}}</label>
    </div>
    <h2 class="fm-margin-top fm-margin-bottom">3. As-tu imputé ton travail sur
      <a [href]="envService.rha.projeqtorServer" target="_blank">ProjeQtor</a>
       ?
    </h2>
    <div class="p-field-radiobutton">
      <p-radioButton
        [attr.data-testid]="'rha_field_projeqtor_true'"
        value="true"
        name="projeqtor"
        [(ngModel)]="projeqtor"
        formControlName="projeqtor"
        inputId="choiceYes">
      </p-radioButton>
      <label>Oui</label>
    </div>
    <div class="p-field-radiobutton">
      <p-radioButton
        [attr.data-testid]="'rha_field_projeqtor_false'"
        value="false"
        name="projeqtor"
        [(ngModel)]="projeqtor"
        formControlName="projeqtor"
        inputId="choiceNo">
      </p-radioButton>
      <label>Non</label>
    </div>

    <div>En cas de besoin, suivez la procédure :
      <a target="_blank"
         [attr.data-testid]="'rha_link_projeqtor'"
         rel="noopener noreferrer"
         [href]="envService.rha.projeqtorPdf">
      https://minds.groupe-creative.fr/documents/projeqtor.pdf
      </a>
    </div>

    <h2 class="fm-margin-top fm-margin-bottom">4. Travail effectué pendant la semaine</h2>
    <p-editor
      [attr.data-testid]="'rha_field_task'"
      [style]="editorStyle"
      id="task"
      formControlName="task"
      placeholder="Décris en quelques mots ton travail : avancées, problèmes, questions... (Champ obligatoire)">
    </p-editor>

    <h2 class="fm-margin-top fm-margin-bottom">5. As-tu de la visibilité sur ta prochaine mission ?</h2>
    <p-editor
      [attr.data-testid]="'rha_field_mission'"
      [style]="editorStyle"
      id="mission"
      formControlName="mission"
      placeholder="Entretien passé cette semaine, date de démarrage, type de mission... (Champ obligatoire)">
    </p-editor>

    <h2 class="fm-margin-top fm-margin-bottom">6. As-tu des jours de congés la semaine prochaine ?</h2>
    <p-editor
      [attr.data-testid]="'rha_field_vacation'"
      [style]="editorStyle"
      id="vacation"
      formControlName="vacation"
      placeholder="Si oui, lesquels ? (Champ obligatoire)">
    </p-editor>

    <div class="fm-bottom">
      <i class="fm-icon-chat"></i><span>Si possible, impute ces jours de congés par anticipation dans ProjeQtor</span>
    </div>
  </div>
</form>


<div class="fm-buttons">
  <app-fm-button
    [attr.data-testid]="'rha_button_cancel'"
    type="cancel"
    btnText="Annuler"
    routerLink="/home">
  </app-fm-button>
  <app-fm-button
    [attr.data-testid]="'rha_button_validate'"
    type="text-gradient"
    btnText="VALIDER"
    (onClick)="validateWeeklyReport()">
  </app-fm-button>
</div>
