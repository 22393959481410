import { Component, Input, OnInit } from '@angular/core';
import { LoginInfo } from '../loginInfo';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  @Input() loginInfo: LoginInfo;

  constructor() { }

  ngOnInit(): void {
  }

}
