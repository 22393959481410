import { Project } from '../../../shared/models/project';

export class MakeSnapshotProject {
  static readonly type = '[Project] MakeSnapshot';
}

export class RestoreSnapshotProject {
  static readonly type = '[Project] RestoreSnapshot';
}

export class ClearSnapshotProject {
  static readonly type = '[Project] ClearSnapshot';
}

export class GetProjects {
  static readonly type = '[Project] GetProjects';
}

export class GetSnapshot {
  static readonly type = '[Project] GetSnapshot';
}

export class AddProject {
  static readonly type = '[Project] Add';
  constructor(public payload: Project) {}
}

export class UpdateProject {
  static readonly type = '[Project] update';
  constructor(public payload: Project) {}
}

export class DeleteProject {
  static readonly type = '[Project] delete';
  constructor(public payload: Project) {}
}

export class FilterProjects {
  static readonly type = '[Project] Filter';
  constructor(public payload: string) {}
}
