import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../../shared/models/user';
import {ResetFilterTeamUsers, UpdateTeamUserManager} from '../../../store/team-users.actions';
import {ClearTeamUser, SetCurrentTeamUser, UpdateTeamUser} from '../../../store/team.actions';
import {MessageService, SelectItem} from 'primeng/api';
import {InterestService} from '../../../../../core/services/interest-services/interest.service';
import {LevelService} from '../../../../../core/services/level-services/level.service';
import {AuthenticationService} from '../../../../../core/authentication/authentication.service';
import {Select, Store} from '@ngxs/store';
import {ActivatedRoute, Router} from '@angular/router';
import {TeamState} from '../../../store/team.state';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {Interest} from '../../../../../shared/models/interest';
import {Level} from '../../../../../shared/models/level';
import {withLatestFrom} from 'rxjs/operators';
import {Constants} from '../../../../../shared/constants/constants';

@Component({
  selector: 'app-summary-update-profile',
  templateUrl: './summary-update-profile.component.html',
  styleUrls: ['./summary-update-profile.component.scss']
})
export class SummaryUpdateProfileComponent implements OnInit, OnDestroy {
  @Select(TeamState.getCurrentTeamUser)
  public user$: Observable<User>;

  public isLoaded = false;
  public module = 'summary';

  public updateUser: User;
  public memberId: number;

  public mainManager: boolean;

  public modal: any = {};
  public showModal = false;

  public displayOnlyQualificationsNotValidated = false;

  public subscriptions: Subscription;
  public iconLevel = Constants.LEVEL_ICONS;
  public interests: Interest[] = [];
  public levels: Level[] = [];
  public interestsOptions: SelectItem[] = [];
  public levelsOptions: SelectItem[] = [];
  public selectedItem = 'Compétences métiers';
  public selectItems: SelectItem[] = [];

  constructor(
    private messageService: MessageService,
    private interestService: InterestService,
    private levelService: LevelService,
    private authenticationService: AuthenticationService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.memberId = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    const interests$ = this.interestService.getAll();
    const levels$ = this.levelService.getAll();

    this.subscriptions = forkJoin({
      interests$,
      levels$,
    }).subscribe({
      next: value => {
        value.interests$.map(item => {
          this.interestsOptions.push({
            label: item.name,
            value: {...item},
            icon: 'fm-icon-' + item.name
          });
          item.icon = 'fm-icon-' + item.name;
          this.interests.push({...item});
        });

        value.levels$.map(item => {
          this.levelsOptions.push({
            label: item.name,
            value: {...item},
            icon: this.iconLevel[item.value - 1]
          });
          item.icon = this.iconLevel[item.value - 1];
          this.levels.push(item);
        });
      },
      complete: () => {
        this.store
          .dispatch(new SetCurrentTeamUser(this.memberId))
          .pipe(withLatestFrom(this.user$))
          .subscribe(([_, user]) => {
            this.mainManager = this.isMainManager(user);

            // Set menu items
            if (this.mainManager) {
              this.selectItems = [
                {label: 'Compétences métiers', value: 'Compétences métiers'},
                {label: 'Compétences transverses', value: 'Compétences transverses' },
                {label: 'Formations et certifications', value: 'Formations et certifications'},
                {label: 'Missions', value: 'Missions'},
              ];
            } else {
              this.selectItems = [
                {label: 'Compétences métiers', value: 'Compétences métiers'},
                {label: 'Formations et certifications', value: 'Formations et certifications'},
                {label: 'Missions', value: 'Missions'},
              ];
            }

            this.isLoaded = true;
          });
      },
      error: e => this.displayError(e)
    });
  }

  public isMainManager(selectedUser: User): boolean {
    const team = selectedUser.mainTeam;
    const currentManager = this.store.snapshot().team.manager;

    return team !== null &&
      currentManager !== null &&
      team.mainManager &&
      team.manager.id === currentManager.id;
  }

  public updateMainManager(member: User, mainManager: boolean): void {
    const team = {
      teamId: member.currentTeam.teamId,
      manager: { id: member.currentTeam.manager.id },
      member: { id: member.id },
      mainManager
    };
    this.store.dispatch(new UpdateTeamUserManager(team)).subscribe({
      next: () => {
        const type = mainManager ? 'update-main-manager' : 'update-not-main-manager';
        this.displayMessage(type);

        const currentUser = this.store.snapshot().team.currentTeamUser;
        this.store.dispatch(new UpdateTeamUser(currentUser.id));
      },
      error: (e) => this.displayError(e.error?.status)
    });
  }

  public handleMainManager(event, user): void {
    if (event.checked) {
      if (user.mainTeam !== null && user.mainTeam !== user.currentTeam) {
        this.updateUser = user;
        this.displayModal();
      } else {
        this.updateMainManager(user, event.checked);
      }
    } else {
      this.updateMainManager(user, event.checked);
    }
  }

  public handleValidateQualificationsForUser(event): void {
    this.displayOnlyQualificationsNotValidated = !!event.checked;
  }

  public handleBack(): void {
    this.router.navigateByUrl('/team/summary/show-profile/' + this.memberId);
    this.store.dispatch(new ClearTeamUser());
    this.store.dispatch(new ResetFilterTeamUsers());
  }

  public handleModalConfirm(): void {
    const updateOldTeam = {
      teamId: this.updateUser.mainTeam.teamId,
      manager: { id: this.updateUser.mainTeam.manager.id },
      member: { id: this.updateUser.id },
      mainManager: false
    };
    this.store.dispatch(new UpdateTeamUserManager(updateOldTeam)).subscribe({
      next: () => {
        this.displayMessage('update-old-team');
        this.updateMainManager(this.updateUser, true);
        this.updateUser = null;
      },
      error: (e) => this.displayError(e.status)
    });
  }

  public handleModalCancel(): void {
    this.mainManager = !this.mainManager;
    this.updateUser = null;
  }

  private displayModal(): void {
    this.modal.header = 'Activer lien manager principal';
    this.modal.content = this.updateUser.firstname + ' ' + this.updateUser.lastname + ' a pour manager principal ' +
      this.updateUser.mainTeam.manager.firstname + ' ' + this.updateUser.mainTeam.manager.lastname  +
      '. Voulez-vous vous déclarer en tant que manager principal ? ' +
      'Cela effacera le lien manager principal existant.';
    this.modal.type = 'yesOrNo';
    this.showModal = true;
  }

  private displayMessage(type: string): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'update-main-manager': {
        severity = 'success';
        summary = 'Lien manager principal';
        detail = 'Le lien manager principal a bien été activé.';
        break;
      }
      case 'update-not-main-manager': {
        severity = 'success';
        summary = 'Lien manager';
        detail = 'Le lien manager principal a bien été désactivé.';
        break;
      }
    }
    this.messageService.add({severity, summary, detail});
  }

  private displayError(error: any): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (error) {
      case (401):
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Connexion perdue.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur';
        detail = 'Une erreur est survenue. Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.store.dispatch(new ClearTeamUser());
  }
}
