import { Action, Selector, State, StateContext } from '@ngxs/store';
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Assignement } from '../../../shared/models/assignement';
import { AssignmentService } from '../../../core/services/assignment-service/assignment.service';
import { GetDirectoryAssignmentsByUser } from './directory-assigments.actions';

export class DirectoryAssignmentStateModel {
  userDirectoryAssignments: Assignement[];
  snapshot: Assignement[];
}

@State<DirectoryAssignmentStateModel>({
  name: 'directoryUserAssignments',
  defaults: {
    userDirectoryAssignments: [],
    snapshot: [],
  }
})

@Injectable()
export class DirectoryAssignmentState {

  constructor(private assignmentService: AssignmentService) {
  }

  @Selector()
  static getDirectoryAssignmentsByUser(state: DirectoryAssignmentStateModel) {
    return state.userDirectoryAssignments;
  }

  @Action(GetDirectoryAssignmentsByUser)
  getAllByUserId({getState, setState}: StateContext<DirectoryAssignmentStateModel>, {payload}: any) {
    return this.assignmentService.getAllByUserId(payload).pipe(tap((result) => {
      const userDirectoryAssignments = result.map(r => this.assignmentService.mapAssignment(r));
      this.assignmentService.sortAssignments(userDirectoryAssignments);
      const state = getState();
      setState({
        ...state,
        userDirectoryAssignments
      });
    }));
  }
}
