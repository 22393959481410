import { UserCourse } from '../../../shared/models/user-course';

export class MakeSnapshotUserCourse {
  static readonly type = '[User Course] MakeSnapshot';
}

export class RestoreSnapshotUserCourse {
  static readonly type = '[User Course] RestoreSnapshot';
}

export class GetUserCourses {
  static readonly type = '[User Course] Get all';
}

export class GetUserCoursesByUserId {
  static readonly type = '[User Course] Get by user id';
  constructor(public payload: number) {}
}

export class AddUserCourse {
  static readonly type = '[User Course] Add';
  constructor(public payload: UserCourse) {}
}

export class UpdateUserCourse {
  static readonly type = '[User Course] update';
  constructor(public payload: UserCourse) {}
}

export class DeleteUserCourse {
  static readonly type = '[User Course] delete';
  constructor(public payload: UserCourse) {}
}

export class ClearUserCourses {
  static readonly type = '[User Course] Clear user courses';
}
