import {Selector, State, Action, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import { GetRequestByRequestId } from './request.action';
import {tap} from 'rxjs/operators';
import { Request } from 'src/app/shared/models/request';
import { RequestService } from 'src/app/core/services/request-service/request.service';
import {GetFilteredRequest, GetRequestByManager} from './request.action';

export class RequestStateModel {
  allRequests: Request[];
  filteredRequests: Request[];
  request: Request;
  error: number;
}

@State<RequestStateModel>({
  name: 'request',
  defaults: {
    request: null,
    error: null,
    allRequests : [],
    filteredRequests: []
  }
})

@Injectable()
export class RequestState {

  constructor(
              private requestService: RequestService) {
  }

  @Selector()
  static getRequestById(state: RequestStateModel) {
    return state.request;
  }

  @Selector()
  static getRequestsByManager(state: RequestStateModel) {
    return state.filteredRequests;
  }

  @Action(GetRequestByManager)
  getAllByUser({getState, setState}: StateContext<RequestStateModel>, {user, startDate, endDate, search}: any) {
    return this.requestService.getAllByManager(user.id, startDate, endDate).pipe(tap((allRequests) => {
      const filteredRequests = search ? this.setFilteredRequests(allRequests, search) : allRequests;
      const state = getState();
      setState({
        ...state,
        allRequests,
        filteredRequests
      });
    }));
  }

  @Action(GetFilteredRequest)
  getFilteredRRequests({getState, setState}: StateContext<RequestStateModel>, {search}: any) {
    const state = getState();
    const filteredRequests = search && search !== '' ? this.setFilteredRequests(state.allRequests, search.toLowerCase()) :
      state.allRequests;
    setState({
      ...state,
      filteredRequests
    });
  }

  setFilteredRequests(allRequests, search) {
    return allRequests.filter(request => request.type.name.toLowerCase().indexOf(search, 0) !== -1 ||
      request.user.fullname.toLowerCase().indexOf(search, 0) !== -1 ) ;
  }

  @Action(GetRequestByRequestId)
  getRequestById({getState, setState}: StateContext<RequestStateModel>, {requestId}: GetRequestByRequestId){
    setState({
      ...getState(),
      request: null
    });
    return this.requestService.getRequestById(requestId).pipe(tap((request: Request) => {
      return setState({
        ...getState(),
        request
      });
    },
      (e) => {
        return setState({
          ...getState(),
          request: null,
          error: e.status
        });
      }));
  }


}
