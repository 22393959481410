<div class="p-d-flex {{listWrap}}">
  <div *ngFor="let data of chipsData.slice(0,chipsNumber)">
    <app-chip [opacity]="opacity"
              [module]="module"
              [chipsClass]="picto ? iconService.getPicto(data, subFieldName) : iconService.getInterestLevelIcon(data)"
              [label]="notDisplayChipslabel ? '' : data[subFieldName].name"
              [picto]="picto && notDisplayChipslabel"
              [isValidated]="isValidated(data)"
              [type]="subFieldName"
              [name]="data[subFieldName].name"
              [levelLabel]="constants.LEVEL_LABEL[data['level']?.value - 1]"
              [interestLabel]="constants.INTEREST_LABEL[data['interest']?.value - 1]"
              [organizationLabel]="data[subFieldName].organization"
              [descriptionLabel]="data[subFieldName].description"
              >
    </app-chip>
  </div>
  <div class="fm-white-dots" *ngIf="dots && module === 'home'" [pTooltip]="tooltipMsgDots" tooltipPosition="top">...</div>
  <div class="fm-grey-dots" *ngIf="dots && module !== 'home'" [pTooltip]="tooltipMsgDots" tooltipPosition="top">...</div>
</div>
