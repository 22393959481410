import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiService} from '../api.service';
import {Course} from '../../../shared/models/course';

@Injectable({
  providedIn: 'root'
})
export class UserCourseService extends ApiService {

  protected prefix(): string {
    return 'userCourses';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(content: any): Observable<any> {
    return this.put(content);
  }

  doDelete(id: number, options: any = {}): Observable<any> {
    return this.delete(id, options);
  }

  getUserCoursesByUserId(userId: number): Observable<any> {
    return this.get('/user/' + userId);
  }

  userCourseExists(course: Course): Observable<any> {
    return this.get('course/' + course.id).pipe(
      map(items => items.length > 0)
    );
  }

  getAllByString(): Observable<any> {
    return this.get();
  }
}
