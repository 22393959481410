import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Report } from '../../../shared/models/report';
import { WeeklyActivityReport } from '../../../shared/models/weekly-activity-report';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends ApiService {
  protected prefix(): string {
    return 'reports';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(content: any): Observable<any> {
    return this.put(content);
  }

  doDelete(id: number): Observable<any> {
    return this.delete(id);
  }

  getAllByCollaborator(
    userId: number,
    startDate: string,
    endDate: string
  ): Observable<Report[]> {
    return this.get(
      '/collaborator/' +
        userId +
        '?startDate=' +
        startDate +
        '&endDate=' +
        endDate
    );
  }

  getAllByManager(
    userId: number,
    startDate: string,
    endDate: string
  ): Observable<Report[]> {
    return this.get(
      '/manager/' + userId + '?startDate=' + startDate + '&endDate=' + endDate
    );
  }

  getAllByInterviewer(
    userId: number,
    startDate: string,
    endDate: string
  ): Observable<Report[]> {
    return this.get(
      '/interviewer/' +
        userId +
        '?startDate=' +
        startDate +
        '&endDate=' +
        endDate
    );
  }

  getReportsForUserProfile(userId: number): Observable<Report[]> {
    return this.get('/user/' + userId);
  }

  getLastWeeklyReportByUserId(
    userId: number
  ): Observable<WeeklyActivityReport> {
    return this.get('/weeklyActivityReport/last/' + userId);
  }

  saveQuaterlyReport(content: any): Observable<any> {
    return this.post(content, '/quaterlyReport/');
  }

  saveMindsReport(content: any): Observable<any> {
    return this.post(content, '/mindsReport/');
  }

  saveWeeklyReport(content: any): Observable<any> {
    return this.post(content, '/weeklyActivityReport/');
  }

  exportWeeklyActivityReportByTeamManagerId(managerId: number, start: string, end: string, criteria: string): Observable<any> {
    const params = new Map();
    params.set('managerId', managerId);
    params.set('start', start);
    params.set('end', end);
    params.set('criteria', criteria);
    return this.get('/weeklyActivityReport/export/manager', params, 'blob');
  }

  exportWeeklyActivityReportByCollaboratorId(collaboratorId: number, start: string, end: string, criteria: string): Observable<any> {
    const params = new Map();
    params.set('collaboratorId', collaboratorId);
    params.set('start', start);
    params.set('end', end);
    params.set('criteria', criteria);
    return this.get('/weeklyActivityReport/export/collaborator', params, 'blob');
  }

  getWeeklyReportByReportId(reportId: number) {
    return this.get(`/weeklyActivityReport/${reportId}`);
  }
}
