import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  ClearActions,
  GetActions,
} from './actions.actions';

import { ActionService } from 'src/app/core/services/action-service/action.service';
import { Actions } from 'src/app/shared/models/actions';
import {User} from '../../../shared/models/user';
import {Observable} from 'rxjs';

export class ActionStateModel {
  actions: Actions[];
}

@State<ActionStateModel>({
  name: 'actions',
  defaults: {
    actions: []
  }
})

@Injectable()
export class ActionState {

  constructor(private actionService: ActionService) {
  }

  @Selector()
  static getActions(state: ActionStateModel) {
    return state.actions;
  }

  @Action(GetActions)
  getAll({getState, setState}: StateContext<ActionStateModel>) {
    return this.actionService.getAll().pipe(tap((result: Actions[]) => {
      const state = getState();
      setState({
        ...state,
        actions: result,
        });
      })
    );
  }

  @Action(ClearActions)
  ClearActions({getState, setState}: StateContext<ActionStateModel>) {
    const state = getState();
    setState({
      ...state,
      actions: [],
    });
  }
}
