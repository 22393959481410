<div [ngClass]="{
  'fm-chip':!opacity && !picto,
  'fm-chip-picto':!opacity && picto,
  'fm-chip-opacity':opacity,
  'fm-chip-not-validated': !isValidated
  }">
  <div class="p-d-flex p-ai-center" style="font-size: 14px;" [pTooltip]="tooltipMsg" tooltipPosition="top">
    <i [className]="chipsClass"></i>
    <div [ngClass]="{'fm-chip-label':module !== 'home' && module !== 'directory','fm-chip-label-dots':module === 'home' || module === 'directory'}">
      {{ label }}
    </div>
  </div>
</div>
