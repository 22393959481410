import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesforceService extends ApiService {

  protected prefix(): string {
    return 'SalesForce';
  }

  importSalesforceAssignment(userId: number): Observable<any> {
    return this.get('/' + userId);
  }

}
