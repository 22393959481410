import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';
import { Specialty } from '../../../shared/models/specialty';

@Injectable({
  providedIn: 'root'
})
export class UserSpecialtyService extends ApiService {

  protected prefix(): string {
    return 'userSpecialties';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(content: any): Observable<any> {
    return this.put(content);
  }

  doDelete(id: number, options: any = {}): Observable<any> {
    return this.delete(id, options);
  }

  userSpecialtyExists(specialty: Specialty): Observable<any> {
    return this.get('specialty/' + specialty.id).pipe(
      map(items => items.length > 0)
    );
  }

  getUserSpecialtiesByUserId(userId: number): Observable<any> {
    return this.get('/user/' + userId);
  }

  getAllByString(): Observable<any> {
    return this.get();
  }
}
