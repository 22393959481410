import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectoryComponent } from './pages/directory/directory.component';
import {SharedModule} from '../../shared/shared.module';
import {TeamModule} from '../team/team.module';
import { DirectoryShowProfileComponent } from './pages/directory-show-profile/directory-show-profile.component';
import { DirectorySearchComponent } from './pages/directory-search/directory-search.component';


@NgModule({
  declarations: [DirectoryComponent, DirectoryShowProfileComponent, DirectorySearchComponent],
    imports: [
        CommonModule,
        SharedModule,
        TeamModule,
    ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [
    DirectoryComponent,
  ]
})
export class DirectoryModule { }
