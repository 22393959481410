import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {

  tabIndex$ = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) {
  }

  getNavbarItems(): Observable<MenuItem[]> {
    return this.http.get<any>('assets/navbar-data.json');
  }

  getParametersItems(): Observable<MenuItem[]> {
    return this.getNavbarItems().pipe(
      map(navbarItems => navbarItems.find(item =>  item.items)?.items)
    );
  }
}
