import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiService} from '../api.service';
import {Certification} from '../../../shared/models/certification';

@Injectable({
  providedIn: 'root'
})
export class UserCertificationService extends ApiService {

  protected prefix(): string {
    return 'userCertifications';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(content: any): Observable<any> {
    return this.put(content);
  }

  doDelete(id: number, options: any = {}): Observable<any> {
    return this.delete(id, options);
  }

  getUserCertificationsByUserId(userId: number): Observable<any> {
    return this.get('/user/' + userId);
  }

  userCertificationExists(certification: Certification): Observable<any> {
    return this.get('certification/' + certification.id).pipe(
      map(items => items.length > 0)
    );
  }

  getAllByString(): Observable<any> {
    return this.get();
  }
}
