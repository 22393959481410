<div class="fm-box-title">
  <app-fm-button
    type="text-info"
    btnText="RETOUR"
    (click)="redirect()"
  ></app-fm-button>
</div>

<div class="fm-box-title">
  <span> RHA : {{ user.firstname }} {{ user.lastname }}</span>
</div>

<div class="fm-bloc-team" fxLayoutAlign="center">
  <p-card class="fm-p-card">
    <div fxFlex="100%">
      <div class="fm-box-text" fxFlex="100%">
        <div>
          <table>
            <tr>
              <td class="td-bloc-label">Semaine :</td>
              <td class="td-bloc-text">Semaine {{ weekNumber }}</td>
            </tr>
            <tr>
              <td class="td-bloc-label">Evaluation :</td>
              <td class="td-bloc-text">
                <p-rating
                  id="rating"
                  stars="4"
                  [ngModel]="rha.rating"
                  [cancel]="false"
                  [readonly]="true"
                >
                </p-rating>
              </td>
            </tr>
            <tr>
              <td class="td-bloc-label">Date :</td>
              <td class="td-bloc-text">
                du {{ fromDate | date: 'dd/MM/yyyy' }} au
                {{ toDate | date: 'dd/MM/yyyy' }}
              </td>
            </tr>
            <tr>
              <td class="td-bloc-label">Secteur :</td>
              <td class="td-bloc-text">{{ sectorName }}</td>
            </tr>
            <tr>
              <td class="td-bloc-label">Projet :</td>
              <td class="td-bloc-text">{{ projectName }}</td>
            </tr>
            <tr>
              <td class="td-bloc-label">Imputations :</td>
              <td class="td-bloc-text">{{ projeqtor }}</td>
            </tr>
            <tr>
              <td class="td-bloc-label">Résumé :</td>
              <td class="td-bloc-text" [innerHTML]="rha.task"></td>
            </tr>
            <tr>
              <td class="td-bloc-label">Visibilité :</td>
              <td class="td-bloc-text" [innerHTML]="rha.mission"></td>
            </tr>
            <tr>
              <td class="td-bloc-label">Congés prévus :</td>
              <td class="td-bloc-text" [innerHTML]="rha.vacation"></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </p-card>
</div>
