import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {Observable} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {MessageService} from 'primeng/api';
import {FormInlineComponent} from '../../../../../shared/components/form-inline/form-inline.component';
import {
        AddRH,
        ClearSnapshotRH, DeleteRH, GetRH,
        MakeSnapshotRH,
        RestoreSnapshotRH,
        UpdateRH
} from '../../../store/rh.actions';

import {SoftSkill} from '../../../../../shared/models/soft-skill';
import {SoftSkillService} from '../../../../../core/services/soft-skill-services/soft-skill.service';
import { SoftSkillState, RHStateModel } from '../../../store/rh.state';
import {UserSoftSkillService} from '../../../../../core/services/user-soft-skill-services/user-soft-skill.service';
import {Constants} from '../../../../../shared/constants/constants';

@Component({
  selector: 'app-view-rh',
  templateUrl: './view-rh.component.html',
  styleUrls: ['./view-rh.component.scss']
})
export class ViewRhComponent implements OnInit {

  @Select(SoftSkillState.getSoftSkills) softSkills$: Observable<SoftSkill[]>;

  @ViewChild(FormInlineComponent)
  private formRH: FormInlineComponent;

  @Input()
  showAddForm: boolean;

  titleForm = 'Ajouter une compétence RH';

  private stateSnapshot: RHStateModel;
  private itemToDelete: SoftSkill;

  modal: any = {};
  showModal = false;
  disableCreationSave = false;

  inputForm: any = [];

  rhDataColumns: any[];
  emptyMessage = 'Aucune compétence transverse enregistrée';

  constructor(
    private messageService: MessageService,
    private softSkillService: SoftSkillService,
    private userSoftSkillService: UserSoftSkillService,
    private store: Store
  ) {
    this.store.dispatch(new GetRH());
  }

  ngOnInit(): void {
    this.initDatatable();
    this.initForm();
  }

  initDatatable() {
    this.rhDataColumns = [
      {
        type: 'text',
        field: 'name',
        label: 'RH',
        font: 'semibold',
        sort: true,
        maxLength: Constants.NAME_RH_SIZE_MAX
      },
      {
        type: 'text',
        field: 'category',
        label: 'Catégorie',
        font: 'regular',
        sort: true,
        maxLength: Constants.CATEGORY_SIZE_MAX
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: false
      },
      {
        type: 'action',
        action: 'edit',
        width: '8%'
      },
      {
        type: 'action',
        action: 'delete',
        width: '10%'
      },
    ];
  }

  initForm() {
    this.inputForm = [
      {
        key: 'name',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'RH',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.NAME_RH_SIZE_MAX,
      },
      {
        key: 'category',
        typeForm: 'text',
        fieldForm: 'category',
        labelForm: 'Catégorie',
        placeHolder: 'Catégorie',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.CATEGORY_SIZE_MAX
      },
    ];
  }

  handleCreateItem(softSkill: SoftSkill) {
    if (!this.errorsValidation(softSkill)) {
      this.store.dispatch(new AddRH(softSkill)).subscribe(
        () => {
          this.displayMessage('create');
          this.formRH.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  handleUpdateItem() {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotRH());
  }

  handleSaveUpdateItem(softSkill: SoftSkill) {
    if (!this.errorsValidation(softSkill)) {
      this.store.dispatch(new UpdateRH(softSkill)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.store.dispatch(new RestoreSnapshotRH());
          this.store.dispatch(new ClearSnapshotRH());
          this.disableCreationSave = false;
        }
      );
    }
  }

  handleCancelEditItem() {
    this.disableCreationSave = false;
    this.store.dispatch(new RestoreSnapshotRH());
    this.store.dispatch(new ClearSnapshotRH());
  }

  handleDeleteItem(id) {
    this.stateSnapshot = this.store.snapshot().softSkills;
    this.itemToDelete = this.stateSnapshot.softSkills.find((p) => p.id === id);
    this.userSoftSkillService.userSofSkillExists(this.itemToDelete).subscribe(
      (response) => {
        if (response) {
          this.displayError('userSoftSkillExists');
        } else {
          this.displayModal('delete');
        }
      },
      (e) => this.displayError(e.status)
    );
  }

  handleModalConfirm() {
    this.deleteItem();
  }

  deleteItem() {
    this.store.dispatch(new DeleteRH(this.itemToDelete)).subscribe(
      () => this.displayMessage('delete'),
      (e) => this.displayError(e.status)
    );
  }

  errorsValidation(softSkill) {
    let errors = false;
    if (softSkill.name === '') {
      this.displayError('name');
      errors = true;
    }
    if (softSkill.category === '') {
      this.displayError('category');
      errors = true;
    }
    return errors;
  }

  displayModal(type) {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  displayMessage(type) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La compétence transverse a bien été sauvegardée.';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La compétence transverse a bien été sauvegardée.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'La compétence transverse a bien été supprimée.';
        break;
      }
    }
    this.messageService.add({ severity, summary, detail });
  }

  displayError(e) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Cette compétence transverse existe déjà.';
        break;
      case 'name':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner le nom de la compétence transverse.';
        break;
      case 'category':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner la catégorie.';
        break;
      case 'userSoftSkillExists':
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Cette compétence transverse est attribuée à un utilisateur.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

}
