import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {Store, Select} from '@ngxs/store';
import {MessageService, SelectItem} from 'primeng/api';
import {Customer} from 'src/app/shared/models/customer';
import {CustomerState, CustomerStateModel} from '../../../store/customers.state';
import {
  AddCustomer,
  ClearSnapshotCustomer,
  DeleteCustomer,
  GetCustomers,
  MakeSnapshotCustomer, RestoreSnapshotCustomer,
  UpdateCustomer
} from '../../../store/customers.actions';
import {FormInlineComponent} from 'src/app/shared/components/form-inline/form-inline.component';
import {ProjectService} from 'src/app/core/services/project-services/project.service';
import {Constants} from '../../../../../shared/constants/constants';

@Component({
  selector: 'app-view-customers',
  templateUrl: './view-customers.component.html',
  styleUrls: ['./view-customers.component.scss'],
})
export class ViewCustomersComponent implements OnInit, OnDestroy {
  @Select(CustomerState.getCustomers) customers$: Observable<Customer[]>;

  @Input()
  showAddForm: boolean;

  @Input()
  titleForm = 'Ajouter un client';

  @ViewChild(FormInlineComponent)
  private formCustomer: FormInlineComponent;

  @Output()
  eventRedirect = new EventEmitter<Event>();


  private stateSnapshot: CustomerStateModel;
  private itemToDelete: Customer;

  modal: any = {};
  showModal = false;
  disableCreationSave = false;
  customersSelection: SelectItem[] = [];

  inputForm: any = [];

  customerDataColumns = [];
  emptyMessage = 'Aucun client enregistré';

  constructor(
    private messageService: MessageService,
    private projectService: ProjectService,
    private store: Store
  ) {
    this.store.dispatch(new GetCustomers());
  }

  ngOnInit(): void {
    this.initDatatable();
    this.initForm();
  }

  initDatatable() {
    this.customerDataColumns = [
      {type: 'link', field: 'name', label: 'Nom', font: 'semibold', sort: true, maxLength: Constants.NAME_SIZE_MAX },
      {type: 'text', field: 'description', label: 'Description', font: 'regular', sort: true,  maxLength: Constants.DESCRIPTION_SIZE_MAX},
      {type: 'calendar', field: 'archivingDate', label: 'Archivage', font: 'regular', sort: true},
      {type: 'action', action: 'edit', width: '8%' },
      {type: 'action', action: 'delete', width: '10%'},
    ];
  }

  initForm() {
    this.inputForm = [
      {
        key: 'nameCustomer',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Client',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.NAME_SIZE_MAX,
      }, {
        key: 'nameDescription',
        typeForm: 'text',
        fieldForm: 'description',
        labelForm: 'Description',
        valueForm: '',
        requiredForm: false,
        maxLength: Constants.DESCRIPTION_SIZE_MAX,
      },
    ];
  }

  // Redirection From Customer to projects
  handleRedirect(customer){
    this.eventRedirect.emit(customer);
  }

  handleCreateItem(customer: Customer) {
    if (!this.errorsValidation(customer)) {
      this.store.dispatch(new AddCustomer(customer)).subscribe(() => {
          this.displayMessage('create');
          this.formCustomer.handleClearForm();
        },
        (error) => {
          this.displayError(error.status);
        });
    }
  }

  deleteItem() {
    this.store.dispatch(new DeleteCustomer(this.itemToDelete)).subscribe(
      () => {
        this.displayMessage('delete');
      },
      (error) => this.displayError(error.status)
    );
  }

  ngOnDestroy(): void {
    // this.customerSubscription.unsubscribe();
  }

   displayModal(typeError) {
    switch (typeError) {
      case 'delete_confirm': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        // statements;
        break;
      }
    }
    this.showModal = true;
  }

  /**
   *
   * Event from DataTable
   *
   */
  toggleShowAddForm() {
    this.showAddForm = !this.showAddForm;
  }

  handleDeleteItem(id) {
    this.stateSnapshot = this.store.snapshot().customers;
    this.itemToDelete = this.stateSnapshot.customers.find((p) => p.id === id);
    this.projectService.customerExists(id).subscribe(response => {
        if (response) {
          this.displayError('projectExists');
        } else {
          this.displayModal('delete_confirm');
        }
      },
      (e) => this.displayError(e.status));
  }

  handleModalConfirm() {
    this.deleteItem();
  }


  handleUpdateItem(customer: Customer) {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotCustomer());
  }

  handleSaveUpdateItem(customer: Customer) {
    if (!this.errorsValidation(customer)) {
      this.store.dispatch(new UpdateCustomer(customer)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.store.dispatch(new RestoreSnapshotCustomer());
          this.store.dispatch(new ClearSnapshotCustomer());
          this.disableCreationSave = false;
        }
      );
    }
  }

  handleCancelEditItem(data) {
    this.disableCreationSave = false;
    this.store.dispatch(new RestoreSnapshotCustomer());
    this.store.dispatch(new ClearSnapshotCustomer());
  }

  errorsValidation(customer) {
    let errors = false;
    if (!customer || customer.name === '') {
      this.displayError('customer');
      errors = true;
    }
    return errors;
  }

  displayMessage(typeError) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (typeError) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'Le client a bien été sauvegardé.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'Le client a bien été supprimé.';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'Le client a bien été sauvegardé.';
        break;
      }
    }
    this.messageService.add({
      severity,
      summary,
      detail,
    });
  }

  displayError(e) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 400:
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Ce profil est attribué à une compétence ou à une spécialité.';
        break;
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Ce profil existe déjà.';
        break;
      case 'customer':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner le nom du client.';
        break;
      case 'save': {
        severity = 'error';
        summary = 'Une erreur s\'est produite ';
        detail =
          'Le client n\'a pas pu être sauvegardé. Veuillez réessayer';
        break;
      }
      case 'projectExists': {
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Ce client est attribué à un projet.';
        break;
      }
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }
}
