<p-dialog [(visible)]="display" [modal]="true" [closable]="closable" (onHide)="handleHide()">
  <p-header>
    {{header}}
  </p-header>
  <div>
    <ng-content></ng-content>
  </div>
  <div *ngIf="type === 'fusion'" [innerHtml]="content" class="fm-modal-content"></div>
  <div *ngIf="type !== 'radioButton' && type !== 'fusion'" [innerHTML]="content"></div>
  <div *ngIf="type === 'radioButton'">
    <p-radioButton *ngFor="let radioButton of radioButtonElements" name="radioButton" [label]="radioButton.label" [value]="radioButton.value"
      [(ngModel)]="selectedValue"></p-radioButton>
  </div>
  <p-footer *ngIf="type === 'confirm' || type === 'radioButton'" class="fm-modal-footer">
    <app-fm-button *ngIf="type === 'confirm' || (type === 'radioButton' && selectedValue)" [attr.data-testid]="'modal_button_confirm'" type="confirm"
      (click)="handleConfirm()"></app-fm-button>
    <app-fm-button *ngIf="type === 'radioButton' && !selectedValue" btnDisabled="true" [attr.data-testid]="'modal_button_confirm'" type="confirm"
      (click)="handleConfirm()"></app-fm-button>
    <app-fm-button [attr.data-testid]="'modal_button_cancel'" type="cancel" (click)="handleCancel()"></app-fm-button>
  </p-footer>
  <p-footer *ngIf="type === 'yesOrNo'" class="fm-modal-footer-space-between">
    <app-fm-button [attr.data-testid]="'modal_button_yes'" type="yes" (click)="handleConfirm()"></app-fm-button>
    <app-fm-button [attr.data-testid]="'modal_button_no'" type="no" (click)="handleCancel()"></app-fm-button>
  </p-footer>

  <div *ngIf="type==='fusion'" class="fm-modal-dropdown">
    <p-dropdown autoWidth="false" autoHeight="true" appendTo="body" [style]="{'width':'50%', 'text-align':'left', 'margin-top':'2vw', 'margin-bot':'2vw'}"
      [options]="data" [(ngModel)]="selectedOption" optionLabel="name" optionValue="name" placeholder="Selectionnez une option">
      <ng-template let-item pTemplate="selectedOption">
        <div>
          <div>
            {{ item.label }}
          </div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <p-footer class="fm-modal-footer">
    <app-fm-button *ngIf="type === 'fusion'" [btnDisabled]="selectedOption === undefined" [attr.data-testid]="'modal_button_fusion'" type="fusion_confirm"
      (click)="handleFusionItem()"></app-fm-button>
    <app-fm-button *ngIf="type === 'fusion'" [attr.data-testid]="'modal_button_cancel'" type="cancel" (click)="handleCancel()"></app-fm-button>
  </p-footer>
</p-dialog>