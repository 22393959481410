import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Select, Store} from '@ngxs/store';
import {ReportState} from '../../../../../stores/reports/report.state';
import {Observable, Subscription} from 'rxjs';
import {SetPendingAnnualReport} from '../../../../../stores/reports/report.action';
import {AuthenticationState} from '../../../../../stores/authentication/authentication.state';
import {User} from '../../../models/user';
import {AnnualReport} from '../../../models/annual-report';
import {AnnualReportService} from '../../../../core/services/annual-report-service/annual-report.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit, OnDestroy {
  @Select(ReportState.getRole) role$: Observable<string>;
  @Select(AuthenticationState.getAnnualReportUser) annualReportUser$: Observable<User>;
  @Select(ReportState.getAnnualReport) annualReport$: Observable<AnnualReport>;
  @Select(ReportState.getPendingAnnualReport) pendingAnnualReport$: Observable<AnnualReport>;

  workflowCollaborator: number;
  workflowManager: number;
  isTooLate: boolean;

  freeAnswerMaxLength = 5000;
  formData: FormGroup;
  formNameEditorStepTwo = 'collaboratorAssessment';
  title = 'Collaborateur';
  editorStyle: any = { height: '320px', boxShadow: '0 0 10px #1432461A'};
  placeholder: string;
  collaboratorAssessment: string;
  managerAssessment: string;
  pendingAnnualReportPreparation: AnnualReport;
  pendingAnnualReport: AnnualReport;

  pendingAnnualReportSubscription: Subscription;
  roleSubscription: Subscription;
  role: string;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private router: Router,
    private annualReportService: AnnualReportService
  ) {
    this.formData = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.roleSubscription = this.role$.subscribe((role) => {
      this.pendingAnnualReportSubscription = this.pendingAnnualReport$.subscribe((pendingAnnualReport: AnnualReport) => {
        if (pendingAnnualReport){
          this.pendingAnnualReport = pendingAnnualReport;
          this.fillInputWithAnnualReport(pendingAnnualReport);
          this.workflowCollaborator = pendingAnnualReport.workflowCollaborator;
          this.isTooLate = this.annualReportService.isTooLate(pendingAnnualReport.interviewStartDate);
          this.workflowManager = pendingAnnualReport.workflowManager;
        } else {
          // To hook refresh web browser action
          this.router.url.includes('activity') ?
            this.router.navigateByUrl('/activity/reports') :
            this.router.navigateByUrl('/team/reports');
        }
      });

      if (role === 'collaborator') {
        this.initCollaboratorForm();
      } else if (role === 'currentInterviewer') {
        this.initInterviewerForm();
      }
      this.role = role;
    });

    // this.hasCollaboratorValidated = this.annualReportService.hasCollaboratorValidated$.getValue();
    this.placeholder = 'Réalisations, satisfactions, difficultés (' + this.freeAnswerMaxLength + ' caractères max)...';
  }

  initCollaboratorForm() {
    this.formData.addControl('collaboratorAssessment', new FormControl(null, Validators.required));
  }

  initInterviewerForm() {
    this.formData.addControl('managerAssessment', new FormControl(null, Validators.required));
  }

  fillInputWithAnnualReport(annualReport: AnnualReport){
    if (annualReport){
      this.collaboratorAssessment = annualReport.collaboratorAssessment;
      this.managerAssessment = annualReport.managerAssessment;
    }
  }

  // manually setting editor border if form Validator is false as primeNG editor doesn't handle it
  setValidatorBorder(input: any){
    const borderColor = input.textValue === '' ? 'red' : '#dee2e6';
    const borderTop = input.textValue === '' ? 'solid 1px red' : '#dee2e6';
    this.editorStyle = { height: '320px', boxShadow: '0 0 10px #1432461A', borderColor, borderTop};
  }

  handleChange(event: any){
    if (event && this.role === 'collaborator'){
      this.pendingAnnualReport.collaboratorAssessment = this.formData.value.collaboratorAssessment;
    }
    if (event && this.role === 'currentInterviewer'){
      this.pendingAnnualReport.managerAssessment = this.formData.value.managerAssessment;
    }
    this.store.dispatch(new SetPendingAnnualReport(this.pendingAnnualReport));
  }

  ngOnDestroy() {
    this.roleSubscription.unsubscribe();
    this.pendingAnnualReportSubscription.unsubscribe();
  }
}
