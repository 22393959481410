import {ProjectState, ProjectStateModel} from '../../../store/projects.state';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {Store, Select} from '@ngxs/store';
import {MessageService, SelectItem} from 'primeng/api';
import {FormInlineComponent} from 'src/app/shared/components/form-inline/form-inline.component';
import {Project} from 'src/app/shared/models/project';
import {
  AddProject,
  ClearSnapshotProject,
  DeleteProject,
  GetProjects,
  MakeSnapshotProject,
  RestoreSnapshotProject,
  UpdateProject
} from '../../../store/projects.actions';
import {Customer} from 'src/app/shared/models/customer';
import {CustomerService} from 'src/app/core/services/customer-service/customer.service';
import {AssignmentService} from 'src/app/core/services/assignment-service/assignment.service';
import {Constants} from '../../../../../shared/constants/constants';
import {WeeklyReportService} from '../../../../../core/services/weekly-report.services/weekly-report.service';

@Component({
  selector: 'app-view-projects',
  templateUrl: './view-projects.component.html',
  styleUrls: ['./view-projects.component.scss'],
})
export class ViewProjectsComponent implements OnInit, OnDestroy {
  @Select(ProjectState.getProjects) projects$: Observable<Project[]>;

  @ViewChild(FormInlineComponent)
  private formProject: FormInlineComponent;

  @Input()
  showAddForm: boolean;

  @Input()
  titleForm = 'Ajouter un projet';

  @Input()
  isRedirected: boolean;

  disableCreationSave = false;

  private stateSnapshot: ProjectStateModel;
  private itemToDelete: Project;

  modal: any = {};
  showModal = false;
  customersSelection: SelectItem[] = [];
  projectSubscription: Subscription;

  customers: Customer[] = [];

  inputForm: any = [];

  projectDataColumns = [];
  emptyMessage = 'Aucun projet enregistré';

  constructor(private messageService: MessageService,
              private customerService: CustomerService,
              private assignmentService: AssignmentService,
              private weeklyReportService: WeeklyReportService,
              private store: Store) {}

  ngOnInit(): void {
    if (!this.isRedirected) {
      this.store.dispatch(new GetProjects());
    }
    this.projectSubscription = this.customerService.getAll().subscribe(
      customers => {
        customers.forEach((customer) => this.customers.push(customer));
        customers.forEach((customer) =>
          this.customersSelection.push({ label: customer.name, value: customer })
        );
        this.initDatatable();
        this.initForm();
      },
      (error) => this.displayError(error.status)
    );
  }

  initDatatable() {
    this.projectDataColumns = [
      { type: 'text', field: 'name', label: 'Nom', font: 'semibold', sort: true, maxLength: Constants.NAME_SIZE_MAX},
      {
        type: 'select',
        options: this.customersSelection,
        field: 'customer',
        subField: 'name',
        label: 'Client',
        font: 'semibold',
        sort: true,
        sortField: 'customer.name'
      },
      {
        type: 'checkbox', options: [
          {label: 'Oui', value: 1},
          {label: 'Non', value: 0},
        ], field: 'cir', label: 'CIR', sort: true
      },
      {
        type: 'text',
        field: 'description',
        label: 'Description',
        font: 'regular',
        sort: true,
        maxLength: Constants.DESCRIPTION_SIZE_MAX
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: true,
      },
      { type: 'action', action: 'edit', width: '8%' },
      { type: 'action', action: 'delete', width: '10%' },
    ];
  }

  initForm() {
    this.inputForm = [
      {
        key: 'nameProfil',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Projet',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.NAME_SIZE_MAX,
      },
      {
        key: 'nameCustomer',
        typeForm: 'dropdown',
        fieldForm: 'customer',
        labelForm: 'Customer',
        requiredForm: true,
        optionsForm: this.customers,
        placeHolder: 'Client'
      },
      {
        key: 'nameCir',
        typeForm: 'dropdown',
        fieldForm: 'cir',
        labelForm: 'CIR',
        requiredForm: false,
        optionsForm: [
          {name: 'Oui', value: 1},
          {name: 'Non', value: 0},
        ],
        placeHolder: 'CIR'
      },
      {
        key: 'nameDescription',
        typeForm: 'text',
        fieldForm: 'description',
        labelForm: 'Description',
        valueForm: '',
        requiredForm: false,
        maxLength: Constants.DESCRIPTION_SIZE_MAX,
      }
    ];
  }

  handleCreateItem(project: Project) {
    if (!this.errorsValidation(project)) {
      if (project.cir === null) { project.cir = false; }  // Default value for CIR
      this.store.dispatch(new AddProject(this.updateProject(project))).subscribe(
        () => {
          this.displayMessage('create');
          this.formProject.handleClearForm();
        },
        (error) => {
          this.displayError(error.status);
        }
      );
    }
  }

  updateProject(project: any): Project {
    return {
      id: project.id,
      name: project.name,
      description: project.description,
      customer : project.customer,
      cir : project.cir.value
    };
  }

  deleteItem() {
    this.store.dispatch(new DeleteProject(this.itemToDelete)).subscribe(
      () => {
        this.displayMessage('delete');
      },
      (error) => this.displayError(error.status)
    );
  }

  displayModal(typeError) {
    switch (typeError) {
      case 'delete_confirm': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        // statements;
        break;
      }
    }
    this.showModal = true;
  }

  /**
   * Event from DataTable
   */
  handleDeleteItem(id) {
    this.stateSnapshot = this.store.snapshot().projects;
    this.itemToDelete = this.stateSnapshot.projects.find((p) => p.id === id);
    const weeklyReports$ = this.weeklyReportService.projectExists(id);
    const assignments$ = this.assignmentService.projectExists(id);
    const observable = forkJoin({
      weeklyReports$,
      assignments$
    });
    observable.subscribe({
      next: value => {
        let errors = 0;
        if (value.weeklyReports$) {
          errors++;
          this.displayError('weeklyReportExists');
        }
        if (value.assignments$) {
          errors++;
          this.displayError('assignmentExists');
        }
        if (!errors) {
          this.displayModal('delete_confirm');
        }
      },
      error: e => this.displayError(e.status)
    });
  }

  handleModalConfirm() {
    this.deleteItem();
  }

  handleUpdateItem(project: Project) {
    this.disableCreationSave = true;
    const index = this.customers.findIndex(item => item.id === project.customer.id);
    this.customers.splice(index, 1);
    this.customersSelection.splice(index, 1);
    this.customersSelection.unshift({ label: project.customer.name, value: project.customer });
    this.customers.unshift(project.customer);
    this.store.dispatch(new MakeSnapshotProject());
  }

  handleSaveUpdateItem(project: Project) {
    if (!this.errorsValidation(project)) {
      this.store.dispatch(new UpdateProject(project)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.store.dispatch(new RestoreSnapshotProject());
          this.store.dispatch(new ClearSnapshotProject());
          this.disableCreationSave = false;
        }
      );
    }
  }

  handleCancelEditItem(data) {
    this.disableCreationSave = false;
    this.store.dispatch(new RestoreSnapshotProject());
    this.store.dispatch(new ClearSnapshotProject());
  }

  errorsValidation(project) {
    let errors = false;
    if (project.name === '') {
      this.displayError('name');
      errors = true;
    }
    if (!project.customer) {
      this.displayError('customer');
      errors = true;
    }
    return errors;
  }

  displayMessage(typeError) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (typeError) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'Le projet a bien été sauvegardé.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'Le projet a bien été supprimé.';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'Le projet a bien été sauvegardé.';
        break;
      }
    }
    this.messageService.add({
      severity,
      summary,
      detail,
    });
  }

  displayError(e) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Ce projet existe déjà.';
        break;
      case 'name':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner le nom du projet.';
        break;
      case 'customer':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner le client.';
        break;
      case 'weeklyReportExists':
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Ce projet est référencé dans un RHA.';
        break;
      case 'assignmentExists':
        severity = 'error';
        summary = 'Suppression impossible';
        detail = 'Ce projet est attribué à une mission.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  ngOnDestroy(): void {
    this.projectSubscription.unsubscribe();
  }
}
