import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  AddCustomer,
  DeleteCustomer,
  UpdateCustomer,
  MakeSnapshotCustomer,
  RestoreSnapshotCustomer,
  ClearSnapshotCustomer,
  GetCustomers,
  FilterCustomers
} from './customers.actions';
import { CustomerService } from 'src/app/core/services/customer-service/customer.service';
import { Customer } from 'src/app/shared/models/customer';
import { searchIndexOf } from "../../../shared/utils/utils";

export class CustomerStateModel {
  customers: Customer[];
  snapshot: Customer[];
}

@State<CustomerStateModel>({
  name: 'customers',
  defaults: {
    customers: [],
    snapshot: [],
  }
})
@Injectable()
export class CustomerState {

  constructor(private customerService: CustomerService) {
  }

  @Selector()
  static getCustomers(state: CustomerStateModel) {
    return state.customers;
  }

  @Selector()
  static getSnapshot(state: CustomerStateModel) {
    return state.snapshot;
  }

  @Action(GetCustomers)
  getAll({getState, setState}: StateContext<CustomerStateModel>) {
    return this.customerService.getAll().pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        customers: result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddCustomer)
  add({getState, patchState}: StateContext<CustomerStateModel>, {payload}: AddCustomer) {
    return this.customerService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      patchState({
        customers: [...state.customers, result]
      });
    }));
  }

  @Action(UpdateCustomer)
  update({getState, setState}: StateContext<CustomerStateModel>, {payload}: UpdateCustomer) {
    return this.customerService.doPut(payload).pipe(tap((result) => {
      result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
      const state = getState();
      const CustomersList = [...state.customers];
      const CustomerIndex = CustomersList.findIndex(item => item.id === payload.id);
      CustomersList[CustomerIndex] = result;
      setState({
        ...state,
        customers: CustomersList,
      });
    }));
  }

  @Action(DeleteCustomer)
  delete({getState, setState}: StateContext<CustomerStateModel>, {payload}: DeleteCustomer) {
    const state = getState();
    return this.customerService.doDelete(payload).pipe(tap(() => {
      const filteredArray = state.customers.filter(item => item.id !== payload.id);
      setState({
        ...state,
        customers: filteredArray,
      });
    }));
  }

  @Action(FilterCustomers)
  filter({getState, setState}: StateContext<CustomerStateModel>, {payload}: FilterCustomers) {
    return this.customerService.getAll().pipe(tap((result) => {
      const state = getState();
      const customers = result.map( r => {
        r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
        return r;
      });
      const filteredCustomers = customers.filter(item =>
        item.name && searchIndexOf(item.name, payload) ||
        item.description && searchIndexOf(item.description, payload));
      setState({
        ...state,
        customers: filteredCustomers
      });
    }));
  }

  @Action(MakeSnapshotCustomer)
  makeSnapShot({getState, setState}: StateContext<CustomerStateModel>) {
      const state = getState();
      setState({
        ...state,
        snapshot: state.customers.map(item => ({...item}))
      });
  }

  @Action(RestoreSnapshotCustomer)
  restoreSnapshot({getState, setState}: StateContext<CustomerStateModel>) {
      const state = getState();
      setState({
        ...state,
        customers: state.snapshot.map(item => ({...item}))
      });
  }

  @Action(ClearSnapshotCustomer)
  clearSnapshot({getState, setState}: StateContext<CustomerStateModel>) {
      const state = getState();
      setState({
        ...state,
        snapshot: null,
      });
  }
}
