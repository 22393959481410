import { AnnualReportService } from './../../../core/services/annual-report-service/annual-report.service';
import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { ReportState } from 'src/stores/reports/report.state';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-annual-report',
  templateUrl: './annual-report.component.html',
  styleUrls: ['./annual-report.component.scss']
})
export class AnnualReportComponent implements OnInit {

  showModal = false;
  campaignOptions: SelectItem[];
  selectedCampaign: number;

  constructor(private annualReportService: AnnualReportService){}


  ngOnInit(): void {
    this.initCampaignOptions();
  }

  handleModalConfirm() {
    this.annualReportService.initiateNewCollaboratorAnnualReports(this.selectedCampaign).subscribe();
  }

  handleLaunchCampaignNewArrivals() {
    this.showModal = true;
  }

  initCampaignOptions() {
    let currentCampaign = new Date().getFullYear();
    this.campaignOptions = [];
    const LIMIT = Constants.CAMPAIGN_FILTER_YEAR_BOUND;
    for (var y = currentCampaign; y >= currentCampaign - LIMIT; y--) {
      this.campaignOptions.push({
        label: Constants.AR_FILTER_CAMPAIGN_PREFIX + y.toString(),
        value: y,
      });
    }
    this.selectedCampaign = this.campaignOptions[0].value;
  }

}
