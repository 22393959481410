<form [formGroup]="selectformGroup">
  <table class="table table-borderless">
    <tr class="form-group">
      <ng-container *ngIf="isOneSelect; else other">
        <td>
          <div class="select-box xlarge">
            <select class="form-control" required formControlName="name">
              <option value="DEV">Formation Java</option>
              <option value="SCR">Scrum-Master</option>
              <option value="MANAGER">React JS courses</option>
            </select>
          </div>
        </td>
      </ng-container>
      <ng-template #other>
        <td>
          <div class="select-box large">
            <select class="form-control" formControlName="name">
              <option value="DEV">Developpeur</option>
              <option value="SCR">Scrum-Master</option>
              <option value="MANAGER">Manager</option>
            </select>
          </div>
        </td>
        <td>
          <div class="select-box medium">
            <select class="form-control" formControlName="interet">
              <option value="LIKE">J'aime</option>
              <option value="DISLIKE">Je n'aime pas</option>
              <option value="NEUTRAL">Neutre</option>
            </select>
          </div>
        </td>
        <td>
          <div class="select-box medium">
            <select class="form-control" formControlName="niveau">
              <option value="GOLD">Or</option>
              <option value="ARG">Argent</option>
              <option value="BRZ">Bronze</option>
            </select>
          </div>
        </td>
        <td>
          <div class="select-box medium">
            <select class="form-control" formControlName="validationManager">
              <option value="YES">Oui</option>
              <option value="NO">Non</option>
            </select>
          </div>
        </td>
      </ng-template>
    </tr>
  </table>
</form>
