import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor() {
  }

  /**
   * Get icon class to display by level and interest
   * @param data any
   */
  getInterestLevelIcon(data: any): string {
    let iconClass = '';
    if (!data.interest || data.interest.value === -1) {
      if (!data.level) {
        iconClass = 'fm-level-undefined';
      } else {
        switch (data.level.value) {
          case 1 :
            iconClass = 'fm-level-undefined';
            break;
          case 2 :
            iconClass = 'fm-level-bronze';
            break;
          case 3 :
            iconClass = 'fm-level-silver';
            break;
          case 4 :
            iconClass = 'fm-level-gold';
            break;
          case 5 :
            iconClass = 'fm-level-lead';
            break;
        }
      }
    } else {
      if (!data.level) {
        switch (data.interest.value) {
          case 3 :
            iconClass = 'fm-icon-happy';
            break;
          case 1 :
            iconClass = 'fm-icon-sad';
            break;
          case 2 :
            iconClass = 'fm-icon-equal';
            break;
        }
      } else {
        switch (data.interest.value) {
          case 3:
            switch (data.level.value) {
              case 1 :
                iconClass = 'fm-icon-happy-level-undefined';
                break;
              case 2 :
                iconClass = 'fm-icon-happy-level-bronze';
                break;
              case 3 :
                iconClass = 'fm-icon-happy-level-silver';
                break;
              case 4 :
                iconClass = 'fm-icon-happy-level-gold';
                break;
              case 5 :
                iconClass = 'fm-icon-happy-level-lead';
                break;
            }
            break;
          case 1 :
            switch (data.level.value) {
              case 1 :
                iconClass = 'fm-icon-sad-level-undefined';
                break;
              case 2 :
                iconClass = 'fm-icon-sad-level-bronze';
                break;
              case 3 :
                iconClass = 'fm-icon-sad-level-silver';
                break;
              case 4 :
                iconClass = 'fm-icon-sad-level-gold';
                break;
              case 5 :
                iconClass = 'fm-icon-sad-level-lead';
                break;
            }
            break;
          case 2 :
            switch (data.level.value) {
              case 1 :
                iconClass = 'fm-icon-equal-level-undefined';
                break;
              case 2 :
                iconClass = 'fm-icon-equal-level-bronze';
                break;
              case 3 :
                iconClass = 'fm-icon-equal-level-silver';
                break;
              case 4 :
                iconClass = 'fm-icon-equal-level-gold';
                break;
              case 5 :
                iconClass = 'fm-icon-equal-level-lead';
                break;
            }
            break;

        }
      }
    }
    return iconClass;
  }

  getPicto(data: any, subFieldName: string): string {
    let iconClass = '';
    if (subFieldName === 'course') {
      iconClass = 'fm-icon-small-cup';
    }
    if (subFieldName === 'certification') {
      iconClass = 'fm-icon-' + data[subFieldName].icon.name;
    }
    return iconClass;
  }

}
