import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SetPendingAnnualReport} from '../../../../stores/reports/report.action';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-comment-report',
  templateUrl: './comment-report.component.html',
  styleUrls: ['./comment-report.component.scss']
})
export class CommentReportComponent {

  @Input()
  freeAnswerMaxLength: number;

  @Input()
  title: string;

  @Input()
  formNameEditor: string;

  @Input()
  fmNgModel: string;

  @Input()
  formData: FormGroup;

  @Input()
  placeholder: string;

  formats: string[] = ['bold', 'ordered', 'bullet'];

  constructor(private store: Store) {}

  handleChange(event: any){
    if (event){
      this.store.dispatch(new SetPendingAnnualReport(this.formData.value));
    }

  }

}
