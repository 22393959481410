import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from 'src/app/core/services/report-services/report.service';

@Component({
  selector: 'app-profil-rha',
  templateUrl: './profil-rha.component.html',
  styleUrls: ['./profil-rha.component.scss'],
})
export class ProfilRhaComponent implements OnInit {
  public rha: any;
  public userId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reportService: ReportService
  ) {}

  ngOnInit(): void {
    this.getWeeklyReportByReportId(
      parseInt(this.route.snapshot.paramMap.get('id'), 10)
    );
  }

  public handleClickBack(): void {
    this.rha = null;
    this.router.navigateByUrl(`/profile/show`);
  }

  getWeeklyReportByReportId(reportId: number) {
    return this.reportService
      .getWeeklyReportByReportId(reportId)
      .subscribe((weeklyActivityReport) => {
        this.rha = weeklyActivityReport;
      });
  }
}
