import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  ClearDirectoryUser,
  ClearDirectoryUsers,
  GetDirectoryUserByUserId,
  GetDirectoryUsers,
  GetDirectoryUsersByProperties,
  GetDirectoryUsersBySkills,
  GetDirectoryUsersOnlyOrigin,
  GetDirectoryUsersOrigin,
  GetTop10DirectoryUsers
} from './directory-users.actions';
import {UserService} from '../../../core/services/user-services/user.service';
import {User} from '../../../shared/models/user';

export class DirectoryUserStateModel {
  users: User[];
  user: User;
  snapshot: User[];
  usersOrigin: User[];
  loading: boolean;
}

@State<DirectoryUserStateModel>({
  name: 'directoryUsers',
  defaults: {
    users: [],
    user: null,
    snapshot: [],
    usersOrigin: [],
    loading: false
  }
})

@Injectable()
export class DirectoryUsersState {

  constructor(private userService: UserService) {
  }

  @Selector()
  static getUsers(state: DirectoryUserStateModel) {
    return state.users;
  }

  @Selector()
  static getUser(state: DirectoryUserStateModel) {
    return state.user;
  }

  @Selector()
  static getLoading(state: DirectoryUserStateModel) {
    return state.loading;
  }

  @Action(GetTop10DirectoryUsers)
  getTop10({getState, setState}: StateContext<DirectoryUserStateModel>) {
    const state = getState();
    setState({
      ...state,
      loading: true
    });
    return this.userService.getUsersForDirectory('directory', 0).pipe(tap((users) => {
      setState({
        ...state,
        users,
        usersOrigin: users,
        loading: false,
      });
    }));
  }

  @Action(GetDirectoryUsers)
  getAll({getState, setState}: StateContext<DirectoryUserStateModel>) {
    const state = getState();
    setState({
      ...state,
      loading: true
    });
    return this.userService.getUsersForDirectory('directory').pipe(tap((users) => {
      setState({
        ...state,
        users,
        usersOrigin: users,
        loading: false,
      });
    }));
  }

  @Action(GetDirectoryUsersOnlyOrigin)
  getAllOnlyOrigin({getState, setState}: StateContext<DirectoryUserStateModel>) {
    const state = getState();
    setState({
      ...state,
      loading: true
    });
    return this.userService.getUsersForDirectory('directory').pipe(tap((users) => {
      setState({
        ...state,
        usersOrigin: users,
        loading: false
      });
    }));
  }

  @Action(GetDirectoryUsersOrigin)
  getAllOrigin({getState, setState}: StateContext<DirectoryUserStateModel>) {
    const state = getState();
    setState({
      ...state,
      users: state.usersOrigin,
    });
  }

  @Action(GetDirectoryUserByUserId)
  getUserByUserId({getState, setState}: StateContext<DirectoryUserStateModel>, {payload}: GetDirectoryUserByUserId) {
    return this.userService.getOne(payload).pipe(tap((user) => {
      const state = getState();
      setState({
        ...state,
        user
      });
    }));
  }

  @Action(GetDirectoryUsersByProperties)
  getUsersByProperties({getState, setState}: StateContext<DirectoryUserStateModel>, {payload}: GetDirectoryUsersByProperties) {
    return this.userService.getUsersByProperties(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        users: result.map( r => ({
          ...r,
          userSkills: this.userService.orderedBylevelThenByAlphabeticalOrder(r.userSkills, 'skill'),
          userSpecialties: this.userService.orderedBylevelThenByAlphabeticalOrder(r.userSpecialties, 'specialty'),
          userProfiles: this.userService.orderedBylevelThenByAlphabeticalOrder(r.userProfiles, 'profile'),
        })),
      });
    }));
  }

  @Action(GetDirectoryUsersBySkills)
  getUsersBySkills({getState, setState}: StateContext<DirectoryUserStateModel>, {payload}: GetDirectoryUsersBySkills) {
    return this.userService.getUsersBySkills(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        users: result.map( r => ({
          ...r,
          userSkills : this.userService.orderedBylevelThenByAlphabeticalOrder(r.userSkills, 'skill'),
          userSpecialties : this.userService.orderedBylevelThenByAlphabeticalOrder(r.userSpecialties, 'specialty'),
          userProfiles: this.userService.orderedBylevelThenByAlphabeticalOrder(r.userProfiles, 'profile'),
        })),
      });
    }));
  }

  @Action(ClearDirectoryUser)
  ClearDirectoryUser({getState, setState}: StateContext<DirectoryUserStateModel>) {
    const state = getState();
    setState({
      ...state,
      user: null,
    });
  }

  @Action(ClearDirectoryUsers)
  ClearDirectoryUsers({getState, setState}: StateContext<DirectoryUserStateModel>) {
    const state = getState();
    setState({
      ...state,
      users: []
    });
  }
}

