import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { MessageService } from 'primeng/api';
import { UserRoleService } from '../services/user-role-services/user.role.service';

/**
 * Role guard for routes, redirect to /home page if
 * role is wrong
 */
@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private userRoleService: UserRoleService,
    private messageService: MessageService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.authenticationService.isAuthenticated().pipe(
      map((currentUser) => {
        if (!this.userRoleService.isPathAllowedForUser(route.routeConfig.path, this.authenticationService.getUserRoles())) {
          return this.router.parseUrl('/pageNotFound');
        }
        return true;
      })
    );
  }
}
