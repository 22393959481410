import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from '../../../../shared/models/user';
import { Request } from '../../../../shared/models/request';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '../../../../core/services/request-service/request.service';
import { Motivation } from '../../../../shared/models/motivation';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss'],
})
export class RequestDetailsComponent implements OnInit {

  requestId;
  date: Date;
  type: string;
  name: string;
  description: string;
  cpf: string;
  hours: number;
  certification: string;
  motivation = Motivation;
  user: User = {} as User;
  requestServiceSubscription: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private requestService: RequestService) {}

  ngOnInit(): void {
    this.requestId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.requestServiceSubscription = this.requestService.getRequestById(this.requestId).subscribe( (res) => {
      this.user = res.user;
      this.date = res.date;
      this.hours = res.hours;
      this.cpf = res.cpf === true ? 'Non' : 'Oui';
      this.name = res.name;
      this.certification = res.certification === false ? 'Non Certifiante' : 'Certifiante';
      this.description = res.description;
      this.motivation = Motivation[res.motivation];
      this.type = res.type;
    });
  }

  redirect() {
    this.router.navigateByUrl(`/team/requests`);
  }

  ngOnDestroy(): void {
    this.requestServiceSubscription.unsubscribe();
  }
}
