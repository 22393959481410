<div class="fm-box-title">
  <app-fm-button
    type="text-info"
    btnText="RETOUR"
    (click)="redirect()"
  ></app-fm-button>
</div>
<div class="fm-box-title">
<span>Demande de formation : {{ user.firstname }} {{ user.lastname }}</span>
</div>
<div class="fm-box-details">
<p-card class="fm-p-card">
  <div fxFlex="100%">
    <div class="fm-box-text" fxFlex="100%">
      <tr>
        <td class="td-bloc-label">Date :</td>
        <td class="td-bloc-text">{{ date | date: 'dd/MM/yyyy' }}</td>
      </tr>
      <tr>
        <td class="td-bloc-label">Nom :</td>
        <td class="td-bloc-text">{{ name }}</td>
      </tr>
      <tr>
        <td class="td-bloc-label">Type de demande :</td>
        <td class="td-bloc-text">{{ type }}</td>
      </tr>
      <tr>
        <td class="td-bloc-label">Description :</td>
        <td class="td-bloc-text">{{ description }}</td>
      </tr>
      <tr>
        <td class="td-bloc-label">CPF :</td>
        <td class="td-bloc-text">{{ cpf }}</td>
      </tr>
      <tr>
        <td class="td-bloc-label">Heures  :</td>
        <td class="td-bloc-text">{{ hours }}</td>
      </tr>
      <tr>
        <td class="td-bloc-label">Certification :</td>
        <td class="td-bloc-text">{{ certification }}</td>
      </tr>
      <tr>
        <td class="td-bloc-label">Motivation :</td>
        <td class="td-bloc-text">{{ motivation }}</td>
      </tr>
      </div>
    </div>
</p-card>
</div>

