<div class="fm-login-container">
    <div>
        <div>
            <div>
                <img src="../../../../../assets/img/Logo_gradient.svg" alt="Follow'Minds">
                <span>follow'minds</span>
                <app-fm-button *ngIf="this.isButtonVisible" [attr.data-testid]="'login_button_connection'" type="text-gradient" btnText="SE CONNECTER" (onClick)="handleConnect()"></app-fm-button>
                <app-info [loginInfo]=info></app-info>
                <app-fm-button *ngIf="!this.isButtonVisible" type="text-gradient" btnText="RETOUR" (onClick)="logout()"></app-fm-button>
            </div>
            <div></div>
        </div>
    </div>
</div>

<div class="fm-not-responsive">
  <div>
    <img src="../../../../../assets/img/Logo_gradient.svg" alt="Follow'Minds">
    <span>follow'minds</span>
    <br/>
    <p>Cette page est consultable<br/>depuis un ordinateur</p>
  </div>
</div>
