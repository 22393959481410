import { Component, OnInit } from '@angular/core';
import {GetAnnualReportUser} from '../../../../../../../stores/authentication/authentication.action';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-annual-report-team',
  templateUrl: './annual-report-team.component.html',
  styleUrls: ['./annual-report-team.component.scss']
})
export class AnnualReportTeamComponent implements OnInit {

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetAnnualReportUser());
  }

}
