<div class="fm-selection-menu">
  <div style="display: flex; width: 100%; justify-content: space-between; justify-items: center">
    <p-dropdown *ngIf="typeData"
                [(ngModel)]="typeDataSelected"
                [options]="typeData"
                (onChange)="handleChangeType($event)"
                class="fm-select-items"
                [attr.data-testid]="'selectionMenu_dropDown_type'">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="typeDataSelected">
          <div [attr.data-testid]="'selectionMenu_dropDown_type_selected_' + typeDataSelected">{{typeDataSelected}}</div>
        </div>
      </ng-template>
      <ng-template let-type pTemplate="item">
        <div class="country-item">
          <div [attr.data-testid]="'selectionMenu_dropDown_type_item_' + type.label">{{type.label}}</div>
        </div>
      </ng-template>
    </p-dropdown>
    <label>
      <input [attr.data-testid]="'selectionMenu_input_search'" style="width: 250px; margin-left: -180px" pInputText type="text" placeholder="Recherche" [(ngModel)]="globalSearch" (ngModelChange)="handleGlobalSearchChange()"/>
    </label>
    <div>
      <app-fm-button [hidden]="!showAddButton" [attr.data-testid]="'selectionMenu_button_showHide'" [type]="!showAddForm ? 'show' : 'hide'" (click)="handleShowAddForm($event)"></app-fm-button>
    </div>
  </div>
</div>
