import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { TeamStateModel } from '../../../../store/team.state';
import { TeamSpecialtyState, TeamSpecialtyStateModel } from '../../../../store/team-specialties.state';
import {
  AddUserSpecialty,
  ClearUserSpecialties,
  DeleteUserSpecialty,
  GetUserSpecialtiesByUserId,
  MakeSnapshotUserSpecialty,
  RestoreSnapshotUserSpecialty,
  UpdateUserSpecialty
} from '../../../../store/team-specialties.actions';
import { MessageService, SelectItem } from 'primeng/api';
import { SpecialtyService } from '../../../../../../core/services/specialty-services/specialty.service';
import { ProfileService } from '../../../../../../core/services/profile-services/profile.service';
import { UserSpecialty } from '../../../../../../shared/models/user-specialty';
import { Specialty } from '../../../../../../shared/models/specialty';
import { Level } from '../../../../../../shared/models/level';
import { Interest } from '../../../../../../shared/models/interest';
import { FormInlineComponent } from '../../../../../../shared/components/form-inline/form-inline.component';
import { TeamProfileState } from '../../../../store/team-profiles.state';

@Component({
  selector: 'app-view-specialties',
  templateUrl: './view-specialties.component.html',
  styleUrls: ['./view-specialties.component.scss']
})
export class ViewSpecialtiesComponent implements OnInit, OnChanges, OnDestroy {
  @Select(TeamSpecialtyState.getUserSpecialties)
  public userSpecialties$: Observable<UserSpecialty[]>;
  @Select(TeamProfileState.getUserProfiles)
  private userProfiles$: Observable<UserSpecialty[]>;

  @ViewChild(FormInlineComponent)
  private formUserSpecialty: FormInlineComponent;

  @Input()
  isMainManager = false;

  @Input()
  public displayOnlyQualificationsNotValidated = false;

  @Input()
  private interests: Interest[] = [];

  @Input()
  private levels: Level[] = [];

  @Input()
  private interestsOptions: SelectItem[] = [];

  @Input()
  private levelsOptions: SelectItem[] = [];

  public modal: any = {};
  public showModal = false;
  public inputForm: any = [];
  public showAddForm: boolean;
  public disableCreationSave = false;
  public tableColumns: any[];
  public emptyMessage = 'Aucune spécialité n\'est enregistrée pour le ou les profil(s) attribué(s)';

  private teamState: TeamStateModel;
  private userSpecialtyState: TeamSpecialtyStateModel;
  private userProfilesSubscription: Subscription;
  private userSpecialties: UserSpecialty[];
  private itemToDelete: UserSpecialty;
  private specialties: Specialty[];
  private defaultInterestsOptions: SelectItem[] = [];
  private defaultLevelsOptions: SelectItem[] = [];

  public constructor(
    private messageService: MessageService,
    private specialtyService: SpecialtyService,
    private profileService: ProfileService,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.teamState = this.store.snapshot().team;
    this.store.dispatch(new GetUserSpecialtiesByUserId(this.teamState.currentTeamUser.id));

    this.interestsOptions.map(
      item => this.defaultInterestsOptions.push(item)
    );
    this.levelsOptions.map(
      item => this.defaultLevelsOptions.push(item)
    );

    this.initDatatable();

    this.userProfilesSubscription = this.userProfiles$.subscribe(
      result => {
        const specialties = [];
        result.map(
          item => item.profile.specialties
            .filter((specialty) => {
              return specialty.archivingDate === null || (new Date(specialty.archivingDate)).getTime() > (new Date()).getTime();
            })
            .map(
              specialty => {
                specialty.profile = { id: item.profile.id, name: item.profile.name };
                specialties.push({...specialty});
              }
            )
        );
        this.specialties = specialties;
        this.initForm();
      }
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
    this.initDatatable();
  }

  private initDatatable(): void {
    this.tableColumns = [
      {
        type: 'select',
        field: 'specialty',
        subField: 'name',
        label: 'Spécialité',
        isStatic: true,
        font: 'semibold',
        sort: false
      },
      {
        type: 'select',
        field: 'interest',
        subField: 'name',
        label: 'Intérêt',
        icon: 'interest',
        options: this.interestsOptions,
        optionIcon: true,
        optionOnlyIcon: true,
        clearable: true,
        sort: false,
        isStatic: true,
      },
      {
        type: 'select',
        field: 'level',
        subField: 'value',
        subFieldLabel: 'name',
        label: 'Niveau',
        icon: 'level',
        options: this.levelsOptions,
        optionIcon: true,
        font: 'regular',
        sort: false
      },
      {
        type: 'validation',
        action: 'validation',
        label: 'Validation',
        field: 'validation',
        width: '8%',
        isStatic: this.isMainManager === false
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: false
      },
      { type: 'action', action: 'edit', width: '8%' },
      { type: 'action', action: 'delete', width: '10%' },
    ];
  }

  private initForm(): void {
    this.inputForm = [
      {
        key: 'nameSpecialty',
        typeForm: 'dropdown',
        filter: true,
        fieldForm: 'specialty',
        labelForm: 'Spécialité',
        requiredForm: true,
        placeHolder: 'Spécialité',
        optionsForm: this.specialties,
        optionConcat: 'profile'
      },
      {
        key: 'nameLevel',
        typeForm: 'dropdown',
        fieldForm: 'level',
        labelForm: 'Niveau',
        requiredForm: true,
        optionsForm: this.levels,
        placeHolder: 'Niveau',
        icon: true
      },
    ];
    if (this.isMainManager) {
      this.inputForm.push(      {
        key: 'nameValidation',
        typeForm: 'dropdown',
        fieldForm: 'validation',
        labelForm: 'Validation manager',
        optionsForm: [
          { name: 'Oui', value: 1 },
          { name: 'Non', value: 0 },
        ],
        placeHolder: 'Validation manager',
      });
    }
  }

  public toggleShowAddForm(): void {
    this.showAddForm = !this.showAddForm;
  }

  public handleCreateItem(userSpecialty): void {
    if (!this.errorsValidation(userSpecialty, 'create')) {
      userSpecialty.user = this.teamState.currentTeamUser;
      userSpecialty.validation = userSpecialty.validation?.value || null;
      this.store.dispatch(new AddUserSpecialty(userSpecialty)).subscribe(
        () => {
          this.displayMessage('create');
          this.formUserSpecialty.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  public handleDeleteItem(id: number): void {
    this.userSpecialtyState = this.store.snapshot().userSpecialties;
    this.itemToDelete = this.userSpecialtyState.userSpecialties.find((item) => item.id === id);
    this.displayModal('delete');
  }

  private setInterestOptions(userSpecialty: UserSpecialty): void {
    // Interest options
    const indexInterest = this.interests.findIndex(item => item.id === userSpecialty.interest?.id);
    if (indexInterest === -1) {
      // Put blank interest
      this.interestsOptions.unshift({label: null, value: null, icon: null});
    } else {
      const iconNameInterest = this.interestsOptions[indexInterest].icon;
      // Delete current Interest value of the interests options with indexInterest help
      this.interestsOptions.splice(indexInterest, 1);
      // Put current Interest value at first Interests options place
      this.interestsOptions.unshift({ label: userSpecialty.interest?.name, value: userSpecialty.interest, icon: iconNameInterest });
    }
  }

  private setLevelOptions(userSpecialty: UserSpecialty): void {
    // Level options
    const indexLevel = this.levels.findIndex(item => item.id === userSpecialty.level?.id);
    // Get icon's name
    const iconNameLevel = this.levelsOptions[indexLevel].icon;
    // Delete current Level value of the levels options with indexLevel help
    this.levelsOptions.splice(indexLevel, 1);
    // Put current Level value at first Levels options place
    this.levelsOptions.unshift({ label: userSpecialty.level.name, value: userSpecialty.level, icon: iconNameLevel });
  }

  private resetInterestsOptions(): void {
    const length = this.interestsOptions.length;
    this.interestsOptions.splice(0, length);
    this.defaultInterestsOptions.map(
      item => this.interestsOptions.push(item)
    );
  }

  private resetLevelsOptions(): void {
    const length = this.levelsOptions.length;
    this.levelsOptions.splice(0, length);
    this.defaultLevelsOptions.map(
      item => this.levelsOptions.push(item)
    );
  }

  public handleUpdateItem(userSpeciality: UserSpecialty): void {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotUserSpecialty());

    this.resetInterestsOptions();
    this.resetLevelsOptions();
    this.setInterestOptions(userSpeciality);
    this.setLevelOptions(userSpeciality);
  }

  public handleSaveUpdateItem(userSpecialty: UserSpecialty): void {
    if (!this.errorsValidation(userSpecialty, 'update')) {
      this.store.dispatch(new UpdateUserSpecialty(userSpecialty)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.disableCreationSave = false;
        }
      );
    }
  }

  public handleCancelEditItem(data): void {
    this.store.dispatch(new RestoreSnapshotUserSpecialty());
    this.disableCreationSave = false;
  }

  public handleModalConfirm(): void {
    this.deleteItem();
  }

  private deleteItem(): void {
    this.store.dispatch(new DeleteUserSpecialty(this.itemToDelete)).subscribe(
      () => this.displayMessage('delete'),
      (e) => this.displayError(e.status)
    );
  }

  /**
   * Forms validation (create and update)
   * @param userSpecialty UserSpecialty
   * @param action string
   * @return boolean
   */
  private errorsValidation(userSpecialty: UserSpecialty, action: string): boolean {
    let errors = false;
    if (!userSpecialty.specialty) {
      this.displayError('specialty');
      errors = true;
    }
    if (!userSpecialty.level) {
      this.displayError('level');
      errors = true;
    }
    return errors;
  }

  private displayModal(type: string): void {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  private displayMessage(type: string): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La spécialité a bien été sauvegardée';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La spécialité a bien été sauvegardée';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'Spécialité supprimée.';
        break;
      }
    }
    this.messageService.add({severity, summary, detail});
  }

  private displayError(e: any): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Cette spécialité est déjà attribuée à cet utilisateur.';
        break;
      case 'specialty':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner une spécialité.';
        break;
      case 'profile':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un poste.';
        break;
      case 'level':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un niveau.';
        break;
      case 501:
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;

    }
    this.messageService.add({severity, summary, detail});
  }

  public ngOnDestroy(): void {
    this.resetLevelsOptions();
    this.resetInterestsOptions();
    this.userProfilesSubscription.unsubscribe();
    this.store.dispatch(new ClearUserSpecialties());
  }
}
