import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export  class EnumToArrayPipe implements PipeTransform{
  transform(data: any): any {
    return Object.keys(data).map(key => ({id: key, name: data[key] }));
  }

}
