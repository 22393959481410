import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetProfileUserCoursesByUserId,
  AddProfileUserCourse,
  ClearProfileUserCourses,
  UpdateProfileUserCourse,
  MakeSnapshotProfileUserCourse,
  RestoreSnapshotProfileUserCourse, DeleteProfileUserCourse
} from './profile-courses.actions';
import { UserCourseService } from '../../../core/services/user-course-services/user-course.service';
import { UserCourse } from '../../../shared/models/user-course';


export class ProfileCourseStateModel {
  userCourses: UserCourse[];
  snapshot: UserCourse[];
  httpStatus: number;
}

@State<ProfileCourseStateModel>({
  name: 'profileUserCourses',
  defaults: {
    userCourses: [],
    snapshot: [],
    httpStatus: null
  }
})
@Injectable()
export class ProfileCourseState {

  constructor(private userCourseService: UserCourseService) {
  }

  @Selector()
  static getUserCourses(state: ProfileCourseStateModel) {
    return state.userCourses;
  }

  @Action(GetProfileUserCoursesByUserId)
  getProfileUserCoursesByUserId(
    {getState, setState}: StateContext<ProfileCourseStateModel>,
    {payload}: GetProfileUserCoursesByUserId
  ) {
    return this.userCourseService.getUserCoursesByUserId(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userCourses: result.map( r => {
          r.startDate = r.startDate ? new Date(r.startDate) : null;
          r.endDate = r.endDate ? new Date(r.endDate) : null;
          r.rangeDates = [];
          r.rangeDates.push(r.startDate);
          r.rangeDates.push(r.endDate);
          return r;
        }),
      });
    }));
  }

  @Action(AddProfileUserCourse)
  add({getState, patchState}: StateContext<ProfileCourseStateModel>, {payload}: AddProfileUserCourse) {
    return this.userCourseService.doPost(payload).pipe(tap((result) => {
      result.startDate = result.startDate ? new Date(result.startDate) : null;
      result.endDate = result.endDate ? new Date(result.endDate) : null;
      result.rangeDates = [];
      result.rangeDates.push(result.startDate);
      result.rangeDates.push(result.endDate);
      const state = getState();
      patchState({
        userCourses: [...state.userCourses, result]
      });
    }));
  }

  @Action(UpdateProfileUserCourse)
  update({getState, setState}: StateContext<ProfileCourseStateModel>, {payload}: UpdateProfileUserCourse) {
    payload.startDate = payload.rangeDates[0];
    payload.endDate = payload.rangeDates[1];
    return this.userCourseService.doPut(payload).pipe(
      tap((result) => {
        result.startDate = result.startDate ? new Date(result.startDate) : null;
        result.endDate = result.endDate ? new Date(result.endDate) : null;
        result.rangeDates = [];
        result.rangeDates.push(result.startDate);
        result.rangeDates.push(result.endDate);
        const state = getState();
        const userCoursesList = [...state.userCourses];
        const userCourseIndex = userCoursesList.findIndex(item => item.id === payload.id);
        userCoursesList[userCourseIndex] = result;
        setState({
          ...state,
          userCourses: userCoursesList,
        });
    }));
  }

  @Action(DeleteProfileUserCourse)
  delete({getState, setState}: StateContext<ProfileCourseStateModel>, {payload}: DeleteProfileUserCourse) {
    const state = getState();
    return this.userCourseService.doDelete(payload.id, {observe: 'response'}).pipe(tap((observe) => {
      if (observe.status === 200) {
        const filteredArray = state.userCourses.filter(item => item.id !== payload.id);
        setState({
          ...state,
          userCourses: filteredArray,
          httpStatus: 200,
        });
      } else {
        setState({
          ...state,
          httpStatus: 202,
        });
      }
    }));
  }

  @Action(MakeSnapshotProfileUserCourse)
  makeSnapShot({getState, setState}: StateContext<ProfileCourseStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.userCourses.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotProfileUserCourse)
  restoreSnapshot({getState, setState}: StateContext<ProfileCourseStateModel>) {
    const state = getState();
    setState({
      ...state,
      userCourses: state.snapshot.map(item => ({...item}))
    });
  }

  @Action(ClearProfileUserCourses)
  clearProfileUserCourses({getState, setState}: StateContext<ProfileCourseStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: [],
      userCourses: [],
    });
  }
}
