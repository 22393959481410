import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Actions } from 'src/app/shared/models/actions';

@Injectable({
  providedIn: 'root'
})
export class ActionService extends ApiService{

  protected prefix(): string {
    return 'actions';
  }

  /**
   * Get the list of actions
   */
  getAll(): Observable<any> {
    return this.get();
  }

  doPut(action: Actions): Observable<any> {
    return this.put(action);
  }

}
