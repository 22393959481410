import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetUserSkills,
  AddUserSkill,
  DeleteUserSkill,
  UpdateUserSkill,
  GetUserSkillsByUserId,
  MakeSnapshotUserSkill,
  RestoreSnapshotUserSkill,
  ClearUserSkills
} from './team-skills.actions';
import { UserSkillService } from '../../../core/services/user-skill-services/user-skill.service';
import { UserSkill } from '../../../shared/models/user-skill';

export class TeamSkillStateModel {
  userSkills: UserSkill[];
  snapshot: UserSkill[];
}

@State<TeamSkillStateModel>({
  name: 'userSkills',
  defaults: {
    userSkills: [],
    snapshot: []
  }
})
@Injectable()
export class TeamSkillState {

  constructor(private userSkillService: UserSkillService) {
  }

  @Selector()
  static getUserSkills(state: TeamSkillStateModel) {
    return state.userSkills;
  }

  @Action(GetUserSkills)
  getAll({getState, setState}: StateContext<TeamSkillStateModel>) {
    return this.userSkillService.getAll().pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userSkills: result,
      });
    }));
  }

  @Action(GetUserSkillsByUserId)
  getUserSkillsByUserId({getState, setState}: StateContext<TeamSkillStateModel>, {payload}: GetUserSkillsByUserId) {
    return this.userSkillService.getUserSkillsByUserId(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userSkills: result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddUserSkill)
  add({getState, patchState}: StateContext<TeamSkillStateModel>, {payload}: AddUserSkill) {
    return this.userSkillService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      patchState({
        userSkills: [...state.userSkills, result]
      });
    }));
  }

  @Action(UpdateUserSkill)
  update({getState, setState}: StateContext<TeamSkillStateModel>, {payload}: UpdateUserSkill) {
    return this.userSkillService.doPut(payload).pipe(
      tap((result) => {
        result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
        const state = getState();
        const userSkillsList = [...state.userSkills];
        const userSkillIndex = userSkillsList.findIndex(item => item.id === payload.id);
        userSkillsList[userSkillIndex] = result;
        setState({
          ...state,
          userSkills: userSkillsList,
        });
    }));
  }

  @Action(DeleteUserSkill)
  delete({getState, setState}: StateContext<TeamSkillStateModel>, {payload}: DeleteUserSkill) {
    const state = getState();
    return this.userSkillService.doDelete(payload.id).pipe(tap(() => {
      const filteredArray = state.userSkills.filter(item => item.id !== payload.id);
      setState({
        ...state,
        userSkills: filteredArray,
      });
    }));
  }

  @Action(MakeSnapshotUserSkill)
  makeSnapShot({getState, setState}: StateContext<TeamSkillStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.userSkills.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotUserSkill)
  restoreSnapshot({getState, setState}: StateContext<TeamSkillStateModel>) {
    const state = getState();
    setState({
      ...state,
      userSkills: state.snapshot.map(item => ({...item}))
    });
  }

  @Action(ClearUserSkills)
  clearUserSkills({getState, setState}: StateContext<TeamSkillStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: [],
      userSkills: [],
    });
  }
}
