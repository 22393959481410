<div class="fm-top">
    <div class="fm-top-content">
        <div class="fm-top-feedback">
            <i class="fm-icon-interview"></i>
            <span>{{reportUser?.fullname}}: Compte-Rendu d'Entretien</span>
        </div>
    </div>
</div>

<div class="fm-title">
    <h2 *ngIf="!needsProject">Compte-Rendu d'Entretien: {{selectedReportType?.name}}</h2>
    <h2 *ngIf="needsProject && !needsClient">Compte-Rendu d'Entretien: {{selectedReportType?.name}} -
        {{selectedCustomer?.name}}</h2>
    <h2 *ngIf="needsProject && needsClient">Compte-Rendu d'Entretien: {{selectedReportType?.name}} -
        {{selectedCustomer?.name}} - {{selectedProject?.name}}</h2>
</div>
<form [formGroup]="formData">
    <div class="fm-form">

        <h2 class="fm-margin-top fm-margin-bottom">Sélectionner le type d'entretien</h2>
        <div class="p-field-radiobutton">
            <p-dropdown [attr.data-testid]="'team_report_type_selector'" [options]="reportTypes" [required]="true"
                [(ngModel)]="selectedReportType" formControlName="reportType" placeholder="Sélectionner l'Entretien"
                [style]="{'width': '500px'}" (onChange)="handleTypeChoice()">
            </p-dropdown>
        </div>
        <div *ngIf="needsClient">
            <h2 class="fm-margin-top fm-margin-bottom">Sélectionner le client</h2>
            <div class="p-field-radiobutton">
                <p-dropdown [attr.data-testid]="'team_report_customer_selector'" [options]="customers"
                    [required]="needsProject" [(ngModel)]="selectedCustomer" formControlName="customer"
                    placeholder="Sélectionner le Client" [style]="{'width': '500px'}" (onChange)="handleClientChoice()">
                </p-dropdown>
            </div>
        </div>
        <div *ngIf="needsProject">
            <h2 class="fm-margin-top fm-margin-bottom">Sélectionner le projet</h2>
            <div class="p-field-radiobutton">
                <p-dropdown [attr.data-testid]="'team_report_project_selector'" [options]="projects"
                    [required]="needsProject" [(ngModel)]="selectedProject" formControlName="project"
                    placeholder="Sélectionner le Projet" [style]="{'width': '500px'}"
                    (onChange)="handleProjectChoice()">
                </p-dropdown>
            </div>
        </div>

        <div *ngIf="needsDate">
            <h2 class="fm-margin-top fm-margin-bottom">Quel est la date de l'entretien ?</h2>
            <p-calendar id="reportDate"
                [attr.data-testid]="'team_report_field_reportDate'"
                [(ngModel)]="selectedDate"
                placeholder="jj/mm/aaaa"
                dateFormat="dd/mm/yy"
                appendTo="body"
                [locale]="fr"
                [style]="{'width': '500px'}"
                showIcon="true"
                formControlName="date">
            </p-calendar>
        </div>
        <div *ngIf="needsRating">
            <h2 class="fm-margin-top fm-margin-bottom">Comment s'est passée l'entretien ?</h2>
            <p-rating [attr.data-testid]="'team_report_field_rating'" [(ngModel)]="selectedRating" id="rating" stars="4"
                formControlName="rating" [cancel]="false">
            </p-rating>
        </div>
        <div *ngIf="needsCustomerManager">
            <h2 class="fm-margin-top fm-margin-bottom">Indiquez les personnes supplémentaires présentes</h2>
            <textarea [attr.data-testid]="'team_report_customer_manager'" [(ngModel)]="selectedCustomerManager" pInputTextarea
                [maxLength]="freeAnswerMaxLength" id="customer_manager" formControlName="customerManager"
                placeholder="Ajouter les personnes présentes en plus du manager">
            </textarea>
        </div>
        <div *ngIf="needsComment">
            <h2 class="fm-margin-top fm-margin-bottom">Compte Rendu de l'entretien</h2>
            <textarea [attr.data-testid]="'team_report_report'" [(ngModel)]="selectedComment" pInputTextarea
                [maxLength]="freeAnswerMaxLength" id="report" formControlName="report"
                placeholder="Décrire le déroulement de l'entretiens : avancées, problèmes, questions">
            </textarea>
        </div>
    </div>
</form>


<div class="fm-buttons">
    <app-fm-button [attr.data-testid]="'team_report_button_cancel'" type="cancel" btnText="Annuler"
        (onClick)="cancelReport()">
    </app-fm-button>
    <app-fm-button [attr.data-testid]="'team_report_button_validate'" type="text-gradient" btnText="VALIDER"
        (onClick)="validateReport()">
    </app-fm-button>
</div>

<p-dialog [(visible)]="showModal">
    <p-header>
        {{modal.header}}
    </p-header>
    <div>La mission n'est pas déclarée dans le dossier de compétences!</div>
    <div>Sélectionnez une date de début et cliquez sur confirmer afin de créer une mission pour
        {{reportUser?.fullname}}.</div>
    <hr>
    <div class="fm-margin-top fm-margin-bottom">Date de Début</div>
    <p-calendar id="assignmnentStartDate"
        [attr.data-testid]="'team_report_assignment_startDate'"
        [(ngModel)]="selectedStartDate"
        placeholder="jj/mm/aaaa"
        dateFormat="dd/mm/yy"
        [locale]="fr"
        appendTo="body"
        [style]="{'width': '500px'}"
        showIcon="true">
    </p-calendar>
    <div class="fm-margin-top fm-margin-bottom">Date de Fin (si applicable)</div>
    <p-calendar class="fm-margin-top fm-margin-bottom"
        id="assignmnentEndDate"
        [attr.data-testid]="'team_report_assignment_endDate'"
        [(ngModel)]="selectedEndDate"
        placeholder="jj/mm/aaaa"
        dateFormat="dd/mm/yy"
        [locale]="fr"
        appendTo="body"
        [style]="{'width': '500px'}"
        showIcon="true">
    </p-calendar>
    <p-footer class="fm-modal-footer">
        <app-fm-button [attr.data-testid]="'modal_report_button_confirm'" type="confirm"
            (onClick)="handleModalConfirm()">
        </app-fm-button>
        <app-fm-button [attr.data-testid]="'modal_report_button_cancel'" type="cancel" (onClick)="handleModalCancel()">
        </app-fm-button>
    </p-footer>
</p-dialog>
