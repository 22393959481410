import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SoftSkill } from '../../../shared/models/soft-skill';
import {
    AddRH,
    DeleteRH, FilterRH,
    ClearSnapshotRH,
    GetRH,
    MakeSnapshotRH,
    RestoreSnapshotRH,
    UpdateRH,
} from './rh.actions';
import { SoftSkillService } from 'src/app/core/services/soft-skill-services/soft-skill.service';
import { searchIndexOf } from '../../../shared/utils/utils';


export class RHStateModel {
    softSkills: SoftSkill[];
    snapshot: SoftSkill[];
}

@State<RHStateModel>({
    name: 'softSkills',
    defaults: {
        softSkills: [],
        snapshot: [],
    },
})
@Injectable()
export class SoftSkillState {

    constructor(private softSkillService: SoftSkillService){}

    @Selector()
    static getSoftSkills(state: RHStateModel){
      return state.softSkills;
    }

    @Selector()
    static getSnapshot(state: RHStateModel){
        return state.snapshot;
    }

    @Action(GetRH)
    getAll({ getState, setState}: StateContext<RHStateModel>){
        return this.softSkillService.getAll().pipe(
            tap((result) => {
                const state = getState();
                setState({
                    ...state,
                    softSkills: result,
                });

            })
        );
    }

    @Action(AddRH)
    add({ getState, patchState }: StateContext<RHStateModel>, {payload}: AddRH){
        return this.softSkillService.doPost(payload).pipe(tap((result) =>{
            const state = getState();
            patchState({
                softSkills: [...state.softSkills, result],
            });
        }));
    }

    @Action(UpdateRH)
    update(
        {getState, setState }: StateContext<RHStateModel>,
        { payload }: UpdateRH
    ){
        return this.softSkillService.doPut(payload).pipe(
            tap((result) => {
                const state = getState();
                const softSkillsList = [...state.softSkills];
                const softSkillIndex = softSkillsList.findIndex((item) => item.id === payload.id);
                softSkillsList[softSkillIndex] = result;
                setState({
                    ...state,
                    softSkills: softSkillsList,
                });
            })
        );
    }

    @Action(DeleteRH)
    delete({ getState, setState }: StateContext<RHStateModel>, { payload }: DeleteRH){
        const state = getState();
        return this.softSkillService.doDelete(payload).pipe(
            tap(() => {
                const filteredArray = state.softSkills.filter(
                    (item) => item.id !== payload.id
                );
            setState({
                ...state,
                softSkills: filteredArray,
            });
            })
        );
    }

    @Action(FilterRH)
  filter({getState, setState}: StateContext<RHStateModel>, {payload}: FilterRH) {
    return this.softSkillService.getAll().pipe(tap((result) => {
      const state = getState();
      const softSkills = result.map( r => {
        r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
        return r;
      });
      const filteredSoftSkills = softSkills.filter(item =>
        item.name && searchIndexOf(item.name, payload) ||
        item.category && searchIndexOf(item.category, payload));
      setState({
        ...state,
        softSkills: filteredSoftSkills
      });
    }));
  }

  @Action(MakeSnapshotRH)
  makeSnapShot({ getState, setState }: StateContext<RHStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.softSkills.map((item) => ({ ...item })),
    });
  }

  @Action(RestoreSnapshotRH)
  restoreSnapshot({ getState, setState }: StateContext<RHStateModel>) {
    const state = getState();
    setState({
      ...state,
      softSkills: state.snapshot.map((item) => ({ ...item })),
    });
  }

  @Action(ClearSnapshotRH)
  clearSnapshot({ getState, setState }: StateContext<RHStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: null,
    });
  }
}
