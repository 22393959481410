import { User } from '../../../shared/models/user';
import { Assignement } from '../../../shared/models/assignement';

export class MakeSnapshotUserAssignment {
  static readonly type = '[User Assignment] MakeSnapshot';
}

export class RestoreSnapshotUserAssignment {
  static readonly type = '[User Assignment] RestoreSnapshot';
}

export class GetTeamAssignmentsByUser {
  static readonly type = '[Team Assignment] GetTeamAssignmentsByUser';
  constructor(public payload: User) {}
}

export class AddUserAssignment {
  static readonly type = '[User Assignment] Add';
  constructor(public payload: Assignement) {}
}

export class UpdateUserAssignment {
  static readonly type = '[User Assignment] update';
  constructor(public payload: Assignement) {}
}

export class DeleteUserAssignment {
  static readonly type = '[User Assignment] delete';
  constructor(public payload: Assignement) {}
}

export class ClearUserAssignments {
  static readonly type = '[User Assignment] Clear user assignments';
}
