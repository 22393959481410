import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Level } from '../../../shared/models/level';

@Injectable({
  providedIn: 'root'
})
export class LevelService extends ApiService {

  protected prefix(): string {
    return 'levels';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPut(content: Level): Observable<any> {
    return this.put(content);
  }

  doPost(content: Level): Observable<any> {
    return this.post(content);
  }

  doDelete(content: Level): Observable<any> {
    return this.delete(content.id);
  }
}
