import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WeeklyActivityReport } from '../../../../../shared/models/weekly-activity-report';
import { ReportService } from '../../../../../core/services/report-services/report.service';

@Component({
  selector: 'app-summary-rha',
  templateUrl: './summary-rha.component.html',
  styleUrls: ['./summary-rha.component.scss'],
})
export class SummaryRhaComponent implements OnInit {
  public rha: WeeklyActivityReport;
  public userId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reportService: ReportService
  ) {}

  ngOnInit(): void {
    this.userId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.reportService
      .getLastWeeklyReportByUserId(this.userId)
      .subscribe((result) => {
        this.rha = result;
      });
  }

  public handleClickBack(): void {
    this.rha = null;
    this.router.navigateByUrl(`/team/summary/members`);
  }
}
