import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-connect-button',
  templateUrl: './connect-button.component.html',
  styleUrls: ['./connect-button.component.scss'],
})
export class ConnectButtonComponent implements OnInit {
  @Output()
  onClick = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {}
}
