<div class="row">
  <div class="shadow-sm p-3 mb-5 bg-white rounded" style="width: 1000px;">
    <form [formGroup]="selectsformGroup" (submit)="onSubmit()">
      <ng-container formArrayName="selects">
        <ng-container *ngIf="isFormation; else other">
          <div class="row">
            <div class="col-4 text-center font-header">FORMATION</div>
            <div class="col"></div>
          </div>
          <table>
            <ng-container *ngFor="let data of selectsArr.controls; index as i">
              <tr>
                <td>
                  <app-select-item
                    [formControlName]="i"
                    [one-select]="true"
                  ></app-select-item>
                </td>
                <td>
                  <button
                    style="margin-top: -20px;"
                    (click)="removeItem(i)"
                    class="btn btn-secondary btn-sm mt-8"
                  >
                    #
                  </button>
                </td>
                <td>
                  <button
                    [disabled]="selectsArr.invalid"
                    type="submit"
                    style="margin-top: -20px;"
                    class="btn btn-primary btn-sm mt-10"
                  >
                    +
                  </button>
                </td>
              </tr>
            </ng-container>
          </table>
        </ng-container>
        <ng-template #other>
          <div class="row">
            <div class="col-4 text-center font-header">{{ name }}</div>
            <div class="col-2 text-center font-header">INTËRET</div>
            <div class="col-3 text-center font-header">NIVEAU</div>
            <div class="col-2 text-center font-header">VALIDATION MANAGER</div>
            <div class="col"></div>
          </div>
          <table>
            <ng-container *ngFor="let data of selectsArr.controls; index as i">
              <tr>
                <td>
                  <app-select-item [formControlName]="i"></app-select-item>
                </td>
                <td>
                  <button
                    style="margin-top: -20px;"
                    (click)="removeItem(i)"
                    class="btn btn-secondary btn-sm mt-8"
                  >
                    <i class="pi pi-trash"></i>
                  </button>
                </td>
                <td>
                  <button
                    [disabled]="selectsArr.invalid"
                    type="submit"
                    style="margin-top: -20px;"
                    class="btn btn-primary btn-sm mt-10"
                  >
                  <i class="pi pi-check"></i>
                  </button>
                </td>
              </tr>
            </ng-container>
          </table>
        </ng-template>
      </ng-container>
      <button
        (click)="newSelect()"
        class="btn btn-light btn-sm"
        style="border: 1px solid grey;"
      >
        <span style="color: blue;">+</span>
      </button>
    </form>
  </div>
</div>
