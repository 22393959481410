import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { ReportState } from '../../../../../stores/reports/report.state';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationState } from '../../../../../stores/authentication/authentication.state';
import { User } from '../../../models/user';
import { AnnualReport } from '../../../models/annual-report';
import { SetPendingAnnualReport } from '../../../../../stores/reports/report.action';
import { AnnualReportService } from '../../../../core/services/annual-report-service/annual-report.service';
import { FormInlineComponent } from '../../form-inline/form-inline.component';
import { Constants } from '../../../constants/constants';
import { Strength } from '../../../models/strength';
import { Improvement } from '../../../models/improvement';
import { Request } from 'src/app/shared/models/request';
import { UserLight } from 'src/app/shared/models/user-light';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss']
})
export class StepFourComponent implements OnInit, OnDestroy {
  @Select(ReportState.getRole) role$: Observable<string>;
  @Select(AuthenticationState.getAnnualReportUser) annualReportUser$: Observable<User>;
  @Select(ReportState.getAnnualReport) annualReport$: Observable<AnnualReport>;
  @Select(ReportState.getPendingAnnualReport) pendingAnnualReport$: Observable<AnnualReport>;

  @ViewChild(FormInlineComponent)
  private formsAdd: FormInlineComponent;

  public authenticationServiceSubscription: Subscription;
  currentCollaborator: UserLight;
  currentManager: UserLight;

  freeAnswerMaxLength = 3000;
  formData: FormGroup;
  formNameEditorStepFour = 'formNameEditorStepFour';

  skillsStrengthDataColumns: any[];
  skillsStrengthDataColumnsWithoutActions: any[];
  skillsStrengthEmptyMessage = 'Pas de point fort renseigné';
  skillsImprovementDataColumns: any[];
  skillsImprovementDataColumnsWithoutActions: any[];
  skillsCourseRequestDataColumns: any[];
  skillsCourseRequestDataColumnsWithoutActions: any[];
  skillsImprovementEmptyMessage = 'Aucun axe de développement à améliorer';
  skillsCourseRequestEmptyMessage = 'Aucune formation renseignée';

  pendingAnnualReportPreparation: AnnualReport;
  pendingAnnualReport: AnnualReport;
  pendingAnnualReportSubscription: Subscription;

  roleSubscription: Subscription;
  role: string;

  workflowCollaborator: number;
  workflowManager: number;
  isTooLate: boolean;

  collaboratorSkillsStrengthDisableCreationSave = false;
  collaboratorSkillsStrengthShowAddForm = false;
  collaboratorSkillsStrengthInputForm: any = [];
  collaboratorSkillsStrength: Strength[];
  collaboratorSkillsImprovementDisableCreationSave = false;
  collaboratorSkillsImprovementShowAddForm = false;
  collaboratorSkillsImprovementInputForm: any = [];
  collaboratorSkillsImprovement: Improvement[];
  collaboratorSkillsCourseRequestDisableCreationSave = false;
  collaboratorSkillsCourseRequestShowAddForm = false;
  collaboratorSkillsCourseRequestInputForm: any = [];
  collaboratorSkillsCourseRequest: Request[];
  collaboratorSkillsCommentPlaceholder: string;
  collaboratorSkillsComment: string;
  collaboratorSkillsShowActions: boolean;

  managerSkillsStrengthDisableCreationSave = false;
  managerSkillsStrengthShowAddForm = false;
  managerSkillsStrengthInputForm: any = [];
  managerSkillsStrength: Strength[];
  managerSkillsImprovementDisableCreationSave = false;
  managerSkillsImprovementShowAddForm = false;
  managerSkillsImprovementInputForm: any = [];
  managerSkillsImprovement: Improvement[];
  managerSkillsCourseRequestDisableCreationSave = false;
  managerSkillsCourseRequestShowAddForm = false;
  managerSkillsCourseRequestInputForm: any = [];
  managerSkillsCourseRequest: Request[];
  managerRequestCoursesDisableCreationSave = false;
  managerRequestCoursesShowAddForm = false;
  managerRequestCoursesInputForm: any = [];
  managerSkillsCommentPlaceholder: string;
  managerSkillsComment: string;
  managerSkillsShowActions: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private router: Router,
    private annualReportService: AnnualReportService
  ) {
    this.formData = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.roleSubscription = this.role$.subscribe((role) => {
      this.pendingAnnualReportSubscription = this.pendingAnnualReport$.subscribe((pendingAnnualReport: AnnualReport) => {
        if (pendingAnnualReport) {
          this.pendingAnnualReport = pendingAnnualReport;
          // Set skills
          this.setCollaboratorSkillsStrength();
          this.setCollaboratorSkillsImprovement();
          this.setCollaboratorSkillsCourseRequest();

          this.setManagerSkillsStrength();
          this.setManagerSkillsImprovement();
          this.setManagerSkillsCourseRequest();


          if (role === 'collaborator') {
            this.initCollaboratorForm();
            this.collaboratorSkillsShowActions = true;
            this.managerSkillsShowActions = false;
          } else if (role === 'currentInterviewer') {
            this.initInterviewerForm();
            this.collaboratorSkillsShowActions = false;
            this.managerSkillsShowActions = true;
          }

          // Fill input form fields
          this.fillInputWithAnnualReport(pendingAnnualReport);

          this.workflowCollaborator = pendingAnnualReport.workflowCollaborator;
          this.isTooLate = this.annualReportService.isTooLate(pendingAnnualReport.interviewStartDate);
          this.workflowManager = pendingAnnualReport.workflowManager;
          this.initDatatableSkillsStrength();
          this.initDatatableSkillsStrengthWithoutActions();
          this.initDatatableSkillsImprovement();
          this.initDatatableSkillsImprovementWithoutActions();
          this.initDatatableSkillsCourseRequest();
          this.initDatatableSkillsCourseRequestWithoutActions();

        } else {
          // To hook refresh web browser action
          this.router.url.includes('activity') ?
            this.router.navigateByUrl('/activity/reports') :
            this.router.navigateByUrl('/team/reports');
        }
      });
      this.role = role;
    });


    this.collaboratorSkillsCommentPlaceholder = 'Commentaire du collaborateur (' + this.freeAnswerMaxLength + ' caractères max)...';
    this.managerSkillsCommentPlaceholder = 'Commentaire du manager (' + this.freeAnswerMaxLength + ' caractères max)...';
  }

  initDatatableSkillsStrength() {
    this.skillsStrengthDataColumns = [
      {
        type: 'text',
        field: 'name',
        label: 'Compétences métiers et comportementales',
        font: 'regular',
        sort: false,
      },
      { type: 'action', action: 'delete', label: 'Supprimer', width: '10%' },
    ];
  }

  initDatatableSkillsStrengthWithoutActions() {
    this.skillsStrengthDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Compétences métiers et comportementales',
        font: 'regular',
        sort: false,
      }
    ];
  }

  initDatatableSkillsImprovement() {
    this.skillsImprovementDataColumns = [
      {
        type: 'text',
        field: 'name',
        label: 'Compétences métiers et comportementales',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
      { type: 'action', action: 'delete', label: 'Supprimer', width: '10%' },
    ];
  }

  initDatatableSkillsImprovementWithoutActions() {
    this.skillsImprovementDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Compétences métiers et comportementales',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: '',
        label: '',
        font: 'regular',
        sort: false,
        colSize: 'fm-cell-delete'
      },
    ];
  }

  initDatatableSkillsCourseRequestWithoutActions() {
    this.skillsCourseRequestDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Nom de formation',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'description',
        label: 'Description',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: '',
        label: '',
        font: 'regular',
        sort: false,
        colSize: 'fm-cell-delete'
      },
    ];
  }

  initDatatableSkillsCourseRequest() {
    this.skillsCourseRequestDataColumns = [
      {
        type: 'text',
        field: 'name',
        label: 'Nom de formation',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'description',
        label: 'Description',
        font: 'regular',
        sort: false,
      },
      { type: 'action', action: 'delete', label: 'Supprimer', width: '10%' },
    ];
  }

  fillInputWithAnnualReport(annualReport: AnnualReport) {
    if (annualReport) {
      this.collaboratorSkillsComment = annualReport.collaboratorSkillsComment;
      this.managerSkillsComment = annualReport.managerSkillsComment;
    }
  }

  handleChange(event: any) {
    if (event && this.role === 'collaborator') {
      this.pendingAnnualReport.collaboratorSkillsComment = this.formData.value.collaboratorSkillsComment;
    }
    if (event && this.role === 'currentInterviewer') {
      this.pendingAnnualReport.managerSkillsComment = this.formData.value.managerSkillsComment;
    }
    this.store.dispatch(new SetPendingAnnualReport(this.pendingAnnualReport));
  }

  /********** Collaborator **************/
  initCollaboratorForm() {
    this.initCollaboratorSkillsStrengthForm();
    this.initCollaboratorSkillsImprovementForm();
    this.initCollaboratorSkillsCourseRequestForm();
    this.formData.addControl('collaboratorSkillsComment', new FormControl(null));
  }

  initCollaboratorSkillsStrengthForm() {
    this.collaboratorSkillsStrengthInputForm = [
      {
        key: 'nameGoal',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Compétence métier et comportementale',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
    ];
  }

  initCollaboratorSkillsImprovementForm() {
    this.collaboratorSkillsImprovementInputForm = [
      {
        key: 'nameGoal',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Compétence métier et comportementale',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'deadLineGoal',
        typeForm: 'text',
        fieldForm: 'deadLine',
        labelForm: 'Délais',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'waysGoal',
        typeForm: 'text',
        fieldForm: 'ways',
        labelForm: 'Moyens',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
    ];
  }

  initCollaboratorSkillsCourseRequestForm() {
    this.collaboratorSkillsCourseRequestInputForm = [
      {
        key: 'nameRequestCourse',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Formation',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'descriptionRequestCourse',
        typeForm: 'text',
        fieldForm: 'description',
        labelForm: 'Description',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      }
    ];
  }


  setCollaboratorSkillsStrength() {
    this.collaboratorSkillsStrength = [];
    const collaboratorSkillsStrength = this.pendingAnnualReport.strengths.filter(item => item.emitter === Constants.REQUEST_EMITTER_TYPE.collaborator);
    collaboratorSkillsStrength.forEach(item => {
      const strength = { ...item };
      this.collaboratorSkillsStrength.push(strength);
    });
  }

  setCollaboratorSkillsImprovement() {
    this.collaboratorSkillsImprovement = [];
    const collaboratorSkillsImprovements = this.pendingAnnualReport.improvements.filter(goal => goal.emitter === Constants.REQUEST_EMITTER_TYPE.collaborator);
    collaboratorSkillsImprovements.forEach(item => {
      const improvement = { ...item };
      this.collaboratorSkillsImprovement.push(improvement);
    });
  }

  setCollaboratorSkillsCourseRequest() {
    this.collaboratorSkillsCourseRequest = [];
    const collaboratorSkillsCourseRequests = this.pendingAnnualReport.courseRequests.filter(item => item.emitter === Constants.REQUEST_EMITTER_TYPE.collaborator);
    collaboratorSkillsCourseRequests.forEach(item => {
      const course = { ...item };
      this.collaboratorSkillsCourseRequest.push(course);
    });
  }

  toggleCollaboratorSkillsStrengthForm() {
    this.collaboratorSkillsStrengthShowAddForm = !this.collaboratorSkillsStrengthShowAddForm;
  }

  toggleCollaboratorSkillsImprovementForm() {
    this.collaboratorSkillsImprovementShowAddForm = !this.collaboratorSkillsImprovementShowAddForm;
  }

  toggleCollaboratorSkillsCourseRequestForm() {
    this.collaboratorSkillsCourseRequestShowAddForm = !this.collaboratorSkillsCourseRequestShowAddForm;
  }

  handleCollaboratorSkillsStrengthCreateItem(strength: Strength) {
    if (strength && strength.name) {
      strength.reportId = this.pendingAnnualReport.id;
      strength.emitter = Constants.REQUEST_EMITTER_TYPE.collaborator;
      this.pendingAnnualReport.strengths = [...this.pendingAnnualReport.strengths, strength];
      this.setCollaboratorSkillsStrength();
    }
  }

  handleCollaboratorSkillsImprovementCreateItem(improvement: Improvement) {
    if (improvement && improvement.name && improvement.deadLine && improvement.ways) {
      improvement.reportId = this.pendingAnnualReport.id;
      improvement.emitter = Constants.REQUEST_EMITTER_TYPE.collaborator;
      this.pendingAnnualReport.improvements = [...this.pendingAnnualReport.improvements, improvement];
      this.setCollaboratorSkillsImprovement();
    }
  }

  handleCollaboratorSkillsCourseRequestCreateItem(courseRequest: Request) {
    if (courseRequest && courseRequest.name && courseRequest.description) {
      courseRequest.reportId = this.pendingAnnualReport.id;
      courseRequest.emitter = Constants.REQUEST_EMITTER_TYPE.collaborator;
      this.pendingAnnualReport.courseRequests = [...this.pendingAnnualReport.courseRequests, courseRequest];
      this.setCollaboratorSkillsCourseRequest();
    }
  }

  handleCollaboratorSkillsStrengthDeleteItem(event) {
    let index = null;
    if (typeof event === 'number') {
      index = this.pendingAnnualReport.strengths.findIndex(s => s.id === event);
    } else if (typeof event === 'object') {
      index = this.pendingAnnualReport.strengths.findIndex(s => s.name === event.name && s.emitter === Constants.REQUEST_EMITTER_TYPE.collaborator);
    }
    if (index >= 0) {
      this.pendingAnnualReport.strengths.splice(index, 1);
      this.setCollaboratorSkillsStrength();
    }
  }

  handleCollaboratorSkillsImprovementDeleteItem(event) {
    let index = null;
    if (typeof event === 'number') {
      index = this.pendingAnnualReport.improvements.findIndex(i => i.id === event);
    } else if (typeof event === 'object') {
      index = this.pendingAnnualReport.improvements.findIndex(i => i.name === event.name && i.emitter === Constants.REQUEST_EMITTER_TYPE.collaborator);
    }
    if (index >= 0) {
      this.pendingAnnualReport.improvements.splice(index, 1);
      this.setCollaboratorSkillsImprovement();
    }
  }

  handleCollaboratorSkillsCourseRequestDeleteItem(event) {
    let index = null;
    if (typeof event === 'number') {
      index = this.pendingAnnualReport.courseRequests.findIndex(i => i.id === event);
    } else if (typeof event === 'object') {
      index = this.pendingAnnualReport.courseRequests.findIndex(i => i.name === event.name && i.emitter === Constants.REQUEST_EMITTER_TYPE.collaborator);
    }
    if (index >= 0) {
      this.pendingAnnualReport.courseRequests.splice(index, 1);
      this.setCollaboratorSkillsCourseRequest();
    }
  }

  /********** Manager **************/
  initInterviewerForm() {
    this.initManagerSkillsStrengthForm();
    this.initManagerSkillsImprovementForm();
    this.initManagerSkillsCourseRequestForm();
    this.formData.addControl('managerSkillsComment', new FormControl(null, Validators.required));
  }

  initManagerSkillsStrengthForm() {
    this.managerSkillsStrengthInputForm = [
      {
        key: 'nameStrength',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Compétences métiers et comportementales',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
    ];
  }

  initManagerSkillsImprovementForm() {
    this.managerSkillsImprovementInputForm = [
      {
        key: 'nameImprovement',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Compétences métiers et comportementales',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'deadLineImprovement',
        typeForm: 'text',
        fieldForm: 'deadLine',
        labelForm: 'Délais',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'waysImprovement',
        typeForm: 'text',
        fieldForm: 'ways',
        labelForm: 'Moyens',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
    ];
  }

  initManagerSkillsCourseRequestForm() {
    this.managerSkillsCourseRequestInputForm = [
      {
        key: 'nameRequestCourse',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Formation',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'descriptionRequestCourse',
        typeForm: 'text',
        fieldForm: 'description',
        labelForm: 'Description',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      }
    ];
  }

  setManagerSkillsStrength() {
    this.managerSkillsStrength = [];
    const managerSkillsStrength = this.pendingAnnualReport.strengths.filter(item => item.emitter === Constants.REQUEST_EMITTER_TYPE.manager);
    managerSkillsStrength.forEach(item => {
      const goal = { ...item };
      this.managerSkillsStrength.push(goal);
    });
  }

  setManagerSkillsImprovement() {
    this.managerSkillsImprovement = [];
    const managerSkillsImprovement = this.pendingAnnualReport.improvements.filter(item => item.emitter === Constants.REQUEST_EMITTER_TYPE.manager);
    managerSkillsImprovement.forEach(item => {
      const goal = { ...item };
      this.managerSkillsImprovement.push(goal);
    });
  }

  setManagerSkillsCourseRequest() {
    this.managerSkillsCourseRequest = [];
    const managerSkillsCourseRequest = this.pendingAnnualReport.courseRequests.filter(item => item.emitter === Constants.REQUEST_EMITTER_TYPE.manager);
    managerSkillsCourseRequest.forEach(item => {
      const course = { ...item };
      this.managerSkillsCourseRequest.push(course);
    });
  }

  toggleManagerSkillsStrengthForm() {
    this.managerSkillsStrengthShowAddForm = !this.managerSkillsStrengthShowAddForm;
  }

  toggleManagerSkillsImprovementForm() {
    this.managerSkillsImprovementShowAddForm = !this.managerSkillsImprovementShowAddForm;
  }

  toggleManagerSkillsCourseRequestForm() {
    this.managerSkillsCourseRequestShowAddForm = !this.managerSkillsCourseRequestShowAddForm;
  }

  handleManagerSkillsStrengthCreateItem(strength: Strength) {
    if (strength && strength.name) {
      strength.reportId = this.pendingAnnualReport.id;
      strength.emitter = Constants.REQUEST_EMITTER_TYPE.manager;
      this.pendingAnnualReport.strengths = [...this.pendingAnnualReport.strengths, strength];
      this.setManagerSkillsStrength();
    }
  }

  handleManagerSkillsImprovementCreateItem(improvement: Improvement) {
    if (improvement && improvement.name && improvement.deadLine && improvement.ways) {
      improvement.reportId = this.pendingAnnualReport.id;
      improvement.emitter = Constants.REQUEST_EMITTER_TYPE.manager;
      this.pendingAnnualReport.improvements = [...this.pendingAnnualReport.improvements, improvement];
      this.setManagerSkillsImprovement();
    }
  }

  handleManagerSkillsCourseRequestCreateItem(course: Request) {
    if (course && course.name && course.description) {
      course.reportId = this.pendingAnnualReport.id;
      course.emitter = Constants.REQUEST_EMITTER_TYPE.manager;
      this.pendingAnnualReport.courseRequests = [...this.pendingAnnualReport.courseRequests, course];
      this.setManagerSkillsCourseRequest();
    }
  }

  handleManagerSkillsStrengthDeleteItem(event) {
    let index = null;
    if (typeof event === 'number') {
      index = this.pendingAnnualReport.strengths.findIndex(s => s.id === event);
    } else if (typeof event === 'object') {
      index = this.pendingAnnualReport.strengths.findIndex(s => s.name === event.name && s.emitter === Constants.REQUEST_EMITTER_TYPE.manager);
    }
    if (index >= 0) {
      this.pendingAnnualReport.strengths.splice(index, 1);
      this.setManagerSkillsStrength();
    }
  }

  handleManagerSkillsImprovementDeleteItem(event) {
    let index = null;
    if (typeof event === 'number') {
      index = this.pendingAnnualReport.improvements.findIndex(i => i.id === event);
    } else if (typeof event === 'object') {
      index = this.pendingAnnualReport.improvements.findIndex(i => i.name === event.name && i.emitter === Constants.REQUEST_EMITTER_TYPE.manager);
    }
    if (index >= 0) {
      this.pendingAnnualReport.improvements.splice(index, 1);
      this.setManagerSkillsImprovement();
    }
  }

  handleManagerSkillsCourseRequestDeleteItem(event) {
    let index = null;
    if (typeof event === 'number') {
      index = this.pendingAnnualReport.courseRequests.findIndex(i => i.id === event);
    } else if (typeof event === 'object') {
      index = this.pendingAnnualReport.courseRequests.findIndex(i => i.name === event.name && i.emitter === Constants.REQUEST_EMITTER_TYPE.manager);
    }
    if (index >= 0) {
      this.pendingAnnualReport.courseRequests.splice(index, 1);
      this.setManagerSkillsCourseRequest();
    }
  }

  ngOnDestroy(): void {
    this.roleSubscription.unsubscribe();
    this.pendingAnnualReportSubscription.unsubscribe();
  }
}
