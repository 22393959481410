import {ManagerRequest} from '../../app/shared/models/manager-request';

export class AddManagerRequest {
  static readonly type = '[Manager Request] Add';
  constructor(public payload: ManagerRequest) {}
}

export class GetManagerRequest {
  static readonly type = '[Manager Request] GetManagerRequest';
}

export class GetAllManagerRequests {
  static readonly type = '[Manager Request] GetAllManagerRequests';
}

export class UpdateManagerRequest {
  static readonly type = '[Manager Request] Update';
  constructor(public payload: ManagerRequest) {}
}

export class FilterManagerRequest {
  static readonly type = '[Manager Request] Filter';
  constructor(public payload: string) {}
}

export class DeleteManagerRequest {
  static readonly type = '[Manager Request] Delete';
  constructor(public payload: ManagerRequest) {}
}
