import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {TeamStateModel} from '../../../../store/team.state';
import {TeamProfileState} from '../../../../store/team-profiles.state';
import {TeamSkillState, TeamSkillStateModel} from '../../../../store/team-skills.state';
import {
  AddUserSkill,
  ClearUserSkills,
  DeleteUserSkill,
  GetUserSkillsByUserId,
  MakeSnapshotUserSkill,
  RestoreSnapshotUserSkill,
  UpdateUserSkill
} from '../../../../store/team-skills.actions';
import {MessageService, SelectItem} from 'primeng/api';
import {SkillService} from '../../../../../../core/services/skill-service/skill.service';
import {ProfileService} from '../../../../../../core/services/profile-services/profile.service';
import {FormInlineComponent} from '../../../../../../shared/components/form-inline/form-inline.component';
import {UserSkill} from '../../../../../../shared/models/user-skill';
import {Skill} from '../../../../../../shared/models/skill';
import {Interest} from '../../../../../../shared/models/interest';
import {Level} from '../../../../../../shared/models/level';
import {UserProfile} from '../../../../../../shared/models/user-profile';

@Component({
  selector: 'app-view-skills',
  templateUrl: './view-skills.component.html',
  styleUrls: ['./view-skills.component.scss']
})
export class ViewSkillsComponent implements OnInit, OnChanges, OnDestroy {
  @Select(TeamSkillState.getUserSkills)
  public userSkills$: Observable<UserSkill[]>;
  @Input()
  public isMainManager = false;
  @Input()
  public displayOnlyQualificationsNotValidated = false;
  public modal: any = {};
  public showModal = false;
  public inputForm: any = [];
  public showAddForm: boolean;
  public disableCreationSave = false;
  public tableColumns: any[];
  public emptyMessage = 'Aucune compétence n\'est enregistée pour le ou les profil(s) attribué(s)';

  @ViewChild(FormInlineComponent)
  private formUserProfile: FormInlineComponent;
  @Input()
  private interests: Interest[] = [];
  @Input()
  private levels: Level[] = [];
  @Input()
  private interestsOptions: SelectItem[] = [];
  @Input()
  private levelsOptions: SelectItem[] = [];
  private skills: Skill[]=[];

  private teamState: TeamStateModel;
  private userSkillState: TeamSkillStateModel;
  private itemToDelete: UserSkill;
  private defaultInterestsOptions: SelectItem[] = [];
  private defaultLevelsOptions: SelectItem[] = [];

  public constructor(
    private messageService: MessageService,
    private skillService: SkillService,
    private store: Store,
  ) {
  }

  public ngOnInit(): void {
    this.teamState = this.store.snapshot().team;
    this.store.dispatch(new GetUserSkillsByUserId(this.teamState.currentTeamUser.id));

    this.interestsOptions.map(
      item => this.defaultInterestsOptions.push(item)
    );
    this.levelsOptions.map(
      item => this.defaultLevelsOptions.push(item)
    );

    this.skillService.getAll().subscribe( {
      next: (skill) => {
      skill.forEach(element => {
        if(element.profile != null){
          element.profile = {id: element.profile.id, name: element.profile.name};
        }else{
          element.profile = {id: null, name: ""};
        }
        this.skills.push({...element});
      })},
        error: (e) => this.displayError(e.status),
    });

    this.initDatatable();
    this.initForm();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
    this.initDatatable();
  }

  public toggleShowAddForm(): void {
    this.showAddForm = !this.showAddForm;
  }

  public handleCreateItem(userSkill): void {
    if (!this.errorsValidation(userSkill, 'create')) {
      userSkill.user = this.teamState.currentTeamUser;
      userSkill.validation = userSkill.validation?.value || null;
      this.store.dispatch(new AddUserSkill(userSkill)).subscribe(
        () => {
          this.displayMessage('create');
          this.formUserProfile.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  public handleDeleteItem(id: number): void {
    this.userSkillState = this.store.snapshot().userSkills;
    this.itemToDelete = this.userSkillState.userSkills.find((item) => item.id === id);
    this.displayModal('delete');
  }

  public handleUpdateItem(userSkill: UserSkill): void {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotUserSkill());

    this.resetInterestsOptions();
    this.resetLevelsOptions();
    this.setInterestOptions(userSkill);
    this.setLevelOptions(userSkill);
  }

  public handleSaveUpdateItem(userSkill: UserSkill): void {
    if (!this.errorsValidation(userSkill, 'update')) {
      this.store.dispatch(new UpdateUserSkill(userSkill)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.disableCreationSave = false;
        }
      );
    }
  }

  public handleCancelEditItem(): void {
    this.disableCreationSave = false;
    this.store.dispatch(new RestoreSnapshotUserSkill());
  }

  public handleModalConfirm(): void {
    this.deleteItem();
  }

  public ngOnDestroy(): void {
    this.resetLevelsOptions();
    this.resetInterestsOptions();
    this.store.dispatch(new ClearUserSkills());
  }

  private initDatatable(): void {
    this.tableColumns = [
      {
        type: 'image',
        field: 'skill',
        subField: 'image',
        label: '',
        width: '40px',
        isStatic: true,
        sort: false
      },
      {
        type: 'select',
        field: 'skill',
        subField: 'name',
        label: 'Compétence',
        isStatic: true,
        font: 'semibold',
        sort: false,
      },
      {
        type: 'select',
        field: 'interest',
        subField: 'name',
        label: 'Intérêt',
        icon: 'interest',
        options: this.interestsOptions,
        optionIcon: true,
        optionOnlyIcon: true,
        clearable: true,
        sort: false,
        isStatic: true,
      },
      {
        type: 'select',
        field: 'level',
        subField: 'value',
        subFieldLabel: 'name',
        label: 'Niveau',
        icon: 'level',
        options: this.levelsOptions,
        optionIcon: true,
        font: 'regular',
        sort: false
      },
      {
        type: 'validation',
        action: 'validation',
        label: 'Validation',
        field: 'validation',
        width: '8%',
        isStatic: this.isMainManager === false
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: false
      },
      {type: 'action', action: 'edit', width: '8%'},
      {type: 'action', action: 'delete', width: '10%'},
    ];
  }

  private initForm(): void {
    this.inputForm = [
      {
        key: 'nameSkill',
        typeForm: 'dropdown',
        filter: true,
        fieldForm: 'skill',
        labelForm: 'Compétence',
        requiredForm: true,
        placeHolder: 'Compétence',
        optionsForm: this.skills,
        optionConcat: 'profile'
      },
      {
        key: 'nameLevel',
        typeForm: 'dropdown',
        fieldForm: 'level',
        labelForm: 'Niveau',
        requiredForm: true,
        optionsForm: this.levels,
        placeHolder: 'Niveau',
        icon: true
      },
    ];
    if (this.isMainManager) {
      this.inputForm.push({
        key: 'nameValidation',
        typeForm: 'dropdown',
        fieldForm: 'validation',
        labelForm: 'Validation manager',
        optionsForm: [
          {name: 'Oui', value: 1},
          {name: 'Non', value: 0},
        ],
        placeHolder: 'Validation manager',
      });
    }
  }

  private setInterestOptions(userSkill: UserSkill): void {
    // Interest options
    const indexInterest = this.interests.findIndex(item => item.id === userSkill.interest?.id);
    if (indexInterest === -1) {
      // Put blank interest
      this.interestsOptions.unshift({label: null, value: null, icon: null});
    } else {
      const iconNameInterest = this.interestsOptions[indexInterest].icon;
      // Delete current Interest value of the interests options with indexInterest help
      this.interestsOptions.splice(indexInterest, 1);
      // Put current Interest value at first Interests options place
      this.interestsOptions.unshift({
        label: userSkill.interest?.name,
        value: userSkill.interest,
        icon: iconNameInterest
      });
    }
  }

  private setLevelOptions(userSkill: UserSkill): void {
    // Level options
    const indexLevel = this.levels.findIndex(item => item.id === userSkill.level?.id);
    // Get icon's name
    const iconNameLevel = this.levelsOptions[indexLevel].icon;
    // Delete current Level value of the levels options with indexLevel help
    this.levelsOptions.splice(indexLevel, 1);
    // Put current Level value at first Levels options place
    this.levelsOptions.unshift({label: userSkill.level.name, value: userSkill.level, icon: iconNameLevel});
  }

  private resetInterestsOptions(): void {
    const length = this.interestsOptions.length;
    this.interestsOptions.splice(0, length);
    this.defaultInterestsOptions.map(
      item => this.interestsOptions.push(item)
    );
  }

  private resetLevelsOptions(): void {
    const length = this.levelsOptions.length;
    this.levelsOptions.splice(0, length);
    this.defaultLevelsOptions.map(
      item => this.levelsOptions.push(item)
    );
  }

  private deleteItem(): void {
    this.store.dispatch(new DeleteUserSkill(this.itemToDelete)).subscribe(
      () => this.displayMessage('delete'),
      (e) => this.displayError(e.status)
    );
  }

  /**
   * Forms validation (create and update)
   * @param userSkill UserSkill
   * @param action string
   * @return boolean
   */
  private errorsValidation(userSkill: UserSkill, action: string): boolean {
    let errors = false;
    if (!userSkill.skill) {
      this.displayError('skill');
      errors = true;
    }
    if (!userSkill.level) {
      this.displayError('level');
      errors = true;
    }
    return errors;
  }

  private displayModal(type: string): void {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  private displayMessage(type: string): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La compétence a bien été sauvegardée';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La compétence a bien été sauvegardée';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'Compétence supprimée.';
        break;
      }
    }
    this.messageService.add({severity, summary, detail});
  }

  private displayError(e: any): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Cette compétence est déjà attribuée à cet utilisateur.';
        break;
      case 'skill':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner une compétence.';
        break;
      case 'level':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un niveau.';
        break;
      case 501:
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }
}
