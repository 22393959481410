import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import {User} from '../../../shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends ApiService {

  protected prefix(): string {
    return 'teams';
  }

  getMembers(): Observable<any> {
    return this.get('/members');
  }

  getMember(memberId: number): Observable<any> {
    return this.get('/members/' + memberId);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(content: any): Observable<any> {
    content.id = content.teamId;
    return this.put(content);
  }

  doDelete(memberId: number): Observable<any> {
    return this.delete('/delete/' + memberId);
  }

  isMainManager(currentManager: User, selectedUser: User): boolean {
    const team = selectedUser.mainTeam;

    return team !== null &&
      currentManager !== null &&
      team.mainManager &&
      team.manager.id === currentManager.id;
  }

  getMinds(memberId: number): Observable<boolean> {
    return this.get('/minds/' + memberId);
  }

}
