<div *ngIf="user$ | async as user">
  <div class="fm-top-card">
    <app-person-card [user]="user" [module]="module" light="true"></app-person-card>
  </div>

  <div class="fm-buttons">
    <app-fm-button type="text-gradient" btnText="RETOUR" (click)="handleBack()"></app-fm-button>
  </div>
  <!--  TODO: display-soft-skills-->
  <app-fm-profile [user]="user" [displaySoftSkills]="false"></app-fm-profile>

  <div class="fm-title">
    <h2>Missions</h2>
  </div>
  <app-datatable
    [attr.data-testid]="'directory_dataTable_interviews'"
    [data]="assignment$ | async"
    [columns]="directoryProfileDataColumns"
    [emptyMessage]="emptyMessageInterview"
    [rowNumber]="3"
    defaultSortField="date"
    defaultSortOrder="-1">
  </app-datatable>

</div>
