import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { ReportState } from '../../../../../stores/reports/report.state';
import { Observable, Subscription } from 'rxjs';
import { AnnualReport } from '../../../models/annual-report';
import { AuthenticationState } from '../../../../../stores/authentication/authentication.state';
import { User } from '../../../models/user';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SetPendingAnnualReport } from '../../../../../stores/reports/report.action';
import { AnnualReportService } from '../../../../core/services/annual-report-service/annual-report.service';
import { GoalLevel } from '../../../models/goal-level';
import { Goal } from '../../../models/goal';
import { Strength } from '../../../models/strength';
import { Improvement } from '../../../models/improvement';
import { SelectItem } from 'primeng/api';
import { Request } from 'src/app/shared/models/request';

@Component({
  selector: 'app-step-seven',
  templateUrl: './step-seven.component.html',
  styleUrls: ['./step-seven.component.scss']
})
export class StepSevenComponent implements OnInit, OnDestroy {
  @Select(ReportState.getRole) role$: Observable<string>;
  @Select(AuthenticationState.getAnnualReportUser) annualReportUser$: Observable<User>;
  @Select(ReportState.getPendingAnnualReport) pendingAnnualReport$: Observable<AnnualReport>;

  annualReportUrl: string;

  emptyFieldManager = 'Le manager n\'a pas encore renseigné cette section';
  emptyFieldCollaborator = 'Le collaborateur n\'a pas encore renseigné cette section';

  currentGoalDataColumnsWithoutActions: any[];
  nextGoalDataColumnsWithoutActions: any[];
  goalsEmptyMessage = 'Pas d\'objectif renseigné';
  skillsStrengthDataColumnsWithoutActions: any[];
  skillsStrengthEmptyMessage = 'Pas de point fort renseigné';
  skillsImprovementDataColumnsWithoutActions: any[];
  skillsImprovementEmptyMessage = 'Aucun axe de développement à améliorer';
  skillsCourseRequestDataColumnsWithoutActions: any[];
  skillsCourseRequestEmptyMessage = 'Aucune formation à déclarer';
  levelOptions: SelectItem[] = [
    { label: GoalLevel.GOAL_LEVEL_NOT_EVALUABLE, value: { name: GoalLevel.GOAL_LEVEL_NOT_EVALUABLE, value: 'GOAL_LEVEL_NOT_EVALUABLE' } },
    { label: GoalLevel.GOAL_LEVEL_NOT_REACHED, value: { name: GoalLevel.GOAL_LEVEL_NOT_REACHED, value: 'GOAL_LEVEL_NOT_REACHED' } },
    { label: GoalLevel.GOAL_LEVEL_REACHED, value: { name: GoalLevel.GOAL_LEVEL_REACHED, value: 'GOAL_LEVEL_REACHED' } },
    { label: GoalLevel.GOAL_LEVEL_EXCEEDED, value: { name: GoalLevel.GOAL_LEVEL_EXCEEDED, value: 'GOAL_LEVEL_EXCEEDED' } }
  ];

  formData: FormGroup;
  cssSwitchValidated: any;
  cssSwitchUnValidated: any;
  freeAnswerMaxLength = 5000;
  placeholder = 'Raisons pour lesquels vous ne validez pas la synthèse en l\'état';
  reportId: number;
  todayDate = new Date();
  trueSwitch = true;
  interviewStartDate: string;
  collaboratorName: string;
  collaboratorFirstname: string;
  companySeniority: string;
  position: string;
  positionSeniority: string;
  managerName: string;
  managerFirstname: string;
  collaboratorAssessment: string;
  collaboratorCurrentAnnualGoal: string;
  collaboratorNextAnnualGoal: string;
  collaboratorGoalComment: string;
  collaboratorSkillsImprovements: string;
  collaboratorSkillsComment: string;
  collaboratorSalaryComment: string;
  collaboratorFinalComment: string;
  collaboratorValidationStatus: boolean;
  collaboratorValidationComment: string;
  collaboratorValidationDate: string;
  collaboratorCurrentGoals: Goal[];
  collaboratorNextGoals: Goal[] = [];
  collaboratorSkillsStrength: Strength[];
  collaboratorSkillsImprovement: Improvement[];
  collaboratorSkillsCourseRequest: Request[];

  managerValidationDate: string;
  managerAssessment: string;
  managerNextAnnualGoal: string;
  managerCurrentAnnualGoal: string;
  managerGoalComment: string;
  managerSkillsImprovements: string;
  managerSkillsComment: string;
  managerSalaryComment: string;
  managerFinalComment: string;
  managerCurrentGoals: Goal[];
  managerNextGoals: Goal[] = [];
  managerSkillsStrength: Strength[];
  managerSkillsImprovement: Improvement[];
  managerSkillsCourseRequest: Request[];

  role: string;
  pendingAnnualReportPreparation: AnnualReport;
  pendingAnnualReport: AnnualReport;
  pendingAnnualReportSubscription: Subscription;
  roleSubscription: Subscription;
  annualReportUserSubscription: Subscription;
  subscription: Subscription;

  workflowCollaborator: number;
  workflowManager: number;
  isTooLate: boolean;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store,
    private annualReportService: AnnualReportService
  ) {
    this.formData = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.roleSubscription = this.role$.subscribe((role) => {
      this.pendingAnnualReportSubscription = this.pendingAnnualReport$.subscribe((pendingAnnualReport: AnnualReport) => {
        if (pendingAnnualReport) {
          this.pendingAnnualReport = pendingAnnualReport;

          // Set goals
          this.setCollaboratorCurrentGoals();
          this.setCollaboratorNextGoals();
          this.setManagerCurrentGoals();
          this.setManagerNextGoals();

          // Set skills
          this.setCollaboratorSkillsStrength();
          this.setCollaboratorSkillsImprovement();
          this.setCollaboratorSkillsCourseRequest();
          this.setManagerSkillsStrength();
          this.setManagerSkillsImprovement();
          this.setManagerSkillsCourseRequest();


          this.pendingAnnualReport = pendingAnnualReport;
          this.isTooLate = this.annualReportService.isTooLate(pendingAnnualReport.interviewStartDate);
          this.workflowCollaborator = pendingAnnualReport.workflowCollaborator;
          this.workflowManager = pendingAnnualReport.workflowManager;
          this.fillInputWithAnnualReport(pendingAnnualReport);
          this.collaboratorValidationDate = pendingAnnualReport.collaboratorValidationDate;

          this.initDatatablesCurrentGoalWithoutActions();
          this.initDatatablesNextGoalWithoutActions();
          this.initDatatableSkillsStrengthWithoutActions();
          this.initDatatableSkillsImprovementWithoutActions();
          this.initDatatableSkillsCourseREquestWithoutActions();

        }
        else {
          // To hook refresh web browser action
          this.router.url.includes('activity') ?
            this.router.navigateByUrl('/activity/reports') :
            this.router.navigateByUrl('/team/reports');
        }
      });

      if (role === 'collaborator') {
        this.annualReportUrl = '/activity/reports/annualreports/';
        this.initCollaboratorForm();
      } else if (role === 'currentInterviewer') {
        this.annualReportUrl = '/team/reports/annualreports/';
        this.initInterviewerForm();
      }
      this.role = role;
    });
  }

  ngOnDestroy() {
    this.roleSubscription.unsubscribe();
    this.pendingAnnualReportSubscription.unsubscribe();
  }

  initDatatablesCurrentGoalWithoutActions() {
    this.currentGoalDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Objectifs',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
      {
        type: 'select',
        options: this.levelOptions,
        field: 'level',
        subField: 'name',
        label: 'Niveaux d\'atteinte',
        font: 'regular',
        sort: false,
      },
    ];
  }

  initDatatablesNextGoalWithoutActions() {
    this.nextGoalDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Objectifs',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: '',
        label: '',
        font: 'regular',
        sort: false,
      },
    ];
  }

  initDatatableSkillsStrengthWithoutActions() {
    this.skillsStrengthDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Compétences métiers et comportementales',
        font: 'regular',
        sort: false,
      }
    ];
  }

  initDatatableSkillsImprovementWithoutActions() {
    this.skillsImprovementDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Compétences métiers et comportementales',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
    ];
  }


  initDatatableSkillsCourseREquestWithoutActions() {
    this.skillsCourseRequestDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Nom de formation',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'description',
        label: 'Description',
        font: 'regular',
        sort: false,
      },
    ];
  }

  initCollaboratorForm() {
    this.formData.addControl('collaboratorValidationStatus', new FormControl(null, Validators.required));
    this.formData.addControl('collaboratorValidationComment', new FormControl(null, Validators.required));
  }

  initInterviewerForm() {
    this.formData.addControl('collaboratorValidationStatus', new FormControl(null, Validators.required));
  }

  fillInputWithAnnualReport(annualReport: AnnualReport) {
    if (annualReport) {
      this.reportId = annualReport.id;
      this.interviewStartDate = annualReport.interviewStartDate;
      this.collaboratorName = annualReport.collaborator?.lastname || 'Non renseigné';
      this.collaboratorFirstname = annualReport.collaborator?.firstname || 'Non renseigné';
      this.companySeniority = annualReport.companySeniority;
      this.position = annualReport.position;
      this.positionSeniority = annualReport.positionSeniority;
      this.managerName = annualReport.interviewer?.lastname || 'Non renseigné';
      this.managerFirstname = annualReport.interviewer?.firstname || 'Non renseigné';
      this.collaboratorAssessment = annualReport.collaboratorAssessment;
      this.collaboratorCurrentAnnualGoal = annualReport.collaboratorCurrentAnnualGoal;
      this.collaboratorNextAnnualGoal = annualReport.collaboratorNextAnnualGoal;
      this.collaboratorGoalComment = annualReport.collaboratorGoalComment;
      this.collaboratorSkillsImprovements = annualReport.collaboratorSkillsImprovements;
      this.collaboratorSkillsComment = annualReport.collaboratorSkillsComment;
      this.collaboratorSalaryComment = annualReport.collaboratorSalaryComment;
      this.collaboratorFinalComment = annualReport.collaboratorFinalComment;
      this.collaboratorValidationStatus = !!annualReport.collaboratorValidationStatus;
      this.cssSwitchUnValidated = this.getSwitchStyle(annualReport.collaboratorValidationStatus, 'unValidated');
      this.cssSwitchValidated = this.getSwitchStyle(annualReport.collaboratorValidationStatus, 'validated');
      this.collaboratorValidationComment = annualReport.collaboratorValidationComment;

      this.managerValidationDate = annualReport.managerValidationDate;
      this.managerAssessment = annualReport.managerAssessment;
      this.managerNextAnnualGoal = annualReport.managerNextAnnualGoal;
      this.managerCurrentAnnualGoal = annualReport.managerCurrentAnnualGoal;
      this.managerGoalComment = annualReport.managerGoalComment;
      this.managerSkillsImprovements = annualReport.managerSkillsComment;
      this.managerSkillsComment = annualReport.managerSkillsComment;
      this.managerSalaryComment = annualReport.managerSalaryComment;
      this.managerFinalComment = annualReport.managerFinalComment;
    }
  }

  getSwitchStyle(status: boolean, button: string) {
    const switchSelected = { color: '#333333', fontFamily: 'Montserrat-SemiBold, sans-serif' };
    const switchUnSelected = { color: '#b5b1b1', fontFamily: 'Montserrat-Regular, sans-serif' };
    if (button === 'validated') { return status ? switchSelected : switchUnSelected; }
    else if (button === 'unValidated') { return status ? switchUnSelected : switchSelected; }
  }

  handleChange(event: any) {
    if (event && this.role === 'collaborator') {
      this.pendingAnnualReport.id = this.reportId;
      this.pendingAnnualReport.collaboratorValidationStatus = this.formData.value.collaboratorValidationStatus;
      this.cssSwitchUnValidated = this.getSwitchStyle(this.formData.value.collaboratorValidationStatus, 'unValidated');
      this.cssSwitchValidated = this.getSwitchStyle(this.formData.value.collaboratorValidationStatus, 'validated');
      this.pendingAnnualReport.collaboratorValidationComment = this.formData.value.collaboratorValidationStatus ?
        null : this.formData.value.collaboratorValidationComment;
    }
    if (event && this.role === 'currentInterviewer') {
      this.pendingAnnualReport.id = this.reportId;
    }
    this.annualReportService.hasCollaboratorValidated$.next(this.pendingAnnualReport.collaboratorValidationStatus);
    this.store.dispatch(new SetPendingAnnualReport(this.pendingAnnualReport));
  }

  setCollaboratorCurrentGoals() {
    this.collaboratorCurrentGoals = [];
    const collaboratorCurrentGoals = this.pendingAnnualReport.goals.filter(goal => goal.emitter === 1 && goal.year === 1);
    collaboratorCurrentGoals.forEach(item => {
      const goal = { ...item };
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.collaboratorCurrentGoals.push(goal);
    });
  }

  setCollaboratorNextGoals() {
    this.collaboratorNextGoals = [];
    const collaboratorNextGoals = this.pendingAnnualReport.goals.filter(goal => goal.emitter === 1 && goal.year === 2);
    collaboratorNextGoals.forEach(item => {
      const goal = { ...item };
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.collaboratorNextGoals.push(goal);
    });
  }

  setManagerCurrentGoals() {
    this.managerCurrentGoals = [];
    const managerCurrentGoals = this.pendingAnnualReport.goals.filter(goal => goal.emitter === 2 && goal.year === 1);
    managerCurrentGoals.forEach(item => {
      const goal = { ...item };
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.managerCurrentGoals.push(goal);
    });
  }

  setManagerNextGoals() {
    this.managerNextGoals = [];
    const managerNextGoals = this.pendingAnnualReport.goals.filter(goal => goal.emitter === 2 && goal.year === 2);
    managerNextGoals.forEach(item => {
      const goal = { ...item };
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.managerNextGoals.push(goal);
    });
  }

  setCollaboratorSkillsStrength() {
    this.collaboratorSkillsStrength = [];
    const collaboratorSkillsStrength = this.pendingAnnualReport.strengths.filter(item => item.emitter === 1);
    collaboratorSkillsStrength.forEach(item => {
      const strength = { ...item };
      this.collaboratorSkillsStrength.push(strength);
    });
  }

  setCollaboratorSkillsImprovement() {
    this.collaboratorSkillsImprovement = [];
    const collaboratorSkillsImprovements = this.pendingAnnualReport.improvements.filter(goal => goal.emitter === 1);
    collaboratorSkillsImprovements.forEach(item => {
      const improvement = { ...item };
      this.collaboratorSkillsImprovement.push(improvement);
    });
  }

  setCollaboratorSkillsCourseRequest() {
    this.collaboratorSkillsCourseRequest = [];
    const collaboratorSkillsCourseRequests = this.pendingAnnualReport.courseRequests.filter(item => item.emitter === 1);
    collaboratorSkillsCourseRequests.forEach(item => {
      const course = { ...item };
      this.collaboratorSkillsCourseRequest.push(course);
    });
  }

  setManagerSkillsStrength() {
    this.managerSkillsStrength = [];
    const managerSkillsStrength = this.pendingAnnualReport.strengths.filter(item => item.emitter === 2);
    managerSkillsStrength.forEach(item => {
      const goal = { ...item };
      this.managerSkillsStrength.push(goal);
    });
  }

  setManagerSkillsImprovement() {
    this.managerSkillsImprovement = [];
    const managerSkillsImprovement = this.pendingAnnualReport.improvements.filter(item => item.emitter === 2);
    managerSkillsImprovement.forEach(item => {
      const goal = { ...item };
      this.managerSkillsImprovement.push(goal);
    });
  }

  setManagerSkillsCourseRequest() {
    this.managerSkillsCourseRequest = [];
    const managerSkillsCourseRequest = this.pendingAnnualReport.courseRequests.filter(item => item.emitter === 2);
    managerSkillsCourseRequest.forEach(item => {
      const course = { ...item };
      this.managerSkillsCourseRequest.push(course);
    });
  }

  goToStepOne() { this.router.navigateByUrl(this.annualReportUrl + 'stepone'); }
  goToStepTwo() { this.router.navigateByUrl(this.annualReportUrl + 'steptwo'); }
  goToStepThree() { this.router.navigateByUrl(this.annualReportUrl + 'stepthree'); }
  goToStepFour() { this.router.navigateByUrl(this.annualReportUrl + 'stepfour'); }
  goToStepFive() { this.router.navigateByUrl(this.annualReportUrl + 'stepfive'); }
  goToStepSix() { this.router.navigateByUrl(this.annualReportUrl + 'stepsix'); }


}
