<app-selection-menu
  [typeData]="typeData"
  [typeDataSelected]="typeDataSelected"
  [showAddForm]="showAddForm"
  [globalSearch]="globalSearch"
  (changeType)="handleChangeType($event)"
  (toggleShowAddForm)="toggleShowAddForm()"
  (globalSearchChange)="handleGlobalSearchChange($event)"
>
</app-selection-menu>

<app-view-skills
  *ngIf="typeDataSelected === 'Compétences'"
  [isRedirected]="isRedirected"
  [showAddForm]="showAddForm"
></app-view-skills>

<app-view-profiles
  *ngIf="typeDataSelected === 'Postes'"
  [showAddForm]="showAddForm"
  (eventRedirect)="handleRedirectFromProfile($event)"
></app-view-profiles>

<app-view-specialties
  *ngIf="typeDataSelected === 'Spécialités'"
  [showAddForm]="showAddForm"
></app-view-specialties>

