import { Component, OnInit } from '@angular/core';
import { EnvService } from '../../../core/env/env.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public version: string;

  constructor(private envService: EnvService) {
  }

  ngOnInit(): void {
    this.version = this.envService.version;
  }

  scrollToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
}
