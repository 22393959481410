import { Component, OnInit } from '@angular/core';
import {Store} from '@ngxs/store';
import {GetAnnualReportUser} from '../../../../../../stores/authentication/authentication.action';

@Component({
  selector: 'app-annual-reports',
  templateUrl: './annual-reports.component.html',
  styleUrls: ['./annual-reports.component.scss']
})
export class AnnualReportsComponent implements OnInit {
  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }

}
