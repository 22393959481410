import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {User} from '../../../../shared/models/user';
import {ActivatedRoute, Router} from '@angular/router';
import {DirectoryUsersState} from '../../store/directory-users.state';
import {ClearDirectoryUser, GetDirectoryUserByUserId} from '../../store/directory-users.actions';
import {Assignement} from '../../../../shared/models/assignement';
import {GetDirectoryAssignmentsByUser} from '../../store/directory-assigments.actions';
import {DirectoryAssignmentState} from '../../store/directory-assigments.state';

@Component({
  selector: 'app-directory-show-profile',
  templateUrl: './directory-show-profile.component.html',
  styleUrls: ['./directory-show-profile.component.scss']
})
export class DirectoryShowProfileComponent implements OnInit, OnDestroy {
  @Select(DirectoryUsersState.getUser) user$: Observable<User>;
  @Select(DirectoryAssignmentState.getDirectoryAssignmentsByUser) assignment$: Observable<Assignement>;

  public module = 'directory';

  public directoryProfileDataColumns: any = [];
  public emptyMessageInterview = 'Aucune mission';

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {

    const userId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.store.dispatch(new GetDirectoryUserByUserId(userId));
    this.store.dispatch(new GetDirectoryAssignmentsByUser(userId)).subscribe( () => {
      this.initDatatable();
    });
  }

  private initDatatable(): void {
    this.directoryProfileDataColumns = [
      {
        type: 'calendar',
        field: 'startDate',
        label: 'Date de début',
        font: 'regular',
        sort: true,
        sortField: 'startDate',
        isStatic: true,
      },
      {
        type: 'calendar',
        field: 'endDate',
        label: 'Date de fin',
        font: 'regular',
        sort: true,
        sortField: 'endDate',
        isStatic: true,
      },
      {
        type: 'text',
        field: 'customer',
        subField: 'name',
        label: 'Client',
        sort: true,
        sortField: 'customer.name',
        isStatic: true,
      },
      {
        type: 'text',
        field: 'project',
        subField: 'name',
        label: 'Projet',
        sort: true,
        sortField: 'project.name',
        isStatic: true,
      }
    ];
  }

  public handleBack(): void {
    this.router.navigateByUrl('/directory/search');
    this.store.dispatch(new ClearDirectoryUser());
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearDirectoryUser());
  }
}
