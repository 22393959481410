import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconService} from '../../../core/services/icon-service/icon.service';
import {Team} from '../../models/team';
import {User} from '../../models/user';

@Component({
  selector: 'app-fm-table',
  templateUrl: './fm-table.component.html',
  styleUrls: ['./fm-table.component.scss']

})
export class FmTableComponent {

  btntext = 'Afficher toutes les personnes';
  rowNumber = 10;

  @Input()
  displayPagination = true;

  @Input()
  isDurationToBeDisplayed = true;

  @Input()
  dataIsAllLoaded = false;

  @Input()
  columns: any = [];

  @Input()
  data: any = [];

  @Input()
  chipsNumber = 1;

  @Input()
  emptyMessage = 'Aucun résultat';

  @Output()
  eventDetailsUser = new EventEmitter<any>();

  @Output()
  eventGetOutUser = new EventEmitter<any>();

  @Output()
  eventConsultbackground = new EventEmitter<any>();

  @Output()
  eventShowAll = new EventEmitter<any>();

  @Input()
  module: string;

  @Input()
  currentManager: User;

  @Input()
  loading: boolean;

  constructor(public iconService: IconService) {
  }

  displayAllList() {
    this.btntext = this.displayPagination ? 'Afficher moins' : 'Afficher toutes les personnes';
    this.displayPagination = !this.displayPagination;

    if (!this.displayPagination && !this.dataIsAllLoaded) {
      this.eventShowAll.emit(true);
    }
  }

  showDetails(user: any) {
    this.eventDetailsUser.emit(user);
  }

  showBackground(user: any) {
    this.eventConsultbackground.emit(user);
  }

  getOutUser(user: any) {
    this.eventGetOutUser.emit(user);
  }

  isMainManager(team?: Team): boolean {
    if (team === undefined) {
      return true;
    } // No manager link on directory page

    // On team page
    return team !== null &&
      this.currentManager !== null &&
      team.mainManager &&
      team.manager.id === this.currentManager.id;
  }
}
