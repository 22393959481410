<p-table [value]="data"
         styleClass="fm-datatable"
         [paginator]="displayPagination" [rows]="rowNumber"
         [loading]="loading"
>
  <ng-template pTemplate="header">
    <tr>
      <!-- Fixed column who need 3 concatened values -->
      <th [pSortableColumn]="'lastname'" class="fm-cell-lastname">Nom et Agence</th>

      <!-- Loop on each column to show the label -->
      <th *ngFor="let col of columns"
          class="fm-text"
          [pSortableColumn]="col.sort && (col.sortField || col.field)"
          [className]="
          col.type === 'list' && col.field === 'customer' ? 'fm-cell-smaller' :
          col.type === 'chips' && col.field === 'userCertifications' ? 'fm-cell-picto' :
          col.type === 'action' && col.action === 'cr' ? 'fm-cell-action' :
          col.type === 'action' && col.action === 'out' ? 'fm-cell-action' :
          col.type === 'action' && col.action === 'none' ? 'fm-cell-none' :
          col.type === 'button' ? 'fm-cell-button' :
          col.type === 'empty'  ? 'fm-cell-empty' :
            'fm-cell-auto'">
        {{col.label}}
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="columns && columns.length" class="empty-columns">{{emptyMessage}}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-data let-ri="rowIndex">
    <tr [ngClass]="{ 'fm-not-main-manager': isMainManager(data.mainTeam) === false }">
      <!-- Fixed column who need 3 concatened values -->
      <td class="p-d-flex p-flex-column">
        <div  *ngIf="data.lastname.length + data.firstname.length <= 24">
          <span class="fm-username" >
            {{data.lastname | uppercase}} {{data.firstname}}</span>
        </div>
        <div *ngIf="data.lastname.length+ data.firstname.length> 24 && data.lastname.length + data.firstname.length <48 && data.lastname.length < 24" >
        <span class="fm-username" >{{data.lastname | uppercase}}</span><br>
        <span class="fm-username" >{{data.firstname}}</span>
        </div>
        <div *ngIf="data.lastname.length > 24 && data.lastname.length< 48" pTooltip="{{data.lastname}} {{data.firstname}}"   >
          <span class="fm-username" >{{data.lastname | uppercase}} </span>
          <span class="fm-username" > {{ (data.lastname.length + data.firstname.length>48)? (data.firstname | slice:0: 48 - data.lastname.length  )+'..':(data.firstname) }}</span>
        </div>
        <div *ngIf="data.lastname.length >= 48" pTooltip="{{data.lastname}} {{data.firstname}}" >
          <span class="fm-username" >{{ (data.lastname.length >=48)? (data.lastname | uppercase | slice:0: 46 )+'..':(data.lastname) }}</span>
          <span class="fm-username"  > {{ (data.lastname.length >=48)? (data.firstname | slice:0:  - (data.firstname.length -1) )+'..':(data.firstname) }}</span>
        </div>
        <div class="p-d-flex">
        <span class="fm-office">{{data.office?.name}}</span>
          <span>&nbsp;</span>
          <!--c'est ici pour la date-->
        <span *ngIf="isDurationToBeDisplayed" class="fm-office">{{data.duration}}</span>
        </div>
      </td>
      <!-- Loop on each column to show the data -->
      <td *ngFor="let col of columns">
        <!-- Display space between first column and the others-->
        <div *ngIf="col.type === 'none'">
        </div>
          <!-- if data type is define as list, loop on each data to show the subField data value -->
        <div *ngIf="col.type === 'list' " class="p-d-flex">
              <p class="fm-text-inline" >{{data[col.field]}}</p>
        </div>
        <!-- if data type is define as chips, loop on each data to show the subField data value-->
        <div *ngIf="col.type === 'chips'">
          <app-chips-list [chipsData]="data[col.field]"
                          [subFieldName]="col.subField"
                          [module]='module'
                          [notDisplayChipslabel]="col.notDisplayChipslabel"
                          [picto]="col.picto" ></app-chips-list>
        </div>
        <!-- To show actions on button -->
        <span *ngIf="col.type === 'action' && col.action === 'cr' && data[col.field].length > 0" class="fm-action">
          <app-fm-button type="{{col.action}}" [btnDisabled]="!data[col.field]" (click)="showBackground(data)" ></app-fm-button>
        </span>
        <!-- disabled button on -->
        <span *ngIf="col.type === 'action' && col.action === 'cr' && data[col.field].length === 0 " class="fm-action">
          <app-fm-button type="{{col.action}}-disabled" [btnDisabled]="!data[col.field]"></app-fm-button>
        </span>
        <span *ngIf="col.type === 'action' && col.action === 'out'" class="fm-action" (click)="getOutUser(data)">
          <app-fm-button type="{{col.action}}" [btnDisabled]=""></app-fm-button>
        </span>
        <span *ngIf="col.type === 'button'" [attr.data-testid]="'button_showDetails_' + (ri + 1)" class="fm-arrow" (click)="showDetails(data)">
          <div class="rotate-arrow"><i class="fm-icon-arrow fm-icon-white fm-icon-xxs"></i></div>
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>
<div *ngIf="(data.length > rowNumber) || (!dataIsAllLoaded)" class="fm-button-more">
  <app-fm-button
    [attr.data-testid]="'button_showMoreLess'"
    (click)="displayAllList()"
    btnText="{{btntext | uppercase}}"
    type="more">
  </app-fm-button>
</div>
