import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrimeNGModule} from './prime.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PersonCardComponent} from './components/person-card/person-card.component';
import {
  CollaboratorFormComponent
} from '../modules/collaborator/components/collaborator-form/collaborator-form.component';
import {SelectDateComponent} from './components/select-date/select-date.component';
import {SelectItemArrayComponent} from './components/select-item-array/select-item-array.component';
import {SelectItemComponent} from './components/select-item-array/select-item/select-item.component';
import {TableRadioButtonComponent} from './components/table-radio-button/table-radio-button.component';
import {NavbarService} from '../core/services/navbar-services/navbar.service';
import {FmTabMenuComponent} from './components/fm-tab-menu/fm-tab-menu.component';
import {FmButtonComponent} from './components/fm-button/fm-button.component';
import {SkillService} from '../core/services/skill-service/skill.service';
import {HttpClientModule} from '@angular/common/http';
import {DatatableComponent} from './components/datatable/datatable.component';
import {FmTableComponent} from './components/fm-table/fm-table.component';
import {ModalComponent} from './components/modal/modal.component';
import {TabMenuModule} from 'primeng/tabmenu';
import {MessageService} from 'primeng/api';
import {CalendarModule} from 'primeng/calendar';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {SelectionMenuComponent} from './components/selection-menu/selection-menu.component';
import {FormInlineComponent} from './components/form-inline/form-inline.component';
import {ToSelectItemPipe} from './pipes/to-select-item-pipe';
import {SafeSanitizerPipe} from './pipes/safe-sanitizer.pipe';
import {FileUploadModule} from 'primeng/fileupload';
import {ToastModule} from 'primeng/toast';
import {CardModule} from 'primeng/card';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ChipComponent} from './components/chip/chip.component';
import {ChipsListComponent} from './components/chips-list/chips-list.component';
import {FooterComponent} from './components/footer/footer.component';
import {PersonSummaryComponent} from './components/person-summary/person-summary.component';
import {FmProfileComponent} from './components/fm-profile/fm-profile.component';
import {RhaConsultationComponent} from './components/rha-consultation/rha-consultation.component';
import {EnumToArrayPipe} from './pipes/enum-to-array.pipe';
import {CommentReportComponent} from './components/comment-report/comment-report.component';
import {FmAnnualReportComponent} from './components/fm-annual-report/fm-annual-report.component';
import {StepOneComponent} from './components/fm-annual-report/step-one/step-one.component';
import {StepTwoComponent} from './components/fm-annual-report/step-two/step-two.component';
import {StepThreeComponent} from './components/fm-annual-report/step-three/step-three.component';
import {StepFourComponent} from './components/fm-annual-report/step-four/step-four.component';
import {StepFiveComponent} from './components/fm-annual-report/step-five/step-five.component';
import {StepSixComponent} from './components/fm-annual-report/step-six/step-six.component';
import {StepSevenComponent} from './components/fm-annual-report/step-seven/step-seven.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {FmAnnualReportButtonComponent} from './components/fm-annual-report-button/fm-annual-report-button.component';
import {SummaryComponent} from './components/fm-annual-report/summary/summary.component';
import {FmHrReportComponent} from "./components/fm-hr-report/fm-hr-report-component";

@NgModule({
  declarations: [
    CollaboratorFormComponent,
    SelectDateComponent,
    SelectItemArrayComponent,
    SelectItemComponent,
    TableRadioButtonComponent,
    PersonCardComponent,
    FmTabMenuComponent,
    FmButtonComponent,
    DatatableComponent,
    FmTableComponent,
    ModalComponent,
    SelectionMenuComponent,
    FormInlineComponent,
    ToSelectItemPipe,
    SafeSanitizerPipe,
    ChipComponent,
    FooterComponent,
    ChipsListComponent,
    PersonSummaryComponent,
    FmProfileComponent,
    RhaConsultationComponent,
    EnumToArrayPipe,
    CommentReportComponent,
    FmAnnualReportComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    StepFiveComponent,
    StepSixComponent,
    StepSevenComponent,
    FmAnnualReportButtonComponent,
    SummaryComponent,
    FmHrReportComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    PrimeNGModule,
    FormsModule,
    ReactiveFormsModule,
    TabMenuModule,
    CalendarModule,
    AutoCompleteModule,
    FileUploadModule,
    ToastModule,
    MultiSelectModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CardModule,
    InputSwitchModule,
  ],
  exports: [
    PrimeNGModule,
    FmTabMenuComponent,
    DatatableComponent,
    FmTableComponent,
    ModalComponent,
    FmButtonComponent,
    SelectionMenuComponent,
    FormInlineComponent,
    FlexLayoutModule,
    PersonCardComponent,
    ChipComponent,
    FooterComponent,
    ChipsListComponent,
    PersonSummaryComponent,
    FmProfileComponent,
    RhaConsultationComponent,
    EnumToArrayPipe,
    CommentReportComponent,
    FmAnnualReportComponent,
    FmAnnualReportButtonComponent,
    InputSwitchModule,
    FmHrReportComponent
  ],
  providers: [
    HttpClientModule,
    NavbarService,
    SkillService,
    MessageService
  ],
})
export class SharedModule {
}
