import { User } from '../../../shared/models/user';
import { Assignement } from '../../../shared/models/assignement';

export class MakeSnapshotAssignment {
  static readonly type = '[Assignment] MakeSnapshot';
}

export class RestoreSnapshotAssignment {
  static readonly type = '[Assignment] RestoreSnapshot';
}

export class GetAssignmentsByUser {
  static readonly type = '[Assignment] GetAssignmentsByUser';
  constructor(public payload: User) {}
}
export class AddAssignment {
  static readonly type = '[Assignment] Add';
  constructor(public payload: Assignement) {}
}

export class UpdateAssignment {
  static readonly type = '[Assignment] update';
  constructor(public payload: Assignement) {}
}

export class DeleteAssignment {
  static readonly type = '[Assignment] delete';
  constructor(public payload: Assignement) {}
}

export class ClearAssignments {
  static readonly type = '[Assignment] Clear assignments';
}
