import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssignmentService extends ApiService{

  protected prefix(): string {
    return 'assignments';
  }
  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(content: any): Observable<any> {
    return this.put(content);
  }

  doDelete(id: number): Observable<any> {
    return this.delete(id);
  }

  projectExists(projectId: number): Observable<any> {
    return this.get('/project/' + projectId).pipe(
      map(result => result)
    );
  }

  coupleUserProjectExists(userId: number, projectId: number): Observable<any> {
    return this.get('/project/' + projectId + '/user/' + userId) ;
  }


  getAllByUserId(userId: number): Observable<any> {
    return this.get('/user/' + userId);
  }

  mapAssignment(assignmentFromBack: any): any {
    const assignment: any = {};
    assignment.id = assignmentFromBack.id;
    assignment.project = assignmentFromBack.project;
    assignment.customer = assignmentFromBack.project.customer;
    assignment.profile = assignmentFromBack.profile ? assignmentFromBack.profile : null;
    assignment.startDate = assignmentFromBack.startDate ? new Date(assignmentFromBack.startDate) : null;
    assignment.endDate = assignmentFromBack.endDate ? new Date(assignmentFromBack.endDate) : null;
    assignment.user = assignmentFromBack.user;

    return assignment;
  }

  sortAssignments(userAssignments): void {
    userAssignments.sort((a, b) => b.startDate - a.startDate ||
      a.project.customer.name.toLowerCase().localeCompare(b.project.customer.name.toLowerCase()) ||
      a.project.name.toLowerCase().localeCompare(b.project.name.toLowerCase())
    );
  }
}
