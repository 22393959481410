import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Skill } from '../../../shared/models/skill';
import { DomSanitizer} from '@angular/platform-browser';
import {
  AddSkill,
  ClearSnapshotSkill,
  DeleteSkill, FilterSkills,
  GetSkills,
  MakeSnapshotSkill,
  RestoreSnapshotSkill,
  UpdateSkill,
} from './skills.actions';
import { SkillService } from '../../../core/services/skill-service/skill.service';
import { searchIndexOf } from '../../../shared/utils/utils';

export class SkillStateModel {
  skills: Skill[];
  snapshot: Skill[];
}

@State<SkillStateModel>({
  name: 'skills',
  defaults: {
    skills: [],
    snapshot: [],
  },
})
@Injectable()
export class SkillState {
  constructor(private skillService: SkillService, private sanitizer: DomSanitizer) {
  }

  @Selector()
  static getSkills(state: SkillStateModel) {
    return state.skills;
  }

  @Selector()
  static getSnapshot(state: SkillStateModel) {
    return state.snapshot;
  }

  @Action(GetSkills)
  getAll({ getState, setState }: StateContext<SkillStateModel>) {
    return this.skillService.getAll().pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          skills: result.map( r => {
            r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
            r.image = r.image ? this.sanitizer.bypassSecurityTrustResourceUrl(r.image) : null;
            return r;
          }),
        });
      })
    );
  }

  @Action(AddSkill)
  add(
    { getState, patchState }: StateContext<SkillStateModel>,
    { payload }: AddSkill
  ) {
    return this.skillService.doPost(payload).pipe(
      tap((result) => {
        result.image = result.image ? this.sanitizer.bypassSecurityTrustResourceUrl(result.image) : null;
        const state = getState();
        patchState({
          skills: [...state.skills, result],
        });
      })
    );
  }

  @Action(UpdateSkill)
  update(
    { getState, setState }: StateContext<SkillStateModel>,
    { payload }: UpdateSkill
  ) {
    return this.skillService.doPut(payload).pipe(
      tap((result) => {
        result.image = result.image ? this.sanitizer.bypassSecurityTrustResourceUrl(result.image) : null;
        result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
        const state = getState();
        const skillsList = [...state.skills];
        const skillIndex = skillsList.findIndex((item) => item.id === payload.id);
        skillsList[skillIndex] = result;
        setState({
          ...state,
          skills: skillsList,
        });
      })
    );
  }

  @Action(DeleteSkill)
  delete(
    { getState, setState }: StateContext<SkillStateModel>,
    { payload }: DeleteSkill
  ) {
    const state = getState();
    return this.skillService.doDelete(payload).pipe(
      tap(() => {
        const filteredArray = state.skills.filter(
          (item) => item.id !== payload.id
        );
        setState({
          ...state,
          skills: filteredArray,
        });
      })
    );
  }

  @Action(FilterSkills)
  filter({getState, setState}: StateContext<SkillStateModel>, {payload}: FilterSkills) {
    return this.skillService.getAll().pipe(tap((result) => {
      const state = getState();
      const skills = result.map( r => {
        r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
        r.image = r.image ? this.sanitizer.bypassSecurityTrustResourceUrl(r.image) : null;
        return r;
      });
      const filteredSkills = skills.filter(item =>
        item.name && searchIndexOf(item.name, payload) ||
        item.category && searchIndexOf(item.category, payload) ||
        item.profile && searchIndexOf(item.profile != null ? item.profile.name : item.name, payload)
      );
      setState({
        ...state,
        skills: filteredSkills
      });
    }));
  }

  @Action(MakeSnapshotSkill)
  makeSnapShot({ getState, setState }: StateContext<SkillStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.skills.map((item) => ({ ...item })),
    });
  }

  @Action(RestoreSnapshotSkill)
  restoreSnapshot({ getState, setState }: StateContext<SkillStateModel>) {
    const state = getState();
    setState({
      ...state,
      skills: state.snapshot.map((item) => ({ ...item })),
    });
  }

  @Action(ClearSnapshotSkill)
  clearSnapshot({ getState, setState }: StateContext<SkillStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: null,
    });
  }
}
