<div *ngIf="user$ | async as user">
  <div [attr.data-testid]="'home_bloc_top'" class="fm-top">
    <div class="fm-bloc-top">
      <div class="fm-left">
        <img [src]="user.image || '../../../../assets/img/photo_profil.png'" alt="">
        <img alt="" src="../../../../assets/img/Quote.svg">
      </div>
      <div class="fm-right">
        <span class="fm-firstname">{{user?.lastname | uppercase}} {{user?.firstname}}</span>
        <span class="fm-office">{{user?.office?.name}}</span>
        <span class="fm-email">{{user?.email}}</span>
        <span class="fm-office">{{user?.subsidiary?.name}}</span>
      </div>
      <div class="fm-summary">
        <app-person-summary [attr.data-testid]="'person_summary_component'" [user]="user" module="home"></app-person-summary>
      </div>
    </div>
  </div>

  <div class="fm-bloc-content">
    <div [attr.data-testid]="'home_bloc_search'" class="fm-bloc-search" fxLayoutAlign="center">
      <p-card fxFlex="100%">
        <div class="fm-annuaire" fxLayoutAlign="center">Annuaire des compétences</div>
        <div class="fm-search" fxLayoutAlign="center">
          <p-autoComplete [attr.data-testid]="'person_summary_autocomplete_searchDirectory'"
                          (completeMethod)="handleDirectory($event)" (keydown.enter)="handleDirectory($event)"
                          dropdown="true" dropdownIcon="fm-icon-search fm-icon-white fm-icon-sm" dropdownMode="current"
                          minLength="70"
                          placeholder="Rechercher une personne ou une compétence">
          </p-autoComplete>
        </div>
      </p-card>
    </div>

    <div *ngFor="let box of boxes; let ids = index">
      <app-home-box
        [attr.data-testid]="box.dataTestId"
        [blocText]="ids %2 === 0 ? 'left' : 'right' "
        [type]="box.type" >
      </app-home-box>
    </div>

  </div>
</div>
