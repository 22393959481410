import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Project } from 'src/app/shared/models/project';
import {
  AddProject,
  ClearSnapshotProject,
  DeleteProject,
  FilterProjects,
  GetProjects,
  MakeSnapshotProject,
  RestoreSnapshotProject,
  UpdateProject
} from './projects.actions';
import { ProjectService } from 'src/app/core/services/project-services/project.service';
import { searchIndexOf } from "../../../shared/utils/utils";

export class ProjectStateModel {
  projects: Project[];
  snapshot: Project[];
}

@State<ProjectStateModel>({
  name: 'projects',
  defaults: {
    projects: [],
    snapshot: [],
  }
})
@Injectable()
export class ProjectState {

  constructor(private projectService: ProjectService) {
  }

  @Selector()
  static getProjects(state: ProjectStateModel) {
    return state.projects;
  }

  @Selector()
  static getSnapshot(state: ProjectStateModel) {
    return state.snapshot;
  }

  @Action(GetProjects)
  getAll({getState, setState}: StateContext<ProjectStateModel>) {
    return this.projectService.getAll().pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        projects: result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddProject)
  add({getState, patchState}: StateContext<ProjectStateModel>, {payload}: AddProject) {
    return this.projectService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      patchState({
        projects: [...state.projects, result]
      });
    }));
  }

  @Action(UpdateProject)
  update({getState, setState}: StateContext<ProjectStateModel>, {payload}: UpdateProject) {
    return this.projectService.doPut(payload).pipe(tap((result) => {
      result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
      const state = getState();
      const ProjectsList = [...state.projects];
      const ProjectIndex = ProjectsList.findIndex(item => item.id === payload.id);
      ProjectsList[ProjectIndex] = result;
      setState({
        ...state,
        projects: ProjectsList,
      });
    }));
  }

  @Action(DeleteProject)
  delete({getState, setState}: StateContext<ProjectStateModel>, {payload}: DeleteProject) {
    const state = getState();
    return this.projectService.doDelete(payload).pipe(tap(() => {
      const filteredArray = state.projects.filter(item => item.id !== payload.id);
      setState({
        ...state,
        projects: filteredArray,
      });
    }));
  }

  @Action(MakeSnapshotProject)
  makeSnapShot({getState, setState}: StateContext<ProjectStateModel>) {
      const state = getState();
      setState({
        ...state,
        snapshot: state.projects.map(item => ({...item}))
      });
  }


  @Action(RestoreSnapshotProject)
  restoreSnapshot({getState, setState}: StateContext<ProjectStateModel>) {
      const state = getState();
      setState({
        ...state,
        projects: state.snapshot.map(item => ({...item}))
      });
  }


  @Action(ClearSnapshotProject)
  clearSnapshot({getState, setState}: StateContext<ProjectStateModel>) {
      const state = getState();
      setState({
        ...state,
        snapshot: null,
      });
  }

  @Action(FilterProjects)
  filter({getState, setState}: StateContext<ProjectStateModel>, {payload}: FilterProjects) {
    return this.projectService.getAll().pipe(tap((result) => {
      const state = getState();
      const projects = result.map( r => {
        r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
        return r;
      });
      const filteredProjects = projects.filter(item =>
        item.name && searchIndexOf(item.name, payload) ||
        item.description && searchIndexOf(item.description, payload) ||
        item.customer.name && searchIndexOf(item.customer.name, payload));
      setState({
        ...state,
        projects: filteredProjects
      });
    }));
  }

}
