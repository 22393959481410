import {User} from '../../app/shared/models/user';
import {AnnualReport} from '../../app/shared/models/annual-report';

// ------------ANNUAL REPORTS -------------------
// ---------------------------------------------
export class GetAnnualReportById {
  static readonly type = '[Report] GetAnnualReportById';
  constructor(public annualReportId: number) {}
}

export class GetAnnualReportsByUser {
  static readonly type = '[Report] GetAnnualReportsByUser';
  constructor(public user: User) {}
}

export class GetAnnualReportsByInterviewer {
  static readonly type = '[Report] GetAnnualReportsByInterviewer';
  constructor(public user: User) {}
}

export class GetAnnualReportsByCurrentInterviewer {
  static readonly type = '[Report] GetAnnualReportsByCurrentInterviewer';
  constructor(public user: User) {}
}

export class GetAnnualReportsByFilters {
  static readonly type = '[Report] GetAnnualReportsByStatus';
  constructor(public status: string, public campaignYear: number, public officeId: number) {}
}

export class GetAnnualReport {
  static readonly type = '[Report] GetAnnualReport';
  constructor(public user: User) {}
}

export class SetPendingAnnualReport {
  static readonly type = '[Report] SetPendingAnnualReport';
  constructor(public pendingAnnualReport: AnnualReport) {}
}

export class SetAnnualReport {
  static readonly type = '[Report] SetAnnualReport';
  constructor(public annualReport: AnnualReport) {}
}

export class UpdateAnnualReport {
  static readonly type = '[Report] UpdateAnnualReport';
  constructor(public role: string) {}
}

export class ValidateAnnualReport {
  static readonly type = '[Report] ValidateAnnualReport';
  constructor(public workflow: string, public role: string) {}
}

export class ClearPendingAnnualReport {
  static readonly type = '[Report] ClearPendingAnnualReport';
}

export class SetAnnualReportRole {
  static readonly type = '[Report] SetAnnualReportRole';
  constructor(public role: string) {}
}

// ------------ REPORTS -------------------
// ----------------------------------------
export class GetReportsByCollaborator {
  static readonly type = '[Report] GetReportsByCollaborator';
  constructor(public user: User, public startDate: string, public endDate: string, public search: string) {}
}

export class GetFilteredReports {
  static readonly type = '[Report] GetFilteredReports';
  constructor(public search: string) {}
}

// ------------ DELETE REPORTS -------------------
// ----------------------------------------
export class DeleteReport{
  static readonly type = '[Report] DeleteReport';
  constructor(public id: number) {}
}

//Créer méthode delete report

