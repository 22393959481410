import { ProfileService } from 'src/app/core/services/profile-services/profile.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  AddProfile,
  ClearSnapshotProfile,
  DeleteProfile,
  FilterProfiles,
  GetProfiles,
  MakeSnapshotProfile,
  RestoreSnapshotProfile,
  UpdateProfile
} from './profiles.actions';
import { Profile } from 'src/app/shared/models/profile';
import { searchIndexOf } from 'src/app/shared/utils/utils';

export class ProfileStateModel {
  profiles: Profile[];
  snapshot: Profile[];
}

@State<ProfileStateModel>({
  name: 'profiles',
  defaults: {
    profiles: [],
    snapshot: [],
  }
})
@Injectable()
export class ProfileState {

  constructor(private profileService: ProfileService) {
  }

  @Selector()
  static getProfiles(state: ProfileStateModel) {
    return state.profiles;
  }


  @Selector()
  static getSnapshot(state: ProfileStateModel) {
    return state.snapshot;
  }

  @Action(GetProfiles)
  getAll({getState, setState}: StateContext<ProfileStateModel>) {
    return this.profileService.getAll().pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        profiles: result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddProfile)
  add({getState, patchState}: StateContext<ProfileStateModel>, {payload}: AddProfile) {
    return this.profileService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      patchState({
        profiles: [...state.profiles, result]
      });
    }));
  }

  @Action(UpdateProfile)
  update({getState, setState}: StateContext<ProfileStateModel>, {payload}: UpdateProfile) {
    return this.profileService.doPut(payload).pipe(tap((result) => {
      result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
      const state = getState();
      const profilesList = [...state.profiles];
      const profileIndex = profilesList.findIndex(item => item.id === payload.id);
      profilesList[profileIndex] = result;
      setState({
        ...state,
        profiles: profilesList,
      });
    }));
  }

  @Action(DeleteProfile)
  delete({getState, setState}: StateContext<ProfileStateModel>, {payload}: DeleteProfile) {
    const state = getState();
    return this.profileService.doDelete(payload).pipe(tap(() => {
      const filteredArray = state.profiles.filter(item => item.id !== payload.id);
      setState({
        ...state,
        profiles: filteredArray,
      });
    }));
  }

  @Action(FilterProfiles)
  filter({getState, setState}: StateContext<ProfileStateModel>, {payload}: FilterProfiles) {
    return this.profileService.getAll().pipe(tap((result) => {
      const state = getState();
      const profiles = result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        });
      const filteredProfiles = profiles.filter(item =>
        item.name && searchIndexOf(item.name, payload) ||
        item.category && searchIndexOf(item.category, payload));
      setState({
        ...state,
        profiles: filteredProfiles
      });
    }));
  }

  @Action(MakeSnapshotProfile)
  makeSnapShot({getState, setState}: StateContext<ProfileStateModel>) {
      const state = getState();
      setState({
        ...state,
        snapshot: state.profiles.map(item => ({...item}))
      });
  }

  @Action(RestoreSnapshotProfile)
  restoreSnapshot({getState, setState}: StateContext<ProfileStateModel>) {
      const state = getState();
      setState({
        ...state,
        profiles: state.snapshot.map(item => ({...item}))
      });
  }

  @Action(ClearSnapshotProfile)
  clearSnapshot({getState, setState}: StateContext<ProfileStateModel>) {
      const state = getState();
      setState({
        ...state,
        snapshot: null,
      });
  }
}
