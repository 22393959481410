<app-selection-menu
  [typeData]="typeData"
  [typeDataSelected]="typeDataSelected"
  [showAddButton]="false"
  (globalSearchChange)="handleGlobalSearchChange($event)"
>
</app-selection-menu>

<app-view-request-manager
  *ngIf="typeDataSelected === 'Validation des demandes'"
></app-view-request-manager>
