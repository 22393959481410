<app-form-inline
  [attr.data-testid]="'formInline_component'"
  [titleForm]="titleForm"
  [listInput]="inputForm"
  (eventCreateItem)="handleCreateItem($event)"
  *ngIf="showAddForm"
  [saveDisabled]="disableCreationSave"
>
</app-form-inline>
<hr>
<app-datatable
  [attr.data-testid]="'dataTable_component'"
  editMode="row"
  [data]="specialties$ | async"
  [columns]="specialtyDataColumns"
  [emptyMessage]="emptyMessage"
  (eventDeleteItem)="handleDeleteItem($event)"
  (eventUpdateItem)="handleUpdateItem($event)"
  (eventSaveUpdateItem)="handleSaveUpdateItem($event)"
  (eventCancelEditItem)="handleCancelEditItem($event)">
</app-datatable>

<app-modal
  [header]="modal.header"
  [content]="modal.content"
  [type]="modal.type"
  [(display)]="showModal"
  (eventModalConfirm)="handleModalConfirm()"
  [attr.data-testid]="'modal_component'"
>
</app-modal>
