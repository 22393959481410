import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {DirectoryUsersState} from '../../store/directory-users.state';
import {Observable,Subscription} from 'rxjs';
import {User} from '../../../../shared/models/user';
import {MessageService,SelectItem} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {convertSafeCharactersFromUrlToInput, convertUnsafeCharactersForUrl} from '../../../../shared/utils/utils';
import {
  ClearDirectoryUsers,
  GetDirectoryUsers,
  GetDirectoryUsersByProperties,
  GetDirectoryUsersBySkills,
  GetDirectoryUsersOrigin,
  GetTop10DirectoryUsers
} from '../../store/directory-users.actions';
import { Constants } from 'src/app/shared/constants/constants';
import { OfficeService } from 'src/app/core/services/office-service/office.service';


@Component({
  selector: 'app-directory-search',
  templateUrl: './directory-search.component.html',
  styleUrls: ['./directory-search.component.scss']
})
export class DirectorySearchComponent implements OnInit, OnDestroy {
  @Select(DirectoryUsersState.getUsers) users$: Observable<User[]>;
  @Select(DirectoryUsersState.getUser) user$: Observable<User>;
  @Select(DirectoryUsersState.getLoading) loading$: Observable<boolean>;

  @ViewChild('searchInput') searchInput: ElementRef;

  module = 'directory';
  tableColumns: any[];
  isDurationToBeDisplayed = false;
  emptyMessage = 'Aucun collaborateur';
  search: string;
  characterLimitForSearchingOnlySkills = 2;
  dataIsAllLoaded = false;

  officeOptions: SelectItem[];
  selectedOffice: number;
  selectedOfficeName: string;
  officeServiceSubscription: Subscription;

  constructor(
    private store: Store,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private officeService: OfficeService
    
  ) {
  }

  ngOnInit(): void {
    this.initDatatable();
    this.initOfficeOptions();

    this.search = this.activatedRoute.snapshot.queryParamMap.get('search');
    if (this.search !== null) {
      this.search = convertSafeCharactersFromUrlToInput(this.search);
      this.handleSearch();
    }
  }

  initDatatable() {
    this.tableColumns = [
      // columns which displays space between first  and the others columns
      {type: 'empty', action: 'none'},
      {
        type: 'chips',
        field: 'userProfiles',
        subField: 'profile',
        interest: 'interest',
        level: 'level',
        label: 'Postes',
        sort: true
      },
      {
        type: 'chips',
        field: 'userSkills',
        subField: 'skill',
        interest: 'interest',
        level: 'level',
        label: 'Compétences',
        sort: true,
      },
      {
        type: 'chips',
        field: 'userCourses',
        subField: 'course',
        picto: true, // display picto and not level icon
        label: 'Formations',
        sort: true,
      },
      {
        type: 'chips',
        field: 'userCertifications',
        subField: 'certification',
        picto: true, // display picto and not level icon
        notDisplayChipslabel: true,
        level: 'icon', // display picto from icon level
        label: 'Certifications',
        sort: true,
      },
      {type: 'action', action: 'none'},
      {type: 'action', action: 'none'},
      {type: 'button'},
    ];
  }

  initOfficeOptions(): void {
      this.officeOptions = [];
      this.officeOptions.push({
        label: Constants.AR_FILTER_OFFICE_ALL_LABEL,
        value: null,
      });
      this.officeServiceSubscription = this.officeService.getAll().subscribe((result) =>
        result.map((office) =>
          this.officeOptions.push({
            label: office.name,
            value: office.id,
          })
        )
      );
      this.selectedOffice = null;
    }


  inputChanges() {
    if (this.searchInput.nativeElement.value === '') {
      this.store.dispatch(new GetDirectoryUsersOrigin());
    }
  }

  handleSearch() {
    this.search = "";
    if (this.searchInput !== undefined) {
      this.search = this.searchInput.nativeElement.value;
    }

    if(this.search.length === 0 && this.selectedOffice === null) {
      this.getDataWithoutFilter(false);
      return;
    }
    if(this.search.length === 0 && this.selectedOffice !== null){
      this.search = this.selectedOfficeName;
    }
    if(this.search.length > 0 && this.selectedOffice === null){
      this.search = this.search;
    }
    if(this.search.length > 0 && this.selectedOffice !== null){
      this.search = this.search + " " + this.selectedOfficeName;
    }

    if (this.search.length > this.characterLimitForSearchingOnlySkills) {
      this.store.dispatch((new GetDirectoryUsersByProperties(convertUnsafeCharactersForUrl(this.search))));
    } else {
      this.store.dispatch((new GetDirectoryUsersBySkills(convertUnsafeCharactersForUrl(this.search))));
    }
  }

  handleDetailsUser(user: any) {
    this.router.navigateByUrl('/directory/show-profile/' + user.id);
  }

  handleOfficeChange(eventValue: any) {
 
    let selectedItem = this.officeOptions.find(office => office.value === eventValue.value);
    
    this.selectedOfficeName = selectedItem.label;
    this.selectedOffice = selectedItem.value;
    this.handleSearch();  
  }

  displayError(error: any) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (error) {
      case 'empty':
        severity = 'error';
        summary = 'Champ vide';
        detail = 'Le champ recherche doit être renseigné.';
        break;
      case (401):
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Connexion perdue.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur';
        detail = 'Une erreur est survenue. Veuillez contacter l\'administrateur.';
        console.log(error);
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  getDataWithoutFilter(isInit) {
    if (this.search === null || this.search === '') {

      if (isInit) {
        this.store.dispatch(new GetTop10DirectoryUsers());
      } else {
        this.store.dispatch(new GetDirectoryUsers());
        this.dataIsAllLoaded = true;
      }
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearDirectoryUsers());
  }
}
