import { Component, OnInit } from '@angular/core';
import { Store } from "@ngxs/store";
import { SelectItem } from 'primeng/api';
import { FilterCustomers, GetCustomers } from "../../store/customers.actions";
import { FilterProjects, GetProjects } from "../../store/projects.actions";

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {

  typeData: SelectItem[] = [
    { label: 'Clients', value: 'Clients' },
    { label: 'Projets', value: 'Projets' },
  ];

  typeDataSelected = 'Clients';
  showAddForm = false;
  globalSearch: string;
  isRedirected = false;

  constructor(private store: Store) { }

  ngOnInit(): void {}

  handleChangeType(event) {
    this.isRedirected = false;
    this.typeDataSelected = event.value;
    this.toggleShowAddForm(false);
  }

  // Get data selected from customers and do redirection
  handleRedirectFromCustomer(data) {
    this.globalSearch = data.name;
    this.typeDataSelected = 'Projets';
    this.handleGlobalSearchChange(data.name) ;
    this.isRedirected = true;
  }

  toggleShowAddForm(show:boolean = null) {
    this.showAddForm = !this.showAddForm ? show === null : show;
  }

  handleGlobalSearchChange(query) {
    switch (this.typeDataSelected) {
      case 'Projets':
        query && query.length > 0 ?
          this.store.dispatch(new FilterProjects(query)) :
          this.store.dispatch(new GetProjects());
        break;
      case 'Clients':
      default:
        query && query.length > 0 ?
          this.store.dispatch(new FilterCustomers(query)) :
          this.store.dispatch(new GetCustomers());
        break;
    }
  }
}
