<div class="container">
  <div class="fm-title">
    <h2>Compétences métiers</h2>
  </div>

  <div class="fm-form">
    <div class="fm-two-column-size">

      <div class="fm-skill">
        <h3>Postes</h3>
        <div *ngIf="userProfiles.length === 0">Aucun poste attribué</div>
        <div class="fm-skill-align">
          <app-chips-list [chipsData]="userProfiles" [chipsLabel]="userProfiles" [subFieldName]="'profile'"
                          module="profile"></app-chips-list>
        </div>

      <div class="fm-skill">
        <h3>Compétences</h3>
        <div *ngIf="userSkills.length === 0">Aucune compétence attribuée</div>
        <div class="fm-skill-align">
          <app-chips-list [chipsData]="userSkills" [chipsLabel]="userSkills" [subFieldName]="'skill'"
                          module="profile"></app-chips-list>
        </div>

      </div>
    </div>
    <div class="fm-two-column-size">
      <div class="fm-skill">
        <h3>Formations</h3>
        <div *ngIf=" userCourses?.length === 0">Aucune formation attribuée</div>
        <div class="fm-skill-align">
          <app-chips-list [chipsData]="userCourses" [chipsLabel]="userCourses"
                          [subFieldName]="'course'" module="profile"></app-chips-list>
        </div>
      </div>
      <div class="fm-skill">
        <h3>Certifications</h3>
        <div *ngIf=" userCertifications?.length === 0">Aucune certification attribuée</div>
        <div class="fm-skill-align">
          <app-chips-list [chipsData]="userCertifications" [chipsLabel]="userCertifications"
                          [subFieldName]="'certification'" module="profile"></app-chips-list>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="displaySoftSkills" class="container">
  <div class="fm-title">
    <h2>Compétences transverses</h2>
  </div>
  <div class="fm-userSoftskill">
    <div class="fm-table-rh">
      <div *ngFor="let softSkills of evenNumberUserSoftSkillsTable;" class="fm-form-rh">
        <h3>{{ softSkills.category }} </h3>
        <div *ngFor="let softSkill of softSkills.softSkills;" class="rh">
          <div *ngIf="!softSkill.interest && !softSkill.level">
            <div class="softSkillName">
              <div>{{ softSkill.name }}</div>
              <div><i class="fm-level-undefined"></i></div>
            </div>
          </div>
          <div *ngIf="!softSkill.interest">
            <div [ngSwitch]="softSkill.level">
              <div *ngSwitchCase="1">
                <div class="softSkillName">
                  <div>{{ softSkill.name }}</div>
                  <div><i class="fm-level-undefined"></i></div>
                </div>
              </div>
              <div *ngSwitchCase="2">
                <div class="softSkillName">
                  <div>{{ softSkill.name }}</div>
                  <div><i class="fm-level-bronze"></i></div>
                </div>
              </div>
              <div *ngSwitchCase="3">
                <div class="softSkillName">
                  <div>{{ softSkill.name }}</div>
                  <div><i class="fm-level-silver"></i></div>
                </div>
              </div>
              <div *ngSwitchCase="4">
                <div class="softSkillName">
                  <div>{{ softSkill.name }}</div>
                  <div><i class="fm-level-gold"></i></div>
                </div>
              </div>
              <div *ngSwitchCase="5">
                <div class="softSkillName">
                  <div>{{ softSkill.name }}</div>
                  <div><i class="fm-level-lead"></i></div>
                </div>
              </div>
            </div>
          </div>
          <div [ngSwitch]="softSkill.interest ">
            <!-- the same view can be shown in more than one case -->
            <div *ngSwitchCase="3">
              <div [ngSwitch]="softSkill.level">
                <div *ngSwitchCase="1">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-happy-level-undefined"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="2">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-happy-level-bronze"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="3">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-happy-level-silver"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="4">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-happy-level-gold"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="5">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-happy-level-lead"></i></div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="1">
              <div [ngSwitch]="softSkill.level">
                <div *ngSwitchCase="1">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-sad-level-undefined"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="2">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-sad-level-bronze"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="3">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-sad-level-silver"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="4">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-sad-level-gold"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="5">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-sad-level-lead"></i></div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="2">
              <div [ngSwitch]="softSkill.level">
                <div *ngSwitchCase="1">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-equal-level-undefined"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="2">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-equal-level-bronze"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="3">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-equal-level-silver"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="4">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-equal-level-gold"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="5">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-equal-level-lead"></i></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fm-table-rh">
      <div *ngFor="let softSkills of oddNumberUserSoftSkillsTable;" class="fm-form-rh">
        <h3>{{ softSkills.category }} </h3>
        <div *ngFor="let softSkill of softSkills.softSkills;" class="rh">
          <div *ngIf="!softSkill.level && !softSkill.interest">
            <div class="softSkillName">
              <div>{{ softSkill.name }}</div>
              <i class="fm-level-undefined"></i></div>
          </div>
          <div *ngIf="!softSkill.interest">
            <div [ngSwitch]="softSkill.level">
              <div *ngSwitchCase="1">
                <div class="softSkillName">
                  <div>{{ softSkill.name }}</div>
                  <div><i class="fm-level-undefined"></i></div>
                </div>
              </div>
              <div *ngSwitchCase="2">
                <div class="softSkillName">
                  <div>{{ softSkill.name }}</div>
                  <div><i class="fm-level-bronze"></i></div>
                </div>
              </div>
              <div *ngSwitchCase="3">
                <div class="softSkillName">
                  <div>{{ softSkill.name }}</div>
                  <div><i class="fm-level-silver"></i></div>
                </div>
              </div>
              <div *ngSwitchCase="4">
                <div class="softSkillName">
                  <div>{{ softSkill.name }}</div>
                  <div><i class="fm-level-gold"></i></div>
                </div>
              </div>
              <div *ngSwitchCase="5">
                <div class="softSkillName">
                  <div>{{ softSkill.name }}</div>
                  <div><i class="fm-level-lead"></i></div>
                </div>
              </div>
            </div>
          </div>
          <div [ngSwitch]="softSkill.interest">
            <!-- the same view can be shown in more than one case -->
            <div *ngSwitchCase="3">
              <div [ngSwitch]="softSkill.level">
                <div *ngSwitchCase="1">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-happy-level-undefined"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="2">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-happy-level-bronze"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="3">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-happy-level-silver"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="4">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-happy-level-gold"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="5">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-happy-level-lead"></i></div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="1">
              <div [ngSwitch]="softSkill.level">
                <div *ngSwitchCase="1">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-sad-level-undefined"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="2">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-sad-level-bronze"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="3">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-sad-level-silver"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="4">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-sad-level-gold"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="5">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-sad-level-lead"></i></div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="2">
              <div [ngSwitch]="softSkill.level">
                <div *ngSwitchCase="1">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-equal-level-undefined"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="2">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-equal-level-bronze"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="3">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-equal-level-silver"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="4">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-equal-level-gold"></i></div>
                  </div>
                </div>
                <div *ngSwitchCase="5">
                  <div class="softSkillName">
                    <div>{{ softSkill.name }}</div>
                    <div><i class="fm-icon-equal-level-lead"></i></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
