import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Assignement } from '../../../../shared/models/assignement';
import { UserCourse } from '../../../../shared/models/user-course';
import { Constants } from '../../../../shared/constants/constants';

@Component({
  selector: 'app-home-box',
  templateUrl: './home-box.component.html',
  styleUrls: ['./home-box.component.scss']
})
export class HomeBoxComponent implements OnInit {

  @Input()
  public type: string;

  @Input()
  public blocText: string;

  @Input()
  private courses?: UserCourse[];

  @Input()
  private assignments?: Assignement[];

  public displayRhaFormLink: boolean;

  private nowDate: Date;

  public constructor(private router: Router) {}

  public ngOnInit(): void {
    this.nowDate = new Date();
    this.displayRhaFormLink = this.hasMindsAssignmentInProgress() || this.hasCourseInProgress();
  }

  public handleEditProfile(): void {
    this.router.navigateByUrl('/team/summary/members');
  }

  public handleConsultMyProfile(): void {
    this.router.navigateByUrl('/profile/show');
  }

  public handleEditMyProfile(): void {
    this.router.navigateByUrl('/profile/update');
  }

  public handleRhaActivity(): void {
    this.router.navigateByUrl('/activity/rha');
  }

  public handleReportsActivity(): void {
    this.router.navigateByUrl('/activity/reports');
  }

  private hasMindsAssignmentInProgress(): boolean {
    const mindsCustomerNamesRegex = '^(' + Constants.MINDS_CUSTOMER_NAMES.join(')|(') + ')$';
    const results = this.assignments?.filter(
      (assignment) =>
        assignment.project.customer.name.match(new RegExp(mindsCustomerNamesRegex)) &&
        (assignment.endDate >= this.nowDate || assignment.endDate === null)
    );
    return (results === undefined) ? false : results.length !== 0;
  }

  private hasCourseInProgress(): boolean {
    const results = this.courses?.filter(
      (userCourse) => userCourse.endDate >= this.nowDate || userCourse.endDate === null
    );
    return (results === undefined) ? false : results.length !== 0;
  }
}
