<div *ngIf="type === 'team'" class="fm-bloc-team" fxLayoutAlign="center">
  <p-card class="fm-p-card">
    <div fxFlex="100%" [fxLayout]="blocText === 'left' ? 'row' : 'row-reverse'">
      <div class="fm-box-text" fxFlex="50%">
        <div class="fm-box-title"><span>Mon équipe</span></div>
        <div>
          <p>
            La section Mon équipe est accessible aux managers, et permet de gérer les personnes au sein de votre équipe, leur dossier de compétences, leurs missions et leurs entretiens d'évaluation.
          </p>
        </div>
        <div class="fm-global-buttons" fxLayoutGap="25px">
          <div>
            <app-fm-button
              [attr.data-testid]="'button_updateProfile'"
              type="text-info"
              btnText="ÉDITER UN PROFIL"
              (click)="handleEditProfile()">
            </app-fm-button>
          </div>
          <div pTooltip="Fonctionnalité à venir"tooltipPosition="top">
            <app-fm-button
              [attr.data-testid]="'button_newInterview'"
              type="text-gradient"
              btnText="SAISIR UN COMPTE RENDU DE SUIVI">
            </app-fm-button>
          </div>
        </div>
      </div>
      <div class="fm-box-img fm-img-{{blocText === 'left' ? 'right' : 'left'}}" fxFlex="50%">
        <div class="fm-icon-container">
          <i class="fm-icon-team-lg"></i>
        </div>
      </div>
    </div>
  </p-card>
</div>

<div *ngIf="type === 'profile'" class="fm-bloc-profile" fxLayoutAlign="center">
  <p-card>
    <div fxFlex="100%" [fxLayout]="blocText === 'left' ? 'row' : 'row-reverse'">
      <div class="fm-box-text" fxFlex="50%">
        <div class="fm-box-title"><span>Mon profil</span></div>
        <div>
          <p>
            La section Mon profil permet de consulter et mettre à jour son dossier de compétences : postes, spécialités, compétences.
          </p>
        </div>
        <div class="fm-global-buttons" fxLayoutGap="25px">
          <div>
            <app-fm-button
              [attr.data-testid]="'button_showProfile'"
              type="text-info"
              btnText="VOIR MES COMPÉTENCES"
              (click)="handleConsultMyProfile()">
            </app-fm-button>
          </div>
          <div>
            <app-fm-button
              [attr.data-testid]="'button_updateProfile'"
              type="text-gradient"
              btnText="METTRE À JOUR MES COMPÉTENCES"
              (click)="handleEditMyProfile()">
            </app-fm-button>
          </div>
        </div>
      </div>
      <div class="fm-box-img fm-img-{{blocText === 'left' ? 'right' : 'left'}}" fxFlex="50%">
        <div class="fm-icon-container">
          <i class="fm-icon-doc-lg"></i>
        </div>
      </div>
    </div>
  </p-card>
</div>

<div *ngIf="type === 'mission'" class="fm-bloc-mission" fxLayoutAlign="center">
  <p-card>
    <div fxFlex="100%" [fxLayout]="blocText === 'left' ? 'row' : 'row-reverse'">
      <div class="fm-box-text" fxFlex="50%">
        <div class="fm-box-title"><span>Mes missions Minds</span></div>
        <div>
          <p>
            La section Mes missions Minds permet d'accéder aux Rapports Hebdomadaires d'Activité, et de renseigner celui de la semaine courante.
          </p>
        </div>
        <div class="fm-global-buttons" fxLayoutGap="25px">
          <div pTooltip="Fonctionnalité à venir" tooltipPosition="top">
            <app-fm-button
              [attr.data-testid]="'button_showWeeklyReport'"
              type="text-info"
              btnText="VOIR MES RHA">
            </app-fm-button>
          </div>
          <div *ngIf="displayRhaFormLink">
            <app-fm-button
              [attr.data-testid]="'button_addWeeklyReport'"
              type="text-gradient"
              btnText="SAISIR LE RHA DE LA SEMAINE"
              (click)="handleRhaActivity()">
            </app-fm-button>
          </div>
        </div>
      </div>
      <div class="fm-box-img fm-img-{{blocText === 'left' ? 'right' : 'left'}}" fxFlex="50%">
        <div class="fm-icon-container">
          <i class="fm-icon-activity-lg"></i>
        </div>
      </div>
    </div>
  </p-card>
</div>

<div *ngIf="type === 'minds'" class="fm-bloc-minds" fxLayoutAlign="center">
  <p-card>
    <div fxFlex="100%" [fxLayout]="blocText === 'left' ? 'row' : 'row-reverse'">
      <div class="fm-box-text" fxFlex="50%">
        <div class="fm-box-title"><span>Mes missions Minds</span></div>
        <div>
          <p>
            La section Mes missions Minds permet d'accéder aux Rapports Hebdomadaires d'Activité, et de renseigner celui de la semaine courante.
          </p>
        </div>
        <div class="fm-global-buttons" fxLayoutGap="25px">
          <div>
            <app-fm-button
              [attr.data-testid]="'button_showWeeklyReport'"
              type="text-info"
              btnText="VOIR MES RHA"
              (click)="handleReportsActivity()">
            </app-fm-button>
          </div>
          <div>
            <app-fm-button
              [attr.data-testid]="'button_addWeeklyReport'"
              type="text-gradient"
              btnText="SAISIR LE RHA DE LA SEMAINE"
              (click)="handleRhaActivity()">
            </app-fm-button>
          </div>
        </div>
      </div>
      <div class="fm-box-img fm-img-{{blocText === 'left' ? 'right' : 'left'}}" fxFlex="50%">
        <div class="fm-icon-container">
          <i class="fm-icon-activity-lg"></i>
        </div>
      </div>
    </div>
  </p-card>
</div>

<div *ngIf="type === 'certification'" class="fm-bloc-cert" fxLayoutAlign="center">
  <p-card>
    <div fxFlex="100%" [fxLayout]="blocText === 'left' ? 'row' : 'row-reverse'">
      <div class="fm-box-text" fxFlex="50%">
        <div class="fm-box-title"><span>Mes formations & certifications</span></div>
        <div>
          <p>
            La section Mes formations & certifications permet de consulter et éditer ses formations suivies et ses certifications obtenues.
          </p>
        </div>
        <div class="fm-global-buttons" fxLayoutGap="25px">
          <div>
            <app-fm-button
              [attr.data-testid]="'button_showCourse'"
              type="text-info"
              btnText="VOIR MES FORMATIONS"
              (click)="handleConsultMyProfile()">
            </app-fm-button>
          </div>
          <div pTooltip="Fonctionnalité à venir" tooltipPosition="top">
            <app-fm-button
              [attr.data-testid]="'button_askCourse'"
              type="text-gradient"
              btnText="DEMANDER UNE FORMATION">
            </app-fm-button>
          </div>
        </div>
      </div>
      <div class="fm-box-img fm-img-{{blocText === 'left' ? 'right' : 'left'}}" fxFlex="50%">
        <div class="fm-icon-container">
          <i class="fm-icon-label"></i>
        </div>
      </div>
    </div>
  </p-card>
</div>
