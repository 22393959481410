import {User} from '../../../shared/models/user';
// ------------REQUEST-------------------
// ---------------------------------------------
export class GetRequestByRequestId {
  static readonly type = '[Request] GetRequestByRequestId';
  constructor(public requestId: number) {}
}


export class GetFilteredRequest {
  static readonly type = '[Request] GetFilteredRequest';
  constructor(public search: string) {}
}
export class GetRequestByManager {
  static readonly type = '[Request] GetRequestByManager';
  constructor(public user: User, public startDate: string, public endDate: string, public search: string) {}
}

