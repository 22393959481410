<div class="fm-title">
  <h1>5. Rémunération</h1>
</div>
<div class="fm-step">
  <form [formGroup]="formData">
    <div class="fm-form p-d-flex p-flex-column">
      <h2>Collaborateur</h2>
      <p-editor *ngIf="role === 'collaborator' && workflowCollaborator < 3 && !isTooLate && workflowManager < 4" [style]="editorStyle"
        [maxlength]="freeAnswerMaxLength" formControlName="collaboratorSalaryComment" [(ngModel)]="collaboratorSalaryComment" [placeholder]="placeholder"
        (onTextChange)="setValidatorBorder($event)" (focusout)="handleChange($event)">
      </p-editor>
      <span *ngIf="collaboratorSalaryComment && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)" [innerHTML]="collaboratorSalaryComment"
        class="fm-text-editor"></span>
      <span *ngIf="!collaboratorSalaryComment && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)" class="fm-text-editor">Le collaborateur n'a
        pas complété cette partie</span>
      <span *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && !isTooLate && workflowManager < 4" class="fm-text-editor">Le collaborateur n'a
        pas encore validé sa préparation</span>
    </div>
    <div class="fm-form">
      <h2>Manager</h2>
      <p-editor *ngIf="role === 'currentInterviewer' && workflowManager !== 5" [style]="{'height':'320px', 'box-shadow': '0 0 10px #1432461A'}"
        [maxlength]="freeAnswerMaxLength" formControlName="managerSalaryComment" [(ngModel)]="managerSalaryComment" [placeholder]="placeholder"
        (focusout)="handleChange($event)">
      </p-editor>
      <span *ngIf="managerSalaryComment && workflowManager === 5" [innerHTML]="managerSalaryComment" class="fm-text-editor"></span>
      <span *ngIf="managerSalaryComment && role === 'collaborator' && workflowManager !== 5" class="fm-text-editor">Le manager n'a pas encore validé sa
        synthèse</span>
      <span *ngIf="!managerSalaryComment && (workflowManager === 5 || (role === 'collaborator' && workflowManager !== 5))" class="fm-text-editor">Le manager n'a
        pas complété cette partie</span>
    </div>
  </form>
</div>