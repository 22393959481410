
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UploadService } from '../../../core/services/upload-services/upload.service';
import { Constants } from '../../constants/constants';

@Component({
  selector: 'app-form-inline',
  templateUrl: './form-inline.component.html',
  styleUrls: ['./form-inline.component.scss']
})
export class FormInlineComponent implements OnInit {

  @Input()
  titleForm: string;

  @Input()
  listInput: any[] = [];

  @Input()
  saveDisabled = false;

  @Input()
  showSaveButton = true ;

  @Input()
  showClearButton = true ;

  @Input()
  uploadKey = '';

  @Output()
  eventCreateItem = new EventEmitter<any>();

  @Output()
  eventChangeDropdown = new EventEmitter<any>();

  @Output()
  eventLoadEnd = new EventEmitter<boolean>();

  formData: FormGroup;
  initialValues: any;
  loadingEnd = false;
  iconImage: string;
  fr = Constants.CALENDAR_FR;

  constructor(
    private formbuilder: FormBuilder,
    private uploadService: UploadService,
  ) {
    this.formData = this.formbuilder.group({});
  }

  ngOnInit(): void {
    const sortByNameList: string[] = ['profile', 'specialty', 'skill', 'customer', 'course', 'certification'];
    this.listInput.forEach(inputForm => {
      // sort values alphabetically in forms
      if (sortByNameList.includes(inputForm.fieldForm)) {
        inputForm.optionsForm?.sort(
          (a, b) => {
            return a.name.localeCompare(b.name);
          }
        );
      }
      this.formData.addControl(inputForm.fieldForm, inputForm.requiredForm ? new FormControl(inputForm.valueForm , Validators.required)
        : new FormControl(inputForm.valueForm ));
    });
    this.initialValues = this.formData.value;
  }

  handleSaveButtonClick() {
    for (const i in this.formData.controls) {
      if (!this.formData.controls[i].valid) {
        this.formData.controls[i].markAsDirty();
      }
    }
    this.eventCreateItem.emit(this.formData.value);
  }

  handleChangeDropdown(fieldForm: string, event: any) {
    const data: any = { fieldForm, selectedValue: event.value };
    this.eventChangeDropdown.emit(data);
  }

  handleClearForm() {
    this.formData.reset(this.initialValues);
    this.loadingEnd = false;
    this.iconImage = '';
  }

  handleAutocompleteSelect(event) {
    console.log(event);
  }

  handleFileUpload(event: any){
    const file: File = event.files[0];
    this.uploadService.convertFileToString(file).subscribe({
      next: (image: string) => {
        this.loadingEnd = true;
        (document.getElementById(this.uploadKey) as HTMLInputElement).value = image;
        this.iconImage = image;
        this.formData.patchValue({ image });
      }
    });
  }
}
