export enum AnnualReportInput {
  interviewStartDate= 'Date de l\'entretien',
  preparationValidationDate= '',
  collaboratorValidationDate= 'Poste',
  managerValidationDate= '',
  companySeniority= 'Ancienneté dans le groupe',
  position= 'Poste',
  positionSeniority= 'Ancienneté dans le poste',
  collaboratorAssessment= 'Bilan de l\'année écoulée',
  managerAssessment= 'Bilan de l\'année écoulée',
  currentAnnualGoal= 'Niveau d\'atteinte des ojectifs de l\'année écoulée',
  nextAnnualGoal= 'Objectifs pour l\'année à venir',
  managerNextAnnualGoa= 'Objectifs pour l\'année à venir',
  collaboratorNextAnnualGoal= 'Objectifs pour l\'année à venir',
  managerCurrentAnnualGoal= 'Objectifs pour l\'année à venir',
  collaboratorCurrentAnnualGoal= 'Objectifs pour l\'année à venir',
  collaboratorGoalComment= 'Commentaire du collaborateur sur les objectifs',
  managerGoalComment= 'Commentaire du manager sur les objectifs',
  skillsStrength= 'Points forts',
  collaboratorSkillsStrength= 'Points forts',
  managerSkillsStrength= 'Points forts',
  skillsImprovements= 'Axes de développement',
  collaboratorSkillsImprovements= 'Axes de développement',
  managerSkillsImprovements= 'Axes de développement',
  collaboratorSkillsComment= 'Commentaire du collaborateur sur les axes de développement',
  managerSkillsComment= 'Commentaire du manager sur les axes de développement',
  collaboratorSalaryComment= 'Rémunération',
  managerSalaryComment= 'Rémunération',
  collaboratorFinalComment= 'Commentaire final',
  managerFinalComment= 'Commentaire final',
  collaboratorValidationStatus= 'Validation du collaborateur',
  collaboratorValidationComment= 'Commentaire pour la validation'
}
