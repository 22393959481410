<div class="footer">
  <div class="infos">
    <img src="../../../../assets/img/logo_creative.png" class="logo">
    © GROUPE CREATIVE 2020 - Follow'Minds
    {{ version }} -
    <a href=#>Mentions légales</a> -
    <a href=#>Conditions d'utilisation</a>
  </div>
  <app-fm-button type="arrow-up" (click)="scrollToTop()"></app-fm-button>
</div>
