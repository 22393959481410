import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { UserSkill } from '../../../../../../shared/models/user-skill';
import { FormInlineComponent } from '../../../../../../shared/components/form-inline/form-inline.component';
import { Skill } from '../../../../../../shared/models/skill';
import { Interest } from '../../../../../../shared/models/interest';
import { Level } from '../../../../../../shared/models/level';
import { MessageService, SelectItem } from 'primeng/api';
import { SkillService } from '../../../../../../core/services/skill-service/skill.service';
import { ProfileSkillState, ProfileSkillStateModel } from '../../../../store/profile-skills.state';
import { User } from '../../../../../../shared/models/user';
import { UserProfile } from '../../../../../../shared/models/user-profile';
import { ProfileProfileState } from '../../../../store/profile-profiles.state';
import {
  AddProfileUserSkill,
  ClearProfileUserSkills,
  MakeSnapshotProfileUserSkill,
  RestoreSnapshotProfileUserSkill,
  GetProfileUserSkillsByUserId,
  UpdateProfileUserSkill,
  DeleteProfileUserSkill
} from '../../../../store/profile-skills.actions';


@Component({
  selector: 'app-profile-view-skills',
  templateUrl: './profile-view-skills.component.html',
  styleUrls: ['./profile-view-skills.component.scss']
})
export class ProfileViewSkillsComponent implements OnInit, OnDestroy {
  @Select(ProfileSkillState.getUserSkills)
  public userSkills$: Observable<UserSkill[]>;

  @ViewChild(FormInlineComponent)
  private formUserProfile: FormInlineComponent;

  @Input()
  private interests: Interest[] = [];
  @Input()
  private levels: Level[] = [];
  @Input()
  private interestsOptions: SelectItem[] = [];
  @Input()
  private levelsOptions: SelectItem[] = [];

  private currentUser: User;
  private userSkillState: ProfileSkillStateModel;
  private skills: Skill[] = [];
  private itemToDelete: UserSkill;
  private defaultInterestsOptions: SelectItem[] = [];
  private defaultLevelsOptions: SelectItem[] = [];

  public modal: any = {};
  public showModal = false;
  public inputForm: any = [];
  public showAddForm: boolean;
  public disableCreationSave = false;
  public tableColumns: any[];
  public emptyMessage = 'Aucune compétence n\'est enregistrée';
  skillServiceSubscription: Subscription;

  public constructor(
    private messageService: MessageService,
    private skillService: SkillService,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.currentUser = this.store.snapshot().profile.currentUserProfile;
    this.store.dispatch(new GetProfileUserSkillsByUserId(this.currentUser.id));

    this.interestsOptions.map(
      item => this.defaultInterestsOptions.push(item)
    );
    this.levelsOptions.map(
      item => this.defaultLevelsOptions.push(item)
    );


    this.skillServiceSubscription = this.skillService.getAll().subscribe( {
      next: (skill) => {
      skill.forEach(element => {
        if(element.profile != null){
          element.profile = {id: element.profile.id, name: element.profile.name};
        }else{
          element.profile = {id: null, name: ""};
        }
        this.skills.push({...element});
      })},
        error: (e) => this.displayError(e.status),
    });

    this.initDatatable();
    this.initForm();
  }

  private initDatatable(): void {
    this.tableColumns = [
      {
        type: 'image',
        field: 'skill',
        subField: 'image',
        label: '',
        width: '40px',
        isStatic: true,
        sort: false
      },
      {
        type: 'select',
        field: 'skill',
        subField: 'name',
        label: 'Compétence',
        isStatic: true,
        font: 'semibold',
        sort: false
      },
      {
        type: 'select',
        field: 'interest',
        subField: 'name',
        label: 'Intérêt',
        icon: 'interest',
        options: this.interestsOptions,
        optionIcon: true,
        optionOnlyIcon: true,
        clearable: true,
        sort: false
      },
      {
        type: 'select-if-not-validated',
        field: 'level',
        subField: 'value',
        subFieldLabel: 'name',
        label: 'Niveau',
        icon: 'level',
        options: this.levelsOptions,
        optionIcon: true,
        font: 'regular',
        sort: false
      },
      {
        type: 'validation',
        field: 'validation',
        label: 'Validation',
        sort: false,
        isStatic: true
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: false,
        isStatic: true
      },
      { type: 'action', action: 'edit', width: '8%' },
      { type: 'action', action: 'delete', width: '10%' },
    ];
  }

  private initForm(): void {
    this.inputForm = [
      {
        key: 'nameSkill',
        typeForm: 'dropdown',
        filter: true,
        fieldForm: 'skill',
        labelForm: 'Compétence',
        requiredForm: true,
        placeHolder: 'Compétence',
        optionsForm: this.skills,
        optionConcat: 'profile'
      },
      {
        key: 'nameInterest',
        typeForm: 'dropdown',
        fieldForm: 'interest',
        labelForm: 'Intérêt',
        requiredForm: true,
        optionsForm: this.interests,
        placeHolder: 'Intérêt',
        icon: true,
        onlyIcon: true
      },
      {
        key: 'nameLevel',
        typeForm: 'dropdown',
        fieldForm: 'level',
        labelForm: 'Niveau',
        optionsForm: this.levels,
        placeHolder: 'Niveau',
        icon: true
      },
    ];
  }

  public toggleShowAddForm(): void {
    this.showAddForm = !this.showAddForm;
  }

  public handleCreateItem(userSkill: UserSkill): void {
    if (!this.errorsValidation(userSkill, 'create')) {
      userSkill.user = this.currentUser;
      userSkill.validation = null ;
      if (userSkill.level === null) { userSkill.level = this.levels[0]; }
      this.store.dispatch(new AddProfileUserSkill(userSkill)).subscribe(
        () => {
          this.displayMessage('create');
          this.formUserProfile.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  public handleDeleteItem(id: number): void {
    this.userSkillState = this.store.snapshot().profileUserSkills;
    const userSkill = this.userSkillState.userSkills.find((item) => item.id === id);
    this.itemToDelete = userSkill;
    if (userSkill.validation === null) {
      this.displayModal('delete');
    } else {
      this.deleteItem();
    }
  }

  private setInterestOptions(userSkill: UserSkill): void {
    // Interest options
    const indexInterest = this.interests.findIndex(item => item.id === userSkill.interest?.id);
    if (indexInterest === -1) {
      // Put blank interest
      this.interestsOptions.unshift({label: null, value: null, icon: null});
    } else {
      const iconNameInterest = this.interestsOptions[indexInterest].icon;
      // Delete current Interest value of the interests options with indexInterest help
      this.interestsOptions.splice(indexInterest, 1);
      // Put current Interest value at first Interests options place
      this.interestsOptions.unshift({ label: userSkill.interest?.name, value: userSkill.interest, icon: iconNameInterest });
    }
  }

  private setLevelOptions(userSkill: UserSkill): void {
    // Level options
    const indexLevel = this.levels.findIndex(item => item.id === userSkill.level?.id);
    // Get icon's name
    const iconNameLevel = this.levelsOptions[indexLevel].icon;
    // Delete current Level value of the levels options with indexLevel help
    this.levelsOptions.splice(indexLevel, 1);
    // Put current Level value at first Levels options place
    this.levelsOptions.unshift({ label: userSkill.level.name, value: userSkill.level, icon: iconNameLevel });
  }

  private resetInterestsOptions(): void {
    const length = this.interestsOptions.length;
    this.interestsOptions.splice(0, length);
    this.defaultInterestsOptions.map(
      item => this.interestsOptions.push(item)
    );
  }

  private resetLevelsOptions(): void {
    const length = this.levelsOptions.length;
    this.levelsOptions.splice(0, length);
    this.defaultLevelsOptions.map(
      item => this.levelsOptions.push(item)
    );
  }

  public handleUpdateItem(userSkill: UserSkill): void {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotProfileUserSkill());

    this.resetInterestsOptions();
    this.resetLevelsOptions();
    this.setInterestOptions(userSkill);
    this.setLevelOptions(userSkill);
  }

  public handleSaveUpdateItem(userSkill: UserSkill): void {
    if (!this.errorsValidation(userSkill, 'update')) {
      this.store.dispatch(new UpdateProfileUserSkill(userSkill)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.disableCreationSave = false;
        }
      );
    }
  }

  public handleCancelEditItem(): void {
    this.disableCreationSave = false;
    this.store.dispatch(new RestoreSnapshotProfileUserSkill());
  }

  public handleModalConfirm(): void {
    this.deleteItem();
  }

  private deleteItem(): void {
    this.store.dispatch(new DeleteProfileUserSkill(this.itemToDelete)).subscribe(
      (store) => {
        if (store.profileUserSkills.httpStatus === 200) {
          this.displayMessage('delete');
        } else {
          this.displayMessage('delete-notification');
        }
      },
      (e) => this.displayError(e.status)
    );
  }

  /**
   * Forms validation (create and update)
   * @param userSkill UserSkill
   * @param action string
   * @return boolean
   */
  private errorsValidation(userSkill: UserSkill, action: string): boolean {
    let errors = false;
    if (!userSkill.skill) {
      this.displayError('skill');
      errors = true;
    }
    if (!userSkill.interest) {
      this.displayError('interest');
      errors = true;
    }
    return errors;
  }

  private displayModal(type: string): void {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  private displayMessage(type: string): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La compétence a bien été sauvegardée';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La compétence a bien été sauvegardée';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'Compétence supprimée.';
        break;
      }
      case 'delete-notification': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'La demande de suppression a bien été envoyée à votre manager principal.';
        break;
      }
    }
    this.messageService.add({severity, summary, detail});
  }

  private displayError(e: any): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Cette compétence est déjà attribuée à cet utilisateur.';
        break;
      case 'skill':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner une compétence.';
        break;
      case 'interest':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un intérêt.';
        break;
      case 501:
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  public ngOnDestroy(): void {
    this.resetLevelsOptions();
    this.resetInterestsOptions();
    this.store.dispatch(new ClearProfileUserSkills());
    this.skillServiceSubscription.unsubscribe();
  }
}
