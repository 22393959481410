import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Select, Store} from '@ngxs/store';
import {ReportState} from '../../../../../stores/reports/report.state';
import {Observable, Subscription} from 'rxjs';
import {SetPendingAnnualReport} from '../../../../../stores/reports/report.action';
import {AnnualReport} from '../../../models/annual-report';
import {AuthenticationState} from '../../../../../stores/authentication/authentication.state';
import {User} from '../../../models/user';
import {AnnualReportService} from '../../../../core/services/annual-report-service/annual-report.service';
import {Goal} from '../../../models/goal';
import {Constants} from '../../../constants/constants';
import {SelectItem} from 'primeng/api';
import {GoalLevel} from '../../../models/goal-level';
import {FormInlineComponent} from '../../form-inline/form-inline.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit, OnDestroy {
  @Select(ReportState.getRole) role$: Observable<string>;
  @Select(AuthenticationState.getAnnualReportUser) annualReportUser$: Observable<User>;
  @Select(ReportState.getAnnualReport) annualReport$: Observable<AnnualReport>;
  @Select(ReportState.getPendingAnnualReport) pendingAnnualReport$: Observable<AnnualReport>;

  @ViewChild(FormInlineComponent)
  private formsAdd: FormInlineComponent;

  freeAnswerMaxLength = 3000;
  formData: FormGroup;
  currentGoalDataColumns: any[];
  currentGoalDataColumnsWithoutActions: any[];
  nextGoalDataColumns: any[];
  nextGoalDataColumnsWithoutActions: any[];
  levelOptions: SelectItem[] = [
    { label: GoalLevel.GOAL_LEVEL_NOT_EVALUABLE, value: { name: GoalLevel.GOAL_LEVEL_NOT_EVALUABLE, value: 'GOAL_LEVEL_NOT_EVALUABLE' } },
    { label: GoalLevel.GOAL_LEVEL_NOT_REACHED, value: { name: GoalLevel.GOAL_LEVEL_NOT_REACHED, value: 'GOAL_LEVEL_NOT_REACHED' } },
    { label: GoalLevel.GOAL_LEVEL_REACHED, value: { name: GoalLevel.GOAL_LEVEL_REACHED, value: 'GOAL_LEVEL_REACHED' } },
    { label: GoalLevel.GOAL_LEVEL_EXCEEDED, value: { name: GoalLevel.GOAL_LEVEL_EXCEEDED, value: 'GOAL_LEVEL_EXCEEDED' } }
  ];
  levelFormOptions: any[] = [
    { name: GoalLevel.GOAL_LEVEL_NOT_EVALUABLE, value: 'GOAL_LEVEL_NOT_EVALUABLE' },
    { name: GoalLevel.GOAL_LEVEL_NOT_REACHED, value: 'GOAL_LEVEL_NOT_REACHED' },
    { name: GoalLevel.GOAL_LEVEL_REACHED, value: 'GOAL_LEVEL_REACHED' },
    { name: GoalLevel.GOAL_LEVEL_EXCEEDED, value: 'GOAL_LEVEL_EXCEEDED' }
  ];
  goalEmptyMessage = 'Pas d\'objectif renseigné';

  nextAnnualGoalPlaceholder: string;
  currentAnnualGoalPlaceholder: string;

  pendingAnnualReportPreparation: AnnualReport;
  pendingAnnualReport: AnnualReport;
  pendingAnnualReportSubscription: Subscription;

  roleSubscription: Subscription;
  role: string;

  workflowCollaborator: number;
  workflowManager: number;
  isTooLate: boolean;

  collaboratorCurrentGoalDisableCreationSave = false;
  collaboratorCurrentGoalShowAddForm = false;
  collaboratorCurrentGoalInputForm: any = [];
  collaboratorCurrentGoals: Goal[];
  collaboratorNextAnnualGoal: string;
  collaboratorNextGoalDisableCreationSave = false;
  collaboratorNextGoalShowAddForm = false;
  collaboratorNextGoalInputForm: any = [];
  collaboratorNextGoals: Goal[] = [];
  collaboratorGoalComment: string;
  collaboratorGoalCommentPlaceholder: string;

  managerCurrentGoalDisableCreationSave = false;
  managerCurrentGoalShowAddForm = false;
  managerCurrentGoalInputForm: any = [];
  managerCurrentGoals: Goal[];
  managerNextAnnualGoal: string;
  managerNextGoalDisableCreationSave = false;
  managerNextGoalShowAddForm = false;
  managerNextGoalInputForm: any = [];
  managerNextGoals: Goal[] = [];
  managerGoalComment: string;
  managerGoalCommentPlaceholder: string;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private router: Router,
    private annualReportService: AnnualReportService
  ) {
    this.formData = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.roleSubscription = this.role$.subscribe((role) => {
      this.pendingAnnualReportSubscription = this.pendingAnnualReport$.subscribe((pendingAnnualReport: AnnualReport) => {
        if (pendingAnnualReport){
          this.pendingAnnualReport = pendingAnnualReport;
          // Set goals
          this.setCollaboratorCurrentGoals();
          this.setCollaboratorNextGoals();
          this.setManagerCurrentGoals();
          this.setManagerNextGoals();

          if (role === 'collaborator') {
            this.initCollaboratorForm();
          } else if (role === 'currentInterviewer') {
            this.initManagerForm();
          }

          // Fill input form fields
          this.fillInputWithAnnualReport(pendingAnnualReport);

          this.workflowCollaborator = pendingAnnualReport.workflowCollaborator;
          this.workflowManager = pendingAnnualReport.workflowManager;
          this.isTooLate = this.annualReportService.isTooLate(pendingAnnualReport.interviewStartDate);
          this.initDatatablesCurrentGoal();
          this.initDatatablesCurrentGoalWithoutActions();
          this.initDatatablesNextGoal();
          this.initDatatablesNextGoalWithoutActions();
        } else {
          // To hook refresh web browser action
          this.router.url.includes('activity') ?
            this.router.navigateByUrl('/activity/reports') :
            this.router.navigateByUrl('/team/reports');
        }
      });
      this.role = role;
    });

    this.collaboratorGoalCommentPlaceholder = 'Commentaire du collaborateur (' + this.freeAnswerMaxLength + ' caractères max)...';
    this.managerGoalCommentPlaceholder = 'Commentaire du manager (' + this.freeAnswerMaxLength + ' caractères max)...';
  }

  initDatatablesCurrentGoal() {
    this.currentGoalDataColumns = [
      {
        type: 'text',
        field: 'name',
        label: 'Objectifs',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
      {
        type: 'select',
        options: this.levelOptions,
        field: 'level',
        subField: 'name',
        label: 'Niveaux d\'atteinte',
        font: 'regular',
        sort: false,
      },
      { type: 'action', action: 'delete', label: 'Supprimer', width: '10%' },
    ];
  }

  initDatatablesCurrentGoalWithoutActions() {
    this.currentGoalDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Objectifs',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
      {
        type: 'select',
        options: this.levelOptions,
        field: 'level',
        subField: 'name',
        label: 'Niveaux d\'atteinte',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: '',
        label: '',
        font: 'regular',
        sort: false,
        colSize: 'fm-cell-delete'
      },
    ];
  }

  initDatatablesNextGoal() {
    this.nextGoalDataColumns = [
      {
        type: 'text',
        field: 'name',
        label: 'Objectifs',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: '',
        label: '',
        font: 'regular',
        sort: false,
      },
      { type: 'action', action: 'delete', label: 'Supprimer', width: '10%' },
    ];
  }

  initDatatablesNextGoalWithoutActions() {
    this.nextGoalDataColumnsWithoutActions = [
      {
        type: 'text',
        field: 'name',
        label: 'Objectifs',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'deadLine',
        label: 'Délais',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: 'ways',
        label: 'Moyens',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: '',
        label: '',
        font: 'regular',
        sort: false,
      },
      {
        type: 'text',
        field: '',
        label: '',
        font: 'regular',
        sort: false,
        colSize: 'fm-cell-delete'
      },
    ];
  }

  fillInputWithAnnualReport(annualReport: AnnualReport){
    if (annualReport){
      this.collaboratorGoalComment = annualReport.collaboratorGoalComment;
      this.managerGoalComment = annualReport.managerGoalComment;
    }
  }

  handleChange(event: any){
    if (event && this.role === 'collaborator'){
      this.pendingAnnualReport.collaboratorGoalComment = this.formData.value.collaboratorGoalComment;
    }
    if (event && this.role === 'currentInterviewer'){
      this.pendingAnnualReport.managerGoalComment = this.formData.value.managerGoalComment;
    }
    this.store.dispatch(new SetPendingAnnualReport(this.pendingAnnualReport));
  }

  /********** Collaborator **************/

  initCollaboratorForm() {
    this.initCollaboratorCurrentGoalForm();
    this.initCollaboratorNextGoalForm();
    this.formData.addControl('collaboratorGoalComment', new FormControl(null, Validators.required));
  }

  initCollaboratorCurrentGoalForm() {
    this.collaboratorCurrentGoalInputForm = [
      {
        key: 'nameGoal',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Objectif',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'deadLineGoal',
        typeForm: 'text',
        fieldForm: 'deadLine',
        labelForm: 'Délais',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'waysGoal',
        typeForm: 'text',
        fieldForm: 'ways',
        labelForm: 'Moyens',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'levelGoal',
        typeForm: 'dropdown',
        fieldForm: 'level',
        labelForm: 'Niveau d\'atteinte',
        requiredForm: true,
        optionsForm: this.levelFormOptions,
        placeHolder: 'Niveau d\'atteinte'
      },
    ];
  }

  initCollaboratorNextGoalForm() {
    this.collaboratorNextGoalInputForm = [
      {
        key: 'nameGoal',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Objectif',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'deadLineGoal',
        typeForm: 'text',
        fieldForm: 'deadLine',
        labelForm: 'Délais',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'waysGoal',
        typeForm: 'text',
        fieldForm: 'ways',
        labelForm: 'Moyens',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      }
    ];
  }

  setCollaboratorCurrentGoals() {
    this.collaboratorCurrentGoals = [];
    const collaboratorCurrentGoals = this.pendingAnnualReport.goals.filter(goal => goal.emitter === 1 && goal.year === 1);
    collaboratorCurrentGoals.forEach(item => {
      const goal = {...item};
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.collaboratorCurrentGoals.push(goal);
    });
  }

  setCollaboratorNextGoals() {
    this.collaboratorNextGoals = [];
    const collaboratorNextGoals = this.pendingAnnualReport.goals.filter(goal => goal.emitter === 1 && goal.year === 2);
    collaboratorNextGoals.forEach(item => {
      const goal = {...item};
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.collaboratorNextGoals.push(goal);
    });
  }

  toggleCollaboratorCurrentGoalForm() {
    this.collaboratorCurrentGoalShowAddForm = !this.collaboratorCurrentGoalShowAddForm;
  }

  toggleCollaboratorNextGoalForm() {
    this.collaboratorNextGoalShowAddForm = !this.collaboratorNextGoalShowAddForm;
  }

  handleCollaboratorCurrentGoalCreateItem(goal: Goal){
    const newGoal = {...goal};
    if (goal && goal.name && goal.deadLine && goal.ways && goal.level) {
      newGoal.reportId = this.pendingAnnualReport.id;
      newGoal.emitter = 1;
      newGoal.year = 1;
      newGoal.level = goal.level.value;
      this.pendingAnnualReport.goals = [...this.pendingAnnualReport.goals, newGoal];
      this.setCollaboratorCurrentGoals();
      this.formsAdd.handleClearForm();
    }
  }

  handleCollaboratorNextGoalCreateItem(goal: Goal){
    if (goal && goal.name && goal.deadLine && goal.ways) {
      goal.reportId = this.pendingAnnualReport.id;
      goal.emitter = 1;
      goal.year = 2;
      this.pendingAnnualReport.goals = [...this.pendingAnnualReport.goals, goal];
      this.setCollaboratorNextGoals();
      this.formsAdd.handleClearForm();
    }
  }

  handleCollaboratorCurrentGoalDeleteItem(event){
    let index = null;
    if (typeof event === 'number') {
      index = this.pendingAnnualReport.goals.findIndex(g => g.id === event);
    } else if (typeof event === 'object') {
      index = this.pendingAnnualReport.goals.findIndex(g => g.name === event.name && g.emitter === 1 && g.year === 1);
    }
    if (index >= 0) {
      this.pendingAnnualReport.goals.splice(index, 1);
      this.setCollaboratorCurrentGoals();
    }
  }

  handleCollaboratorNextGoalDeleteItem(event){
    let index = null;
    if (typeof event === 'number') {
      index = this.pendingAnnualReport.goals.findIndex(g => g.id === event);
    } else if (typeof event === 'object') {
      index = this.pendingAnnualReport.goals.findIndex(g => g.name === event.name && g.emitter === 1 && g.year === 2);
    }
    if (index >= 0) {
      this.pendingAnnualReport.goals.splice(index, 1);
      this.setCollaboratorNextGoals();
    }
  }

  handleCollaboratorCurrentGoalSaveUpdateItem(event){}

  handleCollaboratorNextGoalSaveUpdateItem(event){}

  handleCollaboratorCurrentGoalCancelEditItem(){}

  handleCollaboratorNextGoalCancelEditItem(){}


  /********** Manager **************/

  initManagerForm() {
    this.initManagerCurrentGoalForm();
    this.initManagerNextGoalForm();
    this.formData.addControl('managerGoalComment', new FormControl(null, Validators.required));
  }

  initManagerCurrentGoalForm() {
    this.managerCurrentGoalInputForm = [
      {
        key: 'nameGoal',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Objectif',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'deadLineGoal',
        typeForm: 'text',
        fieldForm: 'deadLine',
        labelForm: 'Délais',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'waysGoal',
        typeForm: 'text',
        fieldForm: 'ways',
        labelForm: 'Moyens',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'levelGoal',
        typeForm: 'dropdown',
        fieldForm: 'level',
        labelForm: 'Niveau d\'atteinte',
        requiredForm: true,
        optionsForm: this.levelFormOptions,
        placeHolder: 'Niveau d\'atteinte'
      },
    ];
  }

  initManagerNextGoalForm() {
    this.managerNextGoalInputForm = [
      {
        key: 'nameGoal',
        typeForm: 'text',
        fieldForm: 'name',
        labelForm: 'Objectif',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'deadLineGoal',
        typeForm: 'text',
        fieldForm: 'deadLine',
        labelForm: 'Délais',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
      {
        key: 'waysGoal',
        typeForm: 'text',
        fieldForm: 'ways',
        labelForm: 'Moyens',
        valueForm: '',
        requiredForm: true,
        maxLength: Constants.EAE_INPUT_TEXT_SIZE_MAX,
      },
    ];
  }

  setManagerCurrentGoals() {
    this.managerCurrentGoals = [];
    const managerCurrentGoals = this.pendingAnnualReport.goals.filter(goal => goal.emitter === 2 && goal.year === 1);
    managerCurrentGoals.forEach(item => {
      const goal = {...item};
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.managerCurrentGoals.push(goal);
    });
  }

  setManagerNextGoals() {
    this.managerNextGoals = [];
    const managerNextGoals = this.pendingAnnualReport.goals.filter(goal => goal.emitter === 2 && goal.year === 2);
    managerNextGoals.forEach(item => {
      const goal = {...item};
      goal.level = { name: GoalLevel[item.level], value: item.level };
      this.managerNextGoals.push(goal);
    });
  }

  toggleManagerCurrentGoalForm() {
    this.managerCurrentGoalShowAddForm = !this.managerCurrentGoalShowAddForm;
  }

  toggleManagerNextGoalForm() {
    this.managerNextGoalShowAddForm = !this.managerNextGoalShowAddForm;
  }

  handleManagerCurrentGoalCreateItem(goal: Goal){
    const newGoal = {...goal};
    if (goal && goal.name && goal.deadLine && goal.ways && goal.level) {
      newGoal.reportId = this.pendingAnnualReport.id;
      newGoal.emitter = 2;
      newGoal.year = 1;
      newGoal.level = goal.level.value;
      this.pendingAnnualReport.goals = [...this.pendingAnnualReport.goals, newGoal];
      this.setManagerCurrentGoals();
    }
  }

  handleManagerNextGoalCreateItem(goal: Goal){
    if (goal && goal.name && goal.deadLine && goal.ways) {
      goal.reportId = this.pendingAnnualReport.id;
      goal.emitter = 2;
      goal.year = 2;
      this.pendingAnnualReport.goals = [...this.pendingAnnualReport.goals, goal];
      this.setManagerNextGoals();
    }
  }

  handleManagerCurrentGoalDeleteItem(event){
    let index = null;
    if (typeof event === 'number') {
      index = this.pendingAnnualReport.goals.findIndex(g => g.id === event);
    } else if (typeof event === 'object') {
      index = this.pendingAnnualReport.goals.findIndex(g => g.name === event.name && g.emitter === 2 && g.year === 1);
    }
    if (index >= 0) {
      this.pendingAnnualReport.goals.splice(index, 1);
      this.setManagerCurrentGoals();
    }
  }

  handleManagerNextGoalDeleteItem(event){
    let index = null;
    if (typeof event === 'number') {
      index = this.pendingAnnualReport.goals.findIndex(g => g.id === event);
    } else if (typeof event === 'object') {
      index = this.pendingAnnualReport.goals.findIndex(g => g.name === event.name && g.emitter === 2 && g.year === 2);
    }
    if (index >= 0) {
      this.pendingAnnualReport.goals.splice(index, 1);
      this.setManagerNextGoals();
    }
  }

  handleManagerCurrentGoalSaveUpdateItem(event){}

  handleManagerNextGoalSaveUpdateItem(event){}

  handleManagerCurrentGoalCancelEditItem(){}

  handleManagerNextGoalCancelEditItem(){}

  ngOnDestroy(): void {
    this.roleSubscription.unsubscribe();
    this.pendingAnnualReportSubscription.unsubscribe();
  }
}
