import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarService } from '../../core/services/navbar-services/navbar.service';
import { SharedModule } from '../../shared/shared.module';
import { PrimeNGModule } from '../../shared/prime.module';
import { HistoryComponent } from './pages/history.component';

import myLocaleFr from '@angular/common/locales/fr'
import {registerLocaleData} from '@angular/common/';

registerLocaleData(myLocaleFr);
@NgModule({
  declarations: [
      HistoryComponent,
    ],
  imports: [
    CommonModule,
    SharedModule,
    PrimeNGModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [
    HistoryComponent,
  ],
  providers: [NavbarService]
})
export class HistoryModule { }
