
<div [ngSwitch]="type">
  <!-- edit -->
  <button *ngSwitchCase="'edit'" [disabled]="btnDisabled" class="fm-btn fm-btn-primary fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-edit fm-icon-white fm-icon-sm"></i>
  </button>

  <!-- fusion -->
  <button *ngSwitchCase="'fusion'" [disabled]="btnDisabled" class="fm-btn fm-btn-primary fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-merge fm-icon-white fm-icon-sm"></i>
  </button>

  <!-- save -->
  <button *ngSwitchCase="'save'" [disabled]="btnDisabled" class="fm-btn fm-btn-primary fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-save fm-icon-white fm-icon-sm"></i>
  </button>

  <!-- save disabled -->
  <button *ngSwitchCase="'save-disabled'" class="fm-btn fm-btn-disabled fm-btn-sm" [disabled]="btnDisabled">
    <i class="fm-icon-save fm-icon-white fm-icon-sm" pTooltip="Edition en cours"></i>
  </button>

  <!-- void -->
  <button *ngSwitchCase="'void'" [disabled]="btnDisabled" class="fm-btn fm-btn-secondary fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-close fm-icon-grey fm-icon-xs"></i>
  </button>

  <!-- void blank -->
  <button *ngSwitchCase="'void-blank'" [disabled]="btnDisabled" class="fm-btn fm-btn-blank fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-close fm-icon-grey fm-icon-xs"></i>
  </button>

  <!-- delete -->
  <button *ngSwitchCase="'delete'" [disabled]="btnDisabled" class="fm-btn fm-btn-secondary fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-trash fm-icon-grey fm-icon-sm"></i>
  </button>

  <!-- search -->
  <button *ngSwitchCase="'search'" [disabled]="btnDisabled" class="fm-btn fm-btn-gradient fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-search fm-icon-white fm-icon-sm"></i>
  </button>

  <!-- add -->
  <button *ngSwitchCase="'add'" [disabled]="btnDisabled" class="fm-btn fm-btn-lg fm-btn-gradient" (click)="onClick.emit($event)">
    <span>AJOUTER</span>
  </button>

  <!-- confirm -->
  <button *ngSwitchCase="'confirm'" [disabled]="btnDisabled" class="fm-btn fm-btn-lg fm-btn-gradient" (click)="onClick.emit($event)">
    <span>{{btnText || "CONFIRMER"}}</span>
  </button>

   <!-- Fusion -->
   <button *ngSwitchCase="'fusion_confirm'" [disabled]="btnDisabled" class="fm-btn fm-btn-lg fm-btn-gradient" (click)="onClick.emit()">
    <span>FUSIONNER</span>
  </button>

  <!-- cancel -->
  <button *ngSwitchCase="'cancel'" [disabled]="btnDisabled" class="fm-btn fm-btn-lg fm-btn-info" (click)="onClick.emit($event)">
    <span>ANNULER</span>
  </button>

  <!-- yes -->
  <button *ngSwitchCase="'yes'" [disabled]="btnDisabled" class="fm-btn fm-btn-lg fm-btn-gradient" (click)="onClick.emit($event)">
    <span>OUI</span>
  </button>

  <!-- no -->
  <button *ngSwitchCase="'no'" [disabled]="btnDisabled" class="fm-btn fm-btn-lg fm-btn-info" (click)="onClick.emit($event)">
    <span>NON</span>
  </button>

  <!-- exit -->
  <button *ngSwitchCase="'exit'" [disabled]="btnDisabled" class="fm-btn fm-btn-blank fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-out"></i>
  </button>

  <!-- out -->
  <button *ngSwitchCase="'out'" [disabled]="btnDisabled" class="fm-btn fm-btn-secondary fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-out fm-icon-grey fm-icon-sm"></i>
  </button>

  <!-- Show -->
  <button *ngSwitchCase="'show'" [disabled]="btnDisabled" class="fm-btn fm-btn-gradient fm-btn-sm" (click)="onClick.emit($event)">
      <i class="fm-icon-plus fm-icon-white fm-icon-sm"></i>
  </button>

  <!-- Hide -->
  <button *ngSwitchCase="'hide'" [disabled]="btnDisabled" class="fm-btn fm-btn-gradient fm-btn-sm" (click)="onClick.emit($event)">
      <i class="fm-icon-minus fm-icon-white fm-icon-sm"></i>
  </button>

  <!-- Reset team user -->
  <button *ngSwitchCase="'reset-user'" [disabled]="btnDisabled" class="fm-btn fm-btn-secondary fm-btn-square-40" (click)="onClick.emit($event)">
    <i class="fm-icon-team"></i>
  </button>

  <!-- text-info -->
  <button *ngSwitchCase="'text-info'" [disabled]="btnDisabled" class="fm-btn fm-btn-info fm-btn-unsize" (click)="onClick.emit($event)">
    <span>{{btnText}}</span>
  </button>

  <!-- fade fade -->
  <button *ngSwitchCase="'text-fade'" [disabled]="btnDisabled" class="fm-btn fm-btn-fade fm-btn-unsize" (click)="onClick.emit($event)">
    <span>{{btnText}}</span>
  </button>

  <!-- text-gradient -->
  <button *ngSwitchCase="'text-gradient'" [disabled]="btnDisabled" class="fm-btn fm-btn-gradient fm-btn-unsize" (click)="onClick.emit($event)">
    <span>{{btnText}}</span>
  </button>

  <!-- text-transparent -->
  <button *ngSwitchCase="'text-transparent'" [disabled]="btnDisabled" class="fm-btn fm-btn-transparent fm-button fm-btn-unsize" (click)="onClick.emit($event)">
    <span>{{btnText}}</span>
  </button>

  <!-- CR -->
  <button *ngSwitchCase="'cr'" [disabled]="btnDisabled" class="fm-btn fm-btn-secondary fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-doc fm-icon-blue fm-icon-sm"></i>
  </button>

  <!--CR disabled -->
  <button *ngSwitchCase="'cr-disabled'" class="fm-btn fm-btn-secondary-disabled  fm-btn-sm" [disabled]="btnDisabled">
    <i class="fm-icon-doc fm-icon-grey fm-icon-sm" pTooltip="{{tooltipText}}"></i>
  </button>

  <!-- ID -->
  <button *ngSwitchCase="'in-out-person'" [disabled]="btnDisabled" class="fm-btn fm-btn-gradient fm-btn-unsize" (click)="onClick.emit($event)">
    <i class="fm-icon-id fm-icon-white fm-icon-sm"></i>
    <span>{{btnText}}</span>
  </button>

  <!-- More -->
  <button *ngSwitchCase="'more'" [disabled]="btnDisabled" class="fm-btn fm-btn-unsize fm-btn-info" (click)="onClick.emit($event)">
    <span>{{btnText}}</span>
  </button>

  <!-- open -->
  <button *ngSwitchCase="'open'" [disabled]="btnDisabled" class="fm-btn fm-btn-primary fm-btn-open" (click)="onClick.emit($event)">
    <i class="fm-icon-arrow fm-icon-grey fm-icon-xs"></i>
  </button>

  <!-- RHA -->
  <button *ngSwitchCase="'rha'" [disabled]="btnDisabled" class="fm-btn fm-btn-blank fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-report"></i>
  </button>

  <!-- arrow up -->
  <button *ngSwitchCase="'arrow-up'" [disabled]="btnDisabled" class="fm-btn fm-btn-arrow-top fm-btn-square-40" (click)="onClick.emit($event)">
    <i class="fm-icon-arrow-up fm-icon-white fm-icon-sm"></i>
  </button>

  <!-- check -->
  <button *ngSwitchCase="'check'" [disabled]="btnDisabled" class="fm-btn fm-btn-secondary fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-check fm-icon-white fm-icon-sm"></i>
  </button>

  <!-- close -->
  <button *ngSwitchCase="'close'" [disabled]="btnDisabled" class="fm-btn fm-btn-secondary fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-close-validation fm-icon-white fm-icon-sm"></i>
  </button>

  <!-- export -->
  <button *ngSwitchCase="'export'" class="fm-btn fm-btn-unsize-light fm-btn-gradient" (click)="onClick.emit($event)">
    <span>{{btnText}}</span>
  </button>

  <!-- import -->
  <button *ngSwitchCase="'import'" class="fm-btn fm-btn-unsize-light fm-btn-gradient" (click)="onClick.emit($event)">
    <span>{{btnText}}</span>
  </button>

  <!-- default -->
  <button *ngSwitchDefault [disabled]="btnDisabled" class="fm-btn fm-btn-lg fm-btn-primary" (click)="onClick.emit($event)">
    <span>DEFAULT</span>
  </button>
  <!-- consulter -->
  <button *ngSwitchCase="'consult'" [disabled]="btnDisabled" class="fm-btn fm-btn-secondary fm-btn-sm" (click)="onClick.emit($event)">
    <i class="fm-icon-doc fm-icon-grey fm-icon-sm"></i>
  </button>
</div>
