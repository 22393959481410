import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { TeamStateModel } from '../../../../store/team.state';
import { TeamCourseState, TeamCourseStateModel } from '../../../../store/team-courses.state';
import {
  AddUserCourse,
  ClearUserCourses,
  DeleteUserCourse,
  GetUserCoursesByUserId,
  MakeSnapshotUserCourse,
  RestoreSnapshotUserCourse,
  UpdateUserCourse
} from '../../../../store/team-courses.actions';
import { MessageService, SelectItem } from 'primeng/api';
import { FormInlineComponent } from '../../../../../../shared/components/form-inline/form-inline.component';
import { UserCourse } from '../../../../../../shared/models/user-course';
import { Course } from '../../../../../../shared/models/course';
import { Interest } from '../../../../../../shared/models/interest';
import { Constants } from '../../../../../../shared/constants/constants';
import { CourseService } from '../../../../../../core/services/course-services/course.service';


@Component({
  selector: 'app-view-courses',
  templateUrl: './view-courses.component.html',
  styleUrls: ['./view-courses.component.scss']
})
export class ViewCoursesComponent implements OnInit, OnDestroy {
  @Select(TeamCourseState.getUserCourses)
  public userCourses$: Observable<UserCourse[]>;

  @ViewChild(FormInlineComponent)
  private formUserCourse: FormInlineComponent;

  @Input()
  private interests: Interest[] = [];

  @Input()
  private interestsOptions: SelectItem[] = [];

  public inputForm: any = [];
  public showAddForm: boolean;
  public disableCreationSave = false;
  public tableColumns: any[];
  public emptyMessage = 'Aucune formation n\'est enregistée';
  public modal: any = {};
  public showModal = false;

  private teamState: TeamStateModel;
  private userCourseState: TeamCourseStateModel;
  private userCourses: UserCourse[];
  private itemToDelete: UserCourse;
  private courses: Course[] = [];
  private defaultInterestsOptions: SelectItem[] = [];
  private userCourseSubscription: Subscription;

  public constructor(
    private messageService: MessageService,
    private courseService: CourseService,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.teamState = this.store.snapshot().team;
    this.store.dispatch(new GetUserCoursesByUserId(this.teamState.currentTeamUser.id));

    this.interestsOptions.map(
      item => this.defaultInterestsOptions.push(item)
    );

    this.initDatatable();

    this.userCourseSubscription = this.courseService.getAll().subscribe({
      next: (courses: Course[]) => this.courses = courses,
      error: (e) => this.displayError(e.status),
      complete: () => {
        this.initForm();
      }
    });
  }

  private initDatatable(): void {
    this.tableColumns = [
      {
        type: 'icon',
        isStatic: true,
        className: 'fm-icon-cup',
        sort: false,
      },
      {
        type: 'text',
        field: 'course',
        subField: 'name',
        label: 'Formation',
        isStatic: true,
        font: 'semibold',
        colSize: 'fm-course-size',
        sort: false
      },
      {
        type: 'select',
        field: 'interest',
        subField: 'name',
        label: 'Intérêt',
        icon: 'interest',
        options: this.interestsOptions,
        optionIcon: true,
        optionOnlyIcon: true,
        clearable: true,
        colSize: 'fm-interest-size',
        sort: false
      },
      {
        type: 'text',
        field: 'comment',
        label: 'Commentaire',
        icon: 'level',
        font: 'regular',
        sort: false,
        maxLength: Constants.COMMENT_SIZE_MAX
      },
      {
        type: 'rangeCalendar',
        field: 'rangeDates',
        label: 'Dates',
        font: 'regular',
        colSize: 'fm-date-size',
        sort: false
      },
      { type: 'action', action: 'edit', width: '8%' },
      { type: 'action', action: 'delete', width: '10%' },
    ];
  }

  private initForm(): void {
    this.inputForm = [
      {
        key: 'nameCourse',
        typeForm: 'dropdown',
        filter: true,
        fieldForm: 'course',
        labelForm: 'Formation',
        requiredForm: true,
        placeHolder: 'Formation',
        optionsForm: this.courses,
      },
      {
        key: 'nameInterest',
        typeForm: 'dropdown',
        fieldForm: 'interest',
        labelForm: 'Intérêt',
        optionsForm: this.interests,
        placeHolder: 'Intérêt',
        icon: true,
        onlyIcon: true
      },
      {
        key: 'nameComment',
        typeForm: 'text',
        fieldForm: 'comment',
        labelForm: 'Commentaire',
        placeHolder: 'Commentaire',
        maxLength: Constants.COMMENT_SIZE_MAX,
      },
      {
        key: 'nameDateStart',
        typeForm: 'calendar',
        fieldForm: 'startDate',
        labelForm: 'Début',
        requiredForm: true,
        placeHolder: 'Date de début',
      },
      {
        key: 'nameDateEnd',
        typeForm: 'calendar',
        fieldForm: 'endDate',
        labelForm: 'Fin',
        placeHolder: 'Date de fin',
      },
    ];
  }

  public toggleShowAddForm(): void {
    this.showAddForm = !this.showAddForm;
  }

  public handleCreateItem(userCourse): void {
    if (!this.errorsValidation(userCourse)) {
      userCourse.user = this.teamState.currentTeamUser;
      userCourse.startDate = userCourse.startDate ? new Date(userCourse.startDate).toISOString() : null;
      userCourse.endDate = userCourse.endDate ? new Date(userCourse.endDate).toISOString() : null;
      this.store.dispatch(new AddUserCourse(userCourse)).subscribe(
        () => {
          this.displayMessage('create');
          this.formUserCourse.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  public handleDeleteItem(id: number): void {
    this.userCourseState = this.store.snapshot().userCourses;
    this.itemToDelete = this.userCourseState.userCourses.find((item) => item.id === id);
    this.displayModal('delete');
  }

  private setInterestOptions(userCourse: UserCourse): void {
    // Interest options
    const indexInterest = this.interests.findIndex(item => item.id === userCourse.interest?.id);
    if (indexInterest === -1) {
      // Put blank interest
      this.interestsOptions.unshift({label: null, value: null, icon: null});
    } else {
      const iconNameInterest = this.interestsOptions[indexInterest].icon;
      // Delete current Interest value of the interests options with indexInterest help
      this.interestsOptions.splice(indexInterest, 1);
      // Put current Interest value at first Interests options place
      this.interestsOptions.unshift({ label: userCourse.interest?.name, value: userCourse.interest, icon: iconNameInterest });
    }
  }

  private resetInterestsOptions(): void {
    const length = this.interestsOptions.length;
    this.interestsOptions.splice(0, length);
    this.defaultInterestsOptions.map(
      item => this.interestsOptions.push(item)
    );
  }

  public handleUpdateItem(userCourse: UserCourse): void {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotUserCourse());

    this.resetInterestsOptions();
    this.setInterestOptions(userCourse);
  }

  public handleSaveUpdateItem(userCourse: UserCourse): void {
    if (!this.errorsValidation(userCourse)) {
      this.store.dispatch(new UpdateUserCourse(userCourse)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.disableCreationSave = false;
        }
      );
    }
  }

  public handleCancelEditItem(): void {
    this.store.dispatch(new RestoreSnapshotUserCourse());
    this.disableCreationSave = false;
  }

  public handleModalConfirm(): void {
    this.deleteItem();
  }

  private deleteItem(): void {
    this.store.dispatch(new DeleteUserCourse(this.itemToDelete)).subscribe(
      () => this.displayMessage('delete'),
      (e) => this.displayError(e.status)
    );
  }

  /**
   * Forms validation (create and update)
   * @param userCourse UserCourse
   * @return boolean
   */
  private errorsValidation(userCourse: UserCourse): boolean {
    let errors = false;
    if (!userCourse.course) {
      errors = true;
      this.displayError('validation_course');
    }
    if (!userCourse.startDate) {
      errors = true;
      this.displayError('validation_startDate');
    }
    if (userCourse.startDate
      && userCourse.endDate
      && new Date(userCourse.endDate).getTime() < new Date(userCourse.startDate).getTime()) {
      errors = true;
      this.displayError('validation_endDate');
    }
    return errors;
  }


  private displayModal(type: string): void {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  private displayMessage(type: string): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La formation a bien été sauvegardée.';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La formation a bien été sauvegardée.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'La formation a bien été supprimée.';
        break;
      }
    }
    this.messageService.add({severity, summary, detail});
  }

  private displayError(e: any): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 'validation_course':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner une formation.';
        break;
      case 'validation_startDate':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner une date de début.';
        break;
      case 'validation_endDate':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner une date de fin supérieure ou égale à la date de début.';
        break;
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Cette formation est déjà attribuée à cet utilisateur.';
        break;
      case 501:
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  public ngOnDestroy(): void {
    this.resetInterestsOptions();
    this.userCourseSubscription.unsubscribe();
    this.store.dispatch(new ClearUserCourses());
  }
}
