import { UserSoftSkill } from '../../../shared/models/user-soft-skill';

export class GetProfileUserSoftSkillsByUserId {
  static readonly type = '[User Profile SoftSkill] Get user soft skills';
  constructor(public payload: number) {}
}
export class AddProfileUserSoftSkill {
  static readonly type = '[User Profile SoftSkill] Add soft skill';
  constructor(public payload: UserSoftSkill) {}
}

export class UpdateProfileUserSoftSkill {
  static readonly type = '[User Profile SoftSkill] Update soft skill';
  constructor(public payload: UserSoftSkill) {}
}

export class ClearProfileUserSoftSkills {
  static readonly type = '[User Profile SoftSkill] Clear soft skills';
}
