import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageService } from 'primeng/api';
import { CustomersComponent } from './pages/customers/customers.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ViewCustomersComponent } from './pages/customers/view-customers/view-customers.component';
import { ViewProjectsComponent } from './pages/customers/view-projects/view-projects.component';

@NgModule({
  declarations: [
    CustomersComponent,
    ViewCustomersComponent,
    ViewProjectsComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [MessageService]

})
export class CustomersModule { }
