import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {ReportState} from 'src/stores/reports/report.state';
import {AuthenticationService} from 'src/app/core/authentication/authentication.service';
import {User} from 'src/app/shared/models/user';
import {Report} from 'src/app/shared/models/report';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import {ReportService} from 'src/app/core/services/report-services/report.service';
import {Router} from '@angular/router';
import {AnnualReport} from 'src/app/shared/models/annual-report';
import {
  GetAnnualReportsByUser,
  GetFilteredReports,
  GetReportsByCollaborator,
  SetAnnualReport,
  SetAnnualReportRole,
  SetPendingAnnualReport,
} from 'src/stores/reports/report.action';
import {Constants} from '../../../../shared/constants/constants';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  @Select(ReportState.getReportsByCollaborator)
  public reports$: Observable<Report[]>;

  @Select(ReportState.getAnnualReport) annualReport$: Observable<AnnualReport>;
  @Select(ReportState.getAnnualReportsByUser) annualReports$: Observable<AnnualReport[]>;

  public displayButtonEAE: boolean;

  public startDate: string = null;
  public endDate: string = null;
  public search: string = null;
  public isPreparationValidated: boolean;
  public today = new Date();

  public reportsDataColumns: any = [];

  public emptyMessage = 'Aucun entretien n\'est enregistré.';
  public annualReportNotification = Constants.COLLABORATOR_MESSAGE[0];
  public authenticationServiceSubscription: Subscription;
  public annualReportsSubscription: Subscription;
  public annualReport: AnnualReport;
  public currentUser: User;
  private interveiwStartDate: Date;
  fr = Constants.CALENDAR_FR;

  constructor(
    private store: Store,
    private authenticationService: AuthenticationService,
    private reportService: ReportService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authenticationServiceSubscription = this.authenticationService
      .getCurrentUser()
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
        // Get All Annual Reports
        this.store.dispatch(new GetAnnualReportsByUser(currentUser)).subscribe(() =>
          // Get all reports
          this.store.dispatch(new GetReportsByCollaborator(currentUser, null, null, null))
        );
        this.initDatatable();
      });

    this.annualReportsSubscription = this.annualReports$.subscribe(annualReports => {
      const collaboratorReport = annualReports.find(report => !report.collaboratorValidationStatus);
      if (collaboratorReport) {
        this.annualReport = collaboratorReport;
        this.displayButtonEAE = this.annualReport.workflowCollaborator !== 5;

        if (this.annualReport.interviewStartDate !== null) {
          this.interveiwStartDate = new Date(this.annualReport.interviewStartDate);
          this.interveiwStartDate.setDate(this.interveiwStartDate.getDate() + 1);
        } else {
          this.interveiwStartDate = null;
        }

        if (this.interveiwStartDate && moment(this.interveiwStartDate).isBefore(this.today)){
          this.annualReportNotification = Constants.COLLABORATOR_MESSAGE[3];
        } else {
          switch (this.annualReport.workflowCollaborator) {
            case 1 :
              this.annualReportNotification = Constants.COLLABORATOR_MESSAGE[0];
              break;
            case 2 :
              this.annualReportNotification = Constants.COLLABORATOR_MESSAGE[1];
              break;
            case 3 :
              this.annualReportNotification = Constants.COLLABORATOR_MESSAGE[2];
              break;
          }
          switch (this.annualReport.workflowManager){
            case 5 :
              this.annualReportNotification = Constants.COLLABORATOR_MESSAGE[4];
              break;
            case 4 :
              if (this.annualReport.workflowCollaborator === 6){
                this.annualReportNotification = Constants.COLLABORATOR_MESSAGE[5];
              }
              break;
          }
        }
      } else {
        this.displayButtonEAE = false;
      }
    });
  }

  private initDatatable(): void {
    this.reportsDataColumns = [
      {
        type: 'calendar',
        field: 'date',
        label: 'Date',
        font: 'regular',
        sort: true,
        sortField: 'date',
        isStatic: true,
      },
      {
        type: 'link',
        field: 'type',
        subField: 'name',
        label: 'Type',
        sort: true,
        sortField: 'type.name',
        isStatic: true,
      },
      {
        type: 'text',
        field: 'collaborator',
        subField: 'fullname',
        label: 'Collaborateur',
        font: 'semibold',
        sort: true,
        sortField: 'collaborator.fullname',
        isStatic: true,
      },
      {
        type: 'text',
        field: 'interviewer',
        subField: 'fullname',
        label: 'Réalisé par',
        font: 'regular',
        sort: true,
        sortField: 'interviewer.fullname',
        isStatic: true,
      },
      { type: 'action', action: 'edit', width: '8%' },
    ];
  }

  handleFilterValidation(): void {
    const startDate = this.startDate ? new Date(this.startDate).toISOString() : null;
    const endDate = this.endDate ? new Date(this.endDate).toISOString() : null;
    this.store.dispatch(new GetReportsByCollaborator(this.currentUser, startDate, endDate, this.search));
  }

  handleSearch(): void {
    this.store.dispatch(new GetFilteredReports(this.search));
  }

  public exportWeeklyReport(): void {
    const startDate = this.startDate ? new Date(this.startDate).toISOString() : null;
    const endDate = this.endDate ? new Date(this.endDate).toISOString() : null;
    this.reportService.exportWeeklyActivityReportByCollaboratorId(
      this.currentUser.id,
      startDate,
      endDate,
      this.search
    ).subscribe((response) => {
      saveAs(response, 'export_rha_' + moment().format('YYYY_DD_MM') + '.csv');
    });
  }

  handleUpdateItem() {}

  handleAnnualReportClick(){
    // Set role to collaborator
    this.store.dispatch(new SetAnnualReportRole('collaborator'));
    // Set pendingAnnualReport
    this.store.dispatch(new SetPendingAnnualReport(this.annualReport));
    this.store.dispatch(new SetAnnualReport(this.annualReport));
    this.router.navigateByUrl('/activity/reports/annualreports/stepone');
  }

  handleEditClick(data: any): void {
    if(!data.type.editionUrl){
      return;
    }
    // L'url est définie dans la table report_type et doit matcher le routage défini dans app-routing.module.
    this.router.navigateByUrl('/activity/' + data.type.editionUrl + data.id).then();
  }

  ngOnDestroy() {
    this.authenticationServiceSubscription.unsubscribe();
    this.annualReportsSubscription.unsubscribe();
  }
}
