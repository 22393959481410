import { UserSpecialty } from '../../../shared/models/user-specialty';

export class MakeSnapshotProfileUserSpecialty {
  static readonly type = '[User Profile Specialty] MakeSnapshot';
}

export class RestoreSnapshotProfileUserSpecialty {
  static readonly type = '[User Profile Specialty] RestoreSnapshot';
}

export class GetProfileUserSpecialtiesByUserId {
  static readonly type = '[User Profile Specialty] Get user specialties';
  constructor(public payload: number) {}
}

export class AddProfileUserSpecialty {
  static readonly type = '[User Profile Specialty] Add';
  constructor(public payload: UserSpecialty) {}
}

export class UpdateProfileUserSpecialty {
  static readonly type = '[User Profile Specialty] update';
  constructor(public payload: UserSpecialty) {}
}

export class DeleteProfileUserSpecialty {
  static readonly type = '[User Profile Specialty] delete';
  constructor(public payload: UserSpecialty) {}
}

export class ClearProfileUserSpecialties {
  static readonly type = '[User Profile User Specialty] Clear user specialties';
}
