<div class="fm-bloc">

  <div class="fm-skill">
    <div class="fm-skill-type">
      <p class="fm-text">Postes :</p>
    </div>
    <div class="fm-default-value" *ngIf="userProfiles.length === 0">Aucun poste attribué</div>
    <div class="fm-skill-align">
      <app-chips-list
        *ngIf="userProfiles.length > 0"
        opacity=true
        [module]="module"
        [chipsData]="userProfiles"
        [chipsLabel]="userProfiles"
        [subFieldName]="'profile'"
        [chipsNumber]="chipsNumber">
      </app-chips-list>
    </div>
  </div>

  <hr>

  <div class="fm-skill">
    <div class="fm-skill-type">
      <p class="fm-text">Compétences :</p>
    </div>
    <div class="fm-default-value" *ngIf="userSkills.length === 0">Aucune compétence attribuée</div>
    <div class="fm-skill-align">
      <app-chips-list
        *ngIf="userSkills.length > 0"
        opacity=true
        [module]="module"
        [chipsData]="userSkills"
        [chipsLabel]="userSkills"
        [subFieldName]="'skill'"
        [chipsNumber]="chipsNumber">
      </app-chips-list>
    </div>
  </div>

  <app-fm-button
    [attr.data-testid]="'person_summary_button_showAll'"
    routerLink="/profile/show"
    class="fm-btn-see-more"
    type="text-transparent" btnText="VOIR TOUT">
  </app-fm-button>
</div>
