import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetUserSpecialties,
  AddUserSpecialty,
  DeleteUserSpecialty,
  UpdateUserSpecialty,
  GetUserSpecialtiesByUserId,
  MakeSnapshotUserSpecialty,
  RestoreSnapshotUserSpecialty,
  ClearUserSpecialties
} from './team-specialties.actions';
import { UserSpecialtyService } from '../../../core/services/user-specialty-services/user-specialty.service';
import { UserSpecialty } from '../../../shared/models/user-specialty';

export class TeamSpecialtyStateModel {
  userSpecialties: UserSpecialty[];
  snapshot: UserSpecialty[];
}

@State<TeamSpecialtyStateModel>({
  name: 'userSpecialties',
  defaults: {
    userSpecialties: [],
    snapshot: [],
  }
})
@Injectable()
export class TeamSpecialtyState {

  constructor(private userSpecialtyService: UserSpecialtyService) {
  }

  @Selector()
  static getUserSpecialties(state: TeamSpecialtyStateModel) {
    return state.userSpecialties;
  }

  @Action(GetUserSpecialties)
  getAll({getState, setState}: StateContext<TeamSpecialtyStateModel>) {
    return this.userSpecialtyService.getAll().pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userSpecialties: result,
      });
    }));
  }

  @Action(GetUserSpecialtiesByUserId)
  getUserProfilesByUserId({getState, setState}: StateContext<TeamSpecialtyStateModel>, {payload}: GetUserSpecialtiesByUserId) {
    return this.userSpecialtyService.getUserSpecialtiesByUserId(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userSpecialties: result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddUserSpecialty)
  add({getState, patchState}: StateContext<TeamSpecialtyStateModel>, {payload}: AddUserSpecialty) {
    return this.userSpecialtyService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      patchState({
        userSpecialties: [...state.userSpecialties, result]
      });
    }));
  }

  @Action(UpdateUserSpecialty)
  update({getState, setState}: StateContext<TeamSpecialtyStateModel>, {payload}: UpdateUserSpecialty) {
    return this.userSpecialtyService.doPut(payload).pipe(
      tap((result) => {
        result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
        const state = getState();
        const userSpecialtiesList = [...state.userSpecialties];
        const userSpecialtyIndex = userSpecialtiesList.findIndex(item => item.id === payload.id);
        userSpecialtiesList[userSpecialtyIndex] = result;
        setState({
          ...state,
          userSpecialties: userSpecialtiesList,
        });
    }));
  }

  @Action(DeleteUserSpecialty)
  delete({getState, setState}: StateContext<TeamSpecialtyStateModel>, {payload}: DeleteUserSpecialty) {
    const state = getState();
    return this.userSpecialtyService.doDelete(payload.id).pipe(tap(() => {
      const filteredArray = state.userSpecialties.filter(item => item.id !== payload.id);
      setState({
        ...state,
        userSpecialties: filteredArray,
      });
    }));
  }

  @Action(MakeSnapshotUserSpecialty)
  makeSnapShot({getState, setState}: StateContext<TeamSpecialtyStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.userSpecialties.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotUserSpecialty)
  restoreSnapshot({getState, setState}: StateContext<TeamSpecialtyStateModel>) {
    const state = getState();
    setState({
      ...state,
      userSpecialties: state.snapshot.map(item => ({...item}))
    });
  }

  @Action(ClearUserSpecialties)
  clearUserSpecialties({getState, setState}: StateContext<TeamSpecialtyStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: [],
      userSpecialties: [],
    });
  }
}
