import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { MessageService, SelectItem } from 'primeng/api';
import { SpecialtyService } from '../../../../../../core/services/specialty-services/specialty.service';
import { UserSpecialty } from '../../../../../../shared/models/user-specialty';
import { Specialty } from '../../../../../../shared/models/specialty';
import { Level } from '../../../../../../shared/models/level';
import { Interest } from '../../../../../../shared/models/interest';
import { FormInlineComponent } from '../../../../../../shared/components/form-inline/form-inline.component';
import { ProfileSpecialtyState, ProfileSpecialtyStateModel } from '../../../../store/profile-specialties.state';
import { User } from '../../../../../../shared/models/user';
import { UserProfile } from '../../../../../../shared/models/user-profile';
import { ProfileProfileState } from '../../../../store/profile-profiles.state';
import {
  AddProfileUserSpecialty,
  ClearProfileUserSpecialties,
  MakeSnapshotProfileUserSpecialty,
  RestoreSnapshotProfileUserSpecialty,
  GetProfileUserSpecialtiesByUserId,
  UpdateProfileUserSpecialty,
  DeleteProfileUserSpecialty
} from '../../../../store/profile-specialties.actions';


@Component({
  selector: 'app-profile-view-specialties',
  templateUrl: './profile-view-specialties.component.html',
  styleUrls: ['./profile-view-specialties.component.scss']
})
export class ProfileViewSpecialtiesComponent implements OnInit, OnDestroy {
  @Select(ProfileSpecialtyState.getUserSpecialties)
  public userSpecialties$: Observable<UserSpecialty[]>;
  @Select(ProfileProfileState.getUserProfiles)
  private userProfiles$: Observable<UserProfile[]>;

  @ViewChild(FormInlineComponent)
  private formUserSpecialty: FormInlineComponent;

  @Input()
  private interests: Interest[] = [];
  @Input()
  private levels: Level[] = [];
  @Input()
  private interestsOptions: SelectItem[] = [];
  @Input()
  private levelsOptions: SelectItem[] = [];

  private currentUser: User;
  private userSpecialtyState: ProfileSpecialtyStateModel;
  private userProfilesSubscription: Subscription;
  private itemToDelete: UserSpecialty;
  private specialties: Specialty[] = [];
  private defaultInterestsOptions: SelectItem[] = [];
  private defaultLevelsOptions: SelectItem[] = [];

  public modal: any = {};
  public showModal = false;
  public inputForm: any = [];
  public showAddForm: boolean;
  public disableCreationSave = false;
  public tableColumns: any[];
  public emptyMessage = 'Aucune spécialité n\'est enregistrée';

  public constructor(
    private messageService: MessageService,
    private specialtyService: SpecialtyService,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.currentUser = this.store.snapshot().profile.currentUserProfile;
    this.store.dispatch(new GetProfileUserSpecialtiesByUserId(this.currentUser.id));

    this.interestsOptions.map(
      item => this.defaultInterestsOptions.push(item)
    );
    this.levelsOptions.map(
      item => this.defaultLevelsOptions.push(item)
    );

    this.userProfilesSubscription = this.userProfiles$.subscribe({
      next: (userProfiles) => {
        this.specialties = [];
        if (userProfiles.length > 0) {
          userProfiles.map(
            (userProfile: UserProfile) => userProfile.profile.specialties
              .filter((specialty) => {
                return specialty.archivingDate === null || (new Date(specialty.archivingDate)).getTime() > (new Date()).getTime();
              })
              .map(
                (specialty: Specialty) => {
                  specialty.profile = { id: userProfile.profile.id, name: userProfile.profile.name };
                  this.specialties.push({...specialty});
                }
              )
          );
          this.initDatatable();
          this.initForm();
        }
      },
      error: (e) => this.displayError(e.status),
    });
  }

  private initDatatable(): void {
    this.tableColumns = [
      {
        type: 'select',
        field: 'specialty',
        subField: 'name',
        label: 'Spécialité',
        isStatic: true,
        font: 'semibold',
        sort: false,
      },
      {
        type: 'select',
        field: 'interest',
        subField: 'name',
        label: 'Intérêt',
        icon: 'interest',
        options: this.interestsOptions,
        optionIcon: true,
        optionOnlyIcon: true,
        clearable: true,
        sort: false
      },
      {
        type: 'select-if-not-validated',
        field: 'level',
        subField: 'value',
        subFieldLabel: 'name',
        label: 'Niveau',
        icon: 'level',
        options: this.levelsOptions,
        optionIcon: true,
        font: 'regular',
        sort: false
      },
      {
        type: 'validation',
        field: 'validation',
        label: 'Validation',
        sort: false,
        isStatic: true
      },
      {
        type: 'calendar',
        field: 'archivingDate',
        label: 'Archivage',
        font: 'regular',
        sort: false,
        isStatic: true
      },
      { type: 'action', action: 'edit', width: '8%' },
      { type: 'action', action: 'delete', width: '10%' },
    ];
  }

  private initForm(): void {
    this.inputForm = [
      {
        key: 'nameSpecialty',
        typeForm: 'dropdown',
        filter: true,
        fieldForm: 'specialty',
        labelForm: 'Spécialité',
        requiredForm: true,
        placeHolder: 'Spécialité',
        optionsForm: this.specialties,
        optionConcat: 'profile'
      },
      {
        key: 'nameInterest',
        typeForm: 'dropdown',
        fieldForm: 'interest',
        labelForm: 'Intérêt',
        requiredForm: true,
        optionsForm: this.interests,
        placeHolder: 'Intérêt',
        icon: true,
        onlyIcon: true
      },
      {
        key: 'nameLevel',
        typeForm: 'dropdown',
        fieldForm: 'level',
        labelForm: 'Niveau',
        optionsForm: this.levels,
        placeHolder: 'Niveau',
        icon: true
      },
    ];
  }

  public toggleShowAddForm(): void {
    this.showAddForm = !this.showAddForm;
  }

  public handleCreateItem(userSpecialty: UserSpecialty): void {
    if (!this.errorsValidation(userSpecialty, 'create')) {
      userSpecialty.user = this.currentUser;
      userSpecialty.validation = null;
      if (userSpecialty.level === null) { userSpecialty.level = this.levels[0]; }
      this.store.dispatch(new AddProfileUserSpecialty(userSpecialty)).subscribe(
        () => {
          this.displayMessage('create');
          this.formUserSpecialty.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  public handleDeleteItem(id: number): void {
    this.userSpecialtyState = this.store.snapshot().profileUserSpecialties;
    const userSpecialty = this.userSpecialtyState.userSpecialties.find((item) => item.id === id);
    this.itemToDelete = userSpecialty;
    if (userSpecialty.validation === null) {
      this.displayModal('delete');
    } else {
      this.deleteItem();
    }
  }

  private setInterestOptions(userSpecialty: UserSpecialty): void {
    // Interest options
    const indexInterest = this.interests.findIndex(item => item.id === userSpecialty.interest?.id);
    if (indexInterest === -1) {
      // Put blank interest
      this.interestsOptions.unshift({label: null, value: null, icon: null});
    } else {
      const iconNameInterest = this.interestsOptions[indexInterest].icon;
      // Delete current Interest value of the interests options with indexInterest help
      this.interestsOptions.splice(indexInterest, 1);
      // Put current Interest value at first Interests options place
      this.interestsOptions.unshift({ label: userSpecialty.interest?.name, value: userSpecialty.interest, icon: iconNameInterest });
    }
  }

  private setLevelOptions(userSpecialty: UserSpecialty): void {
    // Level options
    const indexLevel = this.levels.findIndex(item => item.id === userSpecialty.level?.id);
    // Get icon's name
    const iconNameLevel = this.levelsOptions[indexLevel].icon;
    // Delete current Level value of the levels options with indexLevel help
    this.levelsOptions.splice(indexLevel, 1);
    // Put current Level value at first Levels options place
    this.levelsOptions.unshift({ label: userSpecialty.level.name, value: userSpecialty.level, icon: iconNameLevel });
  }

  private resetInterestsOptions(): void {
    const length = this.interestsOptions.length;
    this.interestsOptions.splice(0, length);
    this.defaultInterestsOptions.map(
      item => this.interestsOptions.push(item)
    );
  }

  private resetLevelsOptions(): void {
    const length = this.levelsOptions.length;
    this.levelsOptions.splice(0, length);
    this.defaultLevelsOptions.map(
      item => this.levelsOptions.push(item)
    );
  }

  public handleUpdateItem(userSpeciality: UserSpecialty): void {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotProfileUserSpecialty());

    this.resetInterestsOptions();
    this.resetLevelsOptions();
    this.setInterestOptions(userSpeciality);
    this.setLevelOptions(userSpeciality);
  }

  public handleSaveUpdateItem(userSpecialty: UserSpecialty): void {
    if (!this.errorsValidation(userSpecialty, 'update')) {
      this.store.dispatch(new UpdateProfileUserSpecialty(userSpecialty)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.disableCreationSave = false;
        }
      );
    }
  }

  public handleCancelEditItem(data): void {
    this.store.dispatch(new RestoreSnapshotProfileUserSpecialty());
    this.disableCreationSave = false;
  }

  public handleModalConfirm(): void {
    this.deleteItem();
  }

  private deleteItem(): void {
    this.store.dispatch(new DeleteProfileUserSpecialty(this.itemToDelete)).subscribe(
      (store) => {
        if (store.profileUserSpecialties.httpStatus === 200) {
          this.displayMessage('delete');
        } else {
          this.displayMessage('delete-notification');
        }
      },
      (e) => this.displayError(e.status)
    );
  }

  /**
   * Forms validation (create and update)
   * @param userSpecialty UserSpecialty
   * @param action string
   * @return boolean
   */
  private errorsValidation(userSpecialty: UserSpecialty, action: string): boolean {
    let errors = false;
    if (!userSpecialty.specialty) {
      this.displayError('specialty');
      errors = true;
    }
    if (!userSpecialty.interest) {
      this.displayError('interest');
      errors = true;
    }
    return errors;
  }

  private displayModal(type: string): void {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  private displayMessage(type: string): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La spécialité a bien été sauvegardée';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La spécialité a bien été sauvegardée';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'Spécialité supprimée.';
        break;
      }
      case 'delete-notification': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'La demande de suppression a bien été envoyée à votre manager principal.';
        break;
      }

    }
    this.messageService.add({severity, summary, detail});
  }

  private displayError(e: any): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Cette spécialité est déjà attribuée à cet utilisateur.';
        break;
      case 'specialty':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner une spécialité.';
        break;
      case 'profile':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un poste.';
        break;
      case 'interest':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner un intérêt.';
        break;
      case 501:
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  public ngOnDestroy(): void {
    this.resetLevelsOptions();
    this.resetInterestsOptions();
    this.userProfilesSubscription.unsubscribe();
    this.store.dispatch(new ClearProfileUserSpecialties());
  }
}
