import { SoftSkill } from '../../../shared/models/soft-skill';

export class MakeSnapshotRH {
    static readonly type = '[SoftSkill] MakeSnapshot';
}

export class RestoreSnapshotRH {
    static readonly type = '[SoftSkill] RestoreSnapshotRH';
}

export class ClearSnapshotRH {
    static readonly type = '[SoftSkill] ClearSnapshot';
}
  
export class GetRH {
    static readonly type = '[SoftSkill] GetRH';
}
  
export class GetSnapshot {
    static readonly type = '[SoftSkill] GetSnapshot';
}
  
export class AddRH {
    static readonly type = '[SoftSkill] Add';
    constructor(public payload: SoftSkill) {}
}
  
export class UpdateRH {
    static readonly type = '[SoftSkill] update';
    constructor(public payload: SoftSkill) {}
}
  
export class DeleteRH {
    static readonly type = '[SoftSkill] delete';
    constructor(public payload: SoftSkill) {}
}
  
export class FilterRH {
    static readonly type = '[SoftSkill] Filter';
    constructor(public payload: string) {}
}