import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetUserCourses,
  AddUserCourse,
  DeleteUserCourse,
  UpdateUserCourse,
  GetUserCoursesByUserId,
  MakeSnapshotUserCourse,
  RestoreSnapshotUserCourse,
  ClearUserCourses
} from './team-courses.actions';
import { UserCourseService } from '../../../core/services/user-course-services/user-course.service';
import { UserCourse } from '../../../shared/models/user-course';

export class TeamCourseStateModel {
  userCourses: UserCourse[];
  snapshot: UserCourse[];
}

@State<TeamCourseStateModel>({
  name: 'userCourses',
  defaults: {
    userCourses: [],
    snapshot: []
  }
})
@Injectable()
export class TeamCourseState {

  constructor(private userCourseService: UserCourseService) {
  }

  @Selector()
  static getUserCourses(state: TeamCourseStateModel) {
    return state.userCourses;
  }

  @Action(GetUserCourses)
  getAll({getState, setState}: StateContext<TeamCourseStateModel>) {
    return this.userCourseService.getAll().pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userCourses: result,
      });
    }));
  }

  @Action(GetUserCoursesByUserId)
  getUserCoursesByUserId({getState, setState}: StateContext<TeamCourseStateModel>, {payload}: GetUserCoursesByUserId) {
    return this.userCourseService.getUserCoursesByUserId(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userCourses: result.map( r => {
          r.startDate = r.startDate ? new Date(r.startDate) : null;
          r.endDate = r.endDate ? new Date(r.endDate) : null;
          r.rangeDates = [];
          r.rangeDates.push(r.startDate);
          r.rangeDates.push(r.endDate);
          return r;
        }),
      });
    }));
  }

  @Action(AddUserCourse)
  add({getState, patchState}: StateContext<TeamCourseStateModel>, {payload}: AddUserCourse) {
    return this.userCourseService.doPost(payload).pipe(tap((result) => {
      result.startDate = result.startDate ? new Date(result.startDate) : null;
      result.endDate = result.endDate ? new Date(result.endDate) : null;
      result.rangeDates = [];
      result.rangeDates.push(result.startDate);
      result.rangeDates.push(result.endDate);
      const state = getState();
      patchState({
        userCourses: [...state.userCourses, result]
      });
    }));
  }

  @Action(UpdateUserCourse)
  update({getState, setState}: StateContext<TeamCourseStateModel>, {payload}: UpdateUserCourse) {
    payload.startDate = payload.rangeDates[0];
    payload.endDate = payload.rangeDates[1];
    return this.userCourseService.doPut(payload).pipe(
      tap((result) => {
        result.startDate = result.startDate ? new Date(result.startDate) : null;
        result.endDate = result.endDate ? new Date(result.endDate) : null;
        result.rangeDates = [];
        result.rangeDates.push(result.startDate);
        result.rangeDates.push(result.endDate);
        const state = getState();
        const userCoursesList = [...state.userCourses];
        const userCourseIndex = userCoursesList.findIndex(item => item.id === payload.id);
        userCoursesList[userCourseIndex] = result;
        setState({
          ...state,
          userCourses: userCoursesList,
        });
    }));
  }

  @Action(DeleteUserCourse)
  delete({getState, setState}: StateContext<TeamCourseStateModel>, {payload}: DeleteUserCourse) {
    const state = getState();
    return this.userCourseService.doDelete(payload.id).pipe(tap(() => {
      const filteredArray = state.userCourses.filter(item => item.id !== payload.id);
      setState({
        ...state,
        userCourses: filteredArray,
      });
    }));
  }

  @Action(MakeSnapshotUserCourse)
  makeSnapShot({getState, setState}: StateContext<TeamCourseStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.userCourses.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotUserCourse)
  restoreSnapshot({getState, setState}: StateContext<TeamCourseStateModel>) {
    const state = getState();
    setState({
      ...state,
      userCourses: state.snapshot.map(item => ({...item}))
    });
  }


  @Action(ClearUserCourses)
  clearUserCourses({getState, setState}: StateContext<TeamCourseStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: [],
      userCourses: [],
    });
  }
}
