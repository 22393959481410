import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-select-item-array',
  templateUrl: './select-item-array.component.html',
  styleUrls: ['./select-item-array.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectItemArrayComponent),
      multi: true,
    },
  ],
})
export class SelectItemArrayComponent implements ControlValueAccessor {
  public value: any;
  selectsformGroup: FormGroup;
  public disabled = false;
  @Input() name: string;
  @Input('is-formation') isFormation;
  submitted = false;

  get selectsArr(): FormArray {
    return this.selectsformGroup.get('selects') as FormArray;
  }

  constructor(private formBuilder: FormBuilder) {
    this.selectsformGroup = formBuilder.group({
      selects: formBuilder.array([]),
    });

    this.selectsformGroup.valueChanges.subscribe((val) => {
      this.onChange(val);
      this.value = val.selects;
    });
  }

  writeValue(selects) {
    this.value = selects;
    if (this.selectsArr.length == 0 || this.selectsformGroup.valid) {
      this.selectsArr.push(new FormControl('', [Validators.required]));
      this.selectsArr.markAllAsTouched();
    }
  }

  newSelect() {
    this.writeValue({});
  }

  removeItem(i) {
    if (this.selectsArr.length > 1) {
      this.selectsArr.removeAt(i);
    } else {
      alert('You cannot remove');
    }
  }

  onSubmit() {
    if (this.selectsArr.valid) {
      this.submitted = true;
      console.log(this.selectsformGroup.value);
    }
  }

  public onChange(newVal: any) {}
  public onTouched(_?: any) {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
