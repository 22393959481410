import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../../shared/models/user';

@Component({
  selector: 'app-selected-collaborator',
  templateUrl: './selected-collaborator.component.html',
  styleUrls: ['./selected-collaborator.component.scss']
})
export class SelectedCollaboratorComponent implements OnInit {

  @Output()
  eventResetCurrentUser = new EventEmitter<string>();

  @Input()
  currentUser: User;

  @Input()
  module;

  constructor() {}

  ngOnInit(): void {
  }

  handleResetCurrentUser() {
    this.eventResetCurrentUser.emit();
  }

}
