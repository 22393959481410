<p-table
   [dataKey]="dataKey"
   [loading]="!data"
   [paginator]="displayPagination"
   [value]="data"
   [sortField]="defaultSortField"
   [sortOrder]="defaultSortOrder"
   [rows]="rowNumber"
   [editMode]="editMode" styleClass="fm-datatable"
   [selectionMode]="selectionMode">
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns"
          [pSortableColumn]="col.sort && (col.sortField || col.field)"
          [className]="col.type === 'image' ? 'fm-cell-image' :
            col.type === 'action' && (col.action === 'edit' || col.action === 'edit-link') ? 'fm-cell-edit' :
            col.type === 'validation' ? 'fm-cell-validation' :
            col.type === 'action' && col.action === 'delete' ? 'fm-cell-delete' :
            col.type === 'action' && col.action === 'clear' ? 'fm-cell-clear' :
            col.type === 'icon' || col.field === 'icon' ? 'fm-cell-icon' :
            col.colSize ? col.colSize :
            'fm-cell-auto'">
        {{col.label}}
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="columns && columns.length">{{emptyMessage}}</td>
    </tr>
  </ng-template>

  <ng-template *ngIf="!editMode" pTemplate="body" let-data>
    <tr>
      <td *ngFor="let col of columns">
        <span *ngIf="data[col.field] != null && col.type !== 'action' && col.type !== 'calendar' && col.type !== 'link'" class="fm-font-{{col.font}}">
          {{ data[col.field][col.subField] || data[col.field] }}
        </span>
        <span *ngIf="col.type == 'link'">
          <a class="fm-link" (click)="handleClickLink(data)" >{{ data[col.field][col.subField] || data[col.field] }}</a>
        </span>
        <span *ngIf="col.type === 'calendar'" class="fm-font-{{col.font}}">
          {{ data[col.field] | date: 'dd/MM/yyyy' }}
        </span>
        <span *ngIf="col.type === 'action' && col.action === 'edit' && displayOrNotEdition === false" pTooltip="Bientôt disponible" tooltipPosition="top">
          <app-fm-button type="{{col.action}}"></app-fm-button>
        </span>
        <span *ngIf="col.type === 'action' && col.action === 'edit' && displayOrNotEdition === true && data.interviewer.id === currentUser.id" pTooltip="Bientôt disponible" tooltipPosition="top">
          <app-fm-button type="{{col.action}}"></app-fm-button>
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template *ngIf="editMode" pTemplate="body" let-data let-editing="editing" let-ri="rowIndex" >
    <tr [pEditableRow]="data" [pSelectableRow]="data" (click)="handleRowSelect(data)" [hidden]="displayOnlyQualificationsNotValidated && data.validation != null" [ngClass]="{ 'fm-row-to-validate': (isMainManager || isUpdatingProfile) && data.validation == null }">
      <td *ngFor="let col of columns"
          [className]="col.type === 'action' && col.action === 'edit' ? 'fm-cell-edit' :
            col.type === 'action' && col.action === 'clear' ? 'fm-cell-clear' :
            col.type === 'action' && col.action === 'delete' ? 'fm-cell-delete' :
            col.type === 'validation' ? 'fm-cell-validation' :
            col.field === 'icon' ? 'fm-cell-icon' :
            col.type === 'select' && col.optionOnlyIcon ? 'fm-cell-interest' :
            col.type === 'select-if-not-validated' && col.optionOnlyIcon ? 'fm-cell-interest' :
            col.type === 'checkbox' ? 'fm-cell-checkbox' :
            'fm-cell-auto'">
        <div *ngIf="!col.isStatic && col.type !== 'action'"
             [className]="col.type === 'image' ? 'fm-cell-image' : ''">
          <p-cellEditor *ngIf="col.type === 'text'" [attr.data-testid]="'datatable_cellEditor_' + col.field + '_' + (ri + 1)">
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="data[col.field]" class="fm-font-{{col.font}}" required [maxLength]="col.maxLength"/>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="fm-font-{{col.font}}">{{ data[col.field] }}</span>
            </ng-template>
          </p-cellEditor>
          <p-cellEditor *ngIf="col.type === 'link'" [attr.data-testid]="'datatable_cellEditor_' + col.field + '_' + (ri + 1)">
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="data[col.field]" class="fm-font-{{col.font}}" required [maxLength]="col.maxLength"/>
            </ng-template>
            <ng-template pTemplate="output">
              <a class="fm-link" (click)="handleRedirect(data)" >{{ data[col.field] }}</a>
            </ng-template>
          </p-cellEditor>
          <p-cellEditor *ngIf="col.type === 'image' && data[col.field]" [attr.data-testid]="'datatable_cellEditor_' + col.field + '_' + (ri + 1)">
            <ng-template pTemplate="input">
              <p-fileUpload
                *ngIf="!loadingEnd"
                mode="basic"
                customUpload="true"
                chooseLabel=""
                chooseIcon="fm-icon-uploadimage"
                (onSelect)="handleUploadImage($event, data)"
                accept="image/*"
                maxFileSize="10000000"
              ></p-fileUpload>
              <img class="fm-datatable-logo" *ngIf="loadingEnd" [src]="iconImage | safeSanitizer"
                   height="30"
                   alt="Aperçu de l’image...">
            </ng-template>
            <ng-template pTemplate="output">
              <img class="fm-datatable-logo"
                   [src]="data[col.field]"
                   height="30"
                   alt="Aperçu de l’image...">
            </ng-template>
          </p-cellEditor>
          <p-cellEditor *ngIf="col.type === 'autocomplete'" [attr.data-testid]="'datatable_cellEditor_' + col.field + '_' + (ri + 1)">
            <ng-template pTemplate="input">
              <p-autoComplete
                [(ngModel)]="data[col.field]" [suggestions]="col.suggestions | async"
                (completeMethod)="col.filterItems($event)"
                [field]="col.suggestionField"
                minLength="2"
                [maxlength]="col.maxLength"
                emptyMessage="Aucun résultat"
                class="fm-font-{{col.font}}">
              </p-autoComplete>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="fm-font-{{col.font}}">{{ data[col.field] }}</span>
            </ng-template>
          </p-cellEditor>
          <p-cellEditor *ngIf="col.type === 'select'" [attr.data-testid]="'datatable_cellEditor_' + col.field + '_' + (ri + 1)">
            <ng-template pTemplate="input">
              <p-dropdown [styleClass]="col.className" [options]="col.options" [(ngModel)]="data[col.field]" [showClear]="col.clearable" dataKey="data[col.field].id"  placeholder="Selectionnez une valeur">
                <ng-template let-item pTemplate="selectedItem">
                  <div>
                    <div *ngIf="!col.optionIcon && data[col.field] !== null"><span class="fm-font-{{col.font}} ">{{data[col.field][col.subField]}}</span></div>
                    <!-- Test si la colonne est la colonne de liste d'icone, présente dans Formation&RH / Certification -->
                    <div *ngIf="col.optionIcon">
                      <i *ngIf="data[col.field] && col.icon !== 'level'" class="fm-icon-{{data[col.field][col.subField]}}"></i>
                      <i *ngIf="data[col.field] && col.icon === 'level'" class="{{iconLevel[data[col.field][col.subField] - 1]}}"></i>
                      &nbsp;
                      <span *ngIf="!col.optionOnlyIcon" class="fm-font-{{col.font}}">{{ data[col.field][col.subFieldLabel] }}</span>
                    </div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>
                    <div *ngIf="!col.optionIcon"><span class="fm-font-{{col.font}}">{{ item.label }}</span></div>
                    <div *ngIf="col.optionIcon">
                      <i [className]="item.icon"></i>
                      <span *ngIf="!col.optionOnlyIcon" class="fm-font-{{col.font}}">&nbsp;{{ item.label }}</span>
                    </div>
                  </div>
                </ng-template>
              </p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              <div *ngIf="!col.icon && data[col.field] !== null">
                <span class="fm-font-{{col.font}}">{{!col.subField ? data[col.field] : data[col.field][col.subField]}}</span>
              </div>
              <div *ngIf="col.icon === 'certification' && data[col.field] !== null">
                <i class="fm-icon-{{data[col.field][col.subField]}}"></i>
              </div>
              <div *ngIf="col.icon === 'interest' && data[col.field] !== null">
                <i class="fm-icon-{{data[col.field][col.subField]}}"></i>
              </div>
              <div *ngIf="col.icon === 'level' && data[col.field] !== null && data[col.field] != undefined">
                <i class="{{iconLevel[data[col.field][col.subField] - 1]}}"></i>
                <span class="fm-font-{{col.font}}">{{data[col.field][col.subFieldLabel]}}</span>
              </div>
            </ng-template>
          </p-cellEditor>
          <p-cellEditor *ngIf="col.type === 'select-if-not-validated'" [attr.data-testid]="'datatable_cellEditor_' + col.field + '_' + (ri + 1)">
            <ng-template pTemplate="input">
              <p-dropdown *ngIf="data.validation == null" [styleClass]="col.className" [options]="col.options" [(ngModel)]="data[col.field]" [showClear]="col.clearable" dataKey="data[col.field].id"  placeholder="Selectionnez une valeur">
                <ng-template let-item pTemplate="selectedItem">
                  <div>
                    <i *ngIf="data[col.field]" class="{{iconLevel[data[col.field][col.subField] - 1]}}"></i>
                    &nbsp;
                    <span class="fm-font-{{col.font}}">{{ data[col.field][col.subFieldLabel] }}</span>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>
                    <i [className]="item.icon"></i>
                    <span class="fm-font-{{col.font}}">&nbsp;{{ item.label }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="data.validation != null">
                <div *ngIf="data[col.field] !== null && data[col.field] != undefined">
                  <i class="{{iconLevel[data[col.field][col.subField] - 1]}}"></i>
                  &nbsp;
                  <span class="fm-font-{{col.font}}">{{data[col.field][col.subFieldLabel]}}</span>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <div *ngIf="data[col.field] !== null && data[col.field] != undefined">
                <i class="{{iconLevel[data[col.field][col.subField] - 1]}}"></i>
                &nbsp;
                <span class="fm-font-{{col.font}}">{{data[col.field][col.subFieldLabel]}}</span>
              </div>
            </ng-template>
          </p-cellEditor>
          <p-cellEditor *ngIf="col.type === 'validation' && (isMainManager || isAdmin)" [attr.data-testid]="'datatable_cellEditor_' + col.field + '_' + (ri + 1)">
            <ng-template pTemplate="input">
                <p-checkbox name="groupname" [binary]="true" [(ngModel)]="data[col.field]"></p-checkbox>
            </ng-template>
            <ng-template pTemplate="output">
              <div *ngIf="data.validation == null">
                <app-fm-button class="fm-button-validation" type="check" (click)="handleValidationItem(data, true)"></app-fm-button>
                <app-fm-button class="fm-button-validation" type="close" (click)="handleValidationItem(data, false)"></app-fm-button>
              </div>
              <i class="fm-icon-check" *ngIf="data.validation == true"></i>
              <i class="fm-icon-close-validation" *ngIf="data.validation == false"></i>
            </ng-template>
          </p-cellEditor>
          <p-cellEditor *ngIf="col.type === 'checkbox'">
            <ng-template pTemplate="input">
              <p-checkbox name="groupname" [binary]="true" [(ngModel)]="data[col.field]"></p-checkbox>
            </ng-template>
            <ng-template pTemplate="output">
              <p-checkbox name="groupname" [binary]="true" [(ngModel)]="data[col.field]" [disabled]="true"></p-checkbox>
            </ng-template>
          </p-cellEditor>
          <p-cellEditor *ngIf="col.type === 'calendar'" [attr.data-testid]="'datatable_cellEditor_' + col.field + '_' + (ri + 1)">
            <ng-template pTemplate="input">
              <p-calendar [(ngModel)]="data[col.field]"
              dateFormat="dd/mm/yy"
              appendTo="body"
              [locale]="fr"
              class="fm-font-{{col.font}}"></p-calendar>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="fm-font-{{col.font}}">{{ data[col.field] | date: 'dd/MM/yyyy' }}</span>
            </ng-template>
          </p-cellEditor>
          <p-cellEditor *ngIf="col.type === 'rangeCalendar'" [attr.data-testid]="'datatable_cellEditor_' + col.field + '_' + (ri + 1)">
            <ng-template pTemplate="input">
              <p-calendar [(ngModel)]="data.rangeDates"
              selectionMode="range"
              [readonlyInput]="true"
              dateFormat="dd/mm/yy"
              appendTo="body"
              [locale]="fr"
              class="fm-font-{{col.font}}"></p-calendar>
            </ng-template>
            <ng-template pTemplate="output">
              <span *ngIf="!data.endDate" class="fm-font-{{col.font}}">{{ data.startDate | date: 'dd/MM/yyyy' }}</span>
              <span *ngIf="data.endDate" class="fm-font-{{col.font}}">{{ data.startDate | date: 'dd/MM/yyyy' }} - {{ data.endDate | date: 'dd/MM/yyyy' }}</span>
            </ng-template>
          </p-cellEditor>
        </div>
        <div *ngIf="col.isStatic && col.type !== 'action'">
          <div [ngSwitch]="col.type">
            <div *ngSwitchCase="'icon'">
              <i *ngIf="col.className" class="{{col.className}}"></i>
              <i *ngIf="!col.className" class="fm-icon-{{data.certification.icon.name}}"></i>
            </div>
            <div *ngSwitchCase="'image'">
              <img class="fm-datatable-logo"
                   [src]="data[col.field][col.subField] || data[col.field] | safeSanitizer"
                   height="30"
                   alt="Aperçu de l’image...">
            </div>
            <div *ngSwitchCase="'validation'">
              <i *ngIf="data[col.field] == null && isUpdatingProfile" class="fm-icon-timer"></i>
              <i *ngIf="data[col.field] == true" class="fm-icon-check"></i>
              <i *ngIf="data[col.field] == false" class="fm-icon-close-validation"></i>
            </div>
            <div *ngSwitchCase="'checkbox'">
              <i *ngIf="data[col.field]" class="fm-icon-check"></i>
            </div>
            <div *ngSwitchCase="'calendar'">
              <span class="fm-font-{{col.font}}">
                {{ data[col.field] | date: 'dd/MM/yyyy' }}
              </span>
            </div>
            <div *ngSwitchCase="'rangeCalendar'">
              <span *ngIf="!data.endDate" class="fm-font-{{col.font}}">{{ data.startDate | date: 'dd/MM/yyyy' }}</span>
              <span *ngIf="data.endDate" class="fm-font-{{col.font}}">{{ data.startDate | date: 'dd/MM/yyyy' }} - {{ data.endDate | date: 'dd/MM/yyyy' }}</span>
            </div>
            <div *ngSwitchCase="'select'">
              <div *ngIf="!col.icon">
                <span class="fm-font-{{col.font}}">{{!col.subField ? data[col.field] : data[col.field][col.subField]}}</span>
              </div>
              <div *ngIf="col.icon === 'interest' && data[col.field] !== null">
                <i class="fm-icon-{{data[col.field][col.subField]}}"></i>
              </div>
            </div>
            <div *ngSwitchDefault><span class="fm-font-{{col.font}}">{{!data[col.field] ? '' : (data[col.field][col.subField] || data[col.field])}}</span></div>
          </div>
        </div>
        <div *ngIf="col.type === 'action' && col.action === 'edit'">
          <app-fm-button [attr.data-testid]="'dataTable_button_edit_item_' + (ri + 1)" *ngIf="!editing && !isEditingTable" type="edit" pInitEditableRow (click)="handleUpdateItem(data)" [btnDisabled]="isEditingTable"></app-fm-button>
          <app-fm-button [attr.data-testid]="'dataTable_button_edit_item_' + (ri + 1)" *ngIf="!editing && isEditingTable" type="edit" [btnDisabled]="isEditingTable"></app-fm-button>
          <app-fm-button [attr.data-testid]="'dataTable_button_save_item_' + (ri + 1)" *ngIf="editing" type="save" pSaveEditableRow (click)="handleSaveUpdateItem(data)"></app-fm-button>
        </div>
        <div *ngIf="col.type === 'action' && col.action === 'delete'">
          <app-fm-button [attr.data-testid]="'dataTable_button_delete_item_' + (ri + 1)" *ngIf="!editing" type="delete" (click)="handleDeleteItem(data.id || data)"></app-fm-button>
          <app-fm-button [attr.data-testid]="'dataTable_button_void_item_' + (ri + 1)" *ngIf="editing" type="void" pCancelEditableRow (click)="handleCancelEditItem()"></app-fm-button>
        </div>
        <div *ngIf="col.type === 'action' && col.action === 'clear'">
          <app-fm-button [attr.data-testid]="'dataTable_button_void_item_' + (ri + 1)" *ngIf="editing" type="void" pCancelEditableRow (click)="handleCancelEditItem()"></app-fm-button>
        </div>
        <div *ngIf="col.type === 'action' && col.action === 'edit-link'">
          <app-fm-button [attr.data-testid]="'dataTable_button_edit_item_' + (ri + 1)" type="edit" (click)="handleUpdateItem(data)"></app-fm-button>
        </div>
        <div *ngIf="col.type === 'action' && col.action === 'consult'">
          <app-fm-button [attr.data-testid]="'dataTable_button_consult_item_' + (ri + 1)" type="consult" (click)="handleConsultItem(data)"></app-fm-button>
        </div>
        <div *ngIf="col.type === 'action' && col.action === 'fusion'" pTooltip="Fusionner vers..." tooltipPosition="top">
          <app-fm-button [attr.data-testid]="'dataTable_button_fusion_item_' + (ri + 1)" type="fusion" (click)="handleFusionItem(data)"></app-fm-button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<div class="fm-button-more" *ngIf="data != null && data.length > rowNumber">
  <app-fm-button [attr.data-testid]="'dataTable_button_showMoreLess'" type="more" btnText="{{btntext | uppercase}}" (click)="displayAllList()"></app-fm-button>
</div>
