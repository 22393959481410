import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectItemComponent),
      multi: true,
    },
  ],
})
export class SelectItemComponent implements OnInit, ControlValueAccessor {
  selectformGroup: FormGroup;
  public disabled = false;
  @Input('one-select') isOneSelect: boolean;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.selectformGroup = this.isOneSelect
      ? this.formBuilder.group({
          name: new FormControl({ name: '' }, Validators.required),
        })
      : this.formBuilder.group({
          name: new FormControl('', Validators.required),
          interet: new FormControl('', Validators.required),
          niveau: new FormControl('', Validators.required),
          validationManager: new FormControl('', Validators.required),
        });

    this.selectformGroup.valueChanges.subscribe((val) => {
      this.value = val;
      this.onChange(val);
    });
  }

  /**
   * Call when value has changed programmatically
   */
  public onChange(newVal: any) {}
  public onTouched(_?: any) {}
  public value: any;

  writeValue(select: any) {
    this.value = select;
    this.selectformGroup.patchValue(select);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
