import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParametersComponent } from './pages/parameters/parameters.component';
import { NavbarService } from '../../core/services/navbar-services/navbar.service';
import { SharedModule } from '../../shared/shared.module';
import { TabMenuModule } from 'primeng/tabmenu';

@NgModule({
  declarations: [ParametersComponent],
  imports: [
    CommonModule,
    SharedModule,
    TabMenuModule,
  ],
  providers: [NavbarService]
})
export class ParametersModule { }
