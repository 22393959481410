import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HierarchyComponent } from './pages/hierarchy/hierarchy.component';
import { ViewRequestManagerComponent } from './pages/hierarchy/view-request-manager/view-request-manager.component';
import {SharedModule} from '../../shared/shared.module';
import {MessageService} from 'primeng/api';



@NgModule({
  declarations: [
    HierarchyComponent,
    ViewRequestManagerComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [MessageService]
})
export class HierarchyModule { }
