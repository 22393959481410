import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService, SelectItem} from 'primeng/api';
import {CourseService} from '../../../../core/services/course-services/course.service';
import {WeeklyReportService} from 'src/app/core/services/weekly-report.services/weekly-report.service';
import {User} from 'src/app/shared/models/user';
import {Observable, Subscription} from 'rxjs';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectService} from '../../../../core/services/project-services/project.service';
import {ReportService} from 'src/app/core/services/report-services/report.service';
import {EnvService} from '../../../../core/env/env.service';

@Component({
  selector: 'app-rha',
  templateUrl: './rha.component.html',
  styleUrls: ['./rha.component.scss']
})
export class RhaComponent implements OnInit, OnDestroy {
  user: User;
  coursesOptions: SelectItem[] = [];
  selectCourse: SelectItem;
  selectProject: any;
  projeqtor: boolean;
  projects$: Observable<any>;
  coursesSubscription: Subscription;
  userSubscription: Subscription;
  formData: FormGroup;
  freeAnswerMaxLength = 5000;
  editorStyle: any = { height: '320px', boxShadow: '0 0 10px #1432461A'};

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private messageService: MessageService,
    private courseService: CourseService,
    private projectService: ProjectService,
    private weeklyReportService: WeeklyReportService,
    private reportService: ReportService,
    public envService: EnvService
    ) {
    this.formData = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.getCurrentUser().subscribe(
      user => this.user = user
    );

    this.coursesSubscription = this.courseService.getAll().subscribe(
      result => result.map(
        item => this.coursesOptions.push({ label: item.name, value: item })
      )
    );

    this.projects$ = this.projectService.getMindsProjects();

    this.formData.addControl('rating', new FormControl(null, Validators.required));
    this.formData.addControl('project', new FormControl(null, Validators.required));
    this.formData.addControl('course', new FormControl(null, Validators.required));
    this.formData.addControl('projeqtor', new FormControl(null, Validators.required));
    this.formData.addControl('task', new FormControl(null, Validators.required));
    this.formData.addControl('mission', new FormControl(null, Validators.required));
    this.formData.addControl('vacation', new FormControl(null, Validators.required));
  }


  displayMessage(e) {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 'incomplete':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez renseigner tous les champs du formulaire.';
        break;
      case 'courseNeeded':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Une formation est obligatoire lorsque vous sélectionnez Creative Academy.';
        break;
      case 'weeklyReportSent':
        severity = 'success';
        summary = 'Formulaire envoyé';
        detail = 'Votre Rapport Hebdomadaire d\'Activité a bien été envoyé.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  validateWeeklyReport(): void {
    const course = 'course';

    // Reset course select if project != academy
    if (this.formData.value.project !== '-1'){
      this.selectCourse = null;
      this.formData.patchValue( { course: null }, { onlySelf: true });
      this.formData.controls[course].markAsPristine({ onlySelf: true });
    }

    // Mark invalid fields to dirty
    for (const i in this.formData.controls) {
      if (!this.formData.controls[i].valid && i !== 'course') {
        this.formData.controls[i].markAsDirty();
      }
    }
    // Mark invalid field course to dirty if project is academy
    if (this.formData.value.project === '-1' && !this.formData.value.course) {
      this.formData.controls[course].markAsDirty();
    }

    const allAnswers: boolean = this.formData.value.rating &&
      this.formData.value.project &&
      this.formData.value.projeqtor !== undefined &&
      this.formData.value.task &&
      this.formData.value.mission &&
      this.formData.value.vacation;

    let errors = 0;
    if (!allAnswers){
      errors++;
      this.displayMessage('incomplete');
    }
    if (this.formData.value.project === '-1' && !this.formData.value.course){
      errors++;
      this.displayMessage('courseNeeded');
    }
    if (!errors) {
      const weeklyReport: any = {
        id: 0,
        date: Date.now(),
        type: { id: 1, name: 'RHA' },
        collaborator: { id: this.user.id },
        interviewer: { id: this.user.id },
        mission: this.formData.value.mission,
        projeqtor: this.formData.value.projeqtor,
        task: this.formData.value.task,
        vacation: this.formData.value.vacation,
        rating: this.formData.value.rating,
        project: this.formData.value.project === '-1' ? null : { id: this.formData.value.project },
        course: this.formData.value.course,
      };

      this.reportService.saveWeeklyReport(weeklyReport).subscribe(
        () => {
          this.displayMessage('weeklyReportSent');
          this.formData.reset();
        },
        (e) => console.log(e)
      );
    }
  }

  redirectToRhaSurvey() {
    window.open(
    this.envService.rha.surveyFormsOffice,
    '_blank'
    );
  }

  ngOnDestroy() {
    this.coursesSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }
}
