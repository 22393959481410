<app-selection-menu
  [typeData]="typeData"
  [typeDataSelected]="typeDataSelected"
  [showAddForm]="showAddForm"
  (changeType)="handleChangeType($event)"
  (toggleShowAddForm)="toggleShowAddForm()"
  (globalSearchChange)="handleGlobalSearchChange($event)"
>
</app-selection-menu>

<app-view-courses
  *ngIf="typeDataSelected === 'Formations'"
  [showAddForm]="showAddForm"
></app-view-courses>

<app-view-certifications
  *ngIf="typeDataSelected === 'Certifications'"
  [showAddForm]="showAddForm"
></app-view-certifications>

<app-view-rh
  *ngIf="typeDataSelected === 'RH'"
  [showAddForm]="showAddForm"
></app-view-rh>
