import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { Skill } from '../../models/skill';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  @Output()
  eventModalConfirm = new EventEmitter<any> ();

  @Output()
  eventFusionConfirm = new EventEmitter<any> ();

  @Output()
  eventModalCancel = new EventEmitter<Event> ();

  @Output()
  displayChange = new EventEmitter<boolean> ();

  @Input()
  type: 'dialog' | 'confirm' | 'yesOrNo' | 'radioButton' | 'fusion';

  @Input()
  display: boolean;

  @Input()
  header: string;

  @Input()
  content: string;

  @Input()
  closable = false;

  @Input()
  radioButtonElements: any[];

  @Input()
  data: string;

  selectedOption: any;
  selectedValue: string = null;

  constructor() { }

  ngOnInit(): void {
  }

  handleCancel() {
    this.eventModalCancel.emit();
    this.selectedValue = null;
    this.selectedOption = null;
    this.handleHide();
  }

  handleConfirm() {
    this.type === 'radioButton' ? this.eventModalConfirm.emit(this.selectedValue) : this.eventModalConfirm.emit();
    this.selectedValue = null;
    this.handleHide();
  }

  handleFusionItem() {
    this.eventFusionConfirm.emit(this.selectedOption);
    this.handleHide();
  }

  handleHide() {
    this.display = false;
    this.displayChange.emit(this.display);
    this.selectedValue = null;
  }
}
