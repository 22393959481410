import { Certification } from 'src/app/shared/models/certification';

export class MakeSnapshotCertification {
  static readonly type = '[Certification] MakeSnapshot';
}

export class RestoreSnapshotCertification {
  static readonly type = '[Certification] RestoreSnapshot';
}

export class ClearSnapshotCertification {
  static readonly type = '[Certification] ClearSnapshot';
}

export class GetCertifications {
  static readonly type = '[Certification] Get';
}

export class AddCertification {
  static readonly type = '[Certification] Add';
  constructor(public payload: Certification) {}
}

export class UpdateCertification {
  static readonly type = '[Certification] Update';
  constructor(public payload: Certification) {}
}

export class DeleteCertification {
  static readonly type = '[Certification] Delete';
  constructor(public payload: Certification) {}
}

export class FilterCertifications {
  static readonly type = '[Certification] Filter';
  constructor(public payload: string) {}
}
