import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportService extends ApiService {

  protected prefix(): string {
    return 'export';
  }

  getAnnualReports(status: string = 'all'): Observable<any> {
    const params = new Map();
    params.set('status', status);
    return this.get('/rh/annualReport', params, 'blob');
  }
}

