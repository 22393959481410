<div class="fm-form-inline">
  <h5>{{titleForm}}</h5>
  <form [formGroup]="formData">
    <div class="fm-flex">
      <div class="fm-form-fields">
        <div *ngFor="let inputFormComponent of listInput" class="fm-form-field">
          <div [ngSwitch]="inputFormComponent.typeForm">
            <div *ngSwitchCase="'image'">
              <input
                [id]="inputFormComponent.key"
                [attr.data-testid]="'formInline_field_' + inputFormComponent.fieldForm"
                type="text"
                pInputText
                [formControlName]="inputFormComponent.fieldForm"
                [hidden]="true"
              />
              <img *ngIf="loadingEnd" [src]="iconImage | safeSanitizer" width="37" height="37" alt="Aperçu de l’image...">
              <p-fileUpload
                [attr.data-testid]="'formInline_field_' + inputFormComponent.fieldForm"
                *ngIf="!loadingEnd"
                mode="basic"
                customUpload="true"
                chooseLabel=""
                chooseIcon="fm-icon-uploadimage"
                (onSelect)="handleFileUpload($event)"
                accept="image/*"
                maxFileSize="150000"
              ></p-fileUpload>
            </div>
            <div *ngSwitchCase="'checkbox'" class="p-field-checkbox">
              <p-checkbox
                [id]="inputFormComponent.key"
                [attr.data-testid]="'formInline_field_' + inputFormComponent.fieldForm"
                [binary]="true"
                [formControlName]="inputFormComponent.fieldForm">
              </p-checkbox>
              <label>{{inputFormComponent.labelForm}}</label>
            </div>
            <div *ngSwitchCase="'text'">
              <input
                [id]="inputFormComponent.key"
                [attr.data-testid]="'formInline_field_' + inputFormComponent.fieldForm"
                type="text"
                pInputText
                [maxlength]="inputFormComponent.maxLength"
                [formControlName]="inputFormComponent.fieldForm"
                [placeholder]="inputFormComponent.labelForm"
              />
            </div>
            <div *ngSwitchCase="'dropdown'">
              <p-dropdown
                [attr.data-testid]="'formInline_field_' + inputFormComponent.fieldForm"
                [options]="inputFormComponent.optionsForm | toSelectItem"
                [optionLabel]="inputFormComponent.optionLabel"
                [formControlName]="inputFormComponent.fieldForm"
                [filter]="inputFormComponent.filter"
                [showClear]="true"
                [placeholder]="inputFormComponent.placeHolder"
                [styleClass]="inputFormComponent.className"
                (onChange)="handleChangeDropdown(inputFormComponent.fieldForm, $event)"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>
                    <div *ngIf="!item.icon">{{ item.label }}</div>
                    <div *ngIf="item.icon">
                      <i class="{{item.icon}}"></i>
                      &nbsp;
                      <span *ngIf="!inputFormComponent.onlyIcon">{{ item.label }}</span>
                    </div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>
                    <div *ngIf="!inputFormComponent.icon">
                      {{ item.label }}
                      <span *ngIf="inputFormComponent.optionConcat === 'profile'" class="fm-profile-name">&nbsp;&nbsp;{{ item.value.profile.name }}</span>
                    </div>
                    <div *ngIf="inputFormComponent.icon">
                      <i class="{{item.icon}}"></i>
                      &nbsp;
                      <span *ngIf="!inputFormComponent.onlyIcon">{{ item.label }}</span>
                    </div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div *ngSwitchCase="'calendar'">
              <p-calendar [id]="inputFormComponent.key"
                          [attr.data-testid]="'formInline_field_' + inputFormComponent.fieldForm"
                          [formControlName]="inputFormComponent.fieldForm"
                          [placeholder]="inputFormComponent.labelForm"
                          dateFormat="dd/mm/yy"
                          [locale]="fr"
                          appendTo="body">
              </p-calendar>
            </div>
            <div *ngSwitchCase="'autocomplete'">
              <p-autoComplete [attr.data-testid]="'formInline_field_' + inputFormComponent.fieldForm"
                              [formControlName]="inputFormComponent.fieldForm"
                              [placeholder]="inputFormComponent.placeHolder"
                              [suggestions]="inputFormComponent.suggestions | async"
                              [maxlength]="inputFormComponent.maxLength"
                              (completeMethod)="inputFormComponent.filterItems($event)"
                              [field]="inputFormComponent.suggestionField"
                              minLength="2"
                              emptyMessage="Aucun résultat">
              </p-autoComplete>
            </div>
          </div>
        </div>
      </div>
      <div class="fm-form-buttons">
        <app-fm-button
          class="fm-form-button"
          *ngIf="!saveDisabled && showSaveButton"
          type="save"
          (click)="handleSaveButtonClick()"
          [attr.data-testid]="'formInline_button_save'"
        ></app-fm-button>
        <app-fm-button
          *ngIf="saveDisabled && showSaveButton"
          type="save-disabled"
          btnDisabled="true"
          class="fm-form-button"
          [attr.data-testid]="'formInline_button_save_disabled'"
        ></app-fm-button>
        <app-fm-button
          *ngIf="showClearButton"
          type="void"
          (click)="handleClearForm()"
          class="fm-form-button"
          [attr.data-testid]="'formInline_button_clear'"
        ></app-fm-button>
      </div>
    </div>
  </form>
</div>
