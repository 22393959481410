import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {HrReport} from "../../models/hr-report";
import {ReportService} from "../../../core/services/report-services/report.service";

@Component({
  selector: 'app-fm-hr-report',
  templateUrl: './fm-hr-report-component.html',
  styleUrls: ['./fm-hr-report-component.scss']
})
export class FmHrReportComponent implements OnInit {

  @Input()
  hrReport: HrReport;

  @Input()
  editable: boolean = false;

  editorStyle: any = {height: '320px', boxShadow: '0 0 10px #1432461A'};
  freeAnswerMaxLength = 5000;
  formData: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private reportService: ReportService,
  ) {
    this.formData = this.formBuilder.group({});

    this.formData.addControl('rating', new FormControl(null, Validators.required));
    this.formData.addControl('motivationAssessmentPart1', new FormControl(null));
    this.formData.addControl('motivationAssessmentPart2', new FormControl(null));
    this.formData.addControl('motivationAssessmentPart3', new FormControl(null));
    this.formData.addControl('motivationAssessmentPart4', new FormControl(null));
    this.formData.addControl('motivationAssessmentPart5', new FormControl(null));
  }

  public ngOnInit(): void {
    this.formData.patchValue({rating: this.hrReport.rating}, {onlySelf: true});
    this.formData.patchValue({motivationAssessmentPart1: this.hrReport.motivationAssessmentPart1}, {onlySelf: true});
    this.formData.patchValue({motivationAssessmentPart2: this.hrReport.motivationAssessmentPart2}, {onlySelf: true});
    this.formData.patchValue({motivationAssessmentPart3: this.hrReport.motivationAssessmentPart3}, {onlySelf: true});
    this.formData.patchValue({motivationAssessmentPart4: this.hrReport.motivationAssessmentPart4}, {onlySelf: true});
    this.formData.patchValue({motivationAssessmentPart5: this.hrReport.motivationAssessmentPart5}, {onlySelf: true});

    if(!this.editable){
      this.formData.controls['rating'].disable();
    }
  }

  public saveAndQuit(): void {
    const hrReport: HrReport = {
      id: this.hrReport.id,
      date: this.hrReport.date,
      type: this.hrReport.type,
      collaborator: this.hrReport.collaborator,
      interviewer: this.hrReport.interviewer,
      rating: this.formData.value.rating,
      assignment: this.hrReport.assignment,
      motivationAssessmentPart1: this.formData.value.motivationAssessmentPart1,
      motivationAssessmentPart2: this.formData.value.motivationAssessmentPart2,
      motivationAssessmentPart3: this.formData.value.motivationAssessmentPart3,
      motivationAssessmentPart4: this.formData.value.motivationAssessmentPart4,
      motivationAssessmentPart5: this.formData.value.motivationAssessmentPart5,
    }

    this.reportService.saveHrReport(hrReport).subscribe(report => {
      if (report) {
        this.return();
      }
    });
  }

  public return(): void {
    // TODO il y'a probablement moyen d'améliorer ceci. Selon qu'on vienne de la page manager ou utilisateur, on veut un retour arrière différent.
    if (this.router.url.includes('activity')) {
      this.router.navigateByUrl('activity/reports').then();
    } else {
      this.router.navigateByUrl('team/reports').then();
    }
  }
}
