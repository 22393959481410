<form [formGroup]="formData">
  <div class="container fm-annual-report">
    <div class="content p-d-flex p-flex-column ">
      <div class="p-d-flex p-flex-column p-ai-center">
        <h2>{{title}}</h2>
        <p-steps *ngIf="!isSummary" [model]="items" [(activeIndex)]="activeIndex" [readonly]="false" styleClass="stepItems"
          (activeIndexChange)="handleStep($event)"></p-steps>
      </div>
      <router-outlet #target></router-outlet>
    </div>
    <div class="buttons p-mt-3 p-d-flex p-jc-between p-ai-end">
      <div>
        <app-fm-button *ngIf="activeIndex === 0 && !isSummary" type="text-fade" btnText="ANNULER" (click)="cancelForm()"></app-fm-button>
        <app-fm-button *ngIf="activeIndex !== 0 && !isSummary" type="text-fade" btnText="PRÉCEDENT" (click)="previousPage(target)"></app-fm-button>
        <app-fm-button *ngIf="isSummary" type="text-fade" btnText="RETOUR" (click)="cancelForm()"></app-fm-button>
      </div>
      <div class="p-d-flex p-jc-end">
        <!-- ========= quit and save button for collaborator ============== -->
        <app-fm-button *ngIf="role === 'collaborator' &&
                            workflowCollaborator < 3 &&
                            workflowManager < 4 &&
                            !isSummary &&
                            !isTooLate" type="text-info" btnText="ENREGISTRER ET QUITTER" (click)="saveAndQuit()" class="p-mr-3">
        </app-fm-button>

        <!-- ========= quit and save button for interviewer ============== -->
        <app-fm-button *ngIf="role === 'currentInterviewer'
                        && !isSummary
                        && workflowManager !== 5" type="text-info" btnText="ENREGISTRER ET QUITTER" (click)="saveAndQuit()" class="p-mr-3">
        </app-fm-button>

        <!-- ========= NEXT button ============== -->
        <app-fm-button *ngIf="activeIndex !== items.length-1 && !isSummary" type="text-gradient" btnText="SUIVANT" (click)="nextPage(target)">
        </app-fm-button>

        <!-- ========= preparation button for collaborator ============== -->
        <app-fm-button *ngIf="activeIndex === (items.length - 1) &&
                            role === 'collaborator' &&
                            workflowCollaborator < 3 &&
                            workflowManager < 4 &&
                            !isSummary &&
                            !isTooLate" type="text-gradient" btnText="VALIDER LA PRÉPARATION" (click)="validateForm('preparation', role)">
        </app-fm-button>

        <!-- ========= validation button for collaborator ============== -->
        <app-fm-button *ngIf="activeIndex === (items.length - 1)
              && role === 'collaborator'
              && workflowManager === 5
              && annualReportService.hasCollaboratorValidated$.getValue()
              && !isSummary" type="text-gradient" btnText="VALIDER LA SYNTHÈSE" (click)="validateForm('validation', role)">
        </app-fm-button>

        <!-- ========= validation button for interviewer ============== -->
        <app-fm-button *ngIf="(activeIndex === items.length-1)
              && role === 'currentInterviewer'
              && workflowManager !== 5
              && !isSummary" type="text-gradient" btnText="VALIDER LA SYNTHÈSE" (click)="validateForm('validation', role)">
        </app-fm-button>
        <!-- ========= comment button for collaborator ============== -->
        <app-fm-button *ngIf="(activeIndex === items.length-1)
                          && role === 'collaborator'
                          && workflowManager === 5
                          && !annualReportService.hasCollaboratorValidated$.getValue()
                          && !isSummary" type="text-gradient" btnText="ENVOYER MON COMMENTAIRE" (click)="validateForm('invalidation', role)">
        </app-fm-button>
      </div>
    </div>
  </div>
</form>

<app-modal header="Confirmation" [content]="collaboratorModalConfirmContent" type="confirm" [(display)]="showCollaboratorModalConfirm"
  (eventModalConfirm)="handleCollaboratorModalConfirm()">
</app-modal>

<app-modal header="Formulaire incomplet" [content]="modalDialogContent" type="dialog" closable="true" [(display)]="showModalDialog">
</app-modal>

<app-modal header="Confirmation" [content]="managerModalConfirmContent" type="confirm" [(display)]="showManagerModalConfirm"
  (eventModalConfirm)="handleManagerModalConfirm()">
</app-modal>