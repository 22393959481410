import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageService } from 'primeng/api';
import { QualificationsComponent } from './pages/qualifications/qualifications.component';
import { ViewProfilesComponent } from './pages/qualifications/view-profiles/view-profiles.component';
import { ViewSkillsComponent } from './pages/qualifications/view-skills/view-skills.component';
import { ViewSpecialtiesComponent } from './pages/qualifications/view-specialties/view-specialties.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [
        QualificationsComponent,
        ViewProfilesComponent,
        ViewSkillsComponent,
        ViewSpecialtiesComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
    ],
    exports: [
        ViewSpecialtiesComponent
    ],
    providers: [MessageService]

})
export class QualificationsModule { }
