<div *ngIf="isLoaded && user$ | async as user">
  <app-selected-collaborator [currentUser]="user" [module]=module></app-selected-collaborator>

  <div class="fm-team-view-header">
    <p-dropdown
      [attr.data-testid]="'summary_update_dropdown_type'"
      [options]="selectItems"
      [(ngModel)]="selectedItem"
      class="fm-select-items">
    </p-dropdown>

    <div *ngIf="mainManager" class="fm-top-switch fm-small">
      <p-inputSwitch
        [attr.data-testid]="'summary_update_toggle_validation'"
        (onChange)="handleValidateQualificationsForUser($event)"
        [(ngModel)]="displayOnlyQualificationsNotValidated"
      ></p-inputSwitch>
      <span class="fm-label">A Valider</span>
    </div>

    <div class="fm-buttons fm-right-button">
      <app-fm-button [attr.data-testid]="'summary_update_button_back'" type="text-gradient" btnText="RETOUR" (onClick)="handleBack()"></app-fm-button>
    </div>
  </div>

  <div *ngIf="selectedItem === 'Compétences métiers'">
    <app-view-profiles
      [attr.data-testid]="'summary_update_view_profiles'"
      [interests]="interests"
      [interestsOptions]="interestsOptions"
      [levels]="levels"
      [levelsOptions]="levelsOptions"
      [isMainManager]="mainManager"
      [displayOnlyQualificationsNotValidated]="displayOnlyQualificationsNotValidated"
    ></app-view-profiles>
    <app-view-specialties
      [attr.data-testid]="'summary_update_view_specialties'"
      [interests]="interests"
      [interestsOptions]="interestsOptions"
      [levels]="levels"
      [levelsOptions]="levelsOptions"
      [isMainManager]="mainManager"
      [displayOnlyQualificationsNotValidated]="displayOnlyQualificationsNotValidated"
    ></app-view-specialties>
    <app-view-skills
      [attr.data-testid]="'summary_update_view_skills'"
      [interests]="interests"
      [interestsOptions]="interestsOptions"
      [levels]="levels"
      [levelsOptions]="levelsOptions"
      [isMainManager]="mainManager"
      [displayOnlyQualificationsNotValidated]="displayOnlyQualificationsNotValidated"
    ></app-view-skills>
  </div>

  <div *ngIf="selectedItem === 'Compétences transverses' && mainManager">
    <app-view-soft-skills
      [attr.data-testid]="'summary_update_view_softSkills'"
      [interestsOptions]="interestsOptions"
      [levelsOptions]="levelsOptions"
    ></app-view-soft-skills>
  </div>

  <div *ngIf="selectedItem === 'Formations et certifications'">
    <app-view-courses
      [attr.data-testid]="'summary_update_view_courses'"
      [interests]="interests"
      [interestsOptions]="interestsOptions"
    ></app-view-courses>
    <app-view-certifications
      [attr.data-testid]="'summary_update_view_certifications'"
      [interests]="interests"
      [interestsOptions]="interestsOptions"
    ></app-view-certifications>
  </div>

  <div *ngIf="selectedItem === 'Missions'">
    <app-view-assignments [attr.data-testid]="'summary_update_view_assignments'"></app-view-assignments>
  </div>
</div>

<app-modal
  [header]="modal.header"
  [content]="modal.content"
  [type]="modal.type"
  [(display)]="showModal"
  closable="true"
  (eventModalConfirm)="handleModalConfirm()"
  (eventModalCancel)="handleModalCancel()"
></app-modal>
