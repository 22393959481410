import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AnnualReport} from 'src/app/shared/models/annual-report';
import {Observable, Subscription} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {ReportState} from 'src/stores/reports/report.state';
import {
  SetPendingAnnualReport,
} from 'src/stores/reports/report.action';
import {Team} from 'src/app/shared/models/team';
import {AnnualReportService} from '../../../../core/services/annual-report-service/annual-report.service';
import {Router} from '@angular/router';
import { Constants } from '../../../constants/constants';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit, OnDestroy {
  @Select(ReportState.getAnnualReport) annualReport$: Observable<AnnualReport>;
  @Select(ReportState.getPendingAnnualReport) pendingAnnualReport$: Observable<AnnualReport>;
  @Select(ReportState.getRole) role$: Observable<string>;

  workflowCollaborator: number;
  workflowManager: number;
  isTooLate: boolean;

  reportId: number;
  formData: FormGroup;
  mainManager: Team;
  companySeniority: string;
  position: string;
  positionSeniority: string;
  interviewStartDate: string;
  annualReportDateCalendar: string;
  collaboratorName: string;
  collaboratorFirstname: string;
  managerName: string;
  managerFirstname: string;
  pendingAnnualReport: AnnualReport;
  pendingAnnualReportSubscription: Subscription;
  roleSubscription: Subscription;
  role: string;
  minDateValue = new Date();
  fr = Constants.CALENDAR_FR;

  constructor(private store: Store,
              private router: Router,
              private formBuilder: FormBuilder,
              private annualReportService: AnnualReportService) {
    this.formData = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.roleSubscription = this.role$.subscribe((role) => {
      this.pendingAnnualReportSubscription = this.pendingAnnualReport$.subscribe((pendingAnnualReport: AnnualReport) => {
        if (pendingAnnualReport){
          this.pendingAnnualReport = pendingAnnualReport;
          this.collaboratorName = pendingAnnualReport.collaborator.lastname;
          this.collaboratorFirstname = pendingAnnualReport.collaborator.firstname;
          this.managerName = pendingAnnualReport.interviewer?.lastname || '';
          this.managerFirstname = pendingAnnualReport.interviewer?.firstname || '';
          this.interviewStartDate = pendingAnnualReport.interviewStartDate;
          this.annualReportDateCalendar = this.interviewStartDate ?
            new Date(pendingAnnualReport.interviewStartDate).toLocaleDateString() :
            null;
          this.position = pendingAnnualReport.position;
          this.companySeniority = pendingAnnualReport.companySeniority;
          this.positionSeniority = pendingAnnualReport.positionSeniority;
          this.isTooLate = this.annualReportService.isTooLate(pendingAnnualReport.interviewStartDate);
          this.workflowCollaborator = pendingAnnualReport.workflowCollaborator;
          this.workflowManager = pendingAnnualReport.workflowManager;
        }
        else {
          // To hook refresh web browser action
          this.router.url.includes('activity') ?
            this.router.navigateByUrl('/activity/reports') :
            this.router.navigateByUrl('/team/reports');
        }
      });

      if (role === 'collaborator') {
        this.initCollaboratorForm();
      } else if (role === 'currentInterviewer') {
        this.initInterviewerForm();
      }
      this.role = role;
    });
  }

  initCollaboratorForm() {
    this.formData.addControl('companySeniority', new FormControl(null, Validators.required));
    this.formData.addControl('position', new FormControl(null, Validators.required));
    this.formData.addControl('positionSeniority', new FormControl(null, Validators.required));
  }

  initInterviewerForm() {
    this.formData.addControl('annualReportDateCalendar', new FormControl(null, Validators.required));
    this.formData.addControl('managerName', new FormControl(null, Validators.required));
    this.formData.addControl('managerFirstname', new FormControl(null, Validators.required));
    this.formData.addControl('collaboratorName', new FormControl(null));
    this.formData.addControl('collaboratorFirstname', new FormControl(null));
    this.formData.addControl('companySeniority', new FormControl(null));
    this.formData.addControl('position', new FormControl(null));
    this.formData.addControl('positionSeniority', new FormControl(null));
  }

  handleChange(event: any){
    if (event && this.role === 'collaborator'){
      this.pendingAnnualReport.position = this.formData.value.position;
      this.pendingAnnualReport.companySeniority = this.formData.value.companySeniority;
      this.pendingAnnualReport.positionSeniority = this.formData.value.positionSeniority;
    }
    if (event && this.role === 'currentInterviewer'){
      const interviewStartDate = new Date(this.formData.value.annualReportDateCalendar);

      // If date is not null, set haours, minutes and seconds to 0
      if(this.formData.value.annualReportDateCalendar) {
        interviewStartDate.setHours(0);
        interviewStartDate.setMinutes(0);
        interviewStartDate.setSeconds(0);
      }

      this.pendingAnnualReport.interviewStartDate = this.formData.value.annualReportDateCalendar ?
        interviewStartDate.toISOString() : null;
    }
    this.store.dispatch(new SetPendingAnnualReport(this.pendingAnnualReport));
  }

  ngOnDestroy() {
    this.roleSubscription.unsubscribe();
    this.pendingAnnualReportSubscription.unsubscribe();
  }
}
