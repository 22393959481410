import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TeamComponent } from './pages/team/team.component';
import { SelectCollaboratorComponent } from './components/select-collaborator/select-collaborator.component';
import { SelectedCollaboratorComponent } from './components/selected-collaborator/selected-collaborator.component';
import { ViewProfilesComponent } from './pages/summary/summary-update-profile/view-profiles/view-profiles.component';
import { ViewSpecialtiesComponent } from './pages/summary/summary-update-profile/view-specialties/view-specialties.component';
import { ViewSkillsComponent } from './pages/summary/summary-update-profile/view-skills/view-skills.component';
import { ViewCoursesComponent } from './pages/summary/summary-update-profile/view-courses/view-courses.component';
import { ViewCertificationsComponent } from './pages/summary/summary-update-profile/view-certifications/view-certifications.component';
import { ViewSoftSkillsComponent } from './pages/summary/summary-update-profile/view-soft-skills/view-soft-skills.component';
import { ViewAssignmentsComponent } from './pages/summary/summary-update-profile/view-assignments/view-assignments.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SummaryMembersComponent } from './pages/summary/summary-members/summary-members.component';
import { SummaryShowProfileComponent } from './pages/summary/summary-show-profile/summary-show-profile.component';
import { SummaryUpdateProfileComponent } from './pages/summary/summary-update-profile/summary-update-profile.component';
import { SummaryRhaComponent } from './pages/summary/summary-rha/summary-rha.component';
import { TeamReportsComponent } from './pages/reports/reports-team/reports-list.component';
import { CreateTeamReportsComponent } from './pages/reports/reports-create/reports-create.component';
import { ReportsRhaComponent } from './pages/reports/reports-rha/reports-rha.component';
import { RequestModule } from '../request/request.module';
import { AnnualReportTeamComponent } from './pages/reports/reports-team/annual-report-team/annual-report-team.component';

@NgModule({
  declarations: [
    TeamComponent,
    SelectCollaboratorComponent,
    SelectedCollaboratorComponent,
    ViewProfilesComponent,
    ViewSpecialtiesComponent,
    ViewSkillsComponent,
    ViewCoursesComponent,
    ViewCertificationsComponent,
    ViewSoftSkillsComponent,
    ViewAssignmentsComponent,
    TeamReportsComponent,
    CreateTeamReportsComponent,
    SummaryMembersComponent,
    SummaryShowProfileComponent,
    SummaryUpdateProfileComponent,
    SummaryRhaComponent,
    ReportsRhaComponent,
    AnnualReportTeamComponent,
  ],
  exports: [
    ViewProfilesComponent,
    ViewSkillsComponent,
    ViewSoftSkillsComponent,
    ViewAssignmentsComponent,
    SelectedCollaboratorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    InputSwitchModule,
    ReactiveFormsModule,
  ]
})
export class TeamModule { }
