<app-selection-menu
  [typeData]="typeData"
  [typeDataSelected]="typeDataSelected"
  [showAddForm]="showAddForm"
  [globalSearch]="globalSearch"
  (changeType)="handleChangeType($event)"
  (toggleShowAddForm)="toggleShowAddForm()"
  (globalSearchChange)="handleGlobalSearchChange($event)"
>
</app-selection-menu>

<app-view-customers
  *ngIf="typeDataSelected === 'Clients'"
  [showAddForm]="showAddForm"
  (eventRedirect)="handleRedirectFromCustomer($event)"
></app-view-customers>

<app-view-projects
  *ngIf="typeDataSelected === 'Projets'"
  [showAddForm]="showAddForm"
  [isRedirected]="isRedirected"
></app-view-projects>
