<div class="fm-title">
  <h1>6. Commentaire final</h1>
</div>
<div class="fm-step">
  <form [formGroup]="formData">

    <div class="fm-form p-d-flex p-flex-column">
      <h2>{{title}}</h2>
      <p-editor *ngIf="role === 'collaborator' && workflowCollaborator < 3 && !isTooLate && workflowManager < 4"
        [style]="{'height':'320px', 'box-shadow': '0 0 10px #1432461A'}" [maxlength]="freeAnswerMaxLength" formControlName="collaboratorFinalComment"
        [(ngModel)]="collaboratorFinalComment" [placeholder]="placeholder" (focusout)="handleChange($event)">
      </p-editor>
      <span *ngIf="collaboratorFinalComment && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)" [innerHTML]="collaboratorFinalComment"
        class="fm-text-editor"></span>
      <span *ngIf="!collaboratorFinalComment && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)" class="fm-text-editor">Le collaborateur n'a
        pas complété cette partie</span>
      <span *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && !isTooLate && workflowManager < 4" class="fm-text-editor">Le collaborateur n'a
        pas encore validé sa préparation</span>
    </div>

    <div class="fm-form">
      <h2>Manager</h2>
      <p-editor *ngIf="role === 'currentInterviewer' && workflowManager !== 5" [style]="{'height':'320px', 'box-shadow': '0 0 10px #1432461A'}"
        [maxlength]="freeAnswerMaxLength" formControlName="managerFinalComment" [(ngModel)]="managerFinalComment" [placeholder]="placeholder"
        (focusout)="handleChange($event)">
      </p-editor>
      <span *ngIf="managerFinalComment && workflowManager === 5" [innerHTML]="managerFinalComment" class="fm-text-editor"></span>
      <span *ngIf="managerFinalComment && role === 'collaborator' && workflowManager !== 5" class="fm-text-editor">Le manager n'a pas encore validé sa
        synthèse</span>
      <span *ngIf="!managerFinalComment && (workflowManager === 5 || (role === 'collaborator' && workflowManager !== 5))" class="fm-text-editor">Le manager n'a
        pas complété cette partie</span>
    </div>
  </form>
</div>