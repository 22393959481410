import { UserCertification } from '../../../shared/models/user-certification';

export class MakeSnapshotProfileUserCertification {
  static readonly type = '[User Profile Certification] MakeSnapshot';
}

export class RestoreSnapshotProfileUserCertification {
  static readonly type = '[User Profile Certification] RestoreSnapshot';
}

export class GetProfileUserCertificationsByUserId {
  static readonly type = '[User Profile Certification] Get user certifications';
  constructor(public payload: number) {}
}

export class AddProfileUserCertification {
  static readonly type = '[User Profile Certification] Add user certification';
  constructor(public payload: UserCertification) {}
}

export class UpdateProfileUserCertification {
  static readonly type = '[User Profile Certification] Update user certification';
  constructor(public payload: UserCertification) {}
}

export class DeleteProfileUserCertification {
  static readonly type = '[User Profile User Certification] delete';
  constructor(public payload: UserCertification) {}
}

export class ClearProfileUserCertifications {
  static readonly type = '[User Profile Certification] Clear user certifications';
}
