<div class="fm-title">
  <h1>2. Bilan de l'année écoulée</h1>
</div>
<div class="fm-step">
  <form [formGroup]="formData">
    <div class="fm-form">
      <h2>Collaborateur</h2>
      <p-editor *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate" [style]="editorStyle"
        [maxlength]="freeAnswerMaxLength" formControlName="collaboratorAssessment" [(ngModel)]="collaboratorAssessment" [placeholder]="placeholder"
        (onTextChange)="setValidatorBorder($event)" (focusout)="handleChange($event)">
      </p-editor>
      <span *ngIf="collaboratorAssessment && (workflowCollaborator >= 3 || workflowManager >=4 || isTooLate)" [innerHTML]="collaboratorAssessment"
        class="fm-text-editor"></span>
      <span *ngIf="!collaboratorAssessment && (workflowCollaborator >= 3 || workflowManager >=4 || isTooLate)" class="fm-text-editor">Le collaborateur n'a pas
        complété cette partie</span>
      <span *ngIf="role === 'currentInterviewer' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate" class="fm-text-editor">Le collaborateur n'a
        pas
        encore validé sa préparation</span>
    </div>

    <div class="fm-form">
      <h2>Manager</h2>
      <p-editor *ngIf="role === 'currentInterviewer' && workflowManager !== 5" [style]="editorStyle" [maxlength]="freeAnswerMaxLength"
        formControlName="managerAssessment" [(ngModel)]="managerAssessment" [placeholder]="placeholder" (focusout)="handleChange($event)">
      </p-editor>
      <span *ngIf="managerAssessment && workflowManager === 5" [innerHTML]="managerAssessment" class="fm-text-editor"></span>
      <span *ngIf="managerAssessment && role === 'collaborator' && workflowManager !== 5" class="fm-text-editor">Le manager n'a pas encore validé sa
        synthèse</span>
      <span *ngIf="!managerAssessment && (workflowManager === 5 || (role === 'collaborator' && workflowManager !== 5))" class="fm-text-editor">Le manager n'a
        pas complété cette partie</span>
    </div>
  </form>
</div>