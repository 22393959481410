import { UserSkill } from '../../../shared/models/user-skill';

export class MakeSnapshotProfileUserSkill {
  static readonly type = '[User Profile User Skill] MakeSnapshot';
}

export class RestoreSnapshotProfileUserSkill {
  static readonly type = '[User Profile User Skill] RestoreSnapshot';
}

export class GetProfileUserSkillsByUserId {
  static readonly type = '[User Profile User Skill] Get user skills';
  constructor(public payload: number) {}
}

export class AddProfileUserSkill {
  static readonly type = '[User Profile User Skill] Add';
  constructor(public payload: UserSkill) {}
}

export class UpdateProfileUserSkill {
  static readonly type = '[User Profile User Skill] update';
  constructor(public payload: UserSkill) {}
}

export class DeleteProfileUserSkill {
  static readonly type = '[User Profile User Skill] delete';
  constructor(public payload: UserSkill) {}
}

export class ClearProfileUserSkills {
  static readonly type = '[User Profile User Skill] Clear user skills';
}
