import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class WeeklyReportService extends ApiService {

  protected prefix(): string {
    return 'weeklyReports';
  }

  /**
   * Get the list of weekly-reports
   */
  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(content: any): Observable<any> {
    return this.put(content);
  }

  doDelete(id: number): Observable<any> {
    return this.delete(id);
  }

  getLastOne(memberId: number): Observable<any> {
    return this.get('/last/member/' + memberId);
  }

  isExist(id): Observable<any> {
    return this.getOne(id).pipe(
      map(data => data.id === id),
    );
  }

  projectExists(projectId: number): Observable<any> {
    return this.get('/project/' + projectId).pipe(
      map(result => result)
    );
  }
}
