<span *ngIf="!editable" class="p-text-italic">Note : cet entretien a été réalisé depuis plus de deux semaines, il ne peut donc plus être modifié.</span>

<div class="fm-container">
  <div class="info-title">
    <h2 class="bloc-title"> 1. Informations générales</h2>
    <div class="fm-line"></div>
  </div>

  <div class="fm-card p-d-flex p-flex-row">
    <div class="info">
      <h3 class="bloc-label">Date de l'entretien :</h3>
      <span class="bloc-text">{{ hrReport.date | date: 'dd/MM/yyyy' }}</span>
    </div>
  </div>

  <div class="info-title margin40top">
    <h3 class="bloc-title">Collaborateur</h3>
  </div>
  <div class="fm-card p-d-flex p-flex-row">
    <div class="info">
      <h3 class="bloc-label">Nom :</h3>
      <span class="bloc-text">{{ hrReport.collaborator.lastname }}</span>
    </div>
    <div class="info">
      <h3 class="bloc-label">Prénom :</h3>
      <span class="bloc-text">{{ hrReport.collaborator.firstname }}</span>
    </div>
  </div>

  <div class="info-title margin40top">
    <h3 class="bloc-title">Manager</h3>
  </div>
  <div class="fm-card p-d-flex p-flex-row">
    <div class="info">
      <h3 class="bloc-label">Nom :</h3>
      <span class="bloc-text">{{ hrReport.interviewer.lastname }}</span>
    </div>
    <div class="info">
      <h3 class="bloc-label">Prénom :</h3>
      <span class="bloc-text">{{ hrReport.interviewer.firstname }}</span>
    </div>
  </div>

  <ng-container *ngIf="hrReport.assignment">
    <div class="info-title margin40top">
      <h3 class="bloc-title">Projet</h3>
    </div>
    <div class="fm-card p-d-flex p-flex-row">
      <div class="info">
        <h3 class="bloc-label">Secteur :</h3>
        <span class="bloc-text">{{ hrReport.assignment.project?.customer?.name }}</span>
      </div>
      <div class="info">
        <h3 class="bloc-label">Projet :</h3>
        <span class="bloc-text">{{ hrReport.assignment.project?.name }}</span>
      </div>
    </div>
  </ng-container>

  <div class="fm-container">
    <div class="info-title margin40top">
      <h2 class="bloc-title"> 2. Etat (motivation/engagement)</h2>
      <div class="fm-line"></div>
    </div>
  </div>

  <form [formGroup]="formData">
    <div class="fm-container">
      <div class="rating">
        <h3>Satisfaction générale :</h3>
        <div>
          <p-rating [attr.data-testid]="'rha_field_rating'"
                    id="rating"
                    stars="4"
                    formControlName="rating"
                    [cancel]="false">
          </p-rating>
        </div>
      </div>
    </div>

    <div class="fm-container">
      <div class="fm-card gap1em">
        <div class="info-title">
          <h3 class="bloc-title">
            1/ Qu'est ce qui t'animes au quotidien ? Qu'aimes-tu le plus et/ou le moins en travaillant chez Créative ?
          </h3>
        </div>
        <p-editor [style]="editorStyle"
                  [maxlength]="freeAnswerMaxLength"
                  formControlName="motivationAssessmentPart1"
                  [readonly]="!editable">
        </p-editor>
      </div>
    </div>

    <div class="fm-container">
      <div class="fm-card gap1em">
        <div class="info-title">
          <h3 class="bloc-title">
            2/ Qu'est ce qui te fait rester chez Créative, quel est ton moteur ? Et si tu pouvais changer quelque chose,
            que changerais-tu ?
          </h3>
        </div>
        <p-editor [style]="editorStyle"
                  [maxlength]="freeAnswerMaxLength"
                  formControlName="motivationAssessmentPart2"
                  [readonly]="!editable">
        </p-editor>
      </div>
    </div>

    <div class="fm-container">
      <div class="fm-card gap1em">
        <div class="info-title">
          <h3 class="bloc-title">
            3/ Qu'est ce qui rendrait ton job encore plus valorisant et satisfaisant ? Qu'est-il indispensable à ton
            sens de retrouver dans ta prochaine mission ?
          </h3>
        </div>
        <p-editor [style]="editorStyle"
                  [maxlength]="freeAnswerMaxLength"
                  formControlName="motivationAssessmentPart3"
                  [readonly]="!editable">
        </p-editor>
      </div>
    </div>

    <div class="fm-container">
      <div class="fm-card gap1em">
        <div class="info-title">
          <h3 class="bloc-title">
            4/ Quels sont les talents / compétences / expertises que tu n'utilises pas dans ta fonction actuelle ?
          </h3>
        </div>
        <p-editor [style]="editorStyle"
                  [maxlength]="freeAnswerMaxLength"
                  formControlName="motivationAssessmentPart4"
                  [readonly]="!editable">
        </p-editor>
      </div>
    </div>

    <div class="fm-container">
      <div class="fm-card gap1em">
        <div class="info-title">
          <h3 class="bloc-title">
            5/ Qu'est-ce-qui pourrait t'inciter à quitter Créative ?
          </h3>
        </div>
        <p-editor [style]="editorStyle"
                  [maxlength]="freeAnswerMaxLength"
                  formControlName="motivationAssessmentPart5"
                  [readonly]="!editable">
        </p-editor>
      </div>
    </div>

    <div class="p-d-flex p-jc-end p-my-5">
      <app-fm-button *ngIf="editable" type="text-gradient" btnText="ENREGISTRER ET QUITTER" (click)="saveAndQuit()"
                     class="p-mr-3"></app-fm-button>
      <app-fm-button type="text-info" [btnText]="editable ? 'ANNULER' : 'QUITTER'" (click)="return()"></app-fm-button>
    </div>
  </form>
</div>
