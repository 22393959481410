import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CertificationIconService extends ApiService{

  protected prefix(): string {
    return 'certificationIcons';
  }

  getAll(): Observable<any> {
    return this.get();
  }
}
