import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { UserSkillService } from '../../../core/services/user-skill-services/user-skill.service';
import { UserSkill } from '../../../shared/models/user-skill';
import {
  AddProfileUserSkill,
  UpdateProfileUserSkill,
  MakeSnapshotProfileUserSkill,
  RestoreSnapshotProfileUserSkill,
  GetProfileUserSkillsByUserId,
  ClearProfileUserSkills,
  DeleteProfileUserSkill
} from './profile-skills.actions';


export class ProfileSkillStateModel {
  userSkills: UserSkill[];
  snapshot: UserSkill[];
  httpStatus: number;
}

@State<ProfileSkillStateModel>({
  name: 'profileUserSkills',
  defaults: {
    userSkills: [],
    snapshot: [],
    httpStatus: null
  }
})
@Injectable()
export class ProfileSkillState {
  constructor(private userSkillService: UserSkillService) {
  }

  @Selector()
  static getUserSkills(state: ProfileSkillStateModel) {
    return state.userSkills;
  }

  @Action(GetProfileUserSkillsByUserId)
  getProfileUserSkillsByUserId(
    {getState, setState}: StateContext<ProfileSkillStateModel>,
    {payload}: GetProfileUserSkillsByUserId
  ) {
    return this.userSkillService.getUserSkillsByUserId(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userSkills: result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddProfileUserSkill)
  add({getState, patchState}: StateContext<ProfileSkillStateModel>, {payload}: AddProfileUserSkill) {
    return this.userSkillService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      patchState({
        userSkills: [...state.userSkills, result]
      });
    }));
  }

  @Action(UpdateProfileUserSkill)
  update({getState, setState}: StateContext<ProfileSkillStateModel>, {payload}: UpdateProfileUserSkill) {
    return this.userSkillService.doPut(payload).pipe(
      tap((result) => {
        result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
        const state = getState();
        const userSkillsList = [...state.userSkills];
        const userSkillIndex = userSkillsList.findIndex(item => item.id === payload.id);
        userSkillsList[userSkillIndex] = result;
        setState({
          ...state,
          userSkills: userSkillsList,
        });
    }));
  }

  @Action(DeleteProfileUserSkill)
  delete({getState, setState}: StateContext<ProfileSkillStateModel>, {payload}: DeleteProfileUserSkill) {
    const state = getState();
    return this.userSkillService.doDelete(payload.id, {observe: 'response'}).pipe(tap((observe) => {
      if (observe.status === 200) {
        const filteredArray = state.userSkills.filter(item => item.id !== payload.id);
        setState({
          ...state,
          userSkills: filteredArray,
          httpStatus: 200,
        });
      } else {
        setState({
          ...state,
          httpStatus: 202,
        });
      }
    }));
  }

  @Action(MakeSnapshotProfileUserSkill)
  makeSnapShot({getState, setState}: StateContext<ProfileSkillStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.userSkills.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotProfileUserSkill)
  restoreSnapshot({getState, setState}: StateContext<ProfileSkillStateModel>) {
    const state = getState();
    setState({
      ...state,
      userSkills: state.snapshot.map(item => ({...item}))
    });
  }

  @Action(ClearProfileUserSkills)
  clearProfileUserSkills({getState, setState}: StateContext<ProfileSkillStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: [],
      userSkills: [],
    });
  }
}
