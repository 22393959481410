<div class="fm-container">
  <div class="fm-history">
    <h1 class="fm-titre">Historique des notifications et actions</h1>
    <div *ngFor="let date of dates">
      <div class="fm-day">
        <h3 class="fm-day-title" *ngIf="date === formattedTodayDate">Aujourd'hui - </h3>
        <h3 class="fm-day-title" *ngIf="date === formattedYesterdayDate">Hier - </h3>
        <h3 class="fm-date"> {{ date | date:'EEEE d MMMM y':'':'fr'}}</h3>
        <hr class="fm-hr">
        <div *ngFor="let item of list">
          <div *ngIf="(item.date | date:'yyyy-MM-dd':'') == (date | date:'yyyy-MM-dd')">
            <ul id="list">
              <li>
                <div class="fm-item-hours">
                  {{ item.date | date:'HH:mm':'fr'}}
                </div>
                <div class="fm-item-comment">
                  <span *ngIf="item.historyType === 'action'" [innerHTML]="item.comment"></span>
                  <span *ngIf="item.historyType === 'notification'">
                    <strong *ngIf="item.historyType === 'notification' && item.type !== 'annualReport'">{{item.emitter?.firstname}} {{item.emitter?.lastname}} </strong>
                    {{item.comment}}
                  </span>
                  <em *ngIf="item.historyType === 'action'" class="fm-icon-action"></em>
                  <em *ngIf="item.historyType === 'notification'" class="fm-icon-notification"></em>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
