<app-form-inline
  [listInput]="inputForm"
  [titleForm]="titleForm"
  (eventCreateItem)="handleCreateItem($event)"
  *ngIf="showAddForm"
  [saveDisabled]="disableCreationSave">
</app-form-inline>
<hr>
<app-datatable
  editMode="row"
  [data]="projects$ | async"
  [columns]="projectDataColumns"
  [emptyMessage]="emptyMessage"
  (eventDeleteItem)="handleDeleteItem($event)"
  (eventUpdateItem)="handleUpdateItem($event)"
  (eventSaveUpdateItem)="handleSaveUpdateItem($event)"
  (eventCancelEditItem)="handleCancelEditItem($event)">
</app-datatable>


<app-modal [header]="modal.header"
           [content]="modal.content"
           [type]="modal.type"
           [(display)]="showModal"
           (eventModalConfirm)="handleModalConfirm()">
</app-modal>
