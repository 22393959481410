import {Component, Input, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Constants} from '../../../../../shared/constants/constants';
import {ManagerRequestService} from '../../../../../core/services/manager-request-service/manager-request.service';
import {
  ManagerRequestState,
} from '../../../../../../stores/manager-requests/manager-request.state';
import {ManagerRequest} from '../../../../../shared/models/manager-request';
import {
  DeleteManagerRequest,
  GetAllManagerRequests, GetManagerRequest, UpdateManagerRequest,
} from '../../../../../../stores/manager-requests/manager-request.action';
import {MakeSnapshotRH} from '../../../../formationrh/store/rh.actions';
import {SoftSkill} from '../../../../../shared/models/soft-skill';
import {UserSoftSkill} from '../../../../../shared/models/user-soft-skill';

@Component({
  selector: 'app-view-request-manager',
  templateUrl: './view-request-manager.component.html',
  styleUrls: ['./view-request-manager.component.scss']
})
export class ViewRequestManagerComponent implements OnInit {

  @Select(ManagerRequestState.getAllManagerRequests) managerRequests$: Observable<ManagerRequest[]>;

  requestManagerDataColumns: any[];
  emptyMessage = 'Aucune demande';

  constructor(private messageService: MessageService,
              private managerRequestService: ManagerRequestService,
              private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(new GetAllManagerRequests());
    this.initDatatable();
  }

  initDatatable() {
    this.requestManagerDataColumns = [
      {
        type: 'text',
        field: 'manager',
        subField: 'fullname',
        label: 'Manager',
        font: 'regular',
        isStatic: true,
        sort: true,
        maxLength: Constants.NAME_SIZE_MAX,
      },
      {
        type: 'text',
        field: 'collaborator',
        subField: 'fullname',
        label: 'Collaborateur',
        font: 'regular',
        isStatic: true,
        sort: true,
        maxLength: Constants.NAME_SIZE_MAX,
      },
      {
        type: 'validation',
        field: 'validation',
        font: 'regular',
        sort: true,
      }
    ];
  }

  public handleSaveUpdateItem(managerRequest: ManagerRequest): void {
    this.store.dispatch(new UpdateManagerRequest(managerRequest)).subscribe( () => {
      this.store.dispatch(new GetAllManagerRequests());
    });
  }
}
