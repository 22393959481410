<div class="fm-title">
  <h1>7. Validation</h1>
</div>
<form [formGroup]="formData">
  <div class="fm-step">
    <div class="fm-container">
      <div class="info-title">
        <h2 class="bloc-title"> 1.1 Informations générales</h2>
        <div class="fm-line"></div>
        <i *ngIf="role === 'collaborator'" class="fm-icon-lock"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager < 4" class="fm-icon-edit"
          (click)="goToStepOne()"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager >= 4" class="fm-icon-lock"></i>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Date de l'entretien :</h3>
        <span *ngIf="interviewStartDate" class="bloc-text">{{ interviewStartDate | date: 'dd/MM/yyyy' }}</span>
        <span *ngIf="role === 'collaborator' && !interviewStartDate" class="bloc-text-span">Date de l'entretien non
          renseignée</span>
      </div>
    </div>
    <div class="fm-container">
      <div class="info-title">
        <h2 class="bloc-title"> 1.2 Collaborateur</h2>
        <div class="fm-line"></div>
        <i *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          class="fm-icon-edit" (click)="goToStepOne()"></i>
        <i *ngIf="role === 'collaborator' && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)"
          class="fm-icon-lock"></i>
        <i *ngIf="role === 'currentInterviewer'" class="fm-icon-lock"></i>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Nom :</h3>
        <span class="bloc-text">{{collaboratorName}}</span>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Prénom :</h3>
        <span class="bloc-text">{{collaboratorFirstname}}</span>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Ancienneté dans le groupe :</h3>
        <span *ngIf="role === 'collaborator' && companySeniority" class="bloc-text">{{companySeniority}}</span>
        <span
          *ngIf="role === 'currentInterviewer' && companySeniority && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)"
          class="bloc-text">{{companySeniority}}</span>
        <span
          *ngIf="role === 'currentInterviewer' && companySeniority && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          class="bloc-text-span">Ancienneté dans le groupe en attente de validation par le collaborateur</span>
        <span *ngIf="!companySeniority" class="bloc-text-span">Ancienneté dans le groupe non renseignée</span>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Poste :</h3>
        <span *ngIf="role === 'collaborator' && position" class="bloc-text">{{position}}</span>
        <span
          *ngIf="role === 'currentInterviewer' && position && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)"
          class="bloc-text">{{position}}</span>
        <span
          *ngIf="role === 'currentInterviewer' && position && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          class="bloc-text-span">Poste en
          attente de validation par le collaborateur</span>
        <span *ngIf="!position" class="bloc-text-span">Poste non renseigné</span>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Ancienneté dans le poste :</h3>
        <span *ngIf="role === 'collaborator' && positionSeniority" class="bloc-text">{{positionSeniority}}</span>
        <span
          *ngIf="role === 'currentInterviewer' && positionSeniority && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)"
          class="bloc-text">{{positionSeniority}}</span>
        <span
          *ngIf="role === 'currentInterviewer' && positionSeniority && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          class="bloc-text-span">Ancienneté dans le poste en attente de validation par le collaborateur</span>
        <span *ngIf="!positionSeniority" class="bloc-text-span">Ancienneté dans le poste non renseignée</span>
      </div>
    </div>
    <div class="fm-container">
      <div class="info-title">
        <h2 class="bloc-title"> 1.3 Manager</h2>
        <div class="fm-line"></div>
        <i class="fm-icon-lock"></i>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Nom :</h3>
        <span class="bloc-text">{{managerName}}</span>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Prénom :</h3>
        <span class="bloc-text">{{managerFirstname}}</span>
      </div>
    </div>
    <div class="fm-container">
      <div class="info-title">
        <h2 class="bloc-title"> 2. Bilan de l'année écoulée</h2>
        <div class="fm-line"></div>
        <i *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          class="fm-icon-edit" (click)="goToStepTwo()"></i>
        <i *ngIf="role === 'collaborator' && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)"
          class="fm-icon-lock"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager <= 4" class="fm-icon-edit"
          (click)="goToStepTwo()"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager === 5" class="fm-icon-lock"></i>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Collaborateur :</h3>
        <span *ngIf="role === 'collaborator' && collaboratorAssessment" class="bloc-text-comment"
          [innerHTML]="collaboratorAssessment"></span>
        <span
          *ngIf="role === 'currentInterviewer' && collaboratorAssessment && (workflowCollaborator >= 3 || isTooLate)"
          class="bloc-text-comment" [innerHTML]="collaboratorAssessment"></span>
        <span
          *ngIf="role === 'currentInterviewer' && collaboratorAssessment && (workflowCollaborator < 3 && !isTooLate)"
          class="bloc-text-span">{{emptyFieldCollaborator}}</span>
        <span *ngIf="!collaboratorAssessment" class="bloc-text-span">{{emptyFieldCollaborator}}</span>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Manager :</h3>
        <span *ngIf="role === 'currentInterviewer' && managerAssessment" class="bloc-text-comment"
          [innerHTML]="managerAssessment"></span>
        <span *ngIf="role === 'collaborator' && managerAssessment && workflowManager >= 4" class="bloc-text-comment"
          [innerHTML]="managerAssessment"></span>
        <span *ngIf="role === 'collaborator' && managerAssessment && workflowManager < 4"
          class="bloc-text-span">{{emptyFieldManager}}</span>
        <span *ngIf="!managerAssessment" class="bloc-text-span">{{emptyFieldManager}}</span>
      </div>
    </div>
    <div class="fm-container">
      <div class="info-title">
        <h2 class="bloc-title"> 3. Objectifs individuels</h2>
        <div class="fm-line"></div>
        <i *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          class="fm-icon-edit" (click)="goToStepThree()"></i>
        <i *ngIf="role === 'collaborator' && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)"
          class="fm-icon-lock"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager <= 4" class="fm-icon-edit"
          (click)="goToStepThree()"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager === 5" class="fm-icon-lock"></i>
      </div>
      <div *ngIf="workflowManager < 4" class="info mb-2">
        <h3 *ngIf="role === 'collaborator'" class="bloc-label-padding">Niveau d'atteinte des objectifs de l'année
          écoulée collaborateur :</h3>
        <h3 *ngIf="role === 'currentInterviewer' && (workflowCollaborator >= 3 || isTooLate)"
          class="bloc-label-padding">Niveau d'atteinte des objectifs de
          l'année
          écoulée collaborateur :</h3>
        <h3 *ngIf="role === 'currentInterviewer' && (workflowCollaborator < 3 && !isTooLate)" class="bloc-label">Niveau
          d'atteinte des objectifs de l'année
          écoulée
          collaborateur :</h3>
        <div *ngIf="role === 'collaborator'" class="fm-eae-noAction">
          <app-datatable [data]="collaboratorCurrentGoals" editMode="row"
            [columns]="currentGoalDataColumnsWithoutActions" [emptyMessage]="goalsEmptyMessage">
          </app-datatable>
        </div>
        <div *ngIf="role === 'currentInterviewer' && (workflowCollaborator >= 3 || isTooLate)" class="fm-eae-noAction">
          <app-datatable [data]="collaboratorCurrentGoals" editMode="row"
            [columns]="currentGoalDataColumnsWithoutActions" [emptyMessage]="goalsEmptyMessage">
          </app-datatable>
        </div>
        <span *ngIf="role === 'currentInterviewer' && (workflowCollaborator < 3 && !isTooLate)"
          class="bloc-text-span">Objectifs en attente de validation par le
          collaborateur</span>
      </div>
      <div class="info mb-2">
        <h3 *ngIf="role === 'collaborator' && workflowManager >= 4" class="bloc-label-padding">Niveau d'atteinte des
          objectifs de l'année écoulée manager :</h3>
        <h3 *ngIf="role === 'collaborator' && workflowManager < 4" class="bloc-label">Niveau d'atteinte des objectifs de
          l'année écoulée manager :</h3>
        <h3 *ngIf="role === 'currentInterviewer'" class="bloc-label-padding">Niveau d'atteinte des objectifs de l'année
          écoulée manager :</h3>
        <div *ngIf="role === 'collaborator' && workflowManager >= 4" class="fm-eae-noAction">
          <app-datatable [data]="managerCurrentGoals" editMode="row" [columns]="currentGoalDataColumnsWithoutActions"
            [emptyMessage]="goalsEmptyMessage">
          </app-datatable>
        </div>
        <span *ngIf="role === 'collaborator' && workflowManager < 4" class="bloc-text-span">Objectifs en attente de
          validation par le manager</span>
        <div *ngIf="role === 'currentInterviewer'" class="fm-eae-noAction">
          <app-datatable [data]="managerCurrentGoals" editMode="row" [columns]="currentGoalDataColumnsWithoutActions"
            [emptyMessage]="goalsEmptyMessage">
          </app-datatable>
        </div>
      </div>
      <div *ngIf="workflowManager < 4" class="info mb-2">
        <h3 *ngIf="role === 'collaborator'" class="bloc-label-padding">Objectifs pour l'année à venir collaborateur :
        </h3>
        <h3 *ngIf="role === 'currentInterviewer' && (workflowCollaborator >= 3 || isTooLate)"
          class="bloc-label-padding">Objectifs pour l'année à venir
          collaborateur :
        </h3>
        <h3 *ngIf="role === 'currentInterviewer' && (workflowCollaborator < 3 && !isTooLate)" class="bloc-label">
          Objectifs pour l'année à venir collaborateur :
        </h3>
        <div *ngIf="role === 'collaborator'" class="fm-eae-noAction">
          <app-datatable [data]="collaboratorNextGoals" editMode="row" [columns]="nextGoalDataColumnsWithoutActions"
            [emptyMessage]="goalsEmptyMessage">
          </app-datatable>
        </div>
        <div *ngIf="role === 'currentInterviewer' && (workflowCollaborator >= 3 || isTooLate)" class="fm-eae-noAction">
          <app-datatable [data]="collaboratorNextGoals" editMode="row" [columns]="nextGoalDataColumnsWithoutActions"
            [emptyMessage]="goalsEmptyMessage">
          </app-datatable>
        </div>
        <span *ngIf="role === 'currentInterviewer' && (workflowCollaborator < 3 && !isTooLate)"
          class="bloc-text-span">Objectifs en attente de validation par le
          collaborateur</span>
      </div>
      <div class="info mb-2">
        <h3 *ngIf="role === 'collaborator' && workflowManager >= 4" class="bloc-label-padding">Objectifs pour l'année à
          venir manager :</h3>
        <h3 *ngIf="role === 'collaborator' && workflowManager < 4" class="bloc-label">Objectifs pour l'année à venir
          manager :</h3>
        <h3 *ngIf="role === 'currentInterviewer'" class="bloc-label-padding">Objectifs pour l'année à venir manager :
        </h3>
        <div *ngIf="role === 'collaborator' && workflowManager >= 4" class="fm-eae-noAction">
          <app-datatable [data]="managerNextGoals" editMode="row" [columns]="nextGoalDataColumnsWithoutActions"
            [emptyMessage]="goalsEmptyMessage">
          </app-datatable>
        </div>
        <span *ngIf="role === 'collaborator' && workflowManager < 4" class="bloc-text-span">Objectifs en attente de
          validation par le manager</span>
        <div *ngIf="role === 'currentInterviewer'" class="fm-eae-noAction">
          <app-datatable [data]="managerNextGoals" editMode="row" [columns]="nextGoalDataColumnsWithoutActions"
            [emptyMessage]="goalsEmptyMessage">
          </app-datatable>
        </div>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Commentaire du collaborateur :</h3>
        <span *ngIf="role === 'collaborator' && collaboratorGoalComment" class="bloc-text-comment"
          [innerHTML]="collaboratorGoalComment"></span>
        <span
          *ngIf="role === 'currentInterviewer' && collaboratorGoalComment && (workflowCollaborator >= 3 || isTooLate)"
          class="bloc-text-comment" [innerHTML]="collaboratorGoalComment"></span>
        <span
          *ngIf="role === 'currentInterviewer' && collaboratorGoalComment && (workflowCollaborator < 3 && !isTooLate)"
          class="bloc-text-span">{{emptyFieldCollaborator}}</span>
        <span *ngIf="!collaboratorGoalComment" class="bloc-text-span">{{emptyFieldCollaborator}}</span>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Commentaire du manager :</h3>
        <span *ngIf="role === 'currentInterviewer' && managerGoalComment" class="bloc-text-comment"
          [innerHTML]="managerGoalComment"></span>
        <span *ngIf="role === 'collaborator' && managerGoalComment && workflowManager >= 4" class="bloc-text-comment"
          [innerHTML]="managerGoalComment"></span>
        <span *ngIf="role === 'collaborator' && managerGoalComment && workflowManager < 4"
          class="bloc-text-span">{{emptyFieldManager}}</span>
        <span *ngIf="!managerGoalComment" class="bloc-text-span">{{emptyFieldManager}}</span>
      </div>
    </div>
    <div class="fm-container">
      <div class="info-title">
        <h2 class="bloc-title"> 4. Compétences</h2>
        <div class="fm-line"></div>
        <i *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          class="fm-icon-edit" (click)="goToStepFour()"></i>
        <i *ngIf="role === 'collaborator' && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)"
          class="fm-icon-lock"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager <= 4" class="fm-icon-edit"
          (click)="goToStepFour()"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager === 5" class="fm-icon-lock"></i>
      </div>
      <div class="info mb-2">
        <h3 *ngIf="role === 'collaborator'" class="bloc-label-padding">Points forts collaborateur :</h3>
        <h3 *ngIf="role === 'currentInterviewer' && (workflowCollaborator >= 3 || isTooLate)"
          class="bloc-label-padding">Points forts collaborateur :</h3>
        <h3 *ngIf="role === 'currentInterviewer' && (workflowCollaborator < 3 && !isTooLate)" class="bloc-label">Points
          forts collaborateur :</h3>
        <div *ngIf="role === 'collaborator'" class="fm-eae-noAction">
          <app-datatable [data]="collaboratorSkillsStrength" editMode="row"
            [columns]="skillsStrengthDataColumnsWithoutActions" [emptyMessage]="skillsStrengthEmptyMessage">
          </app-datatable>
        </div>
        <div *ngIf="role === 'currentInterviewer' && (workflowCollaborator >= 3 || isTooLate)" class="fm-eae-noAction">
          <app-datatable [data]="collaboratorSkillsStrength" editMode="row"
            [columns]="skillsStrengthDataColumnsWithoutActions" [emptyMessage]="skillsStrengthEmptyMessage">
          </app-datatable>
        </div>
        <span *ngIf="role === 'currentInterviewer' && (workflowCollaborator < 3 && !isTooLate)"
          class="bloc-text-span">Points forts en attente de validation par
          le
          collaborateur</span>
      </div>
      <div class="info mb-2">
        <h3 *ngIf="role === 'collaborator' && workflowManager >= 4" class="bloc-label-padding">Points forts manager :
        </h3>
        <h3 *ngIf="role === 'collaborator' && workflowManager < 4" class="bloc-label">Points forts manager :</h3>
        <h3 *ngIf="role === 'currentInterviewer'" class="bloc-label-padding">Points forts manager :</h3>
        <div *ngIf="role === 'collaborator' && workflowManager >= 4" class="fm-eae-noAction">
          <app-datatable [data]="managerSkillsStrength" editMode="row"
            [columns]="skillsStrengthDataColumnsWithoutActions" [emptyMessage]="skillsStrengthEmptyMessage">
          </app-datatable>
        </div>
        <span *ngIf="role === 'collaborator' && workflowManager < 4" class="bloc-text-span">Points forts en attente de
          validation par le manager</span>
        <div *ngIf="role === 'currentInterviewer'" class="fm-eae-noAction">
          <app-datatable [data]="managerSkillsStrength" editMode="row"
            [columns]="skillsStrengthDataColumnsWithoutActions" [emptyMessage]="skillsStrengthEmptyMessage">
          </app-datatable>
        </div>
      </div>
      <div class="info mb-2">
        <h3 *ngIf="role === 'collaborator'" class="bloc-label-padding">Axes de développement collaborateur :</h3>
        <h3 *ngIf="role === 'currentInterviewer' && (workflowCollaborator >= 3 || isTooLate)"
          class="bloc-label-padding">Axes de développement collaborateur :
        </h3>
        <h3 *ngIf="role === 'currentInterviewer' && (workflowCollaborator < 3 && !isTooLate)" class="bloc-label">Axes de
          développement collaborateur :</h3>
        <div *ngIf="role === 'collaborator'" class="fm-eae-noAction">
          <app-datatable [data]="collaboratorSkillsImprovement" editMode="row"
            [columns]="skillsImprovementDataColumnsWithoutActions" [emptyMessage]="skillsImprovementEmptyMessage">
          </app-datatable>
        </div>
        <div *ngIf="role === 'currentInterviewer' && (workflowCollaborator >= 3 || isTooLate)" class="fm-eae-noAction">
          <app-datatable [data]="collaboratorSkillsImprovement" editMode="row"
            [columns]="skillsImprovementDataColumnsWithoutActions" [emptyMessage]="skillsImprovementEmptyMessage">
          </app-datatable>
        </div>
        <span *ngIf="role === 'currentInterviewer' && (workflowCollaborator < 3 && !isTooLate)"
          class="bloc-text-span">Axes de développement en attente de
          validation
          par le collaborateur</span>
      </div>
      <div class="info mb-2">
        <h3 *ngIf="role === 'collaborator' && workflowManager >= 4" class="bloc-label-padding">Axes de développement
          manager :</h3>
        <h3 *ngIf="role === 'collaborator' && workflowManager < 4" class="bloc-label">Axes de développement manager :
        </h3>
        <h3 *ngIf="role === 'currentInterviewer'" class="bloc-label-padding">Axes de développement manager :</h3>
        <div *ngIf="role === 'collaborator' && workflowManager >= 4" class="fm-eae-noAction">
          <app-datatable [data]="managerSkillsImprovement" editMode="row"
            [columns]="skillsImprovementDataColumnsWithoutActions" [emptyMessage]="skillsImprovementEmptyMessage">
          </app-datatable>
        </div>
        <span *ngIf="role === 'collaborator' && workflowManager < 4" class="bloc-text-span">Axes de développement en
          attente de validation par le manager</span>
        <div *ngIf="role === 'currentInterviewer'" class="fm-eae-noAction">
          <app-datatable [data]="managerSkillsImprovement" editMode="row"
            [columns]="skillsImprovementDataColumnsWithoutActions" [emptyMessage]="skillsImprovementEmptyMessage">
          </app-datatable>
        </div>
      </div>

      <div class="info mb-2">
        <h3 *ngIf="role === 'collaborator'" class="bloc-label-padding">Formations collaborateur :</h3>
        <h3 *ngIf="role === 'currentInterviewer' && (workflowCollaborator >= 3 || isTooLate)"
          class="bloc-label-padding">Formations collaborateur :
        </h3>
        <h3 *ngIf="role === 'currentInterviewer' && (workflowCollaborator < 3 && !isTooLate)" class="bloc-label">
          Formations collaborateur :</h3>
        <div *ngIf="role === 'collaborator'" class="fm-eae-noAction">
          <app-datatable [data]="collaboratorSkillsCourseRequest" editMode="row"
            [columns]="skillsCourseRequestDataColumnsWithoutActions" [emptyMessage]="skillsCourseRequestEmptyMessage">
          </app-datatable>
        </div>
        <div *ngIf="role === 'currentInterviewer' && (workflowCollaborator >= 3 || isTooLate)" class="fm-eae-noAction">
          <app-datatable [data]="collaboratorSkillsCourseRequest" editMode="row"
            [columns]="skillsCourseRequestDataColumnsWithoutActions" [emptyMessage]="skillsCourseRequestEmptyMessage">
          </app-datatable>
        </div>
        <span *ngIf="role === 'currentInterviewer' && (workflowCollaborator < 3 && !isTooLate)"
          class="bloc-text-span">Formations en attente de
          validation
          par le collaborateur</span>
      </div>
      <div class="info mb-2">
        <h3 *ngIf="role === 'collaborator' && workflowManager >= 4" class="bloc-label-padding">Formations
          manager :</h3>
        <h3 *ngIf="role === 'collaborator' && workflowManager < 4" class="bloc-label">Formations manager :
        </h3>
        <h3 *ngIf="role === 'currentInterviewer'" class="bloc-label-padding">Formations manager :</h3>
        <div *ngIf="role === 'collaborator' && workflowManager >= 4" class="fm-eae-noAction">
          <app-datatable [data]="managerSkillsCourseRequest" editMode="row"
            [columns]="skillsCourseRequestDataColumnsWithoutActions" [emptyMessage]="skillsCourseRequestEmptyMessage">
          </app-datatable>
        </div>
        <span *ngIf="role === 'collaborator' && workflowManager < 4" class="bloc-text-span">Formations en
          attente de validation par le manager</span>
        <div *ngIf="role === 'currentInterviewer'" class="fm-eae-noAction">
          <app-datatable [data]="managerSkillsCourseRequest" editMode="row"
            [columns]="skillsCourseRequestDataColumnsWithoutActions" [emptyMessage]="skillsCourseRequestEmptyMessage">
          </app-datatable>
        </div>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Commentaire du collaborateur :</h3>
        <span *ngIf="role === 'collaborator' && collaboratorSkillsComment" class="bloc-text-comment"
          [innerHTML]="collaboratorSkillsComment"></span>
        <span
          *ngIf="role === 'currentInterviewer' && collaboratorSkillsComment && (workflowCollaborator >= 3 || isTooLate)"
          class="bloc-text-comment" [innerHTML]="collaboratorSkillsComment"></span>
        <span
          *ngIf="role === 'currentInterviewer' && collaboratorSkillsComment && (workflowCollaborator < 3 && !isTooLate)"
          class="bloc-text-span">{{emptyFieldCollaborator}}</span>
        <span *ngIf="!collaboratorSkillsComment" class="bloc-text-span">{{emptyFieldCollaborator}}</span>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Commentaire du manager :</h3>
        <span *ngIf="role === 'currentInterviewer' && managerSkillsComment" class="bloc-text-comment"
          [innerHTML]="managerSkillsComment"></span>
        <span *ngIf="role === 'collaborator' && managerSkillsComment && workflowManager >= 4" class="bloc-text-comment"
          [innerHTML]="managerSkillsComment"></span>
        <span *ngIf="role === 'collaborator' && managerSkillsComment && workflowManager < 4"
          class="bloc-text-span">{{emptyFieldManager}}</span>
        <span *ngIf="!managerSkillsComment" class="bloc-text-span">{{emptyFieldManager}}</span>
      </div>
    </div>
    <div class="fm-container">
      <div class="info-title">
        <h2 class="bloc-title"> 5. Rémunération</h2>
        <div class="fm-line"></div>
        <i *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          class="fm-icon-edit" (click)="goToStepFive()"></i>
        <i *ngIf="role === 'collaborator' && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)"
          class="fm-icon-lock"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager <= 4" class="fm-icon-edit"
          (click)="goToStepFive()"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager === 5" class="fm-icon-lock"></i>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Collaborateur :</h3>
        <span *ngIf="role === 'collaborator' && collaboratorSalaryComment" class="bloc-text-comment"
          [innerHTML]="collaboratorSalaryComment"></span>
        <span
          *ngIf="role === 'currentInterviewer' && collaboratorSalaryComment && (workflowCollaborator >= 3 || isTooLate)"
          class="bloc-text-comment" [innerHTML]="collaboratorSalaryComment"></span>
        <span
          *ngIf="role === 'currentInterviewer' && collaboratorSalaryComment && (workflowCollaborator < 3 && !isTooLate)"
          class="bloc-text-span">{{emptyFieldCollaborator}}</span>
        <span *ngIf="!collaboratorSalaryComment" class="bloc-text-span">{{emptyFieldCollaborator}}</span>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Manager :</h3>
        <span *ngIf="role === 'currentInterviewer' && managerSalaryComment" class="bloc-text-comment"
          [innerHTML]="managerSalaryComment"></span>
        <span *ngIf="role === 'collaborator' && managerSalaryComment && workflowManager >= 4" class="bloc-text-comment"
          [innerHTML]="managerSalaryComment"></span>
        <span *ngIf="role === 'collaborator' && managerSalaryComment && workflowManager < 4"
          class="bloc-text-span">{{emptyFieldManager}}</span>
        <span *ngIf="!managerSalaryComment" class="bloc-text-span">{{emptyFieldManager}}</span>
      </div>
    </div>
    <div class="fm-container">
      <div class="info-title">
        <h2 class="bloc-title"> 6. Commentaire final</h2>
        <div class="fm-line"></div>
        <i *ngIf="role === 'collaborator' && workflowCollaborator < 3 && workflowManager < 4 && !isTooLate"
          class="fm-icon-edit" (click)="goToStepSix()"></i>
        <i *ngIf="role === 'collaborator' && (workflowCollaborator >= 3 || workflowManager >= 4 || isTooLate)"
          class="fm-icon-lock"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager <= 4" class="fm-icon-edit"
          (click)="goToStepSix()"></i>
        <i *ngIf="role === 'currentInterviewer' && workflowManager === 5" class="fm-icon-lock"></i>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Collaborateur :</h3>
        <span *ngIf="role === 'collaborator' && collaboratorFinalComment" class="bloc-text-comment"
          [innerHTML]="collaboratorFinalComment"></span>
        <span
          *ngIf="role === 'currentInterviewer' && collaboratorFinalComment && (workflowCollaborator >= 3 || isTooLate)"
          class="bloc-text-comment" [innerHTML]="collaboratorFinalComment"></span>
        <span
          *ngIf="role === 'currentInterviewer' && collaboratorFinalComment && (workflowCollaborator < 3 && !isTooLate)"
          class="bloc-text-span">{{emptyFieldCollaborator}}</span>
        <span *ngIf="!collaboratorSalaryComment" class="bloc-text-span">{{emptyFieldCollaborator}}</span>
      </div>
      <div class="info mb-2">
        <h3 class="bloc-label">Manager :</h3>
        <span *ngIf="role === 'currentInterviewer' && managerFinalComment" class="bloc-text-comment"
          [innerHTML]="managerFinalComment"></span>
        <span *ngIf="role === 'collaborator' && managerFinalComment && workflowManager >= 4" class="bloc-text-comment"
          [innerHTML]="managerFinalComment"></span>
        <span *ngIf="role === 'collaborator' && managerFinalComment && workflowManager < 4"
          class="bloc-text-span">{{emptyFieldManager}}</span>
        <span *ngIf="!managerFinalComment" class="bloc-text-span">{{emptyFieldManager}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="role === 'collaborator' && workflowManager >= 4" class="fm-step">
    <div class="fm-container">
      <div class="info">
        <span class="bloc-text fm-mr-2"> Synthèse de l'entretien finalisée le </span>
        <span class="bloc-validation fm-mr-2">{{ managerValidationDate | date: 'dd/MM/yyyy' }}</span>
        <span class="bloc-text fm-mr-2">par</span>
        <span class="bloc-validation fm-mr-2">{{managerFirstname}} {{managerName | uppercase}}</span>
      </div>
    </div>
    <div *ngIf="workflowManager === 4" class="info mb-4">
      <h3 class="bloc-text-comment w-250">Validation de la synthèse</h3>
      <span [ngStyle]="cssSwitchUnValidated" class="w-250 ml-4 mr-4 d-flex justify-content-end">Invalidée</span>
      <p-inputSwitch disabled="true" [(ngModel)]="collaboratorValidationStatus"
        formControlName="collaboratorValidationStatus"></p-inputSwitch>
      <span [ngStyle]="cssSwitchValidated" class="font-weight-bold w-250 ml-4">Validée</span>
      <i class="fm-icon-lock flex-grow-1 d-flex justify-content-end"></i>
    </div>
    <div class="mt-4" *ngIf="workflowManager === 4">
      <h3 class="bloc-text-comment w-250">Commentaire explicatif :</h3>
      <span *ngIf="collaboratorValidationComment" [innerHTML]="collaboratorValidationComment"></span>
      <span *ngIf="!collaboratorValidationComment">Aucun commentaire</span>
    </div>
  </div>

  <div *ngIf="role === 'currentInterviewer'" class="fm-step">
    <div class="fm-container">
      <div class="info">
        <span class="bloc-text fm-mr-2"> Synthèse de l'entretien finalisée le </span>
        <span class="bloc-validation fm-mr-2">{{ todayDate | date: 'dd/MM/yyyy' }}</span>
        <span class="bloc-text fm-mr-2">par</span>
        <span class="bloc-validation fm-mr-2">{{managerFirstname}} {{managerName | uppercase}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="role === 'collaborator' && workflowManager === 5" class="fm-step">
    <div class="fm-container">
      <div class="info mb-4">
        <h3 class="bloc-text-comment w-250">Validation de la synthèse</h3>
        <span [ngStyle]="cssSwitchUnValidated" class=" w-250 ml-4 mr-4">Je ne valide pas la synthèse</span>
        <p-inputSwitch [(ngModel)]="collaboratorValidationStatus" formControlName="collaboratorValidationStatus"
          (onChange)="handleChange($event)">
        </p-inputSwitch>
        <span [ngStyle]="cssSwitchValidated" class=" w-250 ml-4">Je valide la synthèse</span>
      </div>
      <div *ngIf="!collaboratorValidationStatus">
        <div class="fm-container">
          <div class="info mb-2">
            <h3 class="bloc-text-comment w-250">Commentaire explicatif</h3>
          </div>
          <p-editor [style]="{'height':'200px', 'box-shadow': '0 0 10px #1432461A'}" [maxlength]="freeAnswerMaxLength"
            formControlName="collaboratorValidationComment" [(ngModel)]="collaboratorValidationComment"
            [placeholder]="placeholder" (focusout)="handleChange($event)"></p-editor>
        </div>
      </div>
      <div *ngIf="collaboratorValidationStatus" class="info">
        <span class="bloc-text fm-mr-2"> Synthèse de l'entretien finalisée le </span>
        <span class="bloc-validation fm-mr-2">{{ todayDate | date: 'dd/MM/yyyy' }}</span>
        <span class="bloc-text fm-mr-2">par</span>
        <span class="bloc-validation fm-mr-2">{{collaboratorFirstname}} {{collaboratorName | uppercase}}</span>
      </div>
    </div>
  </div>
  <div *ngIf="role === 'currentInterviewer'" class="fm-step disabled-color">
    <div class="fm-container">
      <div class="info mb-4">
        <h3 class="bloc-text-comment w-250">Validation de la synthèse</h3>
        <span [ngStyle]="cssSwitchUnValidated" class="w-250 ml-4 mr-4 d-flex justify-content-end">Invalidée</span>
        <p-inputSwitch disabled="true" [(ngModel)]="collaboratorValidationStatus"
          formControlName="collaboratorValidationStatus"></p-inputSwitch>
        <span [ngStyle]="cssSwitchValidated" class="font-weight-bold w-250 ml-4">Validée</span>
        <i class="fm-icon-lock flex-grow-1 d-flex justify-content-end"></i>
      </div>
      <div class="info">
        <span class="bloc-text fm-mr-2">Synthèse de l'entretien finalisée le </span>
        <span *ngIf="!collaboratorValidationStatus" class="bloc-text-bold fm-mr-2">JJ/MM/AAAA</span>
        <span *ngIf="collaboratorValidationStatus" class="bloc-text-bold fm-mr-2">{{todayDate | date:
          'dd/MM/yyyy'}}</span>
        <span class="bloc-text fm-mr-2">par</span>
        <span *ngIf="!collaboratorValidationStatus" class="bloc-text-bold fm-mr-2">Prénom et Nom du collaborateur</span>
        <span *ngIf="collaboratorValidationStatus" class="bloc-text-bold fm-mr-2">{{collaboratorFirstname}}
          {{collaboratorName | uppercase}}</span>
      </div>
      <div class="mt-4">
        <h3 class="bloc-text-comment w-250">Commentaire explicatif :</h3>
        <span *ngIf="collaboratorValidationComment" [innerHTML]="collaboratorValidationComment"></span>
        <span *ngIf="!collaboratorValidationComment">Aucun commentaire</span>
      </div>
    </div>
  </div>
</form>