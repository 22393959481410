import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { TeamStateModel } from '../../../../store/team.state';
import { TeamCertificationState, TeamCertificationStateModel } from '../../../../store/team-certifications.state';
import {
  AddUserCertification,
  ClearUserCertifications,
  DeleteUserCertification,
  GetUserCertificationsByUserId,
  MakeSnapshotUserCertification,
  RestoreSnapshotUserCertification,
  UpdateUserCertification
} from '../../../../store/team-certifications.actions';
import { MessageService, SelectItem } from 'primeng/api';
import { CertificationService } from '../../../../../../core/services/certification-services/certification.service';
import { FormInlineComponent } from '../../../../../../shared/components/form-inline/form-inline.component';
import { UserCertification } from '../../../../../../shared/models/user-certification';
import { Certification } from '../../../../../../shared/models/certification';
import { Interest } from '../../../../../../shared/models/interest';
import { Constants } from '../../../../../../shared/constants/constants';

@Component({
  selector: 'app-view-certifications',
  templateUrl: './view-certifications.component.html',
  styleUrls: ['./view-certifications.component.scss']
})
export class ViewCertificationsComponent implements OnInit, OnDestroy {
  @Select(TeamCertificationState.getUserCertifications)
  public userCertifications$: Observable<UserCertification[]>;

  @ViewChild(FormInlineComponent)
  private formUserCertification: FormInlineComponent;

  @Input()
  private interests: Interest[] = [];

  @Input()
  private interestsOptions: SelectItem[] = [];

  public modal: any = {};
  public showModal = false;
  public inputForm: any = [];
  public showAddForm: boolean;
  public disableCreationSave = false;
  public tableColumns: any[];
  public emptyMessage = 'Aucune certification n\'est enregistée';

  private teamState: TeamStateModel;
  private userCertificationState: TeamCertificationStateModel;
  private userCertifications: UserCertification[];
  private itemToDelete: UserCertification;
  private certifications: Certification[] = [];
  private defaultInterestsOptions: SelectItem[] = [];
  private certificationsSubscription: Subscription;

  public constructor(
    private messageService: MessageService,
    private certificationService: CertificationService,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.teamState = this.store.snapshot().team;
    this.store.dispatch(new GetUserCertificationsByUserId(this.teamState.currentTeamUser.id));

    this.interestsOptions.map(
      item => this.defaultInterestsOptions.push(item)
    );

    this.certificationsSubscription = this.certificationService.getAll().subscribe(
      result => this.certifications = result,
      e => this.displayError(e.status),
      () => {
        this.initDatatable();
        this.initForm();
      }
    );
  }

  private initDatatable(): void {
    this.tableColumns = [
      {
        type: 'icon',
        field: 'icon',
        subField: 'name',
        isStatic: true,
        sort: false,
      },
      {
        type: 'text',
        field: 'certification',
        subField: 'name',
        label: 'Certification',
        isStatic: true,
        font: 'semibold',
        colSize: 'fm-certification-size',
        sort: false,
      },
      {
        type: 'select',
        field: 'interest',
        subField: 'name',
        label: 'Intérêt',
        icon: 'interest',
        options: this.interestsOptions,
        optionIcon: true,
        optionOnlyIcon: true,
        clearable: true,
        colSize: 'fm-interest-size',
        sort: false
      },
      {
        type: 'text',
        field: 'comment',
        label: 'Commentaire',
        icon: 'level',
        font: 'regular',
        sort: false,
        maxLength: Constants.COMMENT_SIZE_MAX
      },
      {
        type: 'calendar',
        field: 'startDate',
        label: 'Date',
        font: 'regular',
        colSize: 'fm-date-size',
        sort: false
      },
      {
        type: 'calendar',
        field: 'endDate',
        label: 'Validité',
        font: 'regular',
        colSize: 'fm-date-size',
        sort: false
      },
      { type: 'action', action: 'edit', width: '8%' },
      { type: 'action', action: 'delete', width: '10%' },
    ];
  }

  private initForm(): void {
    this.inputForm = [
      {
        key: 'nameCertification',
        typeForm: 'dropdown',
        filter: true,
        fieldForm: 'certification',
        labelForm: 'Certification',
        requiredForm: true,
        placeHolder: 'Certification',
        optionsForm: this.certifications,
      },
      {
        key: 'nameInterest',
        typeForm: 'dropdown',
        fieldForm: 'interest',
        labelForm: 'Intérêt',
        optionsForm: this.interests,
        placeHolder: 'Intérêt',
        icon: true,
        onlyIcon: true
      },
      {
        key: 'nameComment',
        typeForm: 'text',
        fieldForm: 'comment',
        labelForm: 'Commentaire',
        placeHolder: 'Commentaire',
        maxLength: Constants.COMMENT_SIZE_MAX,
      },
      {
        key: 'nameDateStart',
        typeForm: 'calendar',
        fieldForm: 'startDate',
        labelForm: 'Date',
        requiredForm: true,
        placeHolder: 'Date',
      },
      {
        key: 'nameDateEnd',
        typeForm: 'calendar',
        fieldForm: 'endDate',
        labelForm: 'Validité',
        placeHolder: 'Validité',
      },
    ];
  }

  public toggleShowAddForm(): void {
    this.showAddForm = !this.showAddForm;
  }

  public handleCreateItem(userCertification): void {
    if (!this.errorsValidation(userCertification)) {
      userCertification.user = this.teamState.currentTeamUser;
      userCertification.startDate = userCertification.startDate ? new Date(userCertification.startDate).toISOString() : null;
      userCertification.endDate = userCertification.endDate ? new Date(userCertification.endDate).toISOString() : null;
      this.store.dispatch(new AddUserCertification(userCertification)).subscribe(
        () => {
          this.displayMessage('create');
          this.formUserCertification.handleClearForm();
        },
        (e) => this.displayError(e.status)
      );
    }
  }

  public handleDeleteItem(id: number): void {
    this.userCertificationState = this.store.snapshot().userCertifications;
    this.itemToDelete = this.userCertificationState.userCertifications.find((item) => item.id === id);
    this.displayModal('delete');
  }

  private setInterestOptions(userCertification: UserCertification): void {
    // Interest options
    const indexInterest = this.interests.findIndex(item => item.id === userCertification.interest?.id);
    if (indexInterest === -1) {
      // Put blank interest
      this.interestsOptions.unshift({label: null, value: null, icon: null});
    } else {
      const iconNameInterest = this.interestsOptions[indexInterest].icon;
      // Delete current Interest value of the interests options with indexInterest help
      this.interestsOptions.splice(indexInterest, 1);
      // Put current Interest value at first Interests options place
      this.interestsOptions.unshift({ label: userCertification.interest?.name, value: userCertification.interest, icon: iconNameInterest });
    }
  }

  private resetInterestsOptions(): void {
    const length = this.interestsOptions.length;
    this.interestsOptions.splice(0, length);
    this.defaultInterestsOptions.map(
      item => this.interestsOptions.push(item)
    );
  }

  public handleUpdateItem(userCertification: UserCertification): void {
    this.disableCreationSave = true;
    this.store.dispatch(new MakeSnapshotUserCertification());

    this.resetInterestsOptions();
    this.setInterestOptions(userCertification);
  }

  public handleSaveUpdateItem(userCertification: UserCertification): void {
    if (!this.errorsValidation(userCertification)) {
      this.store.dispatch(new UpdateUserCertification(userCertification)).subscribe(
        () => {
          this.displayMessage('update');
          this.disableCreationSave = false;
        },
        (e) => {
          this.displayError(e.status);
          this.disableCreationSave = false;
        }
      );
    }
  }

  public handleCancelEditItem(): void {
    this.store.dispatch(new RestoreSnapshotUserCertification());
    this.disableCreationSave = false;
  }

  public handleModalConfirm(): void {
    this.deleteItem();
  }

  private deleteItem(): void {
    this.store.dispatch(new DeleteUserCertification(this.itemToDelete)).subscribe(
      () => this.displayMessage('delete'),
      (e) => this.displayError(e.status)
    );
  }

  /**
   * Forms validation (create and update)
   * @param userCertification UserCertification
   * @return boolean
   */
  private errorsValidation(userCertification: UserCertification): boolean {
    let errors = false;
    if (!userCertification.certification) {
      this.displayError('validation_certification');
      errors = true;
    }
    if (!userCertification.startDate) {
      this.displayError('validation_startDate');
      errors = true;
    }
    if (userCertification.startDate
      && userCertification.endDate
      && new Date(userCertification.endDate).getTime() < new Date(userCertification.startDate).getTime()){
      this.displayError('validation_endDate_lower');
      errors = true;
    }
    return errors;
  }

  private displayModal(type: string): void {
    switch (type) {
      case 'delete': {
        this.modal.header = 'Suppression';
        this.modal.content = 'Souhaitez-vous confirmer la suppression ?';
        this.modal.type = 'confirm';
        break;
      }
      default: {
        break;
      }
    }
    this.showModal = true;
  }

  private displayMessage(type: string): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (type) {
      case 'create': {
        severity = 'success';
        summary = 'Création';
        detail = 'La certification a bien été sauvegardée.';
        break;
      }
      case 'update': {
        severity = 'success';
        summary = 'Edition';
        detail = 'La certification a bien été sauvegardée.';
        break;
      }
      case 'delete': {
        severity = 'success';
        summary = 'Suppression';
        detail = 'La certification a bien été supprimée.';
        break;
      }
    }
    this.messageService.add({severity, summary, detail});
  }

  private displayError(e: any): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (e) {
      case 'validation_certification':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner une certification.';
        break;
      case 'validation_startDate':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner une date.';
        break;
      case 'validation_endDate_lower':
        severity = 'error';
        summary = 'Formulaire incomplet';
        detail = 'Veuillez sélectionner une validité supérieure ou égale à la date.';
        break;
      case 401:
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Veuillez vous reconnecter.';
        break;
      case 409:
        severity = 'error';
        summary = 'Doublon';
        detail = 'Cette certification est déjà attribuée à cet utilisateur.';
        break;
      case 501:
      default:
        severity = 'error';
        summary = 'Erreur serveur';
        detail = 'Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

  public ngOnDestroy(): void {
    this.resetInterestsOptions();
    this.certificationsSubscription.unsubscribe();
    this.store.dispatch(new ClearUserCertifications());
  }
}
