import {Injectable} from '@angular/core';
import {
  EventTypes,
  LogLevel,
  OidcConfigService,
  OidcSecurityService,
  PublicEventsService,
} from 'angular-auth-oidc-client';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {UserService} from '../services/user-services/user.service';
import {EnvService} from '../env/env.service';

/** Configure authentication at startup */
export const configureAuthentication = (
  authenticationService: AuthenticationService
) => () =>
  authenticationService.configure().then(() => authenticationService.init());

/**
 * Authentication service
 *
 * Connect to OpenID Connect Authentication server (OIDC) configured in
 * environment
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor(
    private readonly oidcConfigService: OidcConfigService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly eventService: PublicEventsService,
    private envService: EnvService,
    private userService: UserService,
  ) {}

  /**
   * Configure authentication service
   */
  async configure() {
    const { stsServer, clientId, scope } = this.envService.auth;
    return this.oidcConfigService.withConfig({
      stsServer,
      clientId,
      scope,
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      responseType: 'code',
      silentRenew: true,
      useRefreshToken: true,
      logLevel: LogLevel.Error,
    });
  }

  /**
   * Init authentication service
   *
   * Must be called after configure()
   */
  init() {
    // WARNING: must subscribe to checkAuth() else the service won't work
    this.oidcSecurityService
      .checkAuth()
      .subscribe((isAuthenticated) =>
        console.log('app authenticated', isAuthenticated)
      );
    this.eventService
      .registerForEvents()
      .pipe(
        filter((notification) => notification.type === EventTypes.ConfigLoaded)
      )
      .subscribe((config) => {
        console.log('ConfigLoaded', config ? true : config);
      });
  }

  login(): void {
    this.oidcSecurityService.authorize();
  }

  logout(): void{
    this.oidcSecurityService.logoff();
  }

  getAuthData() {
    return this.oidcSecurityService.getPayloadFromIdToken();
  }

  getUserRoles() {
    return this.getAuthData().roles;
  }

  getCurrentUser() {
    const authData = this.oidcSecurityService.getPayloadFromIdToken();
    return this.userService.getCurrentUser(authData.email).pipe(
      map(user => user )
    );
  }

  getHomeUser() {
    const authData = this.oidcSecurityService.getPayloadFromIdToken();
    return this.userService.getCurrentUser(authData.email, 'home');
  }

  getProfileUser() {
    const authData = this.oidcSecurityService.getPayloadFromIdToken();
    return this.userService.getCurrentUser(authData.email, 'profile');
  }

  getAnnualReportUser() {
    const authData = this.oidcSecurityService.getPayloadFromIdToken();
    return this.userService.getCurrentUser(authData.email, 'annualReport');
  }

  /**
   * Observable for authentication status
   */
  isAuthenticated(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$;
  }

  /**
   * Get current token
   */
  getToken(): string {
    return this.oidcSecurityService.getToken();
  }
}
