<div>
    <app-navbar *ngIf="this.userService.isNavBarVisible && isAuthenticated$ | async"></app-navbar>
    <div class="placeholder">
        <main class="container">
            <router-outlet></router-outlet>
            <p-toast position="top-right">
                <ng-template let-message pTemplate="message">
                    <div class="p-d-flex">
                        <div *ngIf="message.severity === 'error'" class="fm-severity fm-severity-error">
                            <i class="fm-icon-alert fm-toast-icon"></i>
                        </div>
                        <div *ngIf="message.severity === 'success'" class="fm-severity fm-severity-success">
                            <i class="fm-icon-success fm-toast-icon"></i>
                        </div>
                        <div class="fm-toast-content">
                            <span *ngIf="message.severity === 'error'" class="fm-toast-summary-error">{{message.summary}}</span>
                            <span *ngIf="message.severity === 'success'" class="fm-toast-summary-success">{{message.summary}}</span>
                            <span class="fm-toast-text">{{message.detail}}</span>
                        </div>
                    </div>
                </ng-template>
            </p-toast>
        </main>
        <app-footer *ngIf="this.userService.isNavBarVisible && isAuthenticated$ | async"></app-footer>
    </div>
</div>