import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetTeamUserProfiles,
  AddTeamUserProfile,
  DeleteTeamUserProfile,
  UpdateTeamUserProfile,
  GetTeamUserProfilesByUserId,
  MakeSnapshotTeamUserProfile,
  RestoreSnapshotTeamUserProfile,
  ClearTeamUserProfiles
} from './team-profiles.actions';
import { UserProfileService } from '../../../core/services/user-profile-services/user-profile.service';
import { UserProfile } from '../../../shared/models/user-profile';

export class TeamProfileStateModel {
  userProfiles: UserProfile[];
  snapshot: UserProfile[];
}

@State<TeamProfileStateModel>({
  name: 'userProfiles',
  defaults: {
    userProfiles: [],
    snapshot: []
  }
})
@Injectable()
export class TeamProfileState {

  constructor(private userProfileService: UserProfileService) {
  }

  @Selector()
  static getUserProfiles(state: TeamProfileStateModel) {
    return state.userProfiles;
  }

  @Action(GetTeamUserProfiles)
  getAll({getState, setState}: StateContext<TeamProfileStateModel>) {
    return this.userProfileService.getAll().pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userProfiles: result,
      });
    }));
  }

  @Action(GetTeamUserProfilesByUserId)
  getUserProfilesByUserId({getState, setState}: StateContext<TeamProfileStateModel>, {payload}: GetTeamUserProfilesByUserId) {
    return this.userProfileService.getUserProfilesDetailsByUserId(payload).pipe(tap((result) => {
      const state = getState();
      setState({
        ...state,
        userProfiles: result.map( r => {
          r.archivingDate = r.archivingDate ? new Date(r.archivingDate) : null;
          return r;
        }),
      });
    }));
  }

  @Action(AddTeamUserProfile)
  add({getState, patchState}: StateContext<TeamProfileStateModel>, {payload}: AddTeamUserProfile) {
    return this.userProfileService.doPost(payload).pipe(tap((result) => {
      const state = getState();
      patchState({
        userProfiles: [...state.userProfiles, result]
      });
    }));
  }

  @Action(UpdateTeamUserProfile)
  update({getState, setState}: StateContext<TeamProfileStateModel>, {payload}: UpdateTeamUserProfile) {
    return this.userProfileService.doPut(payload).pipe(
      tap((result) => {
        result.archivingDate = result.archivingDate ? new Date(result.archivingDate) : null;
        const state = getState();
        const userProfilesList = [...state.userProfiles];
        const userProfileIndex = userProfilesList.findIndex(item => item.id === payload.id);
        userProfilesList[userProfileIndex] = result;
        setState({
          ...state,
          userProfiles: userProfilesList,
        });
    }));
  }

  @Action(DeleteTeamUserProfile)
  delete({getState, setState}: StateContext<TeamProfileStateModel>, {payload}: DeleteTeamUserProfile) {
    const state = getState();
    return this.userProfileService.doDelete(payload.id).pipe(tap(() => {
      const filteredArray = state.userProfiles.filter(item => item.id !== payload.id);
      setState({
        ...state,
        userProfiles: filteredArray,
      });
    }));
  }

  @Action(MakeSnapshotTeamUserProfile)
  makeSnapShot({getState, setState}: StateContext<TeamProfileStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: state.userProfiles.map(item => ({...item}))
    });
  }

  @Action(RestoreSnapshotTeamUserProfile)
  restoreSnapshot({getState, setState}: StateContext<TeamProfileStateModel>) {
    const state = getState();
    setState({
      ...state,
      userProfiles: state.snapshot.map(item => ({...item}))
    });
  }

  @Action(ClearTeamUserProfiles)
  clearTeamUserProfiles({getState, setState}: StateContext<TeamProfileStateModel>) {
    const state = getState();
    setState({
      ...state,
      snapshot: [],
      userProfiles: [],
    });
  }
}
