import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../core/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { LoginInfo } from '../loginInfo';
import { UserService } from 'src/app/core/services/user-services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input()
  error: string;

  info = new LoginInfo();

  isButtonVisible = true;

  authSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.authSubscription = this.authenticationService.isAuthenticated().subscribe(
      (authenticated) => {
        if (authenticated){
          const auth = this.authenticationService.getAuthData();
          this.userExistInDb(auth.email).subscribe((info) => {
            if (info.length > 0){
              this.router.navigateByUrl('/home');
            }
            else{
              this.userService.showNavBar = false;
              this.info = new LoginInfo({
                info: 'Vous ne disposez pas encore d\'un compte dans l\'application follow\'minds. Veuillez vous rapprocher d\'un administrateur.'
              });
              this.isButtonVisible = false;
            }
          });
        }
      }
    );
  }

  handleConnect() {
    this.authenticationService.login();
  }

  logout() {
    this.authenticationService.logout();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  userExistInDb(userName){
    return this.userService.getUsersByCriteria(userName);
  }
}
