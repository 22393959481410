import { Injectable } from '@angular/core';
import { RoleType } from '../../../shared/models/role';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  private permissions = [
    {
      path: 'home',
      roles: [RoleType.ROLE_COLLABORATOR, RoleType.ROLE_MANAGER, RoleType.ROLE_ADMIN]
    },
    {
      path: 'profile',
      roles: [RoleType.ROLE_COLLABORATOR, RoleType.ROLE_MANAGER, RoleType.ROLE_ADMIN]
    },
    {
      path: 'activity',
      roles: [RoleType.ROLE_COLLABORATOR, RoleType.ROLE_MANAGER, RoleType.ROLE_ADMIN]
    },
    {
      path: 'team',
      roles: [RoleType.ROLE_MANAGER]
    },
    {
      path: 'directory',
      roles: [RoleType.ROLE_COLLABORATOR, RoleType.ROLE_MANAGER, RoleType.ROLE_ADMIN]
    },
    {
      path: 'parameters',
      roles: [RoleType.ROLE_ADMIN]
    },
    {
      path: 'notification',
      roles: [RoleType.ROLE_COLLABORATOR, RoleType.ROLE_MANAGER, RoleType.ROLE_ADMIN]
    },
    {
      path: 'faq',
      roles: [RoleType.ROLE_COLLABORATOR, RoleType.ROLE_MANAGER, RoleType.ROLE_ADMIN]
    }
    ,
    {
      path: 'annualreport',
      roles: [RoleType.ROLE_ADMIN]
    }
  ];

  public isPathAllowedForUser(path: string, userRoles: any[]): boolean {
    let allowed = false;
    this.permissions.map(permission => {
      userRoles.forEach(role => {
        if (path === permission.path && permission.roles.includes(role)) { allowed = true; }
      });
    });

    return allowed;
  }
}
