<div class="fm-team-view-header">
  <div class="fm-summary">
    <h1>Équipe</h1>
    <p>(du {{firstWeekday | date:'dd/MM/yyyy' }} au {{lastWeekDay | date:'dd/MM/yyyy'}})</p>
  </div>
  <div class="fm-top-search">
    <input pInputText [attr.data-testid]="'summary_members_input_search'" type="text" placeholder="Recherche" (keyup)="handleSearch($event)" class="fm-search"/>
  </div>
  <div class="fm-top-switch">
    <p-inputSwitch
      [attr.data-testid]="'summary_members_toggle_validation'"
      (onChange)="handleValidateQualifications($event)"
      [ngModel]="validateQualifications"
    ></p-inputSwitch>
    <span class="fm-label">
        Dossier(s) à valider
        <span class="fm-label fm-icon-blue">
          ({{countUsersToValidate$ | async}})
        </span>
      </span>
  </div>
  <div *ngIf="hasManagerRole" class="fm-action">
    <p class="fm-label">{{ titleAddUser }}</p>
    <!--this is where you can add a user in your team on the page-->
    <app-fm-button
      [attr.data-testid]="'summary_members_button_showHideFormAddMember'"
      [type]="showAddUser ? 'hide' : 'show'"
      (onClick)="toggleShowAddUser()">
    </app-fm-button>
  </div>
</div>

<app-select-collaborator
  *ngIf="showAddUser"
  [attr.data-testid]="'summary_members_form_addMember'"
  [title]="titleAddUser"
  [results]="results"
  (eventFilterUsers)="handleList($event)"
  (eventResultSelect)="handleSelection($event)"
  [emptyMessage]="emptyMessage"
></app-select-collaborator>

<hr>

<app-fm-table *ngIf="currentManager$ | async as currentManager"
              [attr.data-testid]="'summary_members_list'"
              module="team"
              [data]="users$ | async"
              [columns]="tableColumns"
              [currentManager]="currentManager"
              (eventDetailsUser)="handleProfileUser($event)"
              (eventConsultbackground)="handleBackgroundUser($event)"
              (eventGetOutUser)="handleGetOutUser($event)"
              [loading]="loading$ | async"
></app-fm-table>

<app-modal
  [header]="modal.header"
  [content]="modal.content"
  [type]="modal.type"
  [(display)]="showModal"
  closable="true"
  (eventModalConfirm)="handleModalConfirm()"
  (eventModalCancel)="handleModalCancel()"
></app-modal>
