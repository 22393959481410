
import { Skill } from './../../../shared/models/skill';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class UserSkillService extends ApiService {

  protected prefix(): string {
    return 'userSkills';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: any): Observable<any> {
    return this.post(content);
  }

  doPut(content: any): Observable<any> {
    return this.put(content);
  }

  doDelete(id: number, options: any = {}): Observable<any> {
    return this.delete(id, options);
  }

  userSkillExists(skill: Skill): Observable<any> {
    return this.get('skill/' + skill.id).pipe(
      map(items => items.length > 0)
    );
  }

  getUserSkillsByUserId(userId: number): Observable<any> {
    return this.get('/user/' + userId);
  }

  mergeSkills(ids: String[]): Observable<any> {
    let idFrom = ids[0];
    let idTo = ids[1];
    return this.get('skill/merge/from/' + idFrom + '/to/' + idTo);
  }
}
