import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {
  ClearDirectoryUser,
  ClearDirectoryUsers,
} from '../../store/directory-users.actions';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent implements OnInit, OnDestroy {

  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearDirectoryUser());
    this.store.dispatch(new ClearDirectoryUsers());
  }
}
