import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {NavigationStart, Router} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {
  ClearPendingAnnualReport,
  UpdateAnnualReport,
  ValidateAnnualReport,
} from '../../../../stores/reports/report.action';
import {ReportState} from '../../../../stores/reports/report.state';
import {Observable, Subscription} from 'rxjs';
import {AnnualReport} from '../../models/annual-report';
import {AnnualReportService} from '../../../core/services/annual-report-service/annual-report.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AnnualReportInput} from '../../models/annual-report-input';
import * as moment from 'moment';

@Component({
  selector: 'app-fm-annual-report',
  templateUrl: './fm-annual-report.component.html',
  styleUrls: ['./fm-annual-report.component.scss']
})
export class FmAnnualReportComponent implements OnInit, OnDestroy {
  @Select(ReportState.getPendingAnnualReport) pendingAnnualReport$: Observable<AnnualReport>;
  @Select(ReportState.getAnnualReport) annualReport$: Observable<AnnualReport>;
  @Select(ReportState.getRole) role$: Observable<string>;

  @Input()
  annualReportsUrl: string;

  @Input()
  cancelUrl: string;

  workflowCollaborator: number;
  workflowManager: number;
  isSummary = false;
  formData: FormGroup;
  activeIndex: number;
  routerLink: string;
  items: MenuItem [] = [];
  title = 'Entretien annuel d\'évaluation';
  pendingAnnualReport: AnnualReport;
  displayIncompleteDialog = false;
  remainingRequiredFields = [];
  isPreparationValidated: boolean;
  collaboratorValidationStatus: boolean;
  isManagerValidated: boolean;
  companySeniority: string;
  position: string;
  positionSeniority: string;
  role: string;
  pagesInfos: any = [
    {
      pageTitle: 'Informations Générales',
      pageName: 'stepone'
    },
    {
      pageTitle: 'Bilan de l\'année',
      pageName: 'steptwo'
    },
    {
      pageTitle: 'Objectifs individuels',
      pageName: 'stepthree'
    },
    {
      pageTitle: 'Compétences',
      pageName: 'stepfour'
    },
    {
      pageTitle: 'Rémunération',
      pageName: 'stepfive'
    },
    {
      pageTitle: 'Commentaire Final',
      pageName: 'stepsix'
    },
    {
      pageTitle: 'Validation',
      pageName: 'stepseven'
    }
  ];

  pendingAnnualReportSubscription: Subscription;
  roleSubscription: Subscription;
  pendingValidation = false;
  annualReportSubscription: Subscription;

  collaboratorModalConfirmContent = '';
  showCollaboratorModalConfirm = false;
  managerModalConfirmContent = '';
  showManagerModalConfirm = false;
  modalDialogContent = '';
  showModalDialog = false;
  isTooLate: boolean;

  constructor(private router: Router,
              private store: Store,
              public annualReportService: AnnualReportService,
              private formBuilder: FormBuilder,
  ) {
    this.router.events.subscribe(
      (event: NavigationStart) => {
        this.routerLink = event.url;
        this.setActiveIndex(this.routerLink);
      },
      (e) => console.log(e)
    );
    this.formData = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.pagesInfos.forEach(pageInfo => {
      this.items.push({
        label: pageInfo.pageTitle,
        routerLink: this.annualReportsUrl + pageInfo.pageName});
    });
    this.setActiveIndex(this.routerLink);

    this.roleSubscription = this.role$.subscribe((role) => {
      this.role = role;
      if (role === 'collaborator') {
        this.formData.addControl('companySeniority', new FormControl(null, Validators.required));
        this.formData.addControl('position', new FormControl(null, Validators.required));
        this.formData.addControl('positionSeniority', new FormControl(null, Validators.required));
      } else {
        this.formData.addControl('interviewStartDate', new FormControl(null, Validators.required));
      }
    });

    this.pendingAnnualReportSubscription = this.pendingAnnualReport$.subscribe(pendingAnnualReport => {
      this.pendingAnnualReport = pendingAnnualReport;
      if (pendingAnnualReport){
        this.workflowCollaborator = pendingAnnualReport.workflowCollaborator;
        this.workflowManager = pendingAnnualReport.workflowManager;
        this.isPreparationValidated = !!pendingAnnualReport.preparationValidationDate;
        this.isManagerValidated = !!pendingAnnualReport.managerValidationDate;
        this.collaboratorValidationStatus = pendingAnnualReport.collaboratorValidationStatus;
        this.isTooLate = this.annualReportService.isTooLate(pendingAnnualReport.interviewStartDate);
      }
    });

    // TODO : check if always used
    // this.annualReportSubscription = this.annualReport$.subscribe(() => {
    //   if (this.pendingValidation) {
    //     this.pendingValidation = false;
    //     this.cancelForm();
    //   }
    // });
  }

  // Has to be set in constructor AND ngOnInit to navigate from application buttons OR browser buttons
  setActiveIndex(routerLink: string){
    if (routerLink !== undefined && routerLink?.indexOf('summary') === -1) {
      this.items.forEach((item, activeIndex) => {
        if (item.routerLink === routerLink) {
          this.activeIndex = activeIndex;
        }
      });
    } else if (routerLink?.indexOf('summary') > -1) {
      this.isSummary = true;
    }
  }

  navigateTo(activeIndex: number){
    return this.router.navigateByUrl(this.annualReportsUrl + this.pagesInfos[activeIndex].pageName);
  }

  nextPage(el: HTMLElement) {
    this.activeIndex += 1;
    if ((this.role === 'collaborator' && this.pendingAnnualReport.workflowCollaborator < 3 && this.pendingAnnualReport.workflowManager < 4)
          || (this.role === 'currentInterviewer' && this.pendingAnnualReport.workflowManager < 5)) {
      this.store.dispatch(new UpdateAnnualReport(this.role)).subscribe();
    }
    this.navigateTo(this.activeIndex);
    el.scrollIntoView();
  }

  previousPage(el: HTMLElement) {
    this.activeIndex -= 1;
    if ((this.role === 'collaborator' && this.pendingAnnualReport.workflowCollaborator < 3 && this.pendingAnnualReport.workflowManager < 4)
          || (this.role === 'currentInterviewer' && this.pendingAnnualReport.workflowManager < 5)) {
      this.store.dispatch(new UpdateAnnualReport(this.role)).subscribe();
    }
    this.navigateTo(this.activeIndex);
    el.scrollIntoView();
  }

  handleStep(event){
    this.activeIndex = event;
    if ((this.role === 'collaborator' && this.pendingAnnualReport.workflowCollaborator < 3 && this.pendingAnnualReport.workflowManager < 4)
          || (this.role === 'currentInterviewer' && this.pendingAnnualReport.workflowManager < 5)) {
      this.store.dispatch(new UpdateAnnualReport(this.role)).subscribe();
    }
    this.navigateTo(this.activeIndex);
  }

  cancelForm() {
    this.store.dispatch(new ClearPendingAnnualReport());
    this.router.navigateByUrl(this.cancelUrl);
  }

  saveAndQuit(){
    this.store.dispatch(new UpdateAnnualReport(this.role)).subscribe(() => this.cancelForm());
  }

  checkRequiredField(){
    this.remainingRequiredFields = [];
    this.formData.patchValue({
      companySeniority: this.pendingAnnualReport.companySeniority,
      position: this.pendingAnnualReport.position,
      positionSeniority: this.pendingAnnualReport.positionSeniority,
      interviewStartDate: this.pendingAnnualReport.interviewStartDate
    });
    if (this.role === 'collaborator'){
      this.formData.patchValue({ collaboratorValidationComment: this.pendingAnnualReport.collaboratorValidationComment});
    }
    Object.keys(this.formData.controls).forEach(field => {
      if (this.formData.get(field).errors) {
        this.remainingRequiredFields.push(AnnualReportInput[field]);
      }
    });
  }

  validateForm(workflow: string, role: string){
    this.collaboratorModalConfirmContent = '';
    this.managerModalConfirmContent = '';

    switch (workflow){
      case 'preparation':
        if (role === 'collaborator') {
          this.checkRequiredField();
          if (this.formData.valid) {
            this.collaboratorModalConfirmContent = 'Aucune modification ne sera possible après la validation de la préparation';
            this.showCollaboratorModalConfirm = true;
          } else {
            this.modalDialogContent = '<p>Vous devez remplir les champs suivants: </p>';
            this.modalDialogContent += '<ul>';
            this.remainingRequiredFields.forEach(requiredFiled => {
              this.modalDialogContent += '<li>' + requiredFiled + '</li>';
            });
            this.modalDialogContent += '<ul>';
            this.showModalDialog = true;
          }
        }
        break;
      case 'validation':
        if (role === 'collaborator') {
          this.checkRequiredField();
          if (this.formData.valid) {
            this.store.dispatch(new ValidateAnnualReport('validation', 'collaborator'));
            this.cancelForm();
          } else {
            if (this.workflowManager !== 5) {
              this.modalDialogContent = '<p>Vous devez remplir les champs suivants: </p>';
              this.modalDialogContent += '<ul>';
              this.remainingRequiredFields.forEach(requiredFiled => {
                this.modalDialogContent += '<li>' + requiredFiled + '</li>';
              });
              this.modalDialogContent += '<ul>';
              this.showModalDialog = true;
            } else {
              this.store.dispatch(new ValidateAnnualReport('validation', 'collaborator'));
              this.cancelForm();
            }
          }
        }
        if (role === 'currentInterviewer') {
          this.checkRequiredField();
          if (this.formData.valid) {
            const interviewDate = new Date (this.pendingAnnualReport.interviewStartDate);
            const todayDate = new Date();
            if (moment(todayDate).isBefore(interviewDate)) {
              this.managerModalConfirmContent += 'Attention : la date de l’entretien n’est pas encore passée. Si vous confirmez la validation de la synthèse, vous ne pourrez plus modifier l\'entretien.';
            } else {
              this.managerModalConfirmContent += 'Attention : si vous confirmez la validation de la synthèse, vous ne pourrez plus modifier l\'entretien.';
            }
            this.showManagerModalConfirm = true;
          } else {
            this.modalDialogContent = '<p>Vous devez remplir les champs suivants: </p>';
            this.modalDialogContent += '<ul>';
            this.remainingRequiredFields.forEach(requiredFiled => {
              this.modalDialogContent += '<li>' + requiredFiled + '</li>';
            });
            this.modalDialogContent += '<ul>';
            this.showModalDialog = true;
          }
        }
        break;
      case 'invalidation' :
        this.store.dispatch(new ValidateAnnualReport(workflow, role));
        this.cancelForm();
        break;
    }
    this.pendingValidation = true;
  }

  handleCollaboratorModalConfirm() {
    this.showCollaboratorModalConfirm = false;
    this.store.dispatch(new ValidateAnnualReport('preparation', 'collaborator'));
    this.cancelForm();
  }

  handleManagerModalConfirm() {
    this.showManagerModalConfirm = false;
    this.store.dispatch(new ValidateAnnualReport('validation', 'currentInterviewer'));
    this.cancelForm();
  }

  ngOnDestroy(): void {
    this.pendingAnnualReportSubscription.unsubscribe();
    this.roleSubscription.unsubscribe();
    // this.annualReportSubscription.unsubscribe();
  }
}
