import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss'],
})
export class SelectDateComponent implements OnInit {
  dateFormGroup: FormGroup;
  dateEntree: string;
  dateSortie: string;
  data: string;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.dateFormGroup = this.formBuilder.group({
      items: this.formBuilder.array([
        this.formBuilder.group({
          dateEntree: '',
          dateSortie: '',
        }),
      ]),
    });
  }

  get items(): FormArray {
    return this.dateFormGroup.get('items') as FormArray;
  }

  newItem(): FormGroup {
    return this.formBuilder.group({
      dateEntree: '',
      dateSortie: '',
    });
  }

  addDuration() {
    this.items.push(this.newItem());
  }

  removeDuration(i: number) {
    this.items.removeAt(i);
  }

  onSubmit() {
    console.log(this.dateFormGroup);
  }
}
