import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';
import {ManagerRequest} from '../../../shared/models/manager-request';
import {map} from 'rxjs/operators';
import {Customer} from '../../../shared/models/customer';

@Injectable({
  providedIn: 'root'
})
export class ManagerRequestService extends ApiService{

  protected prefix(): string {
    return 'managerRequests';
  }

  getAll(): Observable<any> {
    return this.get();
  }

  getOne(id: number): Observable<any> {
    return this.get(id);
  }

  doPost(content: ManagerRequest): Observable<any> {
    return this.post(content);
  }

  doPut(content: ManagerRequest): Observable<any> {
    return this.put(content);
  }

  doDelete(content: ManagerRequest): Observable<any> {
    return this.delete(content.id);
  }

  managerRequestExists(managerRequest): Observable<any> {
    return this.get('managerRequest/' + managerRequest.id).pipe(
      map(items => items.length > 0)
    );
  }
}
