import {Component, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {MessageService, SelectItem} from 'primeng/api';
import {Interest} from '../../../../shared/models/interest';
import {Level} from '../../../../shared/models/level';
import {forkJoin, Subscription} from 'rxjs';
import {Constants} from '../../../../shared/constants/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {InterestService} from '../../../../core/services/interest-services/interest.service';
import {LevelService} from '../../../../core/services/level-services/level.service';
import {Store} from '@ngxs/store';
import {ClearCurrentProfileUser, SetProfileIsUpdateView, UpdateCurrentProfileUser} from '../../store/profile.actions';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription;
  private iconLevel = Constants.LEVEL_ICONS;
  public module = 'profile';
  public updating = false;
  public interests: Interest[] = [];
  public levels: Level[] = [];
  public interestsOptions: SelectItem[] = [];
  public levelsOptions: SelectItem[] = [];

  public selectedItem: string;
  public selectItems: SelectItem[] = [
    { label: 'Mes compétences métiers', value: 'Mes compétences métiers' },
    // { label: 'Mes compétences transverses', value: 'Mes compétences transverses' },
    { label: 'Mes formations et certifications', value: 'Mes formations et certifications' },
  ];



  public constructor(
    private activatedRoute: ActivatedRoute,
    private interestService: InterestService,
    private levelService: LevelService,
    private store: Store,
    private router: Router,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {

    const interests$ = this.interestService.getAll();
    const levels$ = this.levelService.getAll();
    this.subscriptions = forkJoin({
      interests$,
      levels$,
    }).subscribe({
      next: value => {
        value.interests$.map(item => {
          this.interestsOptions.push({
            label: item.name,
            value: {...item},
            icon: 'fm-icon-' + item.name
          });
          item.icon = 'fm-icon-' + item.name;
          this.interests.push({...item});
        });

        value.levels$.map(item => {
          this.levelsOptions.push({
            label: item.name,
            value: {...item},
            icon: this.iconLevel[item.value - 1]
          });
          item.icon = this.iconLevel[item.value - 1];
          this.levels.push(item);
        });
      },
      error: e => this.displayError(e),
      complete: () => this.initDropdown()
    });
  }

  private initDropdown(): void {
    this.selectedItem = 'Mes compétences métiers';
  }

  public handleBack(): void {
    this.store.dispatch(new UpdateCurrentProfileUser())
      .subscribe(() => {
        this.store.dispatch(new SetProfileIsUpdateView(false));
        this.router.navigateByUrl('profile/show');
      });
  }

  private displayError(error: any): void {
    let severity: string;
    let summary: string;
    let detail: string;
    switch (error) {
      case (401):
        severity = 'error';
        summary = 'Connexion perdue';
        detail = 'Connexion perdue.';
        break;
      default:
        severity = 'error';
        summary = 'Erreur';
        detail = 'Une erreur est survenue. Veuillez contacter l\'administrateur.';
        break;
    }
    this.messageService.add({severity, summary, detail});
  }

   handleShowPageRequest(): void {
    this.router.navigateByUrl('profile/request');
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearCurrentProfileUser());
  }
}
