<div class="row">
  <form [formGroup]="form" (submit)="onSubmit()">
    <div class="shadow-sm p-3 mb-5 bg-white rounded" style="width: 1000px;">
      <table class="table table-borderless">
        <tr>
          <th class="font-header" style="width: 569.6px;">{{ name }}</th>
          <th class="font-header" style="width: 20px;">=</th>
          <th class="font-header" style="width: 20px;">-</th>
          <th class="font-header" style="width: 20px;">+</th>
          <th class="font-header" style="width: 20px;">++</th>
          <th class="font-header" style="width: 20px;">NA</th>
          <th style="text-align: right;">
            <button type="submit" class="btn btn-primary btn-sm mr-2">
              <i class="pi pi-check"></i>
            </button>
            <button class="btn btn-secondary btn-sm">
              <i class="pi pi-trash"></i>
            </button>
          </th>
        </tr>
        <tr *ngFor="let quality of qualityData; index as i">
          <td>{{ quality.name }}</td>
          <td>
            <input
              type="radio"
              name="{{ quality.name }}"
              value="Mal"
              formControlName="{{ quality.name }}"
            />
          </td>
          <td>
            <input
              type="radio"
              name="{{ quality.name }}"
              value="Passable"
              formControlName="{{ quality.name }}"
            />
          </td>
          <td>
            <input
              type="radio"
              name="{{ quality.name }}"
              value="ABien"
              formControlName="{{ quality.name }}"
            />
          </td>
          <td>
            <input
              type="radio"
              name="{{ quality.name }}"
              value="TBien"
              formControlName="{{ quality.name }}"
            />
          </td>
          <td>
            <input
              type="radio"
              name="{{ quality.name }}"
              value="NA"
              formControlName="{{ quality.name }}"
            />
          </td>
          <td></td>
        </tr>
      </table>
    </div>
  </form>
</div>
